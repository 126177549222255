import {AppState, ForeignUser} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeCommentReportForm} from "../../Redux/Actions";
import CommentReportForm from "../Presentation/CommentReportForm/CommentReportForm";

function mapStateToProps(state: AppState) {
    return {
        isOpen: state.commentReportOpen,
        commentUser: state.focusedComment?.commentUser as ForeignUser,
        commentId: state.focusedComment?.commentId ?? "",
        replyId: state.focusedComment?.replyId,
        profileId: state.focusedComment?.profileId ?? ""
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        closeCommentReportForm
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentReportForm);
