import React from "react";
import styles from "./MobileEditingPopover.module.scss";
import {HistoryEditManager} from "./HistoryEditManager";
import Button, {ButtonColor} from "../Button/Button";

interface Props {
    editManager: HistoryEditManager;
    cancel: () => void;
    save: () => void;
}

interface State {

}

export default class MobileEditingPopover extends React.Component<Props, State> {
    render() {
        return (
            <div className={styles.Popover}>
                <div className={styles.State}>Items to delete: {this.props.editManager.size}</div>
                <div className={styles.Buttons}>
                    <Button color={ButtonColor.Regular} onClick={this.props.cancel}><span>Cancel</span></Button>
                    <Button color={ButtonColor.Primary} onClick={this.props.save}><span>Save</span></Button>
                </div>
            </div>
        )
    }
}
