import "./EmailVerify.scss";
import React from "react";
import queryString from 'query-string';
import {Redirect} from "react-router-dom";
import {BarLoader} from "react-spinners";
import {emailVerify} from "../../../API/Calls";
import Button, {ButtonColor} from "../Button/Button";
import Card from "../Card/Card";
import {titleManager} from "../../../Title";

interface EmailVerifyProps {
    /**
     * URL parameters
     */
    location: { search: string },
}

interface EmailVerifyState {
    loading: boolean;
    errorMessage: string;
    successful: boolean;
    code: string | undefined;
    email: string;
    username: string;
}

export default class EmailVerify extends React.Component<EmailVerifyProps, EmailVerifyState> {

    constructor(props: Readonly<EmailVerifyProps>) {
        super(props);

        const code = queryString.parse(this.props.location.search).code as string | undefined;
        this.state = {
            code,
            successful: false,
            errorMessage: "",
            loading: true,
            email: "",
            username: "",
        }
    }

    render() {
        if (!this.state.code) {
            return <Redirect to={"/"}/>
        }
        return (
            <div className={"Container EmailVerifyPage"}>
                <Card className={"EmailVerifyCard"}>
                    {
                        !this.state.loading ? "" :
                            <div className={"BarLoader"}><BarLoader width={"100%"} color={"#46C3D3"}/></div>
                    }
                    <div className={"Content"}>
                        <div className={"Title"}>Email Verification</div>
                        {
                            this.state.loading ? <div className={"LoadingEmpty"}/> :
                                <div className={"Description"}>
                                    {
                                        this.state.successful ?
                                            <div className={"Success"}>
                                                <p>
                                                    Your email, <strong>{this.state.email}</strong>, has been verified.
                                                </p>
                                                <Button color={ButtonColor.Primary} link={{to: "/user/" + this.state.username}}>
                                                    OK, go back to wavy.fm
                                                </Button>
                                            </div>
                                            :
                                            <div className={"Error"}>
                                                <p className={"Red"}>
                                                    <strong>An error occurred while verifying your email.</strong>
                                                </p>
                                                <p>{this.state.errorMessage}</p>
                                            </div>
                                    }
                                </div>
                        }
                    </div>
                </Card>
            </div>
        )
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Email Verification");

        if (!this.state.code) {
            return;
        }

        emailVerify(this.state.code)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    return resp.json().then(json => {
                        if (json.description) {
                            throw Error(json.description);
                        } else {
                            throw Error("An unexpected error happened on our side. We're looking into it, sorry for the inconvenience!")
                        }
                    });
                }
            })
            .then(resp => {
                this.setState({
                    successful: true,
                    errorMessage: "",
                    loading: false,
                    email: resp.email,
                    username: resp.username
                })
            })
            .catch(e => {
                console.error(e);
                this.setState({
                    successful: false,
                    errorMessage: e.message,
                    loading: false,
                    email: "",
                    username: ""
                });
            });
    }
}
