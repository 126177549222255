import React from "react";
import {Countries} from "../../../Countries";
import Button, {ButtonColor} from "../Button/Button";
import {
    editPublicBiography,
    updatePrivacySettingsPublicDiscord,
    updatePrivacySettingsPublicSpotify
} from "../../../API/Calls";
import {UserState} from "../../../Redux/Types";
import styles from "./ProfileEditForm.module.scss";
import {EventEmitter} from "events";

interface Props {
    authUser: UserState,
    reload$: EventEmitter,

    setUserPrivacy: (privacy: object) => void;
}

interface State {
    /**
     * Value of the bio edit field.
     */
    formBio: string,
    /**
     * Value of the country edit field.
     */
    formCountry: string,
    /**
     * Value of the Twitter edit field.
     */
    formTwitter: string,
    /**
     * Value of the Instagram edit field.
     */
    formInstagram: string,
    /**
     * Whether the edit form is currently loading.
     */
    formLoading: boolean
}

enum Field {
    Bio = "Bio",
    Country = "Country",
    Twitter = "Twitter",
    Instagram = "Instagram"
}

export default class ProfileEditForm extends React.Component<Props, State> {
    /**
     * HTML reference to the bio input field.
     */
    private bioRef: React.RefObject<HTMLTextAreaElement> = React.createRef();
    private static readonly INSTAGRAM_REGEX = /(^@?(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$)/
    private static readonly TWITTER_REGEX = /(^@?(\w){1,15}$)/

    private initialDiscord: boolean;
    private initialSpotify: boolean;

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            formBio: "",
            formCountry: "",
            formInstagram: "",
            formTwitter: "",
            formLoading: false
        };

        this.onSubmitEditForm = this.onSubmitEditForm.bind(this);
        this.handleEditChange = this.handleEditChange.bind(this);
        this.toggleSpotify = this.toggleSpotify.bind(this);
        this.toggleDiscord = this.toggleDiscord.bind(this);
        this.cancel = this.cancel.bind(this);

        this.initialDiscord = props.authUser.privacySettings.publicDiscord;
        this.initialSpotify = props.authUser.privacySettings.publicSpotify;
    }

    render() {
        return (
            <form onSubmit={this.onSubmitEditForm}>
                <div className={styles.Form}>
                    <div className={"FormField"}>
                        <label htmlFor={Field.Bio}>Bio</label>
                        <textarea name={Field.Bio} maxLength={100} ref={this.bioRef}
                                  value={this.state.formBio}
                                  onChange={this.handleEditChange}/>
                    </div>
                    <div className={"FormField"}>
                        <label htmlFor={Field.Country}>Location</label>
                        <select name={Field.Country} value={this.state.formCountry}
                                onChange={this.handleEditChange}>
                            <option value={""}>(No Location)</option>
                            {Countries.map((country, index) =>
                                <option value={country.id}
                                        key={index}>{country.name}</option>)}
                        </select>
                    </div>
                    <div className={"FormField"}>
                        <label htmlFor={Field.Twitter}>Twitter</label>
                        <input name={Field.Twitter} maxLength={16}
                               type={"text"}
                               value={this.state.formTwitter}
                               placeholder={"Username only"}
                               onChange={this.handleEditChange}/>
                    </div>
                    <div className={"FormField"}>
                        <label htmlFor={Field.Instagram}>Instagram</label>
                        <input name={Field.Instagram} maxLength={31}
                               value={this.state.formInstagram}
                               type={"text"}
                               placeholder={"Username only"}
                               onChange={this.handleEditChange}/>
                    </div>
                    {
                        !this.props.authUser.spotifyId ? "" :
                            <div className={"FormField " + styles.CheckboxField} onClick={this.toggleSpotify}>
                                <label>Show Spotify account</label>
                                <div className={styles.SpotifyCheckbox} role={"checkbox"}
                                     aria-checked={this.props.authUser.privacySettings.publicSpotify}/>
                            </div>
                    }
                    {
                        !this.props.authUser.discordId ? "" :
                            <div className={"FormField " + styles.CheckboxField} onClick={this.toggleDiscord}>
                                <label>Show Discord account</label>
                                <div className={styles.DiscordCheckbox} role={"checkbox"}
                                     aria-checked={this.props.authUser.privacySettings.publicDiscord}/>
                            </div>
                    }
                    <div className={styles.SubmitRegion}>
                        <Button color={ButtonColor.Light} className={styles.CancelButton}
                                onClick={this.cancel}
                                disabled={this.state.formLoading}>Cancel</Button>
                        <Button color={ButtonColor.Primary} form={true}
                                disabled={this.state.formLoading}>Save</Button>
                    </div>
                </div>
            </form>
        )
    }

    componentDidMount(): void {
        if (this.bioRef.current) {
            this.bioRef.current.style.height = "";
            this.bioRef.current.style.height = (this.bioRef.current.scrollHeight + 2) + "px";
        }

        this.setState({
            formBio: this.props.authUser.bio || "",
            formCountry: this.props.authUser.countryCode || "",
            formInstagram: this.props.authUser.instagram || "",
            formTwitter: this.props.authUser.twitter || ""
        });
    }

    onSubmitEditForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (!this.props.authUser) {
            return;
        }

        let {formBio, formCountry, formInstagram, formTwitter} = this.state;
        if (!formInstagram || !this.validateInstagram(formInstagram)) formInstagram = "";
        if (!formTwitter || !this.validateTwitter(formTwitter)) formTwitter = "";

        this.setState({formLoading: true});
        editPublicBiography(formBio, formCountry, formInstagram, formTwitter)
            .then(resp => {
                if (resp.ok) {
                    return resp.text();
                } else {
                    return resp.json().then(json => {
                        throw Error(json.description);
                    })
                }
            })
            .then(() => this.props.reload$.emit("reload"))
            .catch(console.error)
    }

    handleEditChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
        const target = event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

        switch (target.name) {
            case Field.Bio:
                this.setState({formBio: target.value});
                target.style.height = "";
                target.style.height = (target.scrollHeight + 2) + "px";
                break;
            case Field.Country:
                this.setState({formCountry: target.value});
                break;
            case Field.Twitter:
                this.setState({formTwitter: target.value});
                if (target.value && !this.validateTwitter(target.value)) {
                    target.setCustomValidity("Invalid Twitter username")
                } else {
                    target.setCustomValidity("");
                }
                break;
            case Field.Instagram:
                this.setState({formInstagram: target.value});
                if (target.value && !this.validateInstagram(target.value)) {
                    target.setCustomValidity("Invalid Instagram username")
                } else {
                    target.setCustomValidity("");
                }
                break;
        }
    }

    toggleSpotify() {
        const newValue = !this.props.authUser.privacySettings.publicSpotify;
        this.props.setUserPrivacy({publicSpotify: newValue});
        updatePrivacySettingsPublicSpotify(newValue)
            .then()
            .catch(console.error);
    }

    toggleDiscord() {
        const newValue = !this.props.authUser.privacySettings.publicDiscord;
        this.props.setUserPrivacy({publicDiscord: newValue});
        updatePrivacySettingsPublicDiscord(newValue)
            .then()
            .catch(console.error);
    }

    validateTwitter(username: string) {
        return ProfileEditForm.TWITTER_REGEX.test(username);
    }

    validateInstagram(username: string) {
        return ProfileEditForm.INSTAGRAM_REGEX.test(username);
    }

    cancel() {
        if (this.initialSpotify !== this.props.authUser.privacySettings.publicSpotify) {
            this.toggleSpotify();
        }
        if (this.initialDiscord !== this.props.authUser.privacySettings.publicDiscord) {
            this.toggleDiscord();
        }
        this.props.reload$.emit("reload");
    }
}

