import {AppState, ForeignUser, isOwnProfile} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ProfileContent from "../Presentation/ProfileContent/ProfileContent";
import {loadProfileFriendCount, loadProfileRecentListens} from "../../Redux/Actions";

function mapStateToProps(state: AppState) {
    return {
        profile: state.profilePage.profile as ForeignUser,
        isLoggedIn: state.user !== null && state.profileReady,
        isOwnProfile: isOwnProfile(state),
        hasListenHistory: (state.profilePage.recentListens?.total_tracks ?? 0) > 0 || !!state.profilePage.recentListens?.live,
        hasNonLiveHistory:  (state.profilePage.recentListens?.total_tracks ?? 0) > 0,
        hasLoadedHistory: !!state.profilePage.recentListens,
        reload$: state.profilePage.reload$
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        loadProfileRecentListens,
        loadProfileFriendCount
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContent);
