import React from "react";
import {ForeignUser, RenderedComment, UserState} from "../../../Redux/Types";
import styles from "./CommentView.module.scss";
import Avatar from "../Avatar/Avatar";
import {Link} from "react-router-dom";
import {formatMinutes} from "../../../Times";
import CommentBox from "../CommentBox/CommentBox";
import CommentViewIcon, {Icons} from "./CommentViewIcon";
import MenuIcon from "../Icons/MenuIcon/MenuIcon";
import Card from "../Card/Card";
import PopupMenu from "../PopupMenu/PopupMenu";
import {deleteComment, deleteReply, likeComment, likeReply, unlikeComment, unlikeReply} from "../../../API/Calls";
import CommentViewMenuIcon, {Icons as MenuIcons} from "./CommentViewMenuIcon";
import CardModal from "../CardModal/CardModal";
import Button, {ButtonColor} from "../Button/Button";
import {Comment, CommentText} from "../../../API/Models";
import CommentLikesModal from "../CommentLikesModal/CommentLikesModal";
import UserBadge from "../UserBadge/UserBadge";

interface Props {
    profile: ForeignUser;
    authUser: UserState | null;
    comment: RenderedComment;
    parentComment?: RenderedComment;
    canWrite: boolean;

    onOpenReply?: (replyingTo: RenderedComment) => void;
    onReply?: (value: CommentText, comment: Comment, replyingTo: ForeignUser) => void;
    onDelete?: (comment: RenderedComment, view: CommentView) => void;
    openReportForm: (commentUser: ForeignUser, profileId: string, commentId: string, replyId?: string) => void;
}

interface State {
    isMenuOpen: boolean;
    likeDisabled: boolean;
    highlighted: boolean;
    deleted: boolean;
    deleteConfirmation: boolean;
    likeLoginPopup: boolean;
    likesModal: boolean;
    collapsedReplies: boolean;

    replyingTo?: RenderedComment;
}

function isMobile() {
    return window.innerWidth <= 768;
}

export default class CommentView extends React.Component<Props, State> {
    private toggleRef: React.RefObject<HTMLDivElement> = React.createRef();
    private commentRef = React.createRef<HTMLDivElement>();
    private replyBoxRef = React.createRef<CommentBox>();

    constructor(props: Readonly<Props>) {
        super(props);

        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.checkHash = this.checkHash.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.openDeleteConfirmation = this.openDeleteConfirmation.bind(this);
        this.closeDeleteConfirmation = this.closeDeleteConfirmation.bind(this);
        this.openReplyTo = this.openReplyTo.bind(this);
        this.postReply = this.postReply.bind(this);
        this.openLikesModal = this.openLikesModal.bind(this);

        this.state = {
            isMenuOpen: false,
            likeDisabled: false,
            highlighted: false,
            deleted: false,
            deleteConfirmation: false,
            likeLoginPopup: false,
            likesModal: false,
            collapsedReplies: this.isCollapsible() && !this.isReplyHighlighted()
        }
    }

    render() {
        const {user, comment} = this.props.comment;
        const dateMinutes = Math.floor((Date.now() / 1000 - comment.date.getTime() / 1000) / 60);
        const liked = this.props.authUser && comment.likes.includes(this.props.authUser.id);

        if (this.state.deleted) {
            return "";
        }

        return (
            <div className={styles.CommentParent}>
                {this.renderDeleteConfirmation()}
                {this.renderLikeLoginPopup()}
                {this.renderLikesModal()}
                <div className={this.getCommentViewClasses()} ref={this.commentRef}>
                    <Link to={"/user/" + user.username} className={styles.Avatar}>
                        <Avatar user={user} size={"50px"}/>
                    </Link>
                    <div className={styles.Content}>
                        <div className={styles.Headline}>
                            <div className={styles.Username}>
                                <Link to={"/user/" + user.username} className={styles.UsernameLink}>
                                    {user.username}
                                </Link>
                                <UserBadge admin={user.admin} supporter={user.supporter} size={"0.73em"}/>
                            </div>
                            <div className={styles.Details}>
                                <span className={styles.Date}
                                      title={comment.date.toLocaleString()}>{formatMinutes(dateMinutes, isMobile())}</span>
                                {
                                    this.props.comment.replyingTo ?
                                        <span className={styles.Date}>
                                        &nbsp;&mdash; replied to {this.props.comment.replyingTo.username}
                                    </span>
                                        : ""
                                }
                            </div>
                        </div>
                        <div className={styles.CommentBox}>
                            <CommentBox readOnly={true} initialValue={comment.text.nodes}
                                        blocked={user.$access.blockedByYou}/>
                        </div>
                        <div className={styles.BottomSection}>
                            <div className={styles.Actions}>
                                <div className={styles.LikeSection}>
                                    <span onClick={() => this.setLiked(!liked)} className={styles.Button}>
                                        <CommentViewIcon icon={liked ? Icons.Liked : Icons.Unliked}
                                                         className={liked ? styles.LikedButton : styles.UnlikedButton}/>
                                    </span>
                                    {comment.likes.length > 0 ?
                                        <span onClick={this.openLikesModal}
                                              className={liked ? styles.Liked : styles.Unliked}>{comment.likes.length}</span>
                                        : ""}
                                </div>
                                {
                                    this.props.canWrite ?
                                        <div className={styles.Reply}
                                             onClick={() => this.openReplyTo(this.props.comment)}>Reply</div>
                                        :
                                        ""
                                }
                            </div>
                            <div>
                                {this.renderCollapseButton()}
                            </div>
                        </div>
                    </div>
                    {
                        !this.props.authUser ? "" :
                            <div className={styles.MenuParent}>
                                <div className={this.getMenuIconClasses()} ref={this.toggleRef}
                                     onClick={this.openMenu}>
                                    <MenuIcon/>
                                </div>
                                {
                                    this.state.isMenuOpen ?
                                        <Card className={styles.MenuFloat}>
                                            <PopupMenu toggleRef={this.toggleRef} closeMenu={this.closeMenu}>
                                                {
                                                    !this.canDelete() ? "" :
                                                        <div className={[styles.MenuItem, styles.DeleteItem].join(" ")}
                                                             onClick={this.openDeleteConfirmation}>
                                                            <CommentViewMenuIcon icon={MenuIcons.Delete} fill={" "}/>Delete
                                                        </div>
                                                }
                                                {
                                                    !this.canReport() ? "" :
                                                        <div className={styles.MenuItem}
                                                             onClick={() => {
                                                                 this.closeMenu();
                                                                 this.props.openReportForm(this.props.comment.user, this.props.profile.id, this.props.comment.comment.id, undefined)
                                                             }}>
                                                            <CommentViewMenuIcon icon={MenuIcons.Report}/>Report
                                                        </div>
                                                }
                                            </PopupMenu>
                                        </Card>
                                        : ""
                                }
                            </div>
                    }
                </div>
                <div className={styles.Replies}>
                    {this.renderReplies()}
                    {
                        this.props.authUser && this.props.canWrite && this.state.replyingTo ?
                            <div className={styles.NewReplyArea}>
                                <Avatar user={this.props.authUser} size={"40px"} className={styles.Avatar}/>
                                <CommentBox className={styles.ReplyBox}
                                            placeholder={"Write a reply to " + this.state.replyingTo.user.username + "..."}
                                            ref={this.replyBoxRef}
                                            onSend={this.postReply}/>
                            </div> : ""
                    }
                </div>
            </div>
        )
    }

    renderReplies() {
        if (!this.props.comment.replies) return "";

        const allReplies = this.props.comment.replies
            .sort((a, b) => a.comment.date.getTime() - b.comment.date.getTime());

        const startIndex = Math.max(0, allReplies.length - 5);
        const visibleReplies = this.state.collapsedReplies ? this.props.comment.replies.slice(startIndex, allReplies.length) : allReplies;

        return visibleReplies.map(reply =>
            <CommentView profile={this.props.profile} key={reply.comment.id}
                         authUser={this.props.authUser} comment={reply}
                         onOpenReply={this.openReplyTo} onDelete={this.onClickDeleteReply}
                         parentComment={this.props.comment}
                         openReportForm={(commentUser, profileId, commentId, _) => this.props.openReportForm(commentUser, profileId, this.props.comment.comment.id, reply.comment.id)}
                         canWrite={this.props.canWrite}/>)
    }

    renderCollapseButton() {
        if (this.isCollapsible()) {
            if (this.state.collapsedReplies) {
                const more = this.props.comment.replies.length - 5;
                return <div className={styles.CollapseButton} onClick={() => this.setState({collapsedReplies: false})}>
                    Show {more} more {more > 1 ? "replies" : "reply"} <CommentViewIcon icon={Icons.More}
                                                                                       className={styles.Icon}/>
                </div>
            } else {
                return <div className={styles.CollapseButton} onClick={() => this.setState({collapsedReplies: true})}>
                    Show less replies <CommentViewIcon icon={Icons.Less} className={styles.Icon}/>
                </div>
            }
        } else {
            return "";
        }
    }

    openReplyTo(replyingTo: RenderedComment) {
        if (this.props.onOpenReply) {
            this.props.onOpenReply(replyingTo);
            return;
        }

        if (this.state.replyingTo?.comment.id === replyingTo.comment.id) {
            this.setState({replyingTo: undefined});
        } else {
            this.setState({replyingTo}, () => {
                if (this.replyBoxRef.current) {
                    this.replyBoxRef.current.scrollIntoView();
                }
            })
        }
    }

    postReply(text: CommentText) {
        const replyingTo = this.state.replyingTo;
        if (!replyingTo) return;

        const replyingToUser = replyingTo.user;

        this.setState({replyingTo: undefined});

        if (this.props.onReply) {
            this.props.onReply(text, this.props.comment.comment, replyingToUser);
        }
    }

    renderDeleteConfirmation() {
        const {comment} = this.props.comment;
        return (
            <CardModal title={"Delete Comment"} isOpen={this.state.deleteConfirmation}
                       titleClassName={styles.ModalTitle}
                       onRequestClose={this.closeDeleteConfirmation}>
                <div className={styles.DeleteConfirm}>
                    <p className={styles.Question}>Are you sure you want to delete this comment
                        by <strong>{this.props.comment.user.username}</strong>?</p>
                    <CommentBox readOnly={true} initialValue={comment.text.nodes} className={styles.DeletePreview}/>
                    <div className={styles.ButtonParent}>
                        <Button color={ButtonColor.Regular}
                                onClick={this.closeDeleteConfirmation}>Cancel</Button>
                        <Button color={ButtonColor.Danger} outline={true}
                                onClick={this.onClickDelete}>Delete</Button>
                    </div>
                </div>
            </CardModal>
        )
    }

    renderLikeLoginPopup() {
        return (
            <CardModal title={null} isOpen={this.state.likeLoginPopup}
                       titleClassName={styles.ModalTitle}
                       onRequestClose={() => this.setState({likeLoginPopup: false})}>
                <div className={styles.LikeLoginPopup}>
                    <CommentViewIcon icon={Icons.Liked} className={styles.LikeLoginPopupIcon}/>
                    <div className={styles.PopupTitle}>Join to share the love</div>
                    <div className={styles.PopupDescription}>
                        The best way to share music.
                        <br/>
                        Create an account and start listening!
                    </div>
                    <div className={styles.PopupButtons}>
                        <Button color={ButtonColor.Primary} link={{to: "/join"}}>Join</Button>
                        <Button color={ButtonColor.Light} link={{to: "/login"}}>Login</Button>
                    </div>
                </div>
            </CardModal>
        )
    }

    renderLikesModal() {
        return (
            <CardModal title={null} isOpen={this.state.likesModal}
                       className={styles.LikesModal} contentClassName={styles.LikesModalContent}
                       onRequestClose={() => this.setState({likesModal: false})}>
                <CommentLikesModal comment={this.props.comment}/>
            </CardModal>
        )
    }

    componentDidMount() {
        this.checkHash();
        window.addEventListener("hashchange", this.checkHash, false);
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.checkHash, false);
    }

    checkHash() {
        const commentId = this.props.comment.comment.id;
        if (window.location.hash === `#comment=${commentId}`) {
            this.setState({highlighted: true});
            if (this.commentRef.current) {
                this.commentRef.current.scrollIntoView({behavior: "smooth", block: "center"});
            }
        }
    }

    canDelete(): boolean {
        const isOwnComment = this.props.authUser !== null && this.props.authUser.id === this.props.comment.user.id;
        const isOwnProfile = this.props.authUser !== null && this.props.authUser.id === this.props.profile.id;
        const isAdmin = this.props.authUser !== null && this.props.authUser.admin;

        return isOwnComment || isOwnProfile || isAdmin;
    }

    canReport(): boolean {
        const isOwnComment = this.props.authUser !== null && this.props.authUser.id === this.props.comment.user.id;
        return !isOwnComment;
    }

    openMenu() {
        this.setState({isMenuOpen: !this.state.isMenuOpen})
    }

    closeMenu() {
        this.setState({isMenuOpen: false})
    }

    openDeleteConfirmation() {
        this.closeMenu();
        this.setState({deleteConfirmation: true});
    }

    closeDeleteConfirmation() {
        this.setState({deleteConfirmation: false});
    }

    onClickDeleteReply(reply: RenderedComment, view: CommentView) {
        if (!view.props.authUser) return;

        const profileId = view.props.profile.id;
        const commentId = view.props.parentComment?.comment.id || "???";
        const replyId = view.props.comment.comment.id;

        deleteReply(profileId, commentId, replyId)
            .then(() => {
                view.setState({deleted: true});
            })
            .catch(console.error);
    }

    onClickDelete() {
        if (this.props.onDelete) {
            this.props.onDelete(this.props.comment, this);
            return;
        }

        if (!this.props.authUser) return;

        const profileId = this.props.profile.id;
        const commentId = this.props.comment.comment.id;

        deleteComment(profileId, commentId)
            .then(() => {
                this.setState({deleted: true});
            })
            .catch(console.error);
    }

    setLiked(liked: boolean) {
        if (this.state.likeDisabled) return;

        if (this.props.authUser) {
            const profileId = this.props.profile.id;
            const commentId = this.props.comment.comment.id;
            const parentId = this.props.parentComment?.comment.id;

            if (liked) {
                this.props.comment.comment.likes.push(this.props.authUser.id);
                this.setState({likeDisabled: true}, () => {
                    const func = parentId ? () => likeReply(profileId, parentId, commentId) : () => likeComment(profileId, commentId);
                    func()
                        .then(() => this.setState({likeDisabled: false}))
                        .catch(console.error);
                })
            } else {
                const index = this.props.comment.comment.likes.indexOf(this.props.authUser.id);
                this.props.comment.comment.likes.splice(index, 1);
                this.setState({likeDisabled: true}, () => {
                    const func = parentId ? () => unlikeReply(profileId, parentId, commentId) : () => unlikeComment(profileId, commentId);
                    func()
                        .then(() => this.setState({likeDisabled: false}))
                        .catch(console.error);
                })
            }
            this.forceUpdate();
        } else {
            this.setState({likeLoginPopup: true});
        }
    }

    openLikesModal() {
        this.setState({likesModal: true});
    }

    getMenuIconClasses() {
        const classes = [styles.MenuIcon];
        if (this.state.isMenuOpen) {
            classes.push(styles.Open);
        }
        return classes.join(" ");
    }

    getCommentViewClasses() {
        const classes = [styles.CommentView];
        if (this.state.highlighted) {
            classes.push(styles.Highlighted);
        }
        return classes.join(" ");
    }

    isCollapsible(): boolean {
        return !this.props.parentComment && this.props.comment.replies.length > 5;
    }

    isReplyHighlighted(): boolean {
        return this.props.comment.replies &&
            this.props.comment.replies.some(reply => window.location.hash === `#comment=${reply.comment.id}`)
    }
}
