import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ProfilePictureModal from "../Presentation/ProfilePictureModal/ProfilePictureModal";
import {toggleProfilePictureForm} from "../../Redux/Actions";


function mapStateToProps(state: AppState) {
    return {
        user: state.user,
        open: state.profilePage.profilePictureFormOpen,
        reload$: state.profilePage.reload$
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        toggleProfilePictureForm
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePictureModal);
