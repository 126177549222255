import {AppState, UserState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import UserMenu from "../Presentation/UserMenu/UserMenu";
import {closeUserMenu, logout, setIncomingRequests} from "../../Redux/Actions";
import React from "react";

interface UserMenuContainerProps {
    toggleRef: React.RefObject<HTMLElement>;
}

function mapStateToProps(state: AppState, ownProps: UserMenuContainerProps) {
    return {
        user: state.user as UserState,
        toggleRef: ownProps.toggleRef,
        incomingRequestsCount: state.incomingRequestsCount
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        closeUserMenu,
        logout,
        setIncomingRequests
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
