import React from "react";
import "./Home.scss";
import styles from "./Home.module.scss";
import {UserState} from "../../../Redux/Types";
import IntroUser from "./IntroUser/IntroUser";
import FriendsActivity from "../FriendsActivity/FriendsActivity";
import TrendingArtists from "./TrendingArtists/TrendingArtists";
import TrendingSongs from "./TrendingSongs/TrendingSongs";
import {titleManager} from "../../../Title";
import IntroGuest from "./IntroGuest/IntroGuest";
import IntroMeat from "./IntroMeat/IntroMeat";
import IntroListens from "./IntroListens/IntroListens";
import IntroTimeline from "./IntroTimeline/IntroTimeline";
import GetStarted from "../GetStarted/GetStarted";

import TopBlobImage from "./Blob/TopBlob.svg";
import LeftBlobImage from "./Blob/LeftBlob.svg";
import RightBlobImage from "./Blob/RightBlob.svg";


interface HomePageProps {
    profileReady: boolean;
    user: UserState | null;
}

export default class HomePage extends React.Component<HomePageProps> {
    render() {
        return (
            <div className="HomePage">
                {this.renderOverlay()}
                {this.renderUserIntro()}
                {this.renderGuestIntro()}
                <div className={"TopArtistsContainer"}>
                    <div className={"Container"}>
                        <div className={"Title"}>Trending Artists</div>
                        <TrendingArtists/>
                    </div>
                </div>
                <div className={"Container TopSongsContainer"}>
                    <div className={"Title"}>Trending Songs</div>
                    <TrendingSongs/>
                </div>
                {this.renderUserIntroListens()}
            </div>
        )
    }

    renderGuestIntro() {
        if (!this.props.user) {
            return (
                <>
                    <div className={styles.GuestIntro}>
                        <IntroGuest/>
                    </div>
                    <IntroMeat/>
                    <IntroListens/>
                    <IntroTimeline/>
                    <GetStarted/>
                </>
            )
        }
    }

    renderOverlay() {
        if (this.props.profileReady) {
            return "";
        } else {
            return <div className={styles.Overlay}/>
        }
    }

    renderUserIntro() {
        if (this.props.user) {
            return (
                <>
                    <img className={"Blob TopBlob"} src={TopBlobImage} alt={""} draggable={false}/>
                    <img className={"Blob RightBlob"} src={RightBlobImage} alt={""} draggable={false}/>
                    <img className={"Blob LeftBlob"} src={LeftBlobImage} alt={""} draggable={false}/>
                    <div className="Columns Container">
                        <IntroUser user={this.props.user}/>
                        <FriendsActivity user={this.props.user} isSelf={true} max={3}
                                         className={"FriendsActivityParent"}/>
                    </div>
                </>
            )
        } else {
            return "";
        }
    }

    renderUserIntroListens() {
        if (this.props.user) {
            return (
                <IntroListens/>
            )
        } else {
            return "";
        }
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set();
    }
}
