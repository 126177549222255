import React from "react";
import {DISCORD_INVITE} from "../../../Discord";

export default class Discord extends React.Component {
    render() {
        return "";
    }

    componentDidMount() {
        window.location.href = DISCORD_INVITE;
    }
}
