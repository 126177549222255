import React from "react";
import styles from "./IntroListens.module.scss";
import {countTotalListens} from "../../../../API/Calls";

interface Props {
}

interface State {
    listens?: number;
}

export default class IntroListens extends React.Component<Props, State> {
    abort = new AbortController();
    interval: any;

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className={styles.Container}>
                <div className={styles.Counter}>
                    {this.state.listens?.toLocaleString() ?? "Many"}
                </div>
                <div className={styles.Unit}>
                    Listens so far
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            if (document.hasFocus()) {
                this.refresh();
            }
        }, 3000);
        this.refresh();
    }

    refresh() {
        countTotalListens(this.abort)
            .then(listens => this.setState({listens}))
            .catch(console.error);
    }


    componentWillUnmount() {
        clearInterval(this.interval);
        this.abort.abort();
    }
}
