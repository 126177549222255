import React from "react";
import spotifyIcon from "../../../Images/spotify-icon-green.png";
import styles from "./MusicAction.module.scss";
import DeleteIcon from "../MusicList/DeleteIcon.svg";
import {deletePlays} from "../../../API/Calls";

export interface MusicAction {
    /** A unique ID for th action type */
    id: string;
    /** The text label for the action */
    label: React.ReactNode;
    /** The icon for the action */
    icon: React.ReactNode;
    /** The action callback */
    onClick: () => void;
    /** Metadata for confirming a destructive action. */
    confirmation?: MusicActionConfirmation;
    /** Whether the action is enabled on mobile. Defaults to true */
    mobile?: boolean;
    /** Whether the action is enabled on desktop. Defaults to true */
    desktop?: boolean;
}

export interface MusicActionConfirmation {
    /** Renderable function for the prompt */
    prompt: () => React.ReactNode;
    /** Button label */
    actionLabel: string;
}

export function OPEN_IN_SPOTIFY(url: string): MusicAction {
    return {
        id: "OPEN_IN_SPOTIFY",
        label: "Open in Spotify",
        icon: <img src={spotifyIcon} alt={""}/>,
        onClick: () => {
            window.open(url, "_blank");
        }
    }
}

export function DELETE_LISTEN(playId: string, started: () => void, completed: () => void): MusicAction {
    return {
        id: "DELETE_LISTEN",
        label: <span className={styles.DeleteLabel}>Delete listen</span>,
        icon: <img src={DeleteIcon} alt={""}/>,
        onClick: () => {
            started();
            deletePlays([playId]).then(() => {
                completed();
            }, err => {
                console.error('Failed to delete listen:', err);
                completed();
            })
        },
        confirmation: {
            prompt: () => "Are you sure you want to delete this listen?",
            actionLabel: "Delete",
        }
    }
}
