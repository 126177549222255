import React from "react";
import {UserState} from "../../../../Redux/Types";
import {
    getCurrentUser,
    postToken,
    updatePrivacySettingsPrivateProfile,
    updatePrivacySettingsProfileComments
} from "../../../../API/Calls";
import {titleManager} from "../../../../Title";
import styles from "./PrivacySettings.module.scss";
import PrivacySettingDropdown, {PrivacySettingValue} from "./PrivacySettingDropdown";
import {Icons} from "./PrivacySettingsIcons";
import {ProfileCommentsPrivacy} from "../../../../API/Models";
import Button, {ButtonColor} from "../../Button/Button";
import CardModal from "../../CardModal/CardModal";
import Delete from "./Delete";

interface Props {
    user: UserState | null;
    loadUserData: (data: any) => void;
    toggleSettingsLoading: (loading: boolean) => void;
    setUserPrivacy: (privacy: object) => void;

    // Delete stuff
    logout: () => void;
    toggleDeleteModal: (visible: boolean) => void;
    showDeleteModal: boolean;
}

interface State {
    ready: boolean;
}

const EVERYONE: PrivacySettingValue = {
    id: 0,
    icon: Icons.Everyone,
    name: "Everyone"
}

const FRIENDS: PrivacySettingValue = {
    id: 1,
    icon: Icons.Friends,
    name: "My friends"
}

const ONLY_ME: PrivacySettingValue = {
    id: 2,
    icon: Icons.OnlyMe,
    name: "Only me"
}

// const NO_ONE: PrivacySettingValue = {
//     id: 3,
//     icon: Icons.OnlyMe,
//     name: "No one"
// }

const VALUES_VIEW_PROFILE = [EVERYONE, FRIENDS];
const VALUES_PROFILE_COMMENTS = [EVERYONE, FRIENDS, ONLY_ME];
// const VALUES_SEND_FRIEND_REQUESTS = [EVERYONE, NO_ONE];
// const VALUES_SEND_DIRECT_MESSAGES = [EVERYONE, FRIENDS, NO_ONE];

export default class PrivacySettings extends React.Component<Props, State> {
    private signal: AbortController = new AbortController();

    constructor(props: Readonly<Props>) {
        super(props);

        this.onChangeViewProfile = this.onChangeViewProfile.bind(this);
        this.onChangeProfileComments = this.onChangeProfileComments.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);

        this.state = {
            ready: false
        }
    }

    render() {
        return (
            <div>
                <CardModal isOpen={this.props.showDeleteModal}
                           contentLabel={"Delete Account"}
                           onRequestClose={this.closeDeleteModal}
                           title={"Delete Account"}>
                    <Delete user={this.props.user} closeFunction={this.closeDeleteModal}
                            logout={this.props.logout}/>
                </CardModal>
                <div className={"Title"}>
                    Privacy
                </div>
                <div className={"Content"}>
                    {this.renderContent()}
                </div>
            </div>
        )
    }

    renderContent() {
        if (this.state.ready && this.props.user !== null) {
            return (
                <div className={styles.Sections}>
                    <div className={styles.Section}>
                        <div className={styles.SectionName}>
                            Profile
                        </div>
                        <div className={styles.Table}>
                            <div className={styles.Row}>
                                <div className={styles.Setting}>Who can view your profile?</div>
                                <PrivacySettingDropdown value={this.getViewProfileValue()}
                                                        onChange={this.onChangeViewProfile}
                                                        possibleValues={VALUES_VIEW_PROFILE}/>
                            </div>
                            <div className={styles.Row}>
                                <div className={styles.Setting}>Who can post comments on your profile?</div>
                                <PrivacySettingDropdown value={this.getProfileCommentsValue()}
                                                        onChange={this.onChangeProfileComments}
                                                        possibleValues={VALUES_PROFILE_COMMENTS}/>
                            </div>
                        </div>
                    </div>
                    {/*<div className={styles.Section}>*/}
                    {/*    <div className={styles.SectionName}>*/}
                    {/*        Social*/}
                    {/*    </div>*/}
                    {/*    <div className={styles.Table}>*/}
                    {/*        <div className={styles.Row}>*/}
                    {/*            <div className={styles.Setting}>Who can send you friend requests?</div>*/}
                    {/*            <PrivacySettingDropdown value={EVERYONE} possibleValues={VALUES_SEND_FRIEND_REQUESTS}*/}
                    {/*                                    disabled={true}/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={styles.Section + " Section"}>
                        <div className={"DescriptionParent"}>
                            <div className={"DescriptionTitle"}>Delete Account</div>
                            <div className={"Description"}>
                                Deletes your wavy.fm account permanently
                            </div>
                        </div>
                        <div className={"ButtonParent"}>
                            <Button onClick={this.openDeleteModal}
                                    color={ButtonColor.Danger}>Delete Account</Button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <div className={"LoadingEmpty"}/>
        }
    }

    loadUser(): void {
        this.props.toggleSettingsLoading(true);

        postToken()
            .then(() => getCurrentUser()
                .then(data => {
                    this.props.loadUserData(data);
                    this.props.toggleSettingsLoading(false);
                    this.setState({ready: true});
                }))
            .catch(console.error)
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Privacy Settings");

        if (this.props.user) {
            this.loadUser();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.user && !prevProps.user) {
            this.setState({ready: true});
            this.props.toggleSettingsLoading(false);
        }
    }

    componentWillUnmount(): void {
        this.signal.abort();
        this.closeDeleteModal();
    }

    getViewProfileValue() {
        if (!this.props.user) return EVERYONE;

        if (this.props.user.privacySettings.privateProfile) {
            return FRIENDS;
        } else {
            return EVERYONE;
        }
    }

    getProfileCommentsValue() {
        if (!this.props.user) return EVERYONE;

        switch (this.props.user.privacySettings.profileComments) {
            case ProfileCommentsPrivacy.Everyone:
                return EVERYONE;
            case ProfileCommentsPrivacy.Friends:
                return FRIENDS;
            case ProfileCommentsPrivacy.Me:
                return ONLY_ME;
        }
    }

    onChangeViewProfile(newVal: PrivacySettingValue) {
        if (newVal === EVERYONE) {
            this.setPrivateProfile(false);
        } else if (newVal === FRIENDS) {
            this.setPrivateProfile(true);
        }
    }

    onChangeProfileComments(newVal: PrivacySettingValue) {
        if (newVal === EVERYONE) {
            this.setProfileComments(ProfileCommentsPrivacy.Everyone);
        } else if (newVal === FRIENDS) {
            this.setProfileComments(ProfileCommentsPrivacy.Friends);
        } else if (newVal === ONLY_ME) {
            this.setProfileComments(ProfileCommentsPrivacy.Me);
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////
    // Internals

    setPrivateProfile(value: boolean) {
        this.props.setUserPrivacy({privateProfile: value});
        updatePrivacySettingsPrivateProfile(value, this.signal)
            .then()
            .catch(console.error);
    }

    setProfileComments(value: ProfileCommentsPrivacy) {
        this.props.setUserPrivacy({profileComments: value});
        updatePrivacySettingsProfileComments(value, this.signal)
            .then()
            .catch(console.error);
    }

    openDeleteModal() {
        this.props.toggleDeleteModal(true);
    }

    closeDeleteModal() {
        this.props.toggleDeleteModal(false);
    }
}
