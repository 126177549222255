import React from "react";
import {NavLink} from "react-router-dom";
import styles from "./UserSocialNavigation.module.scss";
import NavIconFriend from "./NavIcons/NavIconFriend";
import NavIconFriendAdd from "./NavIcons/NavIconFriendAdd";
import NavIconRequests from "./NavIcons/NavIconRequests";
import {countPendingFriendRequests} from "../../../API/Calls";
import NavIconBlocked from "./NavIcons/NavIconBlocked";

interface Props {
    self: boolean;
    username: string;
    incomingRequestsCount?: number;
    setIncomingRequests: (count: number) => void;
}

interface State {
    abort: AbortController;
}

export default class UserSocialNavigation extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            abort: new AbortController()
        }
    }

    render() {
        return (
            <div className={styles.Nav}>
                <div className={styles.Title}>Friends</div>
                <div>
                    <NavLink to={"/user/" + this.props.username + "/friends"} className={styles.NavLink}
                             activeClassName={styles.Selected} exact={true}>
                        <div className={styles.Label}>
                            <div className={styles.IconFriend}><NavIconFriend/></div>
                            Friends
                        </div>
                    </NavLink>
                </div>
                {
                    !this.props.self ? "" :
                        <div>
                            <NavLink to={"/user/" + this.props.username + "/friends/add"} className={styles.NavLink}
                                     activeClassName={styles.Selected}>
                                <div className={styles.Label}>
                                    <div className={styles.IconFriendAdd}><NavIconFriendAdd/></div>
                                    Add a Friend
                                </div>
                            </NavLink>
                        </div>
                }
                {
                    !this.props.self ? "" :
                        <div>
                            <NavLink to={"/user/" + this.props.username + "/friends/pending"} className={styles.NavLink}
                                     activeClassName={styles.Selected}>
                                <div className={styles.Label}>
                                    {
                                        this.props.incomingRequestsCount === undefined || this.props.incomingRequestsCount === 0 ?
                                            <div className={styles.IconFriendRequests}><NavIconRequests/></div>
                                            :
                                            <div className={styles.CountFriendRequests}>
                                                <span>{this.props.incomingRequestsCount}</span>
                                            </div>
                                    }
                                    {
                                        this.isMobile() ? "Requests" : "Pending Requests"
                                    }
                                </div>
                            </NavLink>
                        </div>
                }
                {
                    !this.props.self ? "" :
                        <div>
                            <NavLink to={"/user/" + this.props.username + "/friends/blocked"} className={styles.NavLink}
                                     activeClassName={styles.Selected}>
                                <div className={styles.Label}>
                                    <div className={styles.IconBlocked}><NavIconBlocked/></div>
                                    {
                                        this.isMobile() ? "Blocked" : "Blocked Users"
                                    }
                                </div>
                            </NavLink>
                        </div>
                }
            </div>
        )
    }

    componentDidMount(): void {
        if (this.props.self) {
            countPendingFriendRequests(this.state.abort)
                .then(resp => {
                    this.props.setIncomingRequests(resp.incoming);
                })
                .catch(console.error);
        }
    }

    componentWillUnmount(): void {
        this.state.abort.abort();
    }

    isMobile() {
        return window.innerWidth < 768;
    }
}
