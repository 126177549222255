import React from "react";
import "./Auth.scss"
import {Link} from "react-router-dom";
import AuthFormParent from "./Parent";
import Card from "../Card/Card";
import {titleManager} from "../../../Title";

export interface AuthPageProps {
    view: "login" | "join",
    error: Error | null,

    setAuthFormsError: (error: Error | null) => void,
}

export default class AuthPage extends React.Component<AuthPageProps> {
    render() {
        return (
            <div className={"Container"}>
                {
                    this.props.error ? <div className={"AuthError"}>{this.props.error.toString()}</div> : ""
                }
                <Card className={"AuthCard"}>
                    <div className={"LogoParent"}>
                        <div className={"Logo"}/>
                    </div>
                    <div className={"AuthTitle"}>
                        <Link to={"/join"} className={this.props.view === "join" ? "Active" : "Inactive"}>Join</Link>
                        <span className={"Or"}>&nbsp;or&nbsp;</span>
                        <Link to={"/login"} className={this.props.view === "login" ? "Active" : "Inactive"}>Login</Link>
                    </div>
                    <div className={"Divider"}/>
                    <div className={"AuthForm View-" + this.props.view}>
                        <AuthFormParent view={this.props.view}/>
                    </div>
                </Card>
            </div>
        );
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        if (this.props.view === "join") {
            titleManager.set("Join");
        } else {
            titleManager.set("Login")
        }
    }

    componentWillUnmount(): void {
        this.props.setAuthFormsError(null);
    }

    componentDidUpdate(prevProps: Readonly<AuthPageProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.view !== this.props.view) {
            this.props.setAuthFormsError(null);
            if (this.props.view === "join") {
                titleManager.set("Join");
            } else {
                titleManager.set("Login")
            }
        }
        if (prevProps.error !== this.props.error) {
            window.scrollTo(0, 0);
        }
    }
}
