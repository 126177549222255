import React from "react";
import {ForeignUser, UserState} from "../../../Redux/Types";
import {deletePlays, getListensCalendar, getProfileByUsername, getRecentListens} from "../../../API/Calls";
import {Error404} from "../Error404/Error404";
import styles from "./UserHistory.module.scss";
import {Link, NavLink, Route} from "react-router-dom";
import AngleLeftIcon from "../Icons/AngleLeft/AngleLeft";
import AngleRightIcon from "../Icons/AngleRight/AngleRight";
import UserHistorySongList from "./UserHistorySongList";
import UserHistoryStatsCard from "./UserHistoryStatsCard";
import Avatar from "../Avatar/Avatar";
import SearchBox from "../SearchBox/SearchBox";
import debounce from "debounce-promise";
import {History} from "history";
import ProfileDateRange, {Ranges} from "../ProfileDateRange/ProfileDateRange";
import ProfileDateCalendar from "../ProfileDateCalendar/ProfileDateCalendar";
import {formatDay, formatDayEnglish} from "../../../API/UniversalTime";
import queryString from 'query-string';
import Button, {ButtonColor} from "../Button/Button";
import {HistoryEditManager} from "./HistoryEditManager";
import {EventEmitter} from "events";
import {titleManager} from "../../../Title";
import MobileEditingPopover from "./MobileEditingPopover";
import {GetProfileListensResponse} from "../../../API/Models";
import {cssVar} from "../../../variables";

interface Props {
    /**
     * URL parameters
     */
    match: { params: { username: string } },
    location: { pathname: string },
    page: number,
    searchFilter?: string;
    dateRange: Ranges;
    dateFilter?: Date;
    history: History;

    toggleGlobalLoading: (loading: boolean) => void;

    authUser: UserState | null;
    authProfileReady: boolean;
}

interface State {
    /**
     * Whether the profile shown here has loaded
     */
    profileLoaded: boolean,
    /**
     * The public user data of the profile.
     */
    profile: ForeignUser | null,
    hasNext: boolean,
    hasPrev: boolean,
    pageNumber: number,
    totalPages: number,
    totalResults: number,

    // Calendar
    isCalendarLoading: boolean,
    calendarSignal: AbortController,
    calendarDates: Map<string, number>,
    calendarLoadedMonths: Set<string>,

    // Average amount of listens per day
    stats: GetProfileListensResponse | null;

    // Editing
    editing: boolean,
    abortCtl: AbortController
}

export default class UserHistory extends React.Component<Props, State> {
    private inputContainer: React.RefObject<HTMLInputElement> = React.createRef();
    private unregisterHistoryListener: any;
    private editManager = new HistoryEditManager();
    private reload$: EventEmitter = new EventEmitter();

    constructor(props: Readonly<Props>) {
        super(props);

        this.pageFunc = this.pageFunc.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onDateRangeChange = this.onDateRangeChange.bind(this);
        this.startEditing = this.startEditing.bind(this);
        this.stopEditing = this.stopEditing.bind(this);
        this.saveEditing = this.saveEditing.bind(this);
        this.countProvider = this.countProvider.bind(this);

        this.state = {
            profileLoaded: false,
            profile: null,
            hasNext: false,
            hasPrev: false,
            pageNumber: 0,
            totalPages: 0,
            totalResults: 0,

            isCalendarLoading: false,
            calendarSignal: new AbortController(),
            calendarDates: new Map<string, number>(),
            calendarLoadedMonths: new Set<string>(),

            editing: false,
            abortCtl: new AbortController(),
            stats: null,
        }
    }

    render() {
        return !this.state.profileLoaded ? "" :
            this.state.profile === null ?
                <Error404/>
                :
                <div className={"Container " + styles.HistoryPage}>
                    <div className={styles.SummaryColumn}>
                        <div className={styles.UserInfo}>
                            <Link to={"/user/" + this.state.profile.username}>
                                <Avatar user={this.state.profile}
                                        size={"50px"}/>
                            </Link>
                            <Link to={"/user/" + this.state.profile.username}
                                  className={styles.Username}>{this.state.profile.username}</Link>
                        </div>
                        <UserHistoryStatsCard profile={this.state.profile}
                                              reload$={this.reload$}
                                              stats={this.state.stats}
                                              content={this.renderCalendar()}/>
                    </div>
                    <div className={styles.ListColumn}>
                        {this.renderNavigation()}
                        {this.renderDateFilterTitle()}
                        <UserHistorySongList
                            profile={this.state.profile}
                            page={this.props.page}
                            pageFunc={this.pageFunc}
                            searchFilter={this.props.searchFilter}
                            dateFilter={this.props.dateFilter}
                            dateRange={this.props.dateRange}
                            editing={this.state.editing}
                            editManager={this.editManager}
                            isOwnProfile={this.isOwnProfile}
                            reload$={this.reload$}/>
                        <div className={styles.Pagination}>
                            {
                                !this.state.hasPrev || !this.state.profile ?
                                    <span className={styles.PreviousLink}/> :
                                    <Link to={this.paginationLink(this.props.page - 1)}
                                          className={styles.PreviousLink}>
                                        <AngleLeftIcon fill={cssVar('--light-text')}/>
                                        Previous
                                    </Link>
                            }
                            {this.renderPagination()}
                            {
                                !this.state.hasNext || !this.state.profile ? <span className={styles.NextLink}/> :
                                    <Link to={this.paginationLink(this.props.page + 1)}
                                          className={styles.NextLink}>
                                        Next
                                        <AngleRightIcon fill={cssVar('--light-text')}/>
                                    </Link>
                            }
                        </div>
                        {
                            !this.state.totalResults ? "" :
                                <div className={styles.Total}>
                                    {this.state.totalResults.toLocaleString()} results
                                </div>
                        }
                    </div>
                    {this.state.editing && window.innerWidth <= 768 ?
                        <MobileEditingPopover editManager={this.editManager} cancel={this.stopEditing}
                                              save={this.saveEditing}/> : ""}
                </div>
    }

    loadProfileFromRoute() {
        this.reload$.removeAllListeners();
        this.props.toggleGlobalLoading(true);
        getProfileByUsername(this.props.match.params.username)
            .then(profile => {
                this.props.toggleGlobalLoading(false);
                this.setState({
                    profile,
                    profileLoaded: true
                }, () => this.doSearch());
                if (profile) {
                    getRecentListens(profile.id, 0, 0)
                        .then(stats => this.setState({stats}))
                        .catch(console.error);

                    this.reload$.on("reload", () => {
                        getRecentListens(profile.id, 0, 0)
                            .then(stats => this.setState({stats}))
                            .catch(console.error);
                    })
                }
            })
            .catch(console.error);
    }

    linkTo(page: string): string {
        if (!this.state.profile) {
            return "";
        }
        let path = "/user/" + this.state.profile.username + "/history/" + page;
        if (page !== "recent") {
            switch (this.props.dateRange) {
                case Ranges.Days7:
                    path += "?range=7d";
                    break;
                case Ranges.Days30:
                    path += "?range=30d";
                    break;
                case Ranges.Days90:
                    path += "?range=90d";
                    break;
                case Ranges.Days365:
                    path += "?range=365d";
                    break;
            }
        }
        return path;
    }

    paginationLink(page: number): string {
        const params: { [key: string]: any } = {page, range: null, date: null};
        let path = this.props.location.pathname;

        switch (this.props.dateRange) {
            case Ranges.Days7:
                params.range = "7d";
                break;
            case Ranges.Days30:
                params.range = "30d";
                break;
            case Ranges.Days90:
                params.range = "90d";
                break;
            case Ranges.Days365:
                params.range = "365d";
                break;
        }
        if (this.props.dateFilter !== undefined) {
            params.date = formatDay(this.props.dateFilter);
        }
        if (this.props.searchFilter) {
            params.search = this.props.searchFilter;
        }
        return path + "?" + queryString.stringify(params, {skipNull: true});
    }

    pageFunc(hasNext: boolean, hasPrev: boolean, pageNumber: number, totalPages: number, totalResults: number): void {
        this.setState({hasNext, hasPrev, pageNumber, totalPages, totalResults});
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set();
        this.loadProfileFromRoute();
        this.unregisterHistoryListener = this.props.history.listen(location => {
            if (!location.pathname.endsWith("/recent")) {
                this.stopEditing();
            }
        });
        this.editManager.setListener(() => this.forceUpdate());
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.searchFilter !== this.props.searchFilter) {
            this.doSearch();
        }
    }

    componentWillUnmount(): void {
        if (this.unregisterHistoryListener) {
            // Prevents memory leak when navigating away from this component
            this.unregisterHistoryListener();
        }
        this.editManager.unregister();
        this.editManager.flush();
        this.state.abortCtl.abort();
        this.reload$.removeAllListeners();
    }

    onSearchChange() {
        const searchValue = (this.inputContainer.current?.value ?? "");
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("page");
        if (!!searchValue) {
            searchParams.set("search", searchValue);
        } else {
            searchParams.delete("search");
        }
        const path = window.location.pathname + "?" + searchParams.toString();
        this.props.history.push(path);
    }

    doSearch() {
        if (this.inputContainer.current) {
            this.inputContainer.current.value = this.props.searchFilter || "";
        }
    }

    onDateRangeChange(newRange: Ranges) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("page");
        searchParams.delete("range");
        switch (newRange) {
            case Ranges.Days7:
                searchParams.set("range", "7d");
                break;
            case Ranges.Days30:
                searchParams.set("range", "30d");
                break;
            case Ranges.Days90:
                searchParams.set("range", "90d");
                break;
            case Ranges.Days365:
                searchParams.set("range", "365d");
                break;
        }
        const path = window.location.pathname + "?" + searchParams.toString();
        this.props.history.push(path);
    }

    renderNavigation(): React.ReactNode {
        return (
            <div className={styles.Navigation}>
                <NavLink to={this.linkTo("recent")}
                         activeClassName={styles.Selected}>Recent</NavLink>
                {!this.state.editing ? <NavLink to={this.linkTo("songs")}
                                                activeClassName={styles.Selected}>Songs</NavLink> : ""}
                {!this.state.editing ? <NavLink to={this.linkTo("artists")}
                                                activeClassName={styles.Selected}>Artists</NavLink> : ""}
                {!this.state.editing ? <NavLink to={this.linkTo("albums")}
                                                activeClassName={styles.Selected}>Albums</NavLink> : ""}
                {this.renderSearchBox()}
            </div>
        )
    }

    renderSearchBox(): React.ReactNode {
        return (
            <div className={styles.NavigationRight}>
                <Route path={"/user/" + this.username + "/history/recent"}>
                    <SearchBox className={styles.SearchBox} placeholder={"Search Recent"}
                               inputRef={this.inputContainer}
                               onChange={debounce(this.onSearchChange, 200)}/>
                    {this.renderEditButton()}
                </Route>
                <Route path={"/user/" + this.username + "/history/songs"}>
                    <SearchBox className={styles.SearchBox} placeholder={"Search Songs"}
                               inputRef={this.inputContainer}
                               onChange={debounce(this.onSearchChange, 200)}/>
                    <div className={styles.DateRange}>
                        <ProfileDateRange selected={this.props.dateRange} onChange={this.onDateRangeChange}/>
                    </div>
                </Route>
                <Route path={"/user/" + this.username + "/history/artists"}>
                    <SearchBox className={styles.SearchBox} placeholder={"Search Artists"}
                               inputRef={this.inputContainer}
                               onChange={debounce(this.onSearchChange, 200)}/>
                    <div className={styles.DateRange}>
                        <ProfileDateRange selected={this.props.dateRange} onChange={this.onDateRangeChange}/>
                    </div>
                </Route>
                <Route path={"/user/" + this.username + "/history/albums"}>
                    <SearchBox className={styles.SearchBox} placeholder={"Search Albums"}
                               inputRef={this.inputContainer}
                               onChange={debounce(this.onSearchChange, 200)}/>
                    <div className={styles.DateRange}>
                        <ProfileDateRange selected={this.props.dateRange} onChange={this.onDateRangeChange}/>
                    </div>
                </Route>
            </div>
        )
    }

    renderEditButton(): React.ReactNode {
        if (!this.isOwnProfile) {
            return "";
        }
        if (this.state.editing) {
            if (window.innerWidth > 768) {
                return (
                    <div className={styles.EditButton}>
                        <Button onClick={this.stopEditing}>Cancel</Button>
                        <Button onClick={this.saveEditing} color={ButtonColor.Primary}>Save</Button>
                    </div>
                )
            } else {
                return "";
            }
        } else {
            return (
                <div className={styles.EditButton}>
                    <Button outline={true} onClick={this.startEditing}>Edit</Button>
                </div>
            )
        }
    }

    renderCalendar() {
        if (!this.state.profile || !this.state.profileLoaded || !this.state.stats || window.innerWidth <= 768) {
            return "";
        }
        const average = Math.ceil(this.state.stats.total_tracks / this.state.stats.tracked_days);
        return (
            <Route path={"/user/" + this.username + "/history/recent"}>
                <div>
                    <ProfileDateCalendar className={styles.Calendar} loading={this.state.isCalendarLoading}
                                         defaultValue={this.props.dateFilter}
                                         countProvider={this.countProvider.bind(this)}
                                         onChangeMonth={this.switchMonth.bind(this)}
                                         average={average}
                                         onClickDay={this.switchDay.bind(this)}/>
                </div>
                {this.renderClearFilter()}
            </Route>
        )
    }

    renderClearFilter(): React.ReactNode {
        if (this.props.dateFilter === undefined) {
            return "";
        }
        return (
            <Link to={this.linkTo("recent")} className={styles.ClearFilter}>Clear Filter</Link>
        )
    }

    switchDay(date: Date | undefined) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("page");
        if (!!date) {
            searchParams.set("date", formatDay(date));
        } else {
            searchParams.delete("date");
        }
        const path = window.location.pathname + "?" + searchParams.toString();
        this.props.history.push(path);
    }

    switchMonth(month: number, year: number) {
        if (!this.state.profile) return;

        this.state.calendarSignal.abort();
        const key = month + "-" + year;
        if (!this.state.calendarLoadedMonths.has(key)) {
            this.setState({isCalendarLoading: true, calendarSignal: new AbortController()}, () => {
                if (!this.state.profile) return;

                getListensCalendar(this.state.profile.id, month, year, this.state.calendarSignal)
                    .then(result => {
                        this.state.calendarLoadedMonths.add(key);
                        Object.entries(result)
                            .forEach(([key, value]) => this.state.calendarDates.set(key, value));
                        this.setState({isCalendarLoading: false});
                    })
                    .catch(console.error);
            })
        }
    }

    renderDateFilterTitle(): React.ReactNode {
        if (this.props.dateFilter) {
            const listens = this.state.calendarDates.get(formatDay(this.props.dateFilter)) ?? "";
            return (
                <div className={styles.DateFilterTitle}>
                    {formatDayEnglish(this.props.dateFilter)} - <span className={styles.Count}>{listens}
                    <svg className={styles.ListenIcon} width="20" height="20" viewBox="0 0 46 39" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
<path
    d="M14.375 22.2857H12.9375C9.76152 22.2857 7.1875 24.7842 7.1875 27.8676V33.4181C7.1875 36.5007 9.76152 39 12.9375 39H14.375C15.9625 39 17.25 37.7499 17.25 36.2091V25.0767C17.25 23.5349 15.9625 22.2857 14.375 22.2857ZM33.0625 22.2857H31.625C30.0375 22.2857 28.75 23.5349 28.75 25.0767V36.2091C28.75 37.7499 30.0375 39 31.625 39H33.0625C36.2385 39 38.8125 36.5007 38.8125 33.4181V27.8676C38.8125 24.785 36.2385 22.2857 33.0625 22.2857ZM23 0C10.1443 0 0.410586 10.3707 0 22.2857V32.0357C0 32.8053 0.643281 33.4286 1.4375 33.4286H2.875C3.66922 33.4286 4.3125 32.8053 4.3125 32.0357V22.2857C4.3125 12.3033 12.6976 4.19598 23 4.19424C33.3024 4.19598 41.6875 12.3033 41.6875 22.2857V32.0357C41.6875 32.8053 42.3308 33.4286 43.125 33.4286H44.5625C45.3567 33.4286 46 32.8053 46 32.0357V22.2857C45.5894 10.3707 35.8557 0 23 0Z"
    fill={cssVar('--light-text')}/>
</svg>
                </span>
                </div>
            )
        } else {
            return "";
        }
    }

    isDateEnabled(date: Date): boolean {
        const dateStr = formatDay(date);
        return this.state.calendarDates.has(dateStr);
    }

    countProvider(date: Date): number {
        const dateStr = formatDay(date);
        return this.state.calendarDates.get(dateStr) || 0;
    }

    startEditing() {
        this.setState({editing: true});
    }

    stopEditing() {
        this.editManager.flush();
        this.setState({editing: false});
        this.forceUpdate();
    }

    saveEditing() {
        if (this.props.authUser && this.editManager.size > 0) {
            const payload = this.editManager.toArray();
            this.stopEditing();

            deletePlays(payload, this.state.abortCtl)
                .then(() => {
                    this.reload$.emit("reload");
                    this.setState({abortCtl: new AbortController()});
                })
                .catch(console.error);
        } else {
            this.stopEditing();
        }
    }

    get username() {
        return this.props.match.params.username;
    }

    get isOwnProfile() {
        return (this.props.authUser?.username ?? "").toLowerCase() === this.username.toLowerCase();
    }

    renderPagination() {
        if (this.state.pageNumber === 0 || this.state.totalPages === 0) return "";
        return (
            <div className={styles.PageNumberList}>
                {
                    this.generatePageRange(this.state.pageNumber, this.state.totalPages)
                        .map((page, i) => {
                            if (page === "...") {
                                return <span className={styles.PageNumberDots} key={i}>...</span>;
                            } else if (page === `${this.props.page}`) {
                                return <span className={styles.PageNumberSelected} key={i}>{page}</span>;
                            } else {
                                return <Link className={styles.PageNumberLink} to={this.paginationLink(parseInt(page))}
                                             key={i}>{page}</Link>;
                            }
                        })
                }
            </div>
        )
    }

    generatePageRange(currentPage: number, lastPage: number): string[] {
        const delta = 3;

        const range = [];
        for (let i = Math.max(2, (currentPage - delta)); i <= Math.min((lastPage - 1), (currentPage + delta)); i += 1) {
            range.push('' + i);
        }

        if ((currentPage - delta) > 2) {
            range.unshift('...');
        }
        if ((currentPage + delta) < (lastPage - 1)) {
            range.push('...');
        }

        range.unshift('1');
        if (lastPage !== 1) range.push('' + lastPage);

        return range;
    }
}
