import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ProfileReportForm from "../Presentation/ProfileReportForm/ProfileReportForm";
import {toggleProfileReportForm} from "../../Redux/Actions";

function mapStateToProps(state: AppState) {
    return {
        username: state.profilePage.profile?.username ?? "",
        userId: state.profilePage.profile?.id ?? "",
        isOpen: state.profilePage.reportFormOpen
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        toggleProfileReportForm
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileReportForm);
