import React from "react";
import {UserState} from "../../../../Redux/Types";
import {editEmail} from "../../../../API/Calls";
import Button, {ButtonColor} from "../../Button/Button";

interface EmailState {
    password: string,
    email: string,
    loading: boolean,
    error: Error | null,
}

interface EmailProps {
    user: UserState | null
    closeFunction: (newEmail?: string) => void;
}

export default class Email extends React.Component<EmailProps, EmailState> {

    constructor(props: Readonly<EmailProps>) {
        super(props);

        this.state = {
            password: "",
            email: "",
            loading: false,
            error: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {
                    this.state.error ? <div className={"AuthError"}>{this.state.error.toString()}</div> : ""
                }
                <div className={"NewEmailForm"}>
                    <p className={"NewEmailWarning"}>
                        Note: Some features will be disabled until you verify the new email address.
                    </p>
                    <div className={"FormField"}>
                        <label htmlFor={"Email"}>
                            New Email Address
                        </label>
                        <input required={true} name={"Email"} type={"email"} value={this.state.email}
                               autoComplete={"email"}
                               onChange={this.handleChange}/>
                    </div>
                    {
                        this.props.user && !this.props.user.emailVerified ? "" :
                            <div className={"FormField"}>
                                <label htmlFor={"Password"}>
                                    Password
                                </label>
                                <input required={true} name={"Password"} type={"password"}
                                       value={this.state.password}
                                       autoComplete={"current-password"}
                                       onChange={this.handleChange}/>
                            </div>
                    }
                    <div className="FormField SubmitRegion">
                        <Button color={ButtonColor.Regular}
                                onClick={() => this.props.closeFunction()}>Cancel</Button>
                        <Button form={true} color={ButtonColor.Primary} disabled={this.state.loading}>Save</Button>
                    </div>
                </div>
            </form>
        )
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        if (target.name === "Password") {
            this.setState({password: target.value});
        } else if (target.name === "Email") {
            this.setState({email: target.value});
        }
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        this.setState({loading: true, error: null});
        editEmail(this.state.email, this.state.password)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    return resp.json().then(json => {
                        throw Error(json.description);
                    })
                }
            })
            .then(resp => {
                // TODO: Show some message that email was updated
                this.props.closeFunction(this.state.email);
            })
            .catch(e => {
                this.setState({loading: false, error: e});
                console.error(e);
            })
    }
}
