import React from "react";
import {ForeignUser, UserState} from "../../../Redux/Types";
import {RouteChildrenProps} from "react-router-dom";
import {getProfileByUsername, getProfilesById} from "../../../API/Calls";
import {Error404} from "../Error404/Error404";
import ProfileLayout from "../ProfileLayout/ProfileLayout";
import ProfileMetaContainer from "../../Containers/ProfileMetaContainer";
import ProfilePictureModalContainer from "../../Containers/ProfilePictureModalContainer";
import {EventEmitter} from "events";
import ProfileContentContainer from "../../Containers/ProfileContentContainer";
import ProfileAltContainer from "../../Containers/ProfileAltContainer";
import ProfileReportFormContainer from "../../Containers/ProfileReportFormContainer";
import ProfileUnfriendConfirmContainer from "../../Containers/ProfileUnfriendConfirmContainer";
import ProfileBlockConfirmContainer from "../../Containers/ProfileBlockConfirmContainer";
import {titleManager} from "../../../Title";
import SpotifyTrackingAlertContainer from "../../Containers/SpotifyTrackingAlertContainer";

interface Props {
    authUser: UserState | null;
    authProfileReady: boolean;
    profile: ForeignUser | null;
    reload$: EventEmitter;
}

interface MatchProps {
    username: string;
}

interface Actions {
    loadProfile: (user: ForeignUser | null) => void;
    toggleGlobalLoading: (loading: boolean) => void;
    toggleProfileEditing: (editing: boolean) => void;
}

interface State {
    ready: boolean;
}

export default class Profile extends React.Component<Props & Actions & RouteChildrenProps<MatchProps>, State> {

    private static readonly UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    constructor(props: Readonly<Props & Actions & RouteChildrenProps<MatchProps>>) {
        super(props);

        this.state = {
            ready: false
        }
    }

    render() {
        if (!this.state.ready) {
            return <div/>
        }
        if (this.props.profile === null) {
            return <Error404/>
        }
        return (
            <div>
                <ProfilePictureModalContainer/>
                <ProfileReportFormContainer/>
                <ProfileUnfriendConfirmContainer/>
                <ProfileBlockConfirmContainer/>
                {
                    window.innerWidth > 768 ? "" : <SpotifyTrackingAlertContainer/>
                }
                <ProfileLayout meta={ProfileMetaContainer}
                               content={ProfileContentContainer}
                               alt={ProfileAltContainer}/>
            </div>
        )
    }

    componentWillUnmount(): void {
        this.actions.toggleGlobalLoading(false);
        this.props.reload$.removeAllListeners();
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set(); // Clear title until user is loaded
        this.loadProfileFromRoute();
    }

    loadProfileFromRoute(): void {
        this.actions.toggleGlobalLoading(true);
        this.actions.toggleProfileEditing(false);

        titleManager.set(`${this.username}'s Profile`);

        const promise = Profile.UUID_REGEX.test(this.username) ?
            getProfilesById(this.username)
                .then(resp => {
                    if (resp.length === 0) {
                        throw new Error("User ID not found");
                    } else {
                        return resp[0];
                    }
                })
            :
            getProfileByUsername(this.username);

        promise
            .then(profile => {
                this.actions.toggleGlobalLoading(false);
                this.actions.loadProfile(profile);
                this.props.reload$.on("reload", () => {
                    this.loadProfileFromRoute();
                });
                this.setState({ready: true});
                if (profile) {
                    titleManager.set(`${profile?.username}'s Profile`);
                }
            })
            .catch(err => {
                console.error(err);

                titleManager.set();
                this.actions.toggleGlobalLoading(false);
                this.actions.loadProfile(null);
                this.setState({ready: true});
            });
    }

    componentDidUpdate(prevProps: Readonly<Props & Actions & RouteChildrenProps<MatchProps>>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.match?.params?.username !== this.username) {
            this.setState({ready: false}, () => {
                this.loadProfileFromRoute();
                window.scrollTo(0, 0);
            });
        }
    }

    get actions(): Actions {
        return this.props as Actions;
    }

    get username(): string {
        return this.props.match?.params.username ?? "";
    }
}
