import React from "react";
import {Entry as GridEntry} from "../ProfileGrid/ProfileGrid";
import styles from "./ProfileGridSection.module.scss";
import ProfileArtistsGrid from "../ProfileGrid/ProfileArtistsGrid";
import ProfileAlbumsGrid from "../ProfileGrid/ProfileAlbumsGrid";
import {ForeignUser} from "../../../Redux/Types";
import {EventEmitter} from "events";

interface Props {
    profile: ForeignUser;
    isOwnProfile: boolean;
    reload$: EventEmitter;
}

interface State {
    hoveredArtist?: GridEntry;
    hoveredAlbum?: GridEntry;
}

export default class ProfileGridSection extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {}

        this.onAlbumHover = this.onAlbumHover.bind(this);
        this.onArtistHover = this.onArtistHover.bind(this);
    }

    render() {
        return (
            <div className={styles.GridHistorySection}>
                <ProfileArtistsGrid onHover={this.onArtistHover}
                                    focusedEntry={this.state.hoveredAlbum}
                                    isOwnProfile={this.props.isOwnProfile}
                                    reload$={this.props.reload$}
                                    user={this.props.profile}/>
                <ProfileAlbumsGrid onHover={this.onAlbumHover}
                                   focusedEntry={this.state.hoveredArtist}
                                   isOwnProfile={this.props.isOwnProfile}
                                   reload$={this.props.reload$}
                                   user={this.props.profile}/>
            </div>
        )
    }

    onArtistHover(entry?: GridEntry) {
        this.setState({hoveredArtist: entry});
    }

    onAlbumHover(entry?: GridEntry) {
        this.setState({hoveredAlbum: entry});
    }
}
