import React from "react";
import {UserState} from "../../../../Redux/Types";
import Button, {ButtonColor} from "../../Button/Button";
import {viewMfaRecovery} from "../../../../API/Calls";
import styles from "./MfaRecovery.module.scss";

interface Props {
    user: UserState;
    closeFunction: () => void;
}

interface State {
    loading: boolean,
    errorMessage?: string;
    codes?: string[];
}

export default class MfaRecovery extends React.Component<Props, State> {
    abort = new AbortController();

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            loading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        if (this.state.codes) {
            return this.renderCodes();
        } else {
            return this.renderPasswordForm();
        }
    }

    renderPasswordForm() {
        return (
            <form onSubmit={this.handleSubmit}>
                {
                    this.state.errorMessage ? <div className={"AuthError"}>{this.state.errorMessage}</div> : ""
                }
                <div className={styles.RecoveryForm}>
                    <p>
                        Enter your password to view your 2FA recovery codes.
                    </p>
                    <div className={"FormField " + styles.FormField}>
                        <label htmlFor={"Password"}>
                            Password
                        </label>
                        <input required={true} name={"Password"} type={"password"}
                               autoComplete={"off"}/>
                    </div>
                    <div className={"FormField SubmitRegion " + styles.FormField + " " + styles.SubmitRegion}>
                        <Button color={ButtonColor.Regular}
                                onClick={() => this.props.closeFunction()}>Cancel</Button>
                        <Button form={true} color={ButtonColor.Primary} disabled={this.state.loading}>View</Button>
                    </div>
                </div>
            </form>
        )
    }

    renderCodes() {
        return (
            <div className={styles.RecoveryForm}>
                <p>
                    In case you lose access to your phone, you will need one of these 10 recovery
                    codes.
                </p>
                <p>
                    Keep them somewhere safe! We won't be able to recover your account if you lose them.
                </p>
                <div className={styles.Recovery}>
                    {this.state.codes?.join(" ")}
                </div>
                <div className={"FormField SubmitRegion " + styles.FormField + " " + styles.SubmitRegion}
                     style={{textAlign: "center"}}>
                    <Button onClick={() => this.props.closeFunction()} color={ButtonColor.Primary}>Done</Button>
                </div>
            </div>
        )
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (this.state.loading) {
            return;
        }

        const password = ((event.target as HTMLFormElement).elements.namedItem("Password") as HTMLInputElement).value;
        if (!password || !password.trim()) {
            return;
        }

        this.setState({loading: true, errorMessage: undefined}, () => {
            viewMfaRecovery(password, this.abort)
                .then(resp => {
                    this.setState({loading: false, codes: resp})
                })
                .catch(error => this.setState({loading: false, errorMessage: error.toString()}))
        });
    }

    componentWillUnmount(): void {
        this.abort.abort();
    }
}
