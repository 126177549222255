import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import NotificationBell from "../Presentation/NotificationBell/NotificationBell";
import {setNotifications, toggleNotificationsMenu} from "../../Redux/Actions";

function mapStateToProps(state: AppState) {
    return {
        isLoggedIn: state.user !== null && state.profileReady,
        notifications: state.notifications,
        menuOpen: state.menus.notifications
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        setNotifications,
        toggleNotificationsMenu
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBell);
