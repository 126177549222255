import React from "react";
import styles from "./Developers.module.scss";
import Card, {CardElevation} from "../Card/Card";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import {ScaleLoader} from "react-spinners";
import {DocIndex, getDocIndex} from "./DocSource";
import DevelopersContent from "./DevelopersContent";
import {titleManager} from "../../../Title";
import DevelopersApps from "../DevelopersApps/DevelopersApps";

interface Props {
}

interface State {
    index?: DocIndex;
}

export default class Developers extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div>
                <div className={styles.Banner}>
                    This documentation is still work-in-progress. The API described here is not generally available,
                    but you can join the <a href={"https://github.com/wavy/wavyfm-docs/"}>design discussions on
                    GitHub</a>.
                </div>
                <div className={styles.Layout}>
                    <Card elevation={CardElevation.Sheet} className={styles.Sidebar}>
                        <NavLink className={styles.NavEntry} activeClassName={styles.Active} to={"/developers/apps"}>
                            My Applications
                        </NavLink>
                        {this.renderPageTree()}
                    </Card>
                    <Card elevation={CardElevation.Sheet} className={styles.Content}>
                        <div className={styles.Page}>
                            <Switch>
                                <Route path={"/developers/apps"}>
                                    <h1>My Applications</h1>
                                    <DevelopersApps/>
                                </Route>
                                <Route path={"/developers/docs"}>
                                    {this.renderContent()}
                                </Route>
                                <Route>
                                    <Redirect to={"/developers/docs/intro/welcome"}/>
                                </Route>
                            </Switch>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }

    renderPageTree() {
        if (this.state.index) {
            return this.state.index.chapters.map(chapter => (
                <div key={chapter.id}>
                    <div className={styles.Separator}/>
                    <span className={styles.Chapter}>{chapter.title}</span>
                    {chapter.pages.map(page => (
                        <NavLink key={page.id} className={styles.NavEntry} activeClassName={styles.Active}
                                 to={`/developers/docs/${chapter.id}/${page.id}`}>
                            {page.title}
                        </NavLink>
                    ))}
                </div>
            ));
        } else {
            return "";
        }
    }

    renderContent() {
        if (this.state.index) {
            return this.state.index.chapters.map(chapter => (
                chapter.pages.map(page => (
                    <Route exact={true} path={`/developers/docs/${chapter.id}/${page.id}`}
                           key={chapter.id + "#" + page.id}>
                        <DevelopersContent chapter={chapter} page={page}/>
                    </Route>
                ))
            ))
        } else {
            return <div className={styles.Spinner}><ScaleLoader color={"#46C3D3"}/></div>
        }
    }

    componentDidMount() {
        titleManager.set("Developer Documentation");
        getDocIndex().then(index => this.setState({index})).catch(console.error);
    }
}
