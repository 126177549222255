import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ConnectionSettings from "../Presentation/Settings/Connections/ConnectionSettings";
import {loadUserData, toggleSettingsLoading} from "../../Redux/Actions";

function mapStateToProps(state: AppState) {
    return {
        user: state.user,
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        loadUserData,
        toggleSettingsLoading
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionSettings);
