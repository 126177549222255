import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import SpotifyTrackingAlert from "../Presentation/SpotifyTrackingAlert/SpotifyTrackingAlert";
import {dismissTrackingDelayed} from "../../Redux/Actions";

function mapStateToProps(state: AppState) {
    const dismissed = state.trackingDelayedDismissed && Date.now() < state.trackingDelayedDismissed;
    return {
        delayedSince: dismissed ? undefined : state.trackingDelayedSince
    }
}


function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        dismissTrackingDelayed
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SpotifyTrackingAlert);
