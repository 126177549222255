import React from "react";
import {cssVar} from "../../../../variables";

interface Props {
    fill?: string;
}

export default class AngleRightIcon extends React.Component<Props> {
    render() {
        return (
            <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={"IconSvg"}>
                <path className={"IconPath"}
                      d="M6.76154 6.08419L2.15332 10.7577C1.83482 11.0808 1.31978 11.0808 1.00466 10.7577L0.238882 9.9811C-0.0796272 9.65808 -0.0796272 9.13574 0.238882 8.81615L3.50529 5.50344L0.238882 2.19072C-0.0796272 1.8677 -0.0796272 1.34536 0.238882 1.02577L1.00127 0.242268C1.31978 -0.080756 1.83482 -0.080756 2.14994 0.242268L6.75815 4.91581C7.08005 5.23883 7.08005 5.76117 6.76154 6.08419Z"
                      fill={this.props.fill || cssVar("--accent-pink")}/>
            </svg>
        )
    }
}
