import React from "react";
import {UserState} from "../../../../Redux/Types";
import Button, {ButtonColor} from "../../Button/Button";
import styles from "./MfaSetup.module.scss";
import {NewMfaSetupResponse} from "../../../../API/Models";
import {confirmMfaSetup, newMfaSetup} from "../../../../API/Calls";
import MfaInput from "../../MfaInput/MfaInput";

interface Props {
    user: UserState | null
    closeFunction: (success: boolean) => void;
}

interface State {
    loading: boolean,
    errorMessage?: string;
    mfaSetupResponse?: NewMfaSetupResponse,
    qrScanned: boolean;
    recoveryCodes?: string[];
}

export default class MfaSetup extends React.Component<Props, State> {
    abort = new AbortController();
    totpRef = React.createRef<MfaInput>();

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            loading: false,
            qrScanned: false,
        };

        this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
        this.handleConfirmSubmit = this.handleConfirmSubmit.bind(this);
    }

    render() {
        if (this.state.mfaSetupResponse === undefined) {
            return this.renderPasswordForm();
        } else if (!this.state.qrScanned) {
            return this.renderScanPage();
        } else if (!this.state.recoveryCodes) {
            return this.renderConfirmPage();
        } else {
            return this.renderRecoveryPage();
        }
    }

    renderPasswordForm() {
        return (
            <form onSubmit={this.handlePasswordSubmit}>
                {
                    this.state.errorMessage ? <div className={"AuthError"}>{this.state.errorMessage}</div> : ""
                }
                <div className={styles.PasswordForm}>
                    <p>
                        Two-factor authentication adds an extra layer of security to your account.
                    </p>
                    <p>
                        Every time you login, you will be prompted for a 6-digit code. You'll be able to retrieve this
                        code from an authenticator app on your phone, like&nbsp;
                        {/*eslint-disable-next-line*/}
                        <a target={"_blank"} rel={"noopener noreferrer"}
                           href={"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"}
                        >Google Authenticator</a>.
                    </p>
                    <p>
                        Enter your password to get started.
                    </p>
                    <div className={"FormField " + styles.FormField}>
                        <label htmlFor={"Password"}>
                            Password
                        </label>
                        <input required={true} name={"Password"} type={"password"}
                               autoComplete={"off"}/>
                    </div>
                    <div className={"FormField SubmitRegion " + styles.FormField + " " + styles.SubmitRegion}>
                        <Button color={ButtonColor.Regular} disabled={this.state.loading}
                                onClick={() => this.props.closeFunction(false)}>Cancel</Button>
                        <Button form={true} color={ButtonColor.Primary} disabled={this.state.loading}>Next</Button>
                    </div>
                </div>
            </form>
        )
    }

    renderScanPage() {
        return (
            <div className={styles.PasswordForm}>
                <p>
                    Scan this QR code in your authenticator app, for example&nbsp;
                    {/*eslint-disable-next-line*/}
                    <a target={"_blank"} rel={"noopener noreferrer"}
                       href={"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"}
                    >Google Authenticator</a>. Click Next when you're done.
                </p>
                <p>
                    Can't scan the QR code? You can also enter the code under it into your authenticator app.
                </p>
                <div className={styles.QR}>
                    <img src={this.qrCodeSrc} alt={"QR Code"}/>
                    <div className={styles.Secret}>{this.formattedSecret}</div>
                </div>
                <div className={"FormField SubmitRegion " + styles.FormField + " " + styles.SubmitRegion}>
                    <Button color={ButtonColor.Regular}
                            onClick={() => this.props.closeFunction(false)}>Cancel</Button>
                    <Button onClick={() => this.setState({qrScanned: true})} color={ButtonColor.Primary}>Next</Button>
                </div>
            </div>
        )
    }

    renderConfirmPage() {
        return (
            <form onSubmit={this.handleConfirmSubmit}>
                {
                    this.state.errorMessage ? <div className={"AuthError"}>{this.state.errorMessage}</div> : ""
                }
                <div className={styles.PasswordForm}>
                    <p>
                        Great! To finalize the setup, you must input a 6-digit code from your authenticator app.
                    </p>
                    <div className={styles.CodeInput}>
                        <MfaInput ref={this.totpRef}/>
                    </div>
                    <div className={"FormField SubmitRegion " + styles.FormField + " " + styles.SubmitRegion}>
                        <Button color={ButtonColor.Regular} disabled={this.state.loading}
                                onClick={() => this.setState({
                                    qrScanned: false,
                                    errorMessage: undefined,
                                    loading: false
                                })}>Back</Button>
                        <Button form={true} color={ButtonColor.Primary} disabled={this.state.loading}>Next</Button>
                    </div>
                </div>
            </form>
        );
    }

    renderRecoveryPage() {
        return (
            <div className={styles.PasswordForm}>
                <p>
                    One last thing! In case you lose access to your phone, you will need one of these 10 recovery
                    codes.
                </p>
                <p>
                    Keep them somewhere safe! We won't be able to recover your account if you lose them.
                </p>
                <div className={styles.Recovery}>
                    {this.state.recoveryCodes?.join(" ")}
                </div>
                <div className={"FormField SubmitRegion " + styles.FormField + " " + styles.SubmitRegion}
                     style={{textAlign: "center"}}>
                    <Button onClick={() => this.props.closeFunction(true)} color={ButtonColor.Primary}>Done</Button>
                </div>
            </div>
        );
    }

    handlePasswordSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (this.state.loading || !this.props.user) {
            return;
        }

        const password = ((event.target as HTMLFormElement).elements.namedItem("Password") as HTMLInputElement).value;
        if (!password || !password.trim()) {
            return;
        }

        this.setState({loading: true, errorMessage: undefined}, () => {
            newMfaSetup(password, this.abort)
                .then(resp => {
                    this.setState({
                        loading: false,
                        errorMessage: undefined,
                        mfaSetupResponse: resp,
                    })
                })
                .catch(error => {
                    this.setState({loading: false, errorMessage: error.toString()});
                });
        });
    }

    handleConfirmSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (this.state.loading || !this.props.user || !this.state.mfaSetupResponse || !this.totpRef.current?.valid) {
            return;
        }

        const code = this.totpRef.current?.value || "";
        const lease = this.state.mfaSetupResponse.lease;

        this.setState({loading: true, errorMessage: undefined}, () => {
            confirmMfaSetup(code, lease, this.abort)
                .then(resp => {
                    this.setState({
                        loading: false,
                        errorMessage: undefined,
                        recoveryCodes: resp
                    })
                })
                .catch(error => {
                    this.setState({loading: false, errorMessage: error.toString()});
                    if (this.totpRef.current) {
                        this.totpRef.current.reset();
                    }
                });
        });
    }

    get qrCodeSrc(): string {
        if (this.state.mfaSetupResponse) {
            return `data:image/png;base64,${this.state.mfaSetupResponse.qr}`;
        } else {
            return "";
        }
    }

    get formattedSecret(): string {
        if (this.state.mfaSetupResponse) {
            // Add space every 4 characters with regex trick
            return this.state.mfaSetupResponse.secret.match(/.{1,4}/g)?.join(" ") ?? "";
        } else {
            return "";
        }
    }

    componentWillUnmount(): void {
        this.abort.abort();
    }
}
