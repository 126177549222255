import React from "react";
import styles from "./CommentBox.module.scss";
import Button, {ButtonColor} from "../Button/Button";
import {CommentNode, CommentText} from "../../../API/Models";

interface Props {
    placeholder?: string;
    className?: string;
    onSend?: (value: CommentText) => void;
    readOnly?: boolean;
    initialValue?: CommentNode[];
    blocked?: boolean;
}

interface State {
    value: string;
    focused: boolean;
    blocked: boolean;
}

export default class CommentBox extends React.Component<Props, State> {

    private readonly basicEditorRef = React.createRef<HTMLTextAreaElement>();
    private static readonly MAX_CHARACTERS = 500;
    private static readonly MAX_PARAGRAPHS = 5;

    constructor(props: Readonly<Props>) {
        super(props);

        this.send = this.send.bind(this);
        this.handleBasicEditor = this.handleBasicEditor.bind(this);
        this.doEditorFocus = this.doEditorFocus.bind(this);
        this.doEditorSelect = this.doEditorSelect.bind(this);

        this.state = {
            value: "",
            focused: false,
            blocked: props.blocked || false
        }
    }

    render(): React.ReactNode {
        if (this.props.readOnly) {
            return this.renderReadOnly();
        } else {
            return this.renderEditor();
        }
    }

    renderReadOnly() {
        const nodes = this.props.initialValue ?? [];

        return (
            <div className={this.getReadOnlyClasses()}>
                {/* TODO: Support rendering non-text nodes */}
                {
                    this.state.blocked ?
                        <p onClick={() => this.setState({blocked: false})}>
                            This comment is hidden because you blocked the author. Click to view it anyway.
                        </p>
                        :
                        nodes.map((node, index) =>
                            <p key={index}>
                                {node.children.map((child, index) =>
                                    <span key={index}>{child.text}</span>)}
                            </p>)
                }
            </div>
        )
    }

    renderEditor() {
        return (
            <div className={this.getEditorContainerClasses()}
                 onClick={this.doEditorFocus} onDoubleClick={this.doEditorSelect}>
                <textarea className={this.getEditorClasses()} ref={this.basicEditorRef}
                          value={this.state.value} maxLength={CommentBox.MAX_CHARACTERS}
                          onFocus={() => this.setState({focused: true})}
                          onBlur={() => this.setState({focused: false})}
                          onChange={this.handleBasicEditor} placeholder={this.props.placeholder}/>
                {this.renderActions()}
            </div>
        )
    }

    doEditorFocus() {
        if (this.basicEditorRef.current) {
            this.basicEditorRef.current.focus();
        }
    }

    doEditorSelect() {
        if (this.basicEditorRef.current) {
            this.basicEditorRef.current.select();
        }
    }

    handleBasicEditor(e: React.ChangeEvent<HTMLTextAreaElement>) {
        if (this.basicEditorRef.current) {
            let value = e.target.value;

            this.basicEditorRef.current.style.height = "";
            this.basicEditorRef.current.style.height = (this.basicEditorRef.current.scrollHeight) + "px";
            this.setState({value});
        }
    }

    renderActions() {
        if (this.hasText()) {
            return <Button className={styles.SendButton} color={ButtonColor.Primary} outline={true}
                           onClick={this.send}>Send</Button>
        } else {
            return "";
        }
    }

    getEditorContainerClasses(): string {
        const classes = [styles.EditorContainer];
        if (this.state.focused) {
            classes.push(styles.Focused);
        }
        return classes.join(" ");
    }

    getEditorClasses(): string {
        const classes = [styles.Editor, "NoDefaultStyle"];
        if (this.props.className) {
            classes.push(this.props.className);
        }
        return classes.join(" ");
    }

    getReadOnlyClasses(): string {
        const classes = [styles.ReadOnlyContainer];
        if (this.state.blocked) {
            classes.push(styles.BlockedComment)
        }
        if (this.props.className) {
            classes.push(this.props.className);
        }
        return classes.join(" ");
    }

    send(e: React.MouseEvent): void {
        e.stopPropagation();

        if (this.props.onSend) {
            this.props.onSend({nodes: CommentBox.getNodes(this.state.value)});
        }

        if (this.basicEditorRef.current) {
            this.basicEditorRef.current.style.height = "";
            this.setState({value: ""})
        }
    }

    hasText(): boolean {
        return CommentBox.getNodes(this.state.value).length > 0;
    }

    scrollIntoView() {
        if (this.basicEditorRef.current) {
            this.basicEditorRef.current.scrollIntoView({behavior: "smooth", block: "center"});
            this.basicEditorRef.current.focus({preventScroll: true});
        }
    }

    static nodeLength(node: CommentNode): number {
        // TODO: Support non-text children
        return node.children.map(child => child.text).join("").trim().length;
    }

    static getNodes(plainText: string): CommentNode[] {
        const paragraphs = plainText.split("\n").filter(line => !!line.trim());
        if (paragraphs.length === 0) return [];

        const remainder = paragraphs.splice(this.MAX_PARAGRAPHS).join(" ");
        if (remainder) {
            paragraphs[paragraphs.length - 1] += " " + remainder;
        }

        return paragraphs.map(line => {
            return {
                children: [
                    {text: line.trim()}
                ]
            }
        }).filter(node => this.nodeLength(node) > 0);
    }
}
