import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import AboutContact from "../Presentation/AboutContact/AboutContact";

function mapStateToProps(state: AppState) {
    return {
        user: state.user
    }
}


function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutContact);
