import "./ConnectionSettings.module.scss";
import React from "react";
import {UserState} from "../../../../Redux/Types";
import spotifyIcon from "../../../../Images/spotify-icon-green.png";
import discordIcon from "../../../../Images/Discord.svg";
import {
    deleteDiscordData,
    deleteSpotifyData, getCurrentUser,
    openDiscordAuthorization,
    openSpotifyAuthorization,
    postToken
} from "../../../../API/Calls";
import Button, {ButtonColor} from "../../Button/Button";
import CardModal from "../../CardModal/CardModal";
import styles from "./ConnectionSettings.module.scss";
import {titleManager} from "../../../../Title";
import {DISCORD_INVITE} from "../../../../Discord";

interface Props {
    user: UserState | null;
    loadUserData: (data: any) => void;
    toggleSettingsLoading: (loading: boolean) => void;
}

interface State {
    spotifyLoading: boolean;
    spotifyDisconnectConfirmation: boolean;
    discordLoading: boolean;
    discordDisconnectConfirmation: boolean;
    errorDiscordTaken: boolean;
    errorSpotifyFailed: boolean;
    ready: boolean;
}

export default class ConnectionSettings extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            spotifyLoading: false,
            spotifyDisconnectConfirmation: false,
            discordLoading: false,
            discordDisconnectConfirmation: false,
            errorDiscordTaken: false,
            errorSpotifyFailed: false,
            ready: false
        };

        this.openSpotify = this.openSpotify.bind(this);
        this.openDiscord = this.openDiscord.bind(this);
        this.disconnectSpotify = this.disconnectSpotify.bind(this);
        this.disconnectDiscord = this.disconnectDiscord.bind(this);
    }

    render() {
        return (
            <div>
                <CardModal isOpen={this.state.spotifyDisconnectConfirmation} title={"Disconnect Spotify"}
                           onRequestClose={() => this.setState({spotifyDisconnectConfirmation: false})}>
                    <div className={styles.WarningText}>
                        <p>
                            <strong>Are you sure?</strong>
                        </p>
                        <p>
                            wavy.fm will not track listens while your Spotify is disconnected.
                        </p>
                        <p>
                            <span className={styles.DisconnectWarning}>Warning</span>: Disconnecting
                            your Spotify account during a Spotify delay will cause unwanted
                            data loss.
                        </p>
                        <div className={styles.ActionButtons}>
                            <Button color={ButtonColor.Danger} onClick={this.disconnectSpotify}>Disconnect</Button>
                            <Button color={ButtonColor.Regular}
                                    onClick={() => this.setState({spotifyDisconnectConfirmation: false})}>Cancel</Button>
                        </div>
                    </div>
                </CardModal>
                <CardModal isOpen={this.state.discordDisconnectConfirmation} title={"Disconnect Discord"}
                           onRequestClose={() => this.setState({discordDisconnectConfirmation: false})}>
                    <div className={styles.WarningText}>
                        <p>
                            <strong>Are you sure?</strong>
                        </p>
                        <p>
                            Your wavy.fm account will not longer be connected to Discord.
                        </p>
                        <div className={styles.ActionButtons}>
                            <Button color={ButtonColor.Danger} onClick={this.disconnectDiscord}>Disconnect</Button>
                            <Button color={ButtonColor.Regular}
                                    onClick={() => this.setState({discordDisconnectConfirmation: false})}>Cancel</Button>
                        </div>
                    </div>
                </CardModal>
                <CardModal isOpen={this.state.errorDiscordTaken} title={"Error"}
                           onRequestClose={() => this.setState({errorDiscordTaken: false})}>
                    <div className={styles.WarningText}>
                        <p>
                            <strong>Discord Account Taken</strong>
                        </p>
                        <p>
                            This Discord account is already connected to another wavy.fm user.
                        </p>
                        <div className={styles.ActionButtons}>
                            <Button color={ButtonColor.Regular}
                                    onClick={() => this.setState({errorDiscordTaken: false})}>Close</Button>
                        </div>
                    </div>
                </CardModal>
                <CardModal isOpen={this.state.errorSpotifyFailed} title={"Error"}
                           onRequestClose={() => this.setState({errorSpotifyFailed: false})}>
                    <div className={styles.WarningText}>
                        <p>
                            <strong>Failed to connect to Spotify</strong>
                        </p>
                        <p>
                            A temporary issue with Spotify prevented us from connecting your account. Please try again.
                        </p>
                        <div className={styles.ActionButtons}>
                            <Button color={ButtonColor.Regular}
                                    onClick={() => this.setState({errorSpotifyFailed: false})}>Close</Button>
                        </div>
                    </div>
                </CardModal>
                <div className={"Title"}>
                    Connections
                </div>
                <div className={"Content"}>
                    {
                        this.props.user === null ? "" :
                            <div>
                                {
                                    (!this.props.user.spotifyId || !this.props.user.spotifyDisplayName) ?
                                        <div className={"Section"}>
                                            <img src={spotifyIcon} className={"DescriptionLogo"} alt="Spotify"/>
                                            <div className={"DescriptionParent " + styles.DescriptionParent}>
                                                <div className={"DescriptionTitle"}>Connect your Spotify account to your
                                                    profile
                                                </div>
                                                <div className={"Description"}>
                                                    Tracking only works when your wavy.fm profile is connected to a
                                                    Spotify account.
                                                </div>
                                            </div>
                                            <div className={"ButtonParent"}>
                                                <Button color={ButtonColor.Spotify} disabled={this.state.spotifyLoading}
                                                        onClick={this.openSpotify}>Connect</Button>
                                            </div>
                                        </div>
                                        :
                                        <div className={"Section"}>
                                            <img src={spotifyIcon} className={"DescriptionLogo"} alt="Spotify"/>
                                            <div className={"DescriptionParent " + styles.DescriptionParent}>
                                                <div className={"DescriptionTitle"}>Spotify account is connected</div>
                                                <div className={"Description"}>
                                                    {this.props.user.spotifyDisplayName}
                                                </div>
                                            </div>
                                            <div className={"ButtonParent"}>
                                                <Button color={ButtonColor.Danger} disabled={this.state.spotifyLoading}
                                                        onClick={() => this.setState({spotifyDisconnectConfirmation: true})}>Disconnect</Button>
                                            </div>
                                        </div>
                                }
                                {
                                    (!this.props.user.discordId || !this.props.user.discordDisplayName) ?
                                        <div className={"Section"}>
                                            <img src={discordIcon} className={"DescriptionLogo"} alt="Discord"/>
                                            <div className={"DescriptionParent " + styles.DescriptionParent}>
                                                <div className={"DescriptionTitle"}>Connect your Discord account to your
                                                    profile
                                                </div>
                                                <div className={"Description"}>
                                                    By connecting your Discord account, you will be able to interact
                                                    with your wavy.fm account in our official&nbsp;
                                                    {/*eslint-disable-next-line react/jsx-no-target-blank*/}
                                                    <a href={DISCORD_INVITE} target={"_blank"}
                                                       className={styles.DiscordLink}
                                                       rel={"noopener noreferrer"}>Discord server</a>.
                                                </div>
                                            </div>
                                            <div className={"ButtonParent"}>
                                                <Button color={ButtonColor.Discord} disabled={this.state.discordLoading}
                                                        onClick={this.openDiscord}>Connect</Button>
                                            </div>
                                        </div>
                                        :
                                        <div className={"Section"}>
                                            <img src={discordIcon} className={"DescriptionLogo"} alt="Discord"/>
                                            <div className={"DescriptionParent " + styles.DescriptionParent}>
                                                <div className={"DescriptionTitle"}>Discord account is connected</div>
                                                <div className={"Description"}>
                                                    {this.props.user.discordDisplayName}
                                                    &nbsp;&mdash; Come say hi on our&nbsp;
                                                    {/*eslint-disable-next-line react/jsx-no-target-blank*/}
                                                    <a href={DISCORD_INVITE} target={"_blank"}
                                                       className={styles.DiscordLink}
                                                       rel={"noopener noreferrer"}>Discord server</a>!
                                                </div>
                                            </div>
                                            <div className={"ButtonParent"}>
                                                <Button color={ButtonColor.Danger} disabled={this.state.discordLoading}
                                                        onClick={() => this.setState({discordDisconnectConfirmation: true})}>Disconnect</Button>
                                            </div>
                                        </div>
                                }
                            </div>
                    }
                </div>
            </div>
        )
    }

    loadUser(): void {
        this.props.toggleSettingsLoading(true);

        postToken()
            .then(() => getCurrentUser()
                .then(data => {
                    this.props.loadUserData(data);
                    this.props.toggleSettingsLoading(false);
                    this.setState({ready: true});
                }))
            .catch(console.error)
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Connection Settings");

        if (this.props.user) {
            this.loadUser();
        }

        if (window.location.hash === "#error-discord-taken") {
            this.setState({errorDiscordTaken: true});
        } else if (window.location.hash === "#error-spotify-failed") {
            this.setState({errorSpotifyFailed: true});
        }
    }

    openSpotify() {
        if (this.props.user !== null) {
            this.setState({spotifyLoading: true});
            openSpotifyAuthorization();
        }
    }

    disconnectSpotify() {
        if (this.props.user !== null) {
            this.setState({spotifyLoading: true, spotifyDisconnectConfirmation: false});
            deleteSpotifyData()
                .then(() => {
                    // Reload user data
                    postToken()
                        .then(() => getCurrentUser()
                            .then(data => {
                                this.props.loadUserData(data);
                                this.setState({spotifyLoading: false});
                            }))
                        .catch(console.error)
                })
                .catch(console.error);
        }
    }

    openDiscord() {
        if (this.props.user !== null) {
            this.setState({discordLoading: true});
            openDiscordAuthorization();
        }
    }

    disconnectDiscord() {
        if (this.props.user !== null) {
            this.setState({discordLoading: true, discordDisconnectConfirmation: false});
            deleteDiscordData()
                .then(() => {
                    // Reload user data
                    postToken()
                        .then(() => getCurrentUser()
                            .then(data => {
                                this.props.loadUserData(data);
                                this.setState({discordLoading: false});
                            }))
                        .catch(console.error)
                })
                .catch(console.error);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.user && !prevProps.user) {
            this.setState({ready: true});
            this.props.toggleSettingsLoading(false);
        }
    }
}
