import React from "react";
import {titleManager} from "../../../Title";

interface Props {

}

interface State {

}

export default class DevelopersApps extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <p>At the moment, wavy.fm's API is in a&nbsp;
                {/*eslint-disable-next-line*/}
                <a href={"https://medium.com/p/d7fe3a97b620/"} target={"_blank"}
                   rel={"noopener noreferrer"}>private beta</a>.
                If you're interested in participating, please submit this&nbsp;
                {/*eslint-disable-next-line*/}
                <a href={"https://forms.gle/eADHEuhCYXE9rvh5A"} target={"_blank"}
                   rel={"noopener noreferrer"}>form</a> and we'll get back to you!
            </p>
        )
    }

    componentDidMount() {
        titleManager.set("My Applications");
    }
}
