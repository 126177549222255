import React from "react";
import MusicList, {Entry} from "../MusicList/MusicList";
import styles from "./ProfileRecentSongs.module.scss";
import {ForeignUser} from "../../../Redux/Types";
import {createLiveWavyTrack, GetProfileListensResponse} from "../../../API/Models";
import DefaultAlbum from "../../../Images/DefaultAlbum.svg";
import {spotifyUriToId} from "../../../SpotifyUtil";
import SongSubject from "../MusicSubject/SongSubject";
import {DELETE_LISTEN, MusicAction, OPEN_IN_SPOTIFY} from "../MusicActionPopup/MusicAction";

interface Props {
    profile: ForeignUser;
    isOwnProfile: boolean;
    recentListens: GetProfileListensResponse | null;
    recentListensLoading: boolean;
    reloadListens: () => any;
    setProfileRecentListensLoading: () => void;
}

interface State {
}

export default class ProfileRecentSongs extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {};

        this.actionGenerator = this.actionGenerator.bind(this);
    }

    render() {
        if (!this.props.recentListens || (this.props.recentListens.total_tracks === 0 && !this.isLive())) {
            return "";
        }
        return (
            <div className={this.getClasses()}>
                <div className={styles.MobileTitle}>Recently Played</div>
                <MusicList
                    entries={this.getTrackHistoryAsEntries()}
                    metricType={"time"}
                    moreLink={"/user/" + this.props.profile.username + "/history/recent"}
                    defaultSize={(this.isMobile() || this.isLive()) ? 5 : 6}
                    className={styles.TrackHistoryList}
                    autoCollapse={true}
                    isMobile={this.isMobile()}
                    rowClass={this.getRowClass()}
                    actionGenerator={this.actionGenerator}
                />
            </div>
        )
    }

    getClasses(): string {
        const classes = [];
        if (this.props.recentListensLoading) {
            classes.push(styles.Loading);
        }
        return classes.join(" ");
    }

    isMobile() {
        return window.innerWidth <= 768;
    }

    isLive() {
        return !!this.props.recentListens?.live;
    }

    getRowClass(): string {
        const classes = [styles.HistoryListRow];
        if (this.isLive()) {
            classes.push(styles.Live)
        }
        return classes.join(" ");
    }

    getTrackHistoryAsEntries(): Entry[] {
        if (!this.props.recentListens) return [];

        const tracks = [...this.props.recentListens.tracks];
        if (this.props.recentListens.live && this.isMobile()) {
            tracks.unshift(createLiveWavyTrack(this.props.recentListens.live));
            if (tracks.length > 1) {
                tracks.pop();
            }
        }

        return tracks.map(track => {
            // Spotify URL hack
            const url = `https://open.spotify.com/track/${spotifyUriToId(track.track.uri)}`;
            const image = track.track.album.images
                .sort((a, b) => a.width - b.width)[0]?.url ?? DefaultAlbum;

            return {
                id: track.play_id,
                primary: track.track.name,
                secondary: track.track.artists[0].name,
                metric: new Date(track.date.$date),
                icon: image,
                playerLink: url,
                actionSubject: () => <SongSubject name={track.track.name} artist={track.track.artists[0].name}
                                                  image={image}/>
            }
        });
    }

    private actionGenerator(entry: Entry): MusicAction[] {
        const actions = [OPEN_IN_SPOTIFY(entry.playerLink)];
        if (!this.isLiveEntry(entry) && this.props.isOwnProfile) {
            actions.push(DELETE_LISTEN(entry.id,
                () => {
                    this.props.setProfileRecentListensLoading();
                }, () => {
                    this.props.reloadListens();
                }))
        }
        return actions;
    }

    private isLiveEntry(entry: Entry): boolean {
        return entry.id === "live";
    }
}
