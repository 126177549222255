import React from "react";
import FriendsActivity from "../FriendsActivity/FriendsActivity";
import {ForeignUser} from "../../../Redux/Types";

interface Props {
    profile: ForeignUser;
    isOwnProfile: boolean;
}

export default class ProfileAlt extends React.Component<Props> {
    render() {
        if (window.innerWidth <= 768 || !this.props.profile.$access.view) {
            return "";
        }

        return (
            <div>
                <FriendsActivity smaller={true} user={this.props.profile} isSelf={this.props.isOwnProfile} max={5}/>
            </div>
        )
    }
}
