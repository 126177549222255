import React from "react";
import styles from "./MusicActionPopup.module.scss";
import PopoverModal from "../PopoverModal/PopoverModal";
import Card from "../Card/Card";
import PopupMenu from "../PopupMenu/PopupMenu";
import {MusicAction} from "./MusicAction";
import Button, {ButtonColor} from "../Button/Button";

interface Props {
    /**
     * Called when some action should result in the popup being closed.
     */
    onCloseRequested: () => void;
    /**
     * A ref to something that caused the popup to open
     */
    toggleRef: React.RefObject<HTMLElement>;
    /**
     * A component rendering the subject of this popup, e.g. a song, an artist, etc.
     */
    subject: React.ReactNode;
    /**
     * Enables desktop. Defaults to true.
     */
    desktop?: boolean;
    /**
     * Enables mobile. Defaults to true.
     */
    mobile?: boolean;
    /**
     * Actions in the menu.
     */
    actions: MusicAction[];
}

interface State {
    closing?: boolean;
    confirming?: MusicAction;
}

export default class MusicActionPopup extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {};
    }

    render() {
        if (window.innerWidth <= 768 && this.props.mobile !== false) {
            return (
                <PopoverModal focused={true} closed={this.state.closing}
                              onFocusLost={this.props.onCloseRequested}
                              className={styles.Popover}>
                    <div className={styles.Subject}>
                        {this.props.subject}
                    </div>
                    <div className={styles.SubjectSeparator}/>
                    {this.renderPopoverContent()}
                </PopoverModal>
            )
        } else if (window.innerWidth >= 769 && this.props.desktop !== false) {
            return (
                <Card className={styles.MenuFloat}>
                    <PopupMenu toggleRef={this.props.toggleRef} closeMenu={this.props.onCloseRequested}>
                        {this.props.actions.map(action => (
                            <div key={action.id} className={styles.MenuItem} onClick={() => {
                                action.onClick();
                                this.props.onCloseRequested();
                            }}>
                                <div className={styles.Icon}>
                                    {action.icon}
                                </div>
                                <div className={styles.Label}>
                                    {action.label}
                                </div>
                            </div>
                        ))}
                    </PopupMenu>
                </Card>
            )
        } else {
            return "";
        }
    }

    renderPopoverContent() {
        if (this.state.confirming) {
            return (
                <div className={styles.Confirmation}>
                    <div className={styles.Prompt}>
                        {this.state.confirming.confirmation?.prompt() || ""}
                    </div>
                    <div className={styles.ButtonParent}>
                        <Button color={ButtonColor.Regular}
                                onClick={() => {
                                    this.setState({closing: true});
                                    setTimeout(() => this.props.onCloseRequested(), 250);
                                }}>Cancel</Button>
                        <Button color={ButtonColor.Danger} outline={false}
                                onClick={() => {
                                    this.setState({closing: true});
                                    this.state.confirming?.onClick();
                                }}>{this.state.confirming.confirmation?.actionLabel ?? "Confirm"}</Button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={styles.Actions}>
                    {this.props.actions.map(action => (
                        <div key={action.id} className={styles.Action} onClick={() => {
                            if (action.confirmation) {
                                this.setState({closing: true});
                                setTimeout(() => this.setState({closing: false, confirming: action}), 350);
                            } else {
                                action.onClick();
                                this.setState({closing: true});
                                setTimeout(() => this.props.onCloseRequested(), 250);
                            }
                        }}>
                            <div className={styles.Icon}>
                                {action.icon}
                            </div>
                            <div className={styles.Label}>
                                {action.label}
                            </div>
                        </div>
                    ))}
                </div>
            )
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevState.confirming && !this.state.confirming) {
            this.props.onCloseRequested();
        }
    }
}
