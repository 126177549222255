import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {loadUserData, logout, setUserPrivacy, toggleDeleteModal, toggleSettingsLoading} from "../../Redux/Actions";
import PrivacySettings from "../Presentation/Settings/Privacy/PrivacySettings";

function mapStateToProps(state: AppState) {
    return {
        showDeleteModal: state.accountSettings.deleteModal,
        user: state.user,
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        loadUserData,
        toggleSettingsLoading,
        toggleDeleteModal,
        setUserPrivacy,
        logout,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacySettings);
