import React from "react";
import {cssVar} from "../../../../variables";

export default class NavIconFriend extends React.Component {
    render() {
        return (
            <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={"IconSvg"}>
                <path className={"IconPath"}
                      d="M8.91217 3.90347C8.91217 5.4721 7.64092 6.74335 6.07229 6.74335C4.50366 6.74335 3.23241 5.4721 3.23241 3.90347C3.23241 2.33485 4.50366 1.06359 6.07229 1.06359C7.64092 1.06359 8.91217 2.33485 8.91217 3.90347ZM6.07229 9.30456C6.82128 9.30456 7.53485 9.14789 8.18187 8.87082H8.50121C10.1647 8.87082 11.5146 10.2207 11.5146 11.8842V13.0119C11.5146 13.3823 11.2138 13.6831 10.8434 13.6831H1.3012C0.930771 13.6831 0.63 13.3823 0.63 13.0119V11.8842C0.63 10.2207 1.97987 8.87082 3.64337 8.87082H3.96316C4.61135 9.14756 5.3225 9.30456 6.07229 9.30456Z"
                      stroke={cssVar('--light-text')} strokeWidth="1.26"/>
            </svg>
        )
    }
}


// <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M8.91217 3.90347C8.91217 5.4721 7.64092 6.74335 6.07229 6.74335C4.50366 6.74335 3.23241 5.4721 3.23241 3.90347C3.23241 2.33485 4.50366 1.06359 6.07229 1.06359C7.64092 1.06359 8.91217 2.33485 8.91217 3.90347ZM6.07229 9.30456C6.82128 9.30456 7.53485 9.14789 8.18187 8.87082H8.50121C10.1647 8.87082 11.5146 10.2207 11.5146 11.8842V13.0119C11.5146 13.3823 11.2138 13.6831 10.8434 13.6831H1.3012C0.930771 13.6831 0.63 13.3823 0.63 13.0119V11.8842C0.63 10.2207 1.97987 8.87082 3.64337 8.87082H3.96316C4.61135 9.14756 5.3225 9.30456 6.07229 9.30456Z" stroke="#666666" stroke-width="1.26"/>
// </svg>
