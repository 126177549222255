import {AppState, ForeignUser} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ProfileFriendButton from "../Presentation/ProfileFriendButton/ProfileFriendButton";
import {
    toggleProfileBlockConfirm,
    toggleProfileReportForm,
    toggleProfileUnfriendConfirm,
    updateProfileFriendship
} from "../../Redux/Actions";

function mapStateToProps(state: AppState) {
    return {
        isLoggedIn: state.user !== null && state.profileReady,
        profile: state.profilePage.profile as ForeignUser,
        status: state.profilePage.friendship,
        reloadProfile$: state.profilePage.reload$
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        updateProfileFriendship,
        toggleProfileReportForm,
        toggleProfileUnfriendConfirm,
        toggleProfileBlockConfirm
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFriendButton);
