import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {setNotifications, toggleNotificationsMenu} from "../../Redux/Actions";
import React from "react";
import NotificationsMenu from "../Presentation/NotificationsMenu/NotificationsMenu";

interface NotificationsMenuContainer {
    toggleRef: React.RefObject<HTMLElement>;
}

function mapStateToProps(state: AppState, ownProps: NotificationsMenuContainer) {
    return {
        user: state.user,
        toggleRef: ownProps.toggleRef,
        open: state.menus.notifications,
        notifications: state.notifications
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        toggleNotificationsMenu,
        setNotifications
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsMenu);
