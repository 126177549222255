import {AppState, ForeignUser, isOwnProfile} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ProfileRecentSongs from "../Presentation/ProfileRecentSongs/ProfileRecentSongs";
import {setProfileRecentListensLoading} from "../../Redux/Actions";

function mapStateToProps(state: AppState) {
    return {
        profile: state.profilePage.profile as ForeignUser,
        isOwnProfile: isOwnProfile(state),
        recentListens: state.profilePage.recentListens,
        recentListensLoading: state.profilePage.recentListensLoading,
        reloadListens: () => state.profilePage.reload$.emit("reload_listens"),
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({setProfileRecentListensLoading}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileRecentSongs);
