import React from "react";
import styles from "./ProfileTutorial.module.scss";
import Button, {ButtonColor} from "../Button/Button";

interface Props {
    isOwnProfile: boolean;
    isSpotifyLinked: boolean;
    hasHistoryLoaded: boolean;
    hasListenHistory: boolean;
    isListeningLive: boolean;
    isProfilePrivate: boolean;
    isBlockedByThem: boolean;
    isBlockedByYou: boolean;
    isQuarantined: boolean;
}

export default class ProfileTutorial extends React.Component<Props> {
    render() {
        if (this.props.isBlockedByThem || this.props.isBlockedByYou) {
            return "";
        }
        if (!this.props.isOwnProfile && this.props.isProfilePrivate && this.props.isQuarantined) {
            return (
                <div className={[styles.Tutorial, styles.SpotifyLinked].join(" ")}>
                    This profile has been flagged for abusive/spam behavior. Only friends can see their profile.
                </div>
            )
        }
        if (!this.props.isOwnProfile && this.props.isProfilePrivate) {
            return (
                <div className={[styles.Tutorial, styles.SpotifyLinked].join(" ")}>
                    Only friends can see this profile.
                </div>
            )
        }
        if (!this.props.isOwnProfile || this.props.hasListenHistory || !this.props.hasHistoryLoaded) {
            return "";
        }
        if (this.props.isSpotifyLinked) {
            if (this.props.isListeningLive) {
                return (
                    <div className={[styles.Tutorial, styles.SpotifyLinked].join(" ")}>
                        Finish a song to start building your music profile!
                    </div>
                )
            } else {
                return (
                    <div className={[styles.Tutorial, styles.SpotifyLinked].join(" ")}>
                        Go ahead and listen to something to track your first song!
                    </div>
                )
            }
        } else {
            return (
                <div className={[styles.Tutorial, styles.SpotifyNotLinked].join(" ")}>
                    <div className={styles.Title}>
                        Connect your Spotify account to your profile
                    </div>
                    <div className={styles.Description}>
                        Tracking only works when your wavy.fm profile is connected
                        to a Spotify account
                    </div>
                    <div className={styles.SpotifyButton}>
                        <Button color={ButtonColor.Spotify} link={{to: "/settings/connections"}}>
                            Connect to Spotify</Button>
                    </div>
                </div>
            )
        }
    }
}
