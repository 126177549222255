import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {loadUserData, setAuthFormsError} from "../../Redux/Actions";
import {LoginForm} from "../Presentation/LoginForm/LoginForm";
import {bindActionCreators} from "redux";

function mapStateToProps(state: AppState) {
    return {};
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        loadUserData,
        setAuthFormsError,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
