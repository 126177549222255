import React from "react";
import Card, {CardElevation} from "../Card/Card";
import {GetProfileListensResponse} from "../../../API/Models";
import {ForeignUser} from "../../../Redux/Types";
import {countListensSince} from "../../../API/Calls";
import styles from "./UserHistoryStatsCard.module.scss";
import {beginningOfDay} from "../../../API/UniversalTime";
import {EventEmitter} from "events";

interface Props {
    profile: ForeignUser
    content?: React.ReactNode;
    reload$: EventEmitter;
    stats: GetProfileListensResponse | null;
}

interface State {
    listensToday: number | null
}

export default class UserHistoryStatsCard extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            listensToday: null
        }
    }

    render() {
        return (
            <Card elevation={CardElevation.Sheet} className={this.getClasses()} invisible={this.isMobile()}>
                <div className={styles.Stats}>
                    <div className={styles.Stat}>
                        <div className={styles.StatValue}>
                            {this.props.stats ? this.props.stats.total_tracks.toLocaleString() : " "}
                        </div>
                        <div className={styles.StatName}>
                            Listens
                        </div>
                    </div>
                    <div className={styles.Stat}>
                        <div className={styles.StatValue}>
                            {this.getAverage()}
                        </div>
                        <div className={styles.StatName}>
                            Average
                        </div>
                    </div>
                    <div className={styles.Stat}>
                        <div className={styles.StatValue}>
                            {this.state.listensToday !== null ? this.state.listensToday.toLocaleString() : ""}
                        </div>
                        <div className={styles.StatName}>
                            Today
                        </div>
                    </div>
                </div>
                {this.renderContent()}
            </Card>
        )
    }

    loadStats() {
        countListensSince(this.props.profile.id, beginningOfDay())
            .then(resp => this.setState({listensToday: resp.count}))
            .catch(console.error);
    }

    componentDidMount(): void {
        this.loadStats();
        this.props.reload$.on("reload", () => {
            this.loadStats()
        });
    }

    getClasses(): string {
        const classes = [styles.StatsCard];
        if (this.isMobile()) {
            classes.push(styles.Mobile);
        } else {
            classes.push(styles.Default)
        }
        return classes.join(" ");
    }

    isMobile() {
        return window.innerWidth < 768;
    }

    getAverage(): string | number {
        if (this.props.stats) {
            if (this.props.stats.tracked_days === 0) {
                return 0;
            } else {
                return Math.ceil(this.props.stats.total_tracks / this.props.stats.tracked_days).toLocaleString();
            }
        } else {
            return " ";
        }
    }

    renderContent() {
        if (this.props.content) {
            return this.props.content;
        } else {
            return "";
        }
    }
}
