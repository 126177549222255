import {AppState, isOwnProfile} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ProfileTutorial from "../Presentation/ProfileTutorial/ProfileTutorial";

function mapStateToProps(state: AppState) {
    return {
        isOwnProfile: isOwnProfile(state),
        isSpotifyLinked: !!state?.user?.spotifyId,
        hasHistoryLoaded: !!state.profilePage.recentListens,
        hasListenHistory: (state.profilePage.recentListens?.total_tracks ?? 0) > 0,
        isListeningLive: !!state.profilePage.recentListens?.live,
        isProfilePrivate: !(state.profilePage.profile?.$access.view ?? true),
        isBlockedByThem: state.profilePage.profile?.$access?.blockedByThem ?? false,
        isBlockedByYou: state.profilePage.profile?.$access?.blockedByYou ?? false,
        isQuarantined: state.profilePage.profile?.$access?.quarantined ?? false
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTutorial);
