import React from "react";
import {createAccount, getCurrentUser} from "../../../API/Calls";
import {Link} from "react-router-dom";
import Button, {ButtonColor} from "../Button/Button";

export interface JoinFormProps {
    visible: boolean;

    loadUserData: (data: any) => void,
    setAuthFormsError: (error: Error | null) => void,
}

interface JoinFormState {
    username: string;
    password: string;
    confirmPassword: string;
    email: string;

    loading: boolean;
}

export class JoinForm extends React.Component<JoinFormProps, JoinFormState> {
    constructor(props: Readonly<JoinFormProps>) {
        super(props);
        this.state = {username: "", password: "", confirmPassword: "", email: "", loading: false};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <div className={"JoinFormParent"} style={{textAlign: "center"}}>Sign ups are currently disabled. <a
                href={"https://medium.com/p/c5f72b5b1e81"}><strong>Learn more
                here.</strong></a></div>
        )
    }

    renderOld() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"JoinFormParent"}>
                    <div className={"FormField"}>
                        <label htmlFor={"Username"}>
                            Username (3 to 12 characters)
                        </label>
                        <input required={true} name={"Username"} type={"text"} value={this.state.username}
                               tabIndex={this.props.visible ? 0 : -1}
                               autoComplete={"username"}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={"FormField"}>
                        <label htmlFor={"Email"}>
                            Email address
                        </label>
                        <input required={true} name={"Email"} type={"email"} value={this.state.email}
                               tabIndex={this.props.visible ? 0 : -1}
                               autoComplete={"email"}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={"FormField"}>
                        <label htmlFor={"Password"}>
                            Password (8+ characters)
                        </label>
                        <input required={true} name={"Password"} type={"password"} value={this.state.password}
                               tabIndex={this.props.visible ? 0 : -1}
                               autoComplete={"new-password"}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={"FormField"}>
                        <label htmlFor={"ConfirmPassword"}>
                            Confirm password
                        </label>
                        <input required={true} name={"ConfirmPassword"} type={"password"}
                               value={this.state.confirmPassword}
                               tabIndex={this.props.visible ? 0 : -1}
                               autoComplete={"new-password"}
                               onChange={this.handleChange}/>
                    </div>
                    <p className={"TermsNotice"}>
                        By submitting, you agree to
                        the <Link to={"/terms"} tabIndex={this.props.visible ? 0 : -1}>Terms of Service</Link> and
                        the <Link to={"/privacy"} tabIndex={this.props.visible ? 0 : -1}>Privacy Policy</Link>.
                    </p>
                    <div className="FormField SubmitRegion">
                        <Button form={true} color={ButtonColor.Primary} disabled={this.state.loading}
                                tabIndex={this.props.visible ? 0 : -1}>Join wavy.fm</Button>
                    </div>
                </div>
            </form>
        )
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        this.setState({loading: true});
        this.props.setAuthFormsError(null);

        if (this.state.password !== this.state.confirmPassword) {
            this.setState({loading: false});
            this.props.setAuthFormsError(Error("Passwords do not match."));
            return;
        }

        createAccount(this.state.username.trim(), this.state.password, this.state.email.trim())
            .then(() => getCurrentUser())
            .then(data => {
                this.props.loadUserData(data);
            })
            .catch(e => {
                this.setState({loading: false});
                console.error("Join failed:", e);
                this.props.setAuthFormsError(e);
            })
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        if (target.name === "Username") {
            this.setState({username: target.value});
        } else if (target.name === "Password") {
            this.setState({password: target.value});
        } else if (target.name === "ConfirmPassword") {
            this.setState({confirmPassword: target.value});
        } else if (target.name === "Email") {
            this.setState({email: target.value});
        }
    }
}
