import {AppState, ForeignUser, isOwnProfile} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ProfileAlt from "../Presentation/ProfileAlt/ProfileAlt";

function mapStateToProps(state: AppState) {
    return {
        profile: state.profilePage.profile as ForeignUser,
        isOwnProfile: isOwnProfile(state)
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAlt);
