import "./Error404.scss";
import React from "react";
import {Link} from "react-router-dom";
import {titleManager} from "../../../Title";

export class Error404 extends React.Component {
    private errorContainer: React.RefObject<HTMLDivElement> = React.createRef();
    private buoy: React.RefObject<HTMLDivElement>;
    private errorDescription: React.RefObject<HTMLDivElement>;

    constructor(props: Readonly<{}>) {
        super(props);

        this.onMouseMove = this.onMouseMove.bind(this);
        this.errorContainer = React.createRef();
        this.buoy = React.createRef();
        this.errorDescription = React.createRef();
    }

    render() {
        return (
            <div className={"ErrorPage"}>
                <div className={"ErrorContainer"} onMouseMove={this.onMouseMove} ref={this.errorContainer}>
                    <div className={"ErrorDescription"} ref={this.errorDescription}>
                        <div className="ErrorCode">
                            404
                        </div>
                        <div className="ErrorSubtitle">
                            <p>
                                Looks like you got lost in the waves.
                            </p>
                            <p>
                                <Link to={"/"}>Click here to go back to wavy.fm.</Link>
                            </p>
                        </div>
                    </div>
                    <div className="LifeBuoy" ref={this.buoy}>
                    </div>
                </div>
            </div>
        )
    }

    onMouseMove(event: React.MouseEvent) {
        if (!this.errorContainer.current || !this.buoy.current || !this.errorDescription.current) {
            return;
        }

        // Parallax
        const parentOffsetTop = this.errorContainer.current.offsetTop;
        const parentOffsetLeft = this.errorContainer.current.offsetLeft;
        const parentWidth = this.errorContainer.current.clientWidth;
        const parentHeight = this.errorContainer.current.clientHeight;
        if (parentWidth <= 1100) {
            return;
        }
        const relX = (event.pageX - parentOffsetLeft) / parentWidth;
        const relY = (event.pageY - parentOffsetTop) / parentHeight;
        let newX = 100 + (relX - 0.5) * 10;
        let newY = -40 + (relY - 0.5) * 10;

        this.buoy.current.style.backgroundPositionX = String(newX) + "px";
        this.buoy.current.style.backgroundPositionY = String(newY) + "px";
        newX = 50 + (relX - 0.5) * 15;
        newY = 50 + (relY - 0.5) * 15;
        this.errorDescription.current.style.marginLeft = String(newX) + "px";
        this.errorDescription.current.style.marginTop = String(newY) + "px";
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Not Found")
    }
}
