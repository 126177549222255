import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Settings from "../Presentation/Settings/Settings";

function mapStateToProps(state: AppState) {
    return {
        loading: state.settingsLoading,
        authProfileReady: state.profileReady
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
