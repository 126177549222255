import React from "react";
import styles from "./UserPendingRequests.module.scss";
import Card, {CardElevation} from "../Card/Card";
import {GetPendingRequestsResponse} from "../../../API/Models";
import UserFriendBio from "../UserFriendBio/UserFriendBio";
import {cancelFriendRequest, createFriendRequest, getPendingFriendRequests} from "../../../API/Calls";
import Button, {ButtonColor, ButtonSize} from "../Button/Button";
import {titleManager} from "../../../Title";

interface Props {
    incomingRequestsCount?: number;
    setIncomingRequests: (count: number) => void;
}

interface State {
    abort: AbortController;
    response?: GetPendingRequestsResponse;
    actions: Map<string, string>;
    loading: Set<string>;
}

export default class UserPendingRequests extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            abort: new AbortController(),
            response: undefined,
            actions: new Map<string, string>(),
            loading: new Set<string>()
        }
    }

    render() {
        return (
            <div>
                <div className={styles.TitleBar}>
                    <div className={styles.Title}>Pending Requests</div>
                    <div className={styles.TitleRight}>
                    </div>
                </div>
                <Card className={this.getClasses()} invisible={this.isMobile()} elevation={CardElevation.Sheet}>
                    <div className={styles.Content}>
                        <div className={styles.SubTitle}>Received Requests</div>
                        {this.renderIncoming()}
                        <div className={styles.SubTitle}>Sent Requests</div>
                        {this.renderOutgoing()}
                    </div>
                </Card>
            </div>
        )
    }

    renderIncoming() {
        if (!this.state.response) {
            return <div className={styles.Skeleton}/>
        }
        if (this.state.response.incoming.length === 0) {
            return <div className={styles.NoRequests}>No pending requests to show</div>
        }
        return (
            <div className={styles.Grid}>
                {this.state.response.incoming.sort((a, b) => a.username.localeCompare(b.username))
                    .map(user =>
                        <div className={styles.User} key={user.id}>
                            <UserFriendBio user={user} className={styles.UserInfo}/>
                            <div className={styles.UserActions}>
                                {
                                    this.state.actions.has(user.id) ?
                                        this.state.actions.get(user.id) === 'ignored' ?
                                            <div className={styles.ActionDisabled}>Ignored</div>
                                            :
                                            <div className={styles.ActionSuccess}>Accepted</div>
                                        :
                                        <div className={styles.ActionButtons}>
                                            <Button size={ButtonSize.Small} className={styles.AcceptButton}
                                                    color={ButtonColor.Primary}
                                                    onClick={() => this.acceptIncoming(user.id)}
                                                    disabled={this.state.loading.has(user.id)}>Accept</Button>
                                            <Button size={ButtonSize.Small}
                                                    onClick={() => this.ignoreIncoming(user.id)}
                                                    disabled={this.state.loading.has(user.id)}>Ignore</Button>
                                        </div>
                                }
                            </div>
                        </div>)}
            </div>
        )
    }

    renderOutgoing() {
        if (!this.state.response) {
            return <div className={styles.Skeleton}/>
        }
        if (this.state.response.outgoing.length === 0) {
            return <div className={styles.NoRequests}>No pending requests to show</div>
        }
        return (
            <div className={styles.Grid}>
                {this.state.response.outgoing.sort((a, b) => a.username.localeCompare(b.username))
                    .map(user =>
                        <div className={styles.User} key={user.id}>
                            <UserFriendBio user={user} className={styles.UserInfo}/>
                            <div className={styles.UserActions}>
                                {
                                    this.state.actions.has(user.id) ?
                                        <div className={styles.ActionDisabled}>Cancelled</div>
                                        :
                                        <div className={styles.ActionButtons}>
                                            <Button size={ButtonSize.Small}
                                                    onClick={() => this.cancelOutgoing(user.id)}
                                                    disabled={this.state.loading.has(user.id)}>Cancel</Button>
                                        </div>
                                }
                            </div>
                        </div>)}
            </div>
        )
    }

    acceptIncoming(userId: string) {
        this.reduceIncoming();
        this.state.loading.add(userId);
        this.forceUpdate();
        createFriendRequest(userId, this.state.abort)
            .then(() => {
                this.state.actions.set(userId, "accepted");
                this.forceUpdate();
            })
            .catch(console.error);
    }

    ignoreIncoming(userId: string) {
        this.reduceIncoming();
        this.state.loading.add(userId);
        this.forceUpdate();
        cancelFriendRequest(userId, this.state.abort)
            .then(() => {
                this.state.actions.set(userId, "ignored");
                this.forceUpdate();
            })
            .catch(console.error);
    }

    cancelOutgoing(userId: string) {
        this.state.loading.add(userId);
        this.forceUpdate();
        cancelFriendRequest(userId, this.state.abort)
            .then(() => {
                this.state.actions.set(userId, "cancelled");
                this.forceUpdate();
            })
            .catch(console.error);
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Pending Requests");
        getPendingFriendRequests(this.state.abort)
            .then(response => this.setState({response}))
            .catch(console.error);
    }

    componentWillUnmount(): void {
        this.state.abort.abort();
    }

    reduceIncoming(): void {
        if (this.props.incomingRequestsCount !== undefined && this.props.incomingRequestsCount !== 0) {
            this.props.setIncomingRequests(this.props.incomingRequestsCount - 1);
        }
    }

    getClasses() {
        if (this.isMobile()) {
            return "";
        } else {
            return styles.ListCard;
        }
    }

    isMobile() {
        return window.innerWidth < 768;
    }
}
