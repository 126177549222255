import React from "react";
import { DocChapter, DocPage, getDocSource } from "./DocSource";
import styles from "./Developers.module.scss";
import { ScaleLoader } from "react-spinners";
import { titleManager } from "../../../Title";
import ReactMarkdown from "react-markdown";
// import CodeBlock from "./CodeBlock";
// import Heading from "./Heading";

interface Props {
    chapter: DocChapter;
    page: DocPage;
}

interface State {
    source?: string;
}

export default class DevelopersContent extends React.Component<Props, State> {

    abortCtl = new AbortController();

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                {this.renderHeader()}
                {this.renderContent()}
            </>
        )
    }

    renderHeader() {
        return (
            <header>
                <h1>{this.props.page.title}</h1>
                <p className={styles.Subtitle}>{this.props.page.subtitle}</p>
            </header>
        )
    }

    renderContent() {
        if (this.state.source) {
            return <ReactMarkdown className={styles.Source}
                components={{
                    code: () => ""
                }}
                children={this.state.source} />
        } else {
            return <div className={styles.Spinner}><ScaleLoader color={"#46C3D3"} /></div>
        }
    }

    componentDidMount() {
        titleManager.set(`${this.props.page.title} · Developer Documentation`);
        getDocSource(this.props.chapter.id, this.props.page.id, this.abortCtl)
            .then(source => this.setState({ source }))
            .catch(err => {
                console.error(err);
                this.setState({ source: "Failed to load page. Please refresh." })
            })
    }

    componentWillUnmount() {
        this.abortCtl.abort();
    }
}
