import React from "react";
import styles from "./NotificationsPage.module.scss";
import {UserState} from "../../../Redux/Types";
import {Notification} from "../../../API/Models";
import {deleteNotification, getNotifications, markNotificationAsRead} from "../../../API/Calls";
import {notificationUserCache} from "../../../API/NotificationUserCache";
import Button from "../Button/Button";
import NotificationsMenuItem from "../NotificationsMenuItem/NotificationsMenuItem";
import Card, {CardElevation} from "../Card/Card";
import {titleManager} from "../../../Title";

interface Props {
    user: UserState | null;
}

interface State {
    unread: boolean;
    loading: boolean;
    notifications: Notification<any>[];
    hasMore: boolean;
}

export default class NotificationsPage extends React.Component<Props, State> {
    private abort = new AbortController();

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            unread: false,
            loading: true,
            notifications: [],
            hasMore: false
        };

        this.markAllAsRead = this.markAllAsRead.bind(this);
    }

    render(): React.ReactNode {
        return (
            <div className={styles.Container}>
                <div className={styles.Title}>
                    <div className={styles.TitleText}>Notifications</div>
                    <Button outline={true} onClick={this.markAllAsRead} disabled={!this.state.unread}>
                        Mark all as read
                    </Button>
                </div>
                {this.renderContent()}
                {this.renderFooter()}
            </div>
        )
    }

    renderContent(): React.ReactNode {
        if (!this.state.loading && this.state.notifications.length === 0) {
            return (
                <Card elevation={CardElevation.Sheet} className={styles.Empty}>All caught up!</Card>
            )
        }
        return (
            <Card elevation={CardElevation.Sheet} className={styles.List}>
                {this.renderItems()}
            </Card>
        )
    }

    renderItems() {
        if (!this.props.user) {
            return "";
        }
        return this.state.notifications.map(item => {
            return (
                <div className={this.getItemClasses(item)} key={item.id} onClick={() => this.onItemClick(item)}>
                    <NotificationsMenuItem notification={item} user={this.props.user as UserState} canDelete={true}
                                           deleteButtonClass={styles.DeleteIcon} onDelete={() => this.delete(item.id)}/>
                </div>
            )
        });
    }

    onItemClick(item: Notification<any>) {
        if (!item.read) {
            markNotificationAsRead(item.id)
                .then()
                .catch(console.error);
        }
    }

    getItemClasses(item: Notification<any>): string {
        const classes = [styles.Item];
        if (item.read) {
            classes.push(styles.Read);
        }
        return classes.join(" ");
    }

    renderFooter() {
        if (this.state.loading) {
            return (
                <Card elevation={CardElevation.Sheet} className={styles.Loading}>
                    Loading...
                </Card>
            )
        } else if (this.state.hasMore) {
            return (
                <div className={styles.Next}>
                    <Button onClick={() => this.loadItems()}>Load more</Button>
                </div>
            )
        }
    }

    loadItems() {
        this.setState({loading: true}, () => {
            getNotifications(5, this.state.notifications.length, this.abort)
                .then(resp => {
                    notificationUserCache.withUsers(resp.items)
                        .then(items => {
                            this.setState({
                                hasMore: resp.hasNext,
                                loading: false,
                                notifications: [...this.state.notifications, ...items],
                                unread: resp.unread > 0
                            });
                        })
                        .catch(console.error);
                })
                .catch(console.error);
        });
    }

    markAllAsRead() {
        this.state.notifications.forEach(notif => notif.read = true);
        this.setState({unread: false});
        markNotificationAsRead("all", this.abort)
            .then()
            .catch(console.error);
    }

    delete(id: string) {
        const filtered = this.state.notifications.filter(notif => notif.id !== id);
        this.setState({notifications: filtered});
        deleteNotification(id, this.abort)
            .then()
            .catch(console.error);
    }

    componentDidMount(): void {
        titleManager.set("Notifications");
        if (this.props.user !== null) {
            this.loadItems();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.user?.id !== prevProps.user?.id) {
            this.loadItems();
        }
    }

    componentWillUnmount(): void {
        this.abort.abort();
    }
}
