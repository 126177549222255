import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Profile from "../Presentation/Profile/Profile";
import {loadProfile, loadUserData, toggleGlobalLoading, toggleProfileEditing} from "../../Redux/Actions";

function mapStateToProps(state: AppState) {
    return {
        authUser: state.user,
        authProfileReady: state.profileReady,
        profile: state.profilePage.profile,
        reload$: state.profilePage.reload$
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        loadUserData,
        loadProfile,
        toggleGlobalLoading,
        toggleProfileEditing,
    }, dispatch);
}

function mergeProps(stateProps: object, dispatchProps: object, ownProps: object) {
    const merged = {};
    Object.assign(merged, stateProps);
    Object.assign(merged, dispatchProps);
    Object.assign(merged, ownProps);
    return merged;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Profile);
