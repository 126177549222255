import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import Auth from "../Presentation/Auth/Auth";
import {setAuthFormsError} from "../../Redux/Actions";
import {bindActionCreators} from "redux";

export interface AuthContainerProps {
    view: "join" | "login",
}

function mapStateToProps(state: AppState, ownProps: AuthContainerProps) {
    return {
        error: state.authForms.error,
        view: ownProps.view,
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        setAuthFormsError
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);