import React from "react";
import {Route, Switch} from "react-router-dom";
import RecentHistory from "./RecentHistory";
import TopSongs from "./TopSongs";
import TopArtists from "./TopArtists";
import TopAlbums from "./TopAlbums";
import {ForeignUser} from "../../../Redux/Types";
import Card, {CardElevation} from "../Card/Card";
import styles from "./UserHistory.module.scss";
import {Ranges} from "../ProfileDateRange/ProfileDateRange";
import {HistoryEditManager} from "./HistoryEditManager";
import {EventEmitter} from "events";

interface Props {
    profile: ForeignUser,
    page: number,
    pageFunc: (hasNext: boolean, hasPrev: boolean, pageNumber: number, totalPages: number, totalResults: number) => void,
    searchFilter?: string
    dateRange: Ranges;
    dateFilter?: Date;
    editing: boolean;
    editManager: HistoryEditManager;
    reload$: EventEmitter;
    isOwnProfile: boolean;
}

export default class UserHistorySongList extends React.Component<Props> {
    render() {
        return (
            <Card className={this.getClasses()} invisible={this.isMobile()} elevation={CardElevation.Sheet}>
                <Switch>
                    <Route path={"/user/" + this.props.profile.username + "/history/recent"}>
                        <RecentHistory user={this.props.profile} page={this.props.page}
                                       searchFilter={this.props.searchFilter}
                                       dateFilter={this.props.dateFilter}
                                       editing={this.props.editing}
                                       pageFunc={this.props.pageFunc}
                                       reload$={this.props.reload$}
                                       editManager={this.props.editManager}
                                       isOwnProfile={this.props.isOwnProfile}
                        />
                    </Route>
                    <Route path={"/user/" + this.props.profile.username + "/history/songs"}>
                        <TopSongs user={this.props.profile} page={this.props.page}
                                  searchFilter={this.props.searchFilter}
                                  dateRange={this.props.dateRange}
                                  pageFunc={this.props.pageFunc}/>
                    </Route>
                    <Route path={"/user/" + this.props.profile.username + "/history/artists"}>
                        <TopArtists user={this.props.profile} page={this.props.page}
                                    searchFilter={this.props.searchFilter}
                                    dateRange={this.props.dateRange}
                                    pageFunc={this.props.pageFunc}/>
                    </Route>
                    <Route path={"/user/" + this.props.profile.username + "/history/albums"}>
                        <TopAlbums user={this.props.profile} page={this.props.page}
                                   searchFilter={this.props.searchFilter}
                                   dateRange={this.props.dateRange}
                                   pageFunc={this.props.pageFunc}/>
                    </Route>
                </Switch>
            </Card>
        )
    }

    getClasses() {
        if (this.isMobile()) {
            return "";
        } else {
            return styles.ListCard;
        }
    }

    isMobile() {
        return window.innerWidth < 768;
    }
}
