import React from "react";
import styles from "./PopupMenu.module.scss";

interface Props {
    toggleRef: React.RefObject<HTMLElement>;
    closeMenu: () => void;

    children?: React.ReactNode;
}

interface State {
}

export default class PopupMenu extends React.Component<Props, State> {
    private menuContainer: React.RefObject<HTMLDivElement> = React.createRef();

    render() {
        return (
            <div className={styles.Menu} ref={this.menuContainer} children={this.props.children}/>
        )
    }

    handlePageClick(e: MouseEvent) {
        if (e.type === "mousedown" && this.menuContainer.current != null && !this.menuContainer.current.contains(e.target as Node)
            && this.props.toggleRef.current != null && !this.props.toggleRef.current.contains(e.target as Node)) {
            this.props.closeMenu();
        }
    }

    componentDidMount(): void {
        window.addEventListener("mousedown", this.handlePageClick.bind(this));
    }

    componentWillUnmount(): void {
        window.addEventListener("mousedown", this.handlePageClick.bind(this));
    }
}
