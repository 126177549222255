import React from "react";
import {Comment, CommentText} from "../../../API/Models";
import {ForeignUser, RenderedComment, UserState} from "../../../Redux/Types";
import {getProfilesById} from "../../../API/Calls";
import CommentView from "../CommentView/CommentView";

interface Props {
    authUser: UserState | null;
    lastUpdate: number;
    comments: Comment[];
    canWrite: boolean;
    profile: ForeignUser;

    onReply?: (value: CommentText, comment: Comment, replyingTo: ForeignUser) => void;
    openReportForm: (commentUser: ForeignUser, profileId: string, commentId: string, replyId?: string) => void;
}

interface State {
}

export default class CommentTree extends React.Component<Props, State> {
    private readonly users = new Map<string, ForeignUser>();
    private readonly comments = new Map<string, RenderedComment>();

    constructor(props: Props) {
        super(props);

        this.state = {}
    }

    refresh() {
        if (this.props.comments.length === 0) {
            return;
        }

        const after = Array.from(this.props.comments);
        const next = after.shift();
        this.loadComment(next, after);
    }

    loadComment(comment: Comment | undefined, after: Comment[]) {
        if (!comment) {
            return;
        }

        const userIds = new Set<string>([comment.userId]);
        comment.replies.forEach(reply => {
            userIds.add(reply.userId);
            if (reply.replyingTo) {
                userIds.add(reply.replyingTo);
            }
        });

        const newAfter = Array.from(after);
        const next = newAfter.shift();

        this.getUsers(userIds)
            .then(users => {
                const rendered = this.renderComment(comment, users);
                if (rendered) {
                    this.comments.set(comment.id, rendered);
                    this.forceUpdate();
                }
                this.loadComment(next, newAfter);
            })
            .catch(err => {
                console.error(err);
                this.loadComment(next, newAfter);
            });
    }

    renderComment(comment: Comment, users: ForeignUser[]): RenderedComment | undefined {
        const user = users.find(user => user.id === comment.userId);

        if (!user) {
            return undefined;
        }

        const replyingTo = users.find(user => user.id === comment.replyingTo);
        const replies = comment.replies ? comment.replies.map(reply => this.renderComment(reply, users)) : [];

        return {
            user,
            comment,
            replies: replies.filter(reply => reply !== undefined) as RenderedComment[],
            replyingTo
        }
    }

    getUsers(userIdSet: Set<string>): Promise<ForeignUser[]> {
        const userIds = Array.from(userIdSet);

        const notCached = userIds.filter(userId => !this.users.has(userId));
        const users = userIds.map(id => this.users.get(id)).filter(user => !!user) as ForeignUser[];

        if (notCached.length > 0) {
            return getProfilesById(...notCached)
                .then(loaded => {
                    loaded.forEach(user => this.users.set(user.id, user));
                    users.push(...loaded);
                    return users;
                })
        } else {
            return Promise.resolve(users);
        }
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.lastUpdate !== this.props.lastUpdate) {
            this.refresh();
        }
    }

    render() {
        return (
            Array.from(this.comments.values()).sort((a, b) => b.comment.date.getTime() - a.comment.date.getTime())
                .map(comment => <CommentView key={comment.comment.id} comment={comment} canWrite={this.props.canWrite}
                                             profile={this.props.profile} onReply={this.props.onReply}
                                             openReportForm={this.props.openReportForm}
                                             authUser={this.props.authUser}/>)
        )
    }
}
