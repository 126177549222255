import React from "react";
import {ForeignUser, UserState} from "../../../Redux/Types";
import styles from "./FriendsActivity.module.scss";
import Button, {ButtonColor, ButtonSize} from "../Button/Button";
import {SpotifyTrack} from "../../../API/Models";
import {getFriendList, getProfileActivity} from "../../../API/Calls";
import UserFriend from "../UserFriend/UserFriend";
import AngleRightIcon from "../Icons/AngleRight/AngleRight";
import {Link} from "react-router-dom";

interface Props {
    className?: string;
    user: UserState | ForeignUser,
    isSelf: boolean;
    smaller?: boolean;
    max: number;
}

interface State {
    users: ForeignUser[] | null;
    activity: Map<string, SpotifyTrack>;
    abort: AbortController;
    totalFriends: number;
}

export default class FriendsActivity extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            users: null,
            activity: new Map<string, SpotifyTrack>(),
            abort: new AbortController(),
            totalFriends: 0
        };
    }

    render() {
        return (
            <div className={this.getClasses()}>
                <div className={styles.FriendsActivity}>
                    <div className={styles.Title}>
                        Friends&nbsp;
                        {(this.props.smaller && this.state.users && this.state.totalFriends > 0) ?
                            <span className={styles.FriendCount}>({this.state.totalFriends})</span> : ""}
                    </div>
                    {
                        this.state.users === null ? <div className={styles.LoadingPlaceholder}/>
                            :
                            this.state.users.length === 0 ?
                                this.props.isSelf ?
                                    <div className={styles.NoFriends}>
                                        <div className={styles.Note}>wavy is better with friends!</div>
                                        <div className={styles.AddFriendsButton}>
                                            <Button color={ButtonColor.Accent}
                                                    link={{to: "/user/" + this.props.user.username + "/friends/add"}}
                                                    size={ButtonSize.Medium}>Add Friends</Button>
                                        </div>
                                    </div>
                                    :
                                    <div className={styles.NoFriends}>
                                        <div className={styles.Note}>This user doesn't have any friends yet.</div>
                                    </div>
                                :
                                <div>
                                    {
                                        this.state.users.map(user =>
                                            <div className={styles.UserActivity} key={user.id}>
                                                <UserFriend user={user}
                                                            activity={this.state.activity.get(user.id)}/>
                                            </div>
                                        )
                                    }
                                    <Link className={["AccentLink", styles.ViewMoreLink].join(" ")}
                                          aria-label={"View More Friends"}
                                          to={"/user/" + this.props.user.username + "/friends"}>
                                        View More
                                        <AngleRightIcon/>
                                    </Link>
                                </div>
                    }
                </div>
            </div>
        )
    }

    componentDidMount(): void {
        if (!this.props.user.$access.view) {
            return;
        }
        getFriendList(this.props.user.id, this.state.abort)
            .then(friendsResponse => {
                const topFriends = friendsResponse.sort((a, b) => {
                    if (!a.lastActivity && !b.lastActivity) {
                        return a.username.localeCompare(b.username);
                    }
                    if (!a.lastActivity) {
                        return 1;
                    }
                    if (!b.lastActivity) {
                        return -1;
                    }
                    return b.lastActivity.getTime() - a.lastActivity.getTime();
                }).slice(0, this.props.max);
                const userIds = topFriends.map(u => u.id);
                if (userIds.length > 0) {
                    getProfileActivity(userIds, this.state.abort)
                        .then(resp => {
                            Object.keys(resp)
                                .sort((a, b) => userIds.indexOf(a) - userIds.indexOf(b))
                                .forEach(userId => {
                                    const track = resp[userId];
                                    if (track) {
                                        this.state.activity.set(userId, track.track);
                                    }
                                })
                            this.setState({
                                users: topFriends,
                                activity: this.state.activity,
                                totalFriends: friendsResponse.length
                            });
                        })
                        .catch(console.error);
                } else {
                    this.setState({users: []});
                }
            })
            .catch(console.error)
    }

    getClasses(): string {
        const classes = [styles.FriendsActivityParent];
        if (this.props.smaller) {
            classes.push(styles.Smaller);
        }
        if (this.props.className) {
            classes.push(this.props.className);
        }
        return classes.join(" ");
    }
}
