import React from "react";
import Card from "../Card/Card";
import styles from "./ForgotPassword.module.scss";
import Button, {ButtonColor} from "../Button/Button";
import {submitPasswordReset} from "../../../API/Calls";
import {titleManager} from "../../../Title";

interface Props {
}

interface State {
    sentTo?: string;
    loading: boolean;
    error?: Error;
}

export default class ForgotPassword extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            loading: false,
            sentTo: undefined,
            error: undefined,
        }
    }

    render() {
        return (
            <Card className={styles.Card}>
                <div className={styles.Title}>Reset Password</div>
                {this.renderContents()}
            </Card>
        )
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Reset Password");
    }

    private renderContents() {
        if (this.state.sentTo === undefined) {
            return (
                <div>
                    <p>
                        Happens to the best of us.
                        <br/>
                        Enter the email address associated with your account.
                    </p>
                    <form onSubmit={e => this.onSubmit(e)} className={styles.Form}>
                        <div className={"FormField"}>
                            <label htmlFor={"Email"} className={styles.Label}>
                                Email Address
                            </label>
                            <input required={true} name={"Email"} type={"email"}/>
                        </div>
                        <div className={"FormField SubmitRegion " + styles.SubmitRegion}>
                            <Button form={true} color={ButtonColor.Primary} disabled={this.state.loading}>Send</Button>
                        </div>
                    </form>
                    {
                        this.state.error ?
                            <p className={styles.Error}>{this.state.error.toString()}</p>
                            :
                            ""
                    }
                </div>
            )
        } else {
            return (
                <div>
                    <p>
                        An email with instructions to reset your password has been sent
                        to <strong>{this.state.sentTo}</strong>.
                    </p>
                </div>
            )
        }
    }

    private onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (this.state.loading) return;

        const emailAddress = ((event.target as HTMLFormElement).elements.namedItem("Email") as HTMLInputElement).value;

        this.setState({loading: true, error: undefined}, () => {
            submitPasswordReset(emailAddress)
                .then(() => this.setState({loading: false, sentTo: emailAddress}))
                .catch(error => this.setState({loading: false, error}))
        });
    }
}
