import React from "react";
import styles from "./UserHistory.module.scss";

export default class Skeleton extends React.Component {
    render() {
        return (
            <div className={styles.Skeleton}>

            </div>
        )
    }
}
