import React from "react";
import styles from "./UserFriendBio.module.scss";
import {Link} from "react-router-dom";
import Avatar from "../Avatar/Avatar";
import {ForeignUser} from "../../../Redux/Types";
import Flag from "../Flag/Flag";

interface Props {
    user: ForeignUser;
    className?: string;
}

export default class UserFriendBio extends React.Component<Props> {
    render() {
        return (
            <div className={styles.User + " " + this.props.className || ""} key={this.props.user.id}>
                <Link to={"/user/" + this.props.user.username}>
                    <Avatar user={this.props.user} size={"40px"}/></Link>
                <div className={styles.UserInfo}>
                    <div className={styles.UserName}>
                        <Link to={"/user/" + this.props.user.username}>{this.props.user.username}</Link></div>

                    {
                        this.props.user.countryCode ?
                            <div className={styles.Bio}>
                                {this.props.user.countryName}
                                <Flag code={this.props.user.countryCode} className={styles.Flag}/>
                            </div>
                            :
                            ""
                    }
                </div>
            </div>
        )
    }
}
