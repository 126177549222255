import React from "react";
import Card from "../Card/Card";
import styles from "./ForgotPasswordConfirm.module.scss";
import Button, {ButtonColor} from "../Button/Button";
import queryString from 'query-string';
import {Redirect} from "react-router-dom";
import {completePasswordReset} from "../../../API/Calls";
import {titleManager} from "../../../Title";

interface Props {
    /**
     * URL parameters
     */
    location: { search: string },
}

interface State {
    loading: boolean;
    errorMessage?: string;
    successful: boolean;
    code?: string;
}

export default class ForgotPasswordConfirm extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        const code = queryString.parse(this.props.location.search).code as string | undefined;
        this.state = {
            code,
            loading: false,
            successful: false
        }
    }

    render() {
        if (!this.state.code) {
            return <Redirect to={"/password-reset/submit"}/>
        }
        return (
            <Card className={styles.Card}>
                <div className={styles.Title}>Reset Password</div>
                {this.renderContents()}
            </Card>
        )
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Reset Password");
    }

    private renderContents() {
        if (!this.state.successful) {
            return (
                <div>
                    <p>
                        Please submit a new password for your wavy.fm account.
                    </p>
                    <form onSubmit={e => this.onSubmit(e)} className={styles.Form}>
                        <div className={"FormField"}>
                            <label htmlFor={"Password"} className={styles.Label}>
                                Password
                            </label>
                            <input required={true} name={"Password"} type={"password"}/>
                        </div>
                        <div className={"FormField"}>
                            <label htmlFor={"ConfirmPassword"} className={styles.Label}>
                                Confirm Password
                            </label>
                            <input required={true} name={"ConfirmPassword"} type={"password"}/>
                        </div>
                        <div className={"FormField SubmitRegion " + styles.SubmitRegion}>
                            <Button form={true} color={ButtonColor.Primary} disabled={this.state.loading}>Save</Button>
                        </div>
                    </form>
                    {
                        this.state.errorMessage ?
                            <p className={styles.Error}>{this.state.errorMessage}</p>
                            :
                            ""
                    }
                </div>
            )
        } else {
            return (
                <div>
                    <p>
                        Your password has been reset. You may now login using your new password.
                    </p>
                    <div className={styles.SubmitRegion}>
                        <Button color={ButtonColor.Primary} link={{to: "/login"}}>Login</Button>
                    </div>
                </div>
            )
        }
    }

    private onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (this.state.loading) return;

        const password = ((event.target as HTMLFormElement).elements.namedItem("Password") as HTMLInputElement).value;
        const confirm = ((event.target as HTMLFormElement).elements.namedItem("ConfirmPassword") as HTMLInputElement).value;

        if (password !== confirm) {
            this.setState({loading: false, errorMessage: "Passwords to not match."});
            return;
        }

        const code = this.state.code;
        if (code === undefined) {
            return;
        }

        this.setState({loading: true, errorMessage: undefined}, () => {
            completePasswordReset(code, password)
                .then(() => this.setState({loading: false, successful: true}))
                .catch(error => this.setState({loading: false, errorMessage: error.toString()}))
        });
    }
}
