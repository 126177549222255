import {AppState, ForeignUser, isOwnProfile} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ProfileMeta from "../Presentation/ProfileMeta/ProfileMeta";
import {toggleProfileEditing, toggleProfilePictureForm} from "../../Redux/Actions";

function mapStateToProps(state: AppState) {
    return {
        profile: state.profilePage.profile as ForeignUser,
        authUser: state.user,
        authProfileReady: state.profileReady,
        isOwnProfile: isOwnProfile(state),
        isLoggedIn: state.profileReady && state.user !== null,
        isEditing: state.profilePage.editing
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        toggleProfileEditing,
        toggleProfilePictureForm
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMeta);
