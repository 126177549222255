import React from "react";
import styles from "./ProfileComments.module.scss";
import CommentBox from "../CommentBox/CommentBox";
import Avatar from "../Avatar/Avatar";
import {ForeignUser, UserState} from "../../../Redux/Types";
import {Comment, CommentText, ProfileCommentsPrivacy} from "../../../API/Models";
import {getComments, postComment, postReply} from "../../../API/Calls";
import CommentTree from "../CommentTree/CommentTree";
import CardModal from "../CardModal/CardModal";
import Button, {ButtonColor} from "../Button/Button";
import {Link} from "react-router-dom";
import CommentReportFormContainer from "../../Containers/CommentReportFormContainer";

interface Props {
    authUser: UserState | null;
    profile: ForeignUser;
    canWrite: boolean;
    openCommentReportForm: (commentUser: ForeignUser, profileId: string, commentId: string, replyId?: string) => void;
}

interface State {
    comments?: Comment[];
    totalComments: number;
    loading: boolean;

    lastUpdate: number;
    commentError?: string;
    retryAction?: () => void;
}

export default class ProfileComments extends React.Component<Props, State> {
    private abort = new AbortController();

    constructor(props: Readonly<Props>) {
        super(props);

        this.onComment = this.onComment.bind(this);
        this.onRetry = this.onRetry.bind(this);
        this.onCancelError = this.onCancelError.bind(this);
        this.loadMoreComments = this.loadMoreComments.bind(this);
        this.onReply = this.onReply.bind(this);

        this.state = {
            loading: true,
            lastUpdate: 0,
            totalComments: 0
        };
    }

    render(): React.ReactNode {
        return (
            <>
                <CommentReportFormContainer/>
                <CardModal title={"Failed to Post Comment"} isOpen={!!this.state.commentError}
                           onRequestClose={this.onCancelError}>
                    <div className={styles.ErrorContent}>
                        <p className={styles.Content}>
                            Error: {this.state.commentError}
                        </p>
                        <div className={styles.ButtonParent}>
                            <Button color={ButtonColor.Regular}
                                    onClick={this.onCancelError}>Cancel</Button>
                            <Button color={ButtonColor.Primary}
                                    onClick={this.onRetry}>Retry</Button>
                        </div>
                    </div>
                </CardModal>
                <div className={styles.Section}>
                    <div className={styles.Title}>
                        <span>Comments</span>
                    </div>
                    {this.renderCommentBox()}
                    {
                        this.state.comments ?
                            <CommentTree comments={this.state.comments} lastUpdate={this.state.lastUpdate}
                                         profile={this.props.profile} onReply={this.onReply}
                                         openReportForm={this.props.openCommentReportForm}
                                         authUser={this.props.authUser} canWrite={this.props.canWrite}/> : ""
                    }
                    {
                        this.hasMoreComments() ?
                            <div className={styles.Next}>
                                <Button onClick={this.loadMoreComments} disabled={this.state.loading}>
                                    Load more comments ({this.state.totalComments - (this.state.comments?.length ?? 0)})
                                </Button>
                            </div>
                            : ""
                    }
                </div>
            </>
        )
    }

    renderCommentBox() {
        if (this.props.canWrite && this.props.authUser) {
            return (
                <div className={styles.NewCommentArea}>
                    <Avatar user={this.props.authUser} size={"40px"} className={styles.Avatar}/>
                    <CommentBox className={styles.CommentBox} placeholder={"Write a comment..."}
                                onSend={this.onComment}/>
                </div>
            )
        }

        let message = <span>You are unable to post comments,</span>
        if (!this.props.authUser) {
            message = <span><Link to={"/join"}>Join</Link> or <Link to={"/login"}>Login</Link> to post comments.</span>
        } else if (!this.props.authUser.emailVerified) {
            message = <span>Verify your email address to post comments.</span>
        } else if (this.props.profile.$access.profileComments === ProfileCommentsPrivacy.Friends) {
            message = <span>You must be friends with this user to post comments.</span>
        } else if (this.props.profile.$access.profileComments === ProfileCommentsPrivacy.Me) {
            message = <span>This user has disabled comments on their profile.</span>
        }

        return (
            <div className={styles.NewCommentDisabled}>
                {message}
            </div>
        )
    }

    hasMoreComments(): boolean {
        return !!this.state.comments && this.state.comments.length < this.state.totalComments;
    }

    loadMoreComments() {
        if (this.state.loading || !this.hasMoreComments() || !this.state.comments?.length) {
            return;
        }

        const last = this.state.comments[this.state.comments.length - 1];
        const beforeDate = last.date.getTime();

        this.setState({loading: true}, () => {
            getComments(this.props.profile.id, beforeDate, this.abort)
                .then(resp => {
                    this.setState({
                        comments: [...this.state.comments ?? [], ...resp.comments],
                        totalComments: resp.total,
                        loading: false,
                        lastUpdate: this.state.lastUpdate + 1
                    });
                })
                .catch(console.error);
        })
    }

    componentDidMount() {
        getComments(this.props.profile.id, undefined, this.abort)
            .then(resp => {
                this.setState({comments: resp.comments, totalComments: resp.total, loading: false});
            })
            .catch(console.error);
    }

    onCancelError() {
        this.setState({commentError: undefined, retryAction: undefined});
    }

    onRetry() {
        if (this.state.retryAction) {
            this.state.retryAction();
        }
    }

    onComment(value: CommentText) {
        this.setState({commentError: undefined, retryAction: () => this.onComment(value)}, () => {
            postComment(this.props.profile.id, value)
                .then(comment => {
                    if (comment) {
                        this.setState({
                            comments: [comment, ...this.state.comments ?? []],
                            lastUpdate: this.state.lastUpdate + 1,
                            retryAction: undefined,
                            totalComments: this.state.totalComments + 1
                        });
                    }
                })
                .catch((e: Error) => {
                    console.error(e);
                    const description = e.message || "Failed to post comment (" + e.name + ").";
                    this.setState({commentError: description});
                });
        })
    }

    onReply(value: CommentText, parent: Comment, replyingTo: ForeignUser) {
        this.setState({commentError: undefined, retryAction: () => this.onReply(value, parent, replyingTo)}, () => {
            postReply(this.props.profile.id, value, parent.id, replyingTo.id)
                .then(reply => {
                    if (reply) {
                        parent.replies.push(reply);
                        this.setState({
                            lastUpdate: this.state.lastUpdate + 1,
                            retryAction: undefined,
                        });
                    }
                })
                .catch((e: Error) => {
                    console.error(e);
                    const description = e.message || "Failed to post reply (" + e.name + ").";
                    this.setState({commentError: description});
                });
        });
    }

    componentWillUnmount() {
        this.abort.abort();
    }
}
