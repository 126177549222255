import React from "react";

export default class BlockedIcon extends React.Component {
    render() {
        return (
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={"IconSvg"}>
                <path className={"IconPath"}
                      d="M11.5 0C5.14875 0 0 5.14871 0 11.5C0 17.8513 5.14875 23 11.5 23C17.8512 23 23 17.8512 23 11.5C23 5.14875 17.8513 0 11.5 0ZM17.5332 5.46677C20.5681 8.50165 20.7792 13.1403 18.492 16.3935L6.60647 4.508C9.8619 2.21931 14.5 2.43359 17.5332 5.46677ZM5.46677 17.5332C2.43188 14.4983 2.2208 9.85972 4.50795 6.60652L16.3935 18.492C13.1381 20.7807 8.49998 20.5665 5.46677 17.5332Z"
                      fill="#FF5464"/>
            </svg>
        )
    }
}
