import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {toggleProfileBlockConfirm} from "../../Redux/Actions";
import ProfileBlockConfirm from "../Presentation/ProfileBlockConfirm/ProfileBlockConfirm";

function mapStateToProps(state: AppState) {
    return {
        username: state.profilePage.profile?.username ?? "",
        userId: state.profilePage.profile?.id ?? "",
        reload$: state.profilePage.reload$,
        isOpen: state.profilePage.blockConfirmOpen
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        toggleProfileBlockConfirm,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBlockConfirm);
