import * as React from "react";
import {UserState} from "../../../Redux/Types";
import {getProfilePictureEvent, setDefaultPicture, uploadProfilePicture} from "../../../API/Calls";
import DefaultPicture from "../../../Images/Avatar/Default.svg";
import Button, {ButtonColor} from "../Button/Button";
import Avatar from "../Avatar/Avatar";
import styles from "./ProfilePictureForm.module.scss";

interface PictureFormProps {
    user: UserState | null;
    closeFunction: () => void;
    reloadProfileFunction: () => void;
}

interface PictureFormState {
    uploadedImage: File | null;
    uploadedImageUrl: string | null;
    uploading: boolean;
    uploadError: Error | null;
}

export default class ProfilePictureForm extends React.Component<PictureFormProps, PictureFormState> {

    constructor(props: Readonly<PictureFormProps>) {
        super(props);

        this.state = {
            uploadedImage: null,
            uploadedImageUrl: null,
            uploading: false,
            uploadError: null,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleImageBrowse = this.handleImageBrowse.bind(this);
        this.handleDefaultPicture = this.handleDefaultPicture.bind(this);
    }

    render() {
        return (
            <div className={styles.PictureForm}>
                <div className={styles.DefaultPictureSection}>
                    <Avatar
                        className={styles.DefaultPicture}
                        user={undefined}
                        disabled={this.state.uploading}
                        rounder={true}
                        size={"65px"}
                        dom={{onClick: this.handleDefaultPicture}}
                    />
                </div>
                <div className={styles.SectionSeparator}>or</div>
                <form onSubmit={this.handleSubmit}>
                    <div className={styles.UploadPictureSection}>
                        <div>
                            <div className={styles.UploadPreview} style={{backgroundImage: this.getAvatarPreview()}}/>
                        </div>
                        <div className={styles.ActionParent}>
                            <div className={styles.UploadNotice}>
                                File cannot exceed 4MB and must be in JPEG or PNG format.
                            </div>
                            <label
                                className={styles.ChooseFileButton + " " + Button.getButtonClasses({color: ButtonColor.Regular, disabled: this.state.uploading})}>
                                <input type={"file"}
                                       name={"img"}
                                       accept={".jpg,.jpeg,.png"}
                                       onChange={this.handleImageBrowse}
                                       multiple={false}
                                       className={styles.BrowseInput}/>
                                Choose File
                            </label>
                            <Button form={true} color={ButtonColor.Primary}
                                    disabled={this.state.uploading || !this.state.uploadedImage}
                                    block={true}>Upload</Button>
                        </div>
                    </div>
                    <div className={styles.ErrorSection}>
                        {
                            this.state.uploadError !== null ?
                                <div className={styles.UploadError}>Error: {this.state.uploadError.message}</div> : ""
                        }
                    </div>
                </form>
            </div>
        )
    }

    getAvatarPreview(): string {
        if (this.props.user) {
            if (this.state.uploadedImageUrl) {
                return "url(\"" + this.state.uploadedImageUrl + "\")";
            } else {
                return Avatar.getUrl(this.props.user, false);
            }
        } else {
            return "";
        }
    }

    handleImageBrowse(event: React.FormEvent<HTMLInputElement>) {
        const files = (event.target as HTMLInputElement).files;
        if (files?.length === 1) {
            const uploadedImageUrl = URL.createObjectURL(files[0]);
            this.setState({
                uploadedImageUrl,
                uploadedImage: files[0]
            });
        } else {
            this.setState({
                uploadedImageUrl: null,
                uploadedImage: null
            });
        }
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (!this.state.uploading && this.props.user !== null) {
            this.setState({uploading: true, uploadError: null}, () => {
                if (this.state.uploadedImage !== null) {
                    uploadProfilePicture(this.state.uploadedImage)
                        .then(resp => {
                            const eventId = resp.event_id;
                            return this.waitForEvent(eventId);
                        })
                        .then(resp => {
                            this.setState({uploading: false},
                                () => this.props.reloadProfileFunction());
                        })
                        .catch(e => {
                            this.setState({uploadError: e, uploading: false});
                            console.error(e);
                        });
                }
            });
        }
    }

    handleDefaultPicture() {
        if (!this.state.uploading && this.props.user !== null) {
            this.setState({uploading: true, uploadError: null, uploadedImageUrl: DefaultPicture}, () => {
                setDefaultPicture()
                    .then(resp => {
                        this.setState({uploading: false},
                            () => this.props.reloadProfileFunction());
                    })
                    .catch(e => {
                        this.setState({uploadError: e, uploading: false});
                        console.error(e);
                    });
            });
        }
    }

    waitForEvent(eventId: string): Promise<any> {
        return getProfilePictureEvent(eventId)
            .then(resp => {
                const state = resp.state;
                switch (state) {
                    case "success":
                        return Promise.resolve();
                    case "fail":
                        const error = resp.error;
                        throw Error(error);
                    default:
                        return delay(500).then(() => this.waitForEvent(eventId));
                }
            })
            .catch(console.error);
    }
}

// Helper delay function to wait a specific amount of time.
function delay(time: number): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, time));
}
