import React from "react";
import {Redirect, RouteChildrenProps} from "react-router-dom";

interface Props {
}

interface MatchProps {
    username: string;
}

export default class UserDefaultRedirect extends React.Component<Props & RouteChildrenProps<MatchProps>> {
    render() {
        return <Redirect to={`/user/${this.props.match?.params.username}`}/>
    }
}
