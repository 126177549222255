import React from "react";
import styles from "./ProfileLayout.module.scss";

interface Props {
    meta: any;
    content: any;
    alt: any;
}

export default class ProfileLayout extends React.Component<Props> {
    render() {
        return (
            <div className={styles.ProfilePage}>
                <div className={styles.Meta}>
                    {this.renderMeta()}
                </div>
                <div className={styles.Content}>
                    {this.renderContent()}
                </div>
                <div className={styles.Alt}>
                    {this.renderAlt()}
                </div>
            </div>
        )
    }

    renderMeta() {
        if (this.props.meta) {
            return React.createElement(this.props.meta, {});
        }
        return "";
    }

    renderContent() {
        if (this.props.content) {
            return React.createElement(this.props.content, {});
        }
        return "";
    }

    renderAlt() {
        if (this.props.alt) {
            return React.createElement(this.props.alt, {});
        }
        return "";
    }
}
