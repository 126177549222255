import React from "react";
import styles from "./MusicSubject.module.scss";

interface Props {
    name: string;
    image: string;
    subtext?: string;

    rounded?: boolean;
}

export default class MusicSubject extends React.Component<Props> {
    render() {
        return (
            <div className={styles.MusicSubject}>
                <img src={this.props.image} alt={this.props.name} className={this.getImageClasses()}/>
                <div className={styles.Meta}>
                    <div className={styles.Name}>{this.props.name}</div>
                    {
                        this.props.subtext ? <div className={styles.Subtext}>{this.props.subtext}</div> : ""
                    }
                </div>
            </div>
        )
    }

    getImageClasses(): string {
        const classes = [styles.Image];
        if (this.props.rounded) {
            classes.push(styles.Rounded);
        }
        return classes.join(" ");
    }
}
