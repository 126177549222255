import styles from "./GetStarted.module.scss";
import React from "react";
import Button, {ButtonColor, ButtonSize} from "../Button/Button";

export default class GetStarted extends React.Component {
    render() {
        return (
            <div className={styles.SectionGetStarted}>
                <div className={styles.Container}>
                    <div className={styles.Container}>
                        <div className={styles.Title}>
                            Get Started!
                        </div>
                        <div className={styles.Columns}>
                            <div className={styles.StepParent}>
                                <div className={styles.Step}>
                                    <div className={[styles.BigNumber, styles.FirstColor].join(" ")}>1</div>
                                    <div className={[styles.StepNumber, styles.FirstColor].join(" ")}>01</div>
                                    <div className={styles.StepTitle}>Create an account</div>
                                    <div className={styles.StepDetails}>
                                        Signing up on wavy is quick and simple. Get the best username!
                                    </div>
                                </div>
                            </div>
                            <div className={styles.StepParent}>
                                <div className={styles.Step}>
                                    <div className={[styles.BigNumber, styles.SecondColor].join(" ")}>2</div>
                                    <div className={[styles.StepNumber, styles.SecondColor].join(" ")}>02</div>
                                    <div className={styles.StepTitle}>Link your Spotify</div>
                                    <div className={styles.StepDetails}>
                                        Necessary if you want to track your music. We’re working on bringing you
                                        more platforms.
                                    </div>
                                </div>
                            </div>
                            <div className={styles.StepParent}>
                                <div className={styles.Step}>
                                    <div className={[styles.BigNumber, styles.ThirdColor].join(" ")}>3</div>
                                    <div className={[styles.StepNumber, styles.ThirdColor].join(" ")}>03</div>
                                    <div className={styles.StepTitle}>Listen to some music</div>
                                    <div className={styles.StepDetails}>
                                        The easiest step of the three. Think carefully about
                                        which song you want to track first!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.JoinButtonParent}>
                        <Button color={ButtonColor.Accent} className={styles.JoinButton}
                                size={ButtonSize.Big} link={{to: "/join"}}>Join wavy.fm</Button>
                    </div>
                </div>
            </div>
        )
    }
}
