import React from "react";
import styles from "./IntroMeat.module.scss";
import IllustrationDesktop from "./IllustrationDesktop.png";
import IllustrationMobile from "./IllustrationMobile.png";

export default class IntroMeat extends React.Component {
    render() {
        return (
            <div className={styles.Background}>
                <div className={styles.Container}>
                    <div className={styles.Description}>
                        <div className={styles.Headline}>
                            Start building your personal <span className={styles.Blue}>music portfolio</span>
                        </div>
                        <p className={styles.Text}>
                            wavy.fm allows you to gather your daily musical trends
                            and explore all your listening in one place.
                        </p>
                        <p className={styles.Text}>
                            Connect with friends and show them that one song that’s stuck on repeat.
                        </p>
                    </div>
                    <img src={window.innerWidth <= 768 ? IllustrationMobile : IllustrationDesktop}
                         className={styles.Illustration} alt={""}/>
                </div>
            </div>
        )
    }
}
