import React from "react";
import styles from "./UserBadge.module.scss";

interface Props {
    admin?: boolean;
    supporter?: boolean;
    size: string;
}

export default class UserBadge extends React.Component<Props> {
    render() {
        if (this.props.supporter) {
            return (
                /*eslint-disable-next-line react/jsx-no-target-blank*/
                <a href={"https://www.buymeacoffee.com/wavy"} target={"_blank"}
                   style={{"--badge-size": this.props.size} as React.CSSProperties}
                   className={styles.Badge + " " + styles.SupporterBadge} title={"Supporter"} key={"Supporter"}>
                </a>
            )
        } else if (this.props.admin) {
            return (
                <div className={styles.Badge + " " + styles.AdminBadge}
                     style={{"--badge-size": this.props.size} as React.CSSProperties}
                     title={"Staff"} key={"Staff"}/>
            )
        } else {
            return "";
        }
    }
}
