import React from "react";
import {ForeignUser} from "../../../Redux/Types";
import {GetProfileListensResponse} from "../../../API/Models";
import MusicList, {Entry} from "../MusicList/MusicList";
import {getRecentListens} from "../../../API/Calls";
import Skeleton from "./Skeleton";
import {HistoryEditManager} from "./HistoryEditManager";
import {EventEmitter} from "events";
import styles from "./UserHistory.module.scss";
import {titleManager} from "../../../Title";
import DefaultAlbum from "../../../Images/DefaultAlbum.svg";
import {spotifyUriToId} from "../../../SpotifyUtil";
import SongSubject from "../MusicSubject/SongSubject";
import {DELETE_LISTEN, MusicAction, OPEN_IN_SPOTIFY} from "../MusicActionPopup/MusicAction";

interface RecentHistoryProps {
    user: ForeignUser;
    page: number;
    pageFunc: (hasNext: boolean, hasPrev: boolean, pageNumber: number, totalPages: number, totalResults: number) => void,
    searchFilter?: string;
    dateFilter?: Date;
    editing: boolean;
    editManager: HistoryEditManager;
    reload$: EventEmitter;
    isOwnProfile: boolean;
}

interface RecentHistoryState {
    /**
     * Whether the tracks have loaded
     */
    tracksLoaded: boolean;
    /**
     * Response with track history info
     */
    tracksResponse: GetProfileListensResponse | null;
    signal: AbortController;
}

export default class RecentHistory extends React.Component<RecentHistoryProps, RecentHistoryState> {

    constructor(props: Readonly<RecentHistoryProps>) {
        super(props);

        this.actionGenerator = this.actionGenerator.bind(this);

        this.state = {
            tracksLoaded: false,
            tracksResponse: null,
            signal: new AbortController()
        }
    }

    render() {
        return (
            <div className={"RecentHistoryPage"}>
                {this.renderMusicList()}
            </div>
        )
    }

    renderMusicList() {
        if (!this.state.tracksResponse) {
            return <Skeleton/>
        }
        if (this.state.tracksResponse.tracks.length === 0) {
            if (this.props.dateFilter || this.props.searchFilter) {
                return <div className={styles.Placeholder}>No results found</div>
            } else {
                return <div className={styles.Placeholder}>This user has no listening history</div>
            }
        }
        return (
            <MusicList
                entries={this.getTrackHistoryAsEntries()}
                metricType={"time"}
                defaultSize={50}
                autoCollapse={true}
                removeLastRowBorder={true}
                editing={this.props.editing}
                className={this.state.tracksLoaded ? "" : styles.Loading}
                toggleEntry={entry => this.props.editManager.toggle(entry.id)}
                isMobile={window.innerWidth <= 768}
                actionGenerator={this.actionGenerator}
            />
        )
    }

    getTracksPromise(): Promise<any> {
        if (this.props.user && this.props.user.id) {
            return getRecentListens(this.props.user.id, 50, this.props.page - 1, this.startDate, this.endDate, this.props.searchFilter, false, this.state.signal)
                .then(tracks => {
                    this.setState({
                        tracksLoaded: true,
                        tracksResponse: tracks,
                    }, () => {
                        this.props.pageFunc(
                            this.state.tracksLoaded && !!this.state.tracksResponse && this.state.tracksResponse.pages > this.props.page,
                            this.state.tracksLoaded && this.props.page > 1,
                            this.state.tracksLoaded ? this.props.page : 0,
                            this.state.tracksResponse?.pages ?? 0,
                            this.state.tracksResponse?.total_tracks ?? 0,
                        )
                    })
                })
                .catch(console.error);
        } else {
            return Promise.reject("No profile loaded.");
        }
    }

    loadPage(): void {
        this.abortAndReset(() => {
            this.props.pageFunc(false, false, 0, 0, 0);
            this.getTracksPromise()
                .catch(err => console.error(err));
        })
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    componentDidMount(): void {
        titleManager.set(`${this.props.user.username}'s Recent Songs`);
        this.loadPage();
        this.scrollTop();
        this.props.reload$.on("reload", () => this.loadPage());
    }

    componentDidUpdate(prevProps: Readonly<RecentHistoryProps>, prevState: Readonly<RecentHistoryState>, snapshot?: any): void {
        if (prevProps.searchFilter !== this.props.searchFilter) {
            this.loadPage();
            this.scrollTop();
            return;
        }
        if (prevProps.page !== this.props.page) {
            this.loadPage();
            this.scrollTop();
            return;
        }

        // weird date equality thing
        const dateFilterPrev = prevProps.dateFilter?.getTime();
        const dateFilterNext = this.props.dateFilter?.getTime();
        if (dateFilterPrev !== dateFilterNext) {
            this.loadPage();
            this.scrollTop();
            return;
        }
    }

    componentWillUnmount(): void {
        this.state.signal.abort();
    }

    abortAndReset(cb?: () => void) {
        this.state.signal.abort();
        this.setState({signal: new AbortController(), tracksLoaded: false}, cb);
    }

    get startDate(): number {
        if (this.props.dateFilter) {
            return this.props.dateFilter.getTime();
        } else {
            return 0;
        }
    }

    get endDate(): number {
        if (this.props.dateFilter) {
            return this.props.dateFilter.getTime() + 24 * 3600 * 1000;
        } else {
            return 0;
        }
    }

    private getTrackHistoryAsEntries(): Entry[] {
        if (this.state.tracksResponse) {
            return this.state.tracksResponse.tracks.map(track => {
                // Spotify URL hack
                const url = `https://open.spotify.com/track/${spotifyUriToId(track.track.uri)}`;
                const image = track.track.album.images
                    .sort((a, b) => a.width - b.width)[0]?.url ?? DefaultAlbum;
                return {
                    id: track.play_id,
                    primary: track.track.name,
                    secondary: track.track.artists[0].name,
                    metric: new Date(track.date.$date),
                    icon: image,
                    editMode: !this.props.editing ? undefined : (this.props.editManager.has(track.play_id) ? "deleted" : "none"),
                    playerLink: url,
                    actionSubject: () => <SongSubject name={track.track.name} artist={track.track.artists[0].name}
                                                      image={image}/>
                }
            });
        } else {
            return [];
        }
    }

    private actionGenerator(entry: Entry): MusicAction[] {
        const actions = [OPEN_IN_SPOTIFY(entry.playerLink)];
        if (this.props.isOwnProfile) {
            actions.push(
                DELETE_LISTEN(entry.id, () => {
                    this.setState({tracksLoaded: false});
                }, () => {
                    this.props.reload$.emit("reload");
                })
            )
        }
        return actions;
    }
}
