import React from "react";
import {GetTrendingTracksResponse} from "../../../../API/Models";
import {getTrendingTracks} from "../../../../API/Calls";
import MusicList, {Entry} from "../../MusicList/MusicList";
import styles from "./TrendingSongs.module.scss";
import DefaultAlbum from "../../../../Images/DefaultAlbum.svg";
import {cssVar} from "../../../../variables";
import {spotifyUriToId} from "../../../../SpotifyUtil";
import SongSubject from "../../MusicSubject/SongSubject";
import {OPEN_IN_SPOTIFY} from "../../MusicActionPopup/MusicAction";

interface TrendingSongsState {
    tracks: null | GetTrendingTracksResponse
}

export default class TrendingSongs extends React.Component<{}, TrendingSongsState> {

    constructor(props: Readonly<{}>) {
        super(props);

        const cachedTracks = window.localStorage.getItem("wavy.cache.trending_songs");
        let tracks = null;
        if (cachedTracks) {
            tracks = JSON.parse(cachedTracks);
        }

        this.state = {
            tracks,
        }
    }

    render() {
        return (
            <div className={styles.TrendingSongsSection}>
                <div className={styles.ListParent}>
                    {
                        !this.state.tracks ? "" :
                            <MusicList
                                className={styles.TrendingSongsList}
                                entries={this.getEntries()}
                                ranked={true}
                                autoCollapse={true}
                                metricColumnClass={styles.MetricColumn}
                                metricIcon={this.renderIcon()}
                                metricType={"count"}
                                rowClass={styles.Row}
                                iconColumnClass={styles.IconColumn}
                                rankColumnClasses={styles.RankColumn}
                                primaryColumnClasses={styles.PrimaryColumn}
                                actionGenerator={entry => [
                                    OPEN_IN_SPOTIFY(entry.playerLink),
                                ]}
                            />
                    }
                </div>
                <div className={"ArtistBlob"}/>
            </div>
        )
    }

    renderIcon() {
        return (
            <svg width="26" height="31" viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={styles.MetricIcon}>
                <path
                    d="M12.9585 15.7646C17.0072 15.7646 20.2885 12.4401 20.2885 8.33809C20.2885 4.23613 17.0072 0.911621 12.9585 0.911621C8.90983 0.911621 5.6285 4.23613 5.6285 8.33809C5.6285 12.4401 8.90983 15.7646 12.9585 15.7646ZM18.0895 17.6212H17.1332C15.8619 18.213 14.4474 18.5495 12.9585 18.5495C11.4696 18.5495 10.0609 18.213 8.78384 17.6212H7.8275C3.57838 17.6212 0.130981 21.1139 0.130981 25.419V27.8326C0.130981 29.3701 1.3622 30.6175 2.87974 30.6175H23.0373C24.5548 30.6175 25.7861 29.3701 25.7861 27.8326V25.419C25.7861 21.1139 22.3387 17.6212 18.0895 17.6212Z"
                    fill={cssVar('--accent-pink')}/>
            </svg>
        )
    }

    componentDidMount(): void {
        getTrendingTracks()
            .then(tracks => {
                window.localStorage.setItem("wavy.cache.trending_songs", JSON.stringify(tracks));
                this.setState({tracks});
            })
            .catch(console.error);
    }

    getEntries(): Entry[] {
        if (this.state.tracks) {
            return this.state.tracks.map(track => {
                // Spotify URL hack
                const url = `https://open.spotify.com/track/${spotifyUriToId(track.track.uri)}`;
                const image = track.track.album.images
                    .sort((a, b) => a.width - b.width)[0]?.url ?? DefaultAlbum;
                return {
                    id: track.track.uri,
                    primary: track.track.name,
                    secondary: track.track.artists[0].name,
                    metric: track.users.toLocaleString(),
                    icon: image,
                    playerLink: url,
                    actionSubject: () => <SongSubject name={track.track.name} artist={track.track.artists[0].name}
                                                      image={image}/>
                }
            });
        } else {
            return [];
        }
    }
}
