import React from "react";
import styles from "./Terms.module.scss";
import {Link} from "react-router-dom";
import {titleManager} from "../../../Title";

export default class Terms extends React.Component {
    render() {
        return (
            <div className={styles.Container}>
                <h1 className={styles.Title}>Terms of Service</h1>
                <p>Last updated: October 11, 2020</p>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>Overview</h2>
                    <p>
                        This website is operated by Wavy Labs Inc. Throughout the site, the terms "we", "us", and
                        "our" refer to Wavy Labs Inc. We offer this website, including all information, tools, and
                        services available from this site to you, the user, conditioned upon your acceptance of all
                        terms, conditions, policies and notices stated here.
                    </p>
                    <p>
                        By visiting our site, you engage in our “Service" and agree to be bound by the following terms
                        and conditions (“Terms of Service", “Terms"), including those additional terms and conditions
                        and policies referenced herein and/or available by hyperlink. These Terms of Service apply to
                        all users of the site, including without limitation users who are browsers and/or contributors
                        of content.
                    </p>
                    <p>
                        Please read these Terms of Service carefully before accessing or using our website. By accessing
                        or using any part of the site, you agree to be bound by these Terms of Service. If you do not
                        agree to all the terms and conditions of this agreement, then you may not access the website or
                        use any services.
                    </p>
                    <p>
                        You can review the most current version of the Terms of Service at any time on this page. We
                        reserve the right to update, change or replace any part of these Terms of Service by posting
                        updates and/or changes to our website. It is your responsibility to check this page periodically
                        for changes. Your continued use of or access to the website following the posting of any changes
                        constitutes acceptance of those changes.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>Section 1 &mdash; General Conditions</h2>
                    <p>
                        We reserve the right to refuse service to anyone for any reason at any time.
                    </p>
                    <p>
                        You understand that your content may be transferred and involve (a) transmissions over various
                        networks; and (b) changes to conform and adapt to technical requirements of connecting networks
                        or devices.
                    </p>
                    <p>
                        You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service,
                        use of the Service, or access to the Service or any contact on the website through which the
                        service is provided, without written permission by us.
                    </p>
                    <p>
                        We may, but have no obligation to, monitor, edit or remove content that we determine in our sole
                        discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or
                        otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
                    </p>
                    <p>
                        The headings used in this agreement are included for convenience only and will not limit or
                        otherwise affect these Terms.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>
                        Section 2 &mdash; Accuracy, Completeness, and Timeliness of Information
                    </h2>
                    <p>
                        We are not responsible if information made available on this site is not accurate, complete or
                        current. The material on this site is provided for general information only and should not be
                        relied upon or used as the sole basis for making decisions without consulting primary, more
                        accurate, more complete or more timely sources of information. Any reliance on the material on
                        this site is at your own risk.
                    </p>
                    <p>
                        We reserve the right to modify the contents of this site at any time, but we have no obligation
                        to update any information on our site. You agree that it is your responsibility to monitor
                        changes to our site.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>
                        Section 3 &mdash; Modifications to the Service
                    </h2>
                    <p>
                        We reserve the right at any time to modify or discontinue the Service (or any part or content
                        thereof) without notice at any time.
                    </p>
                    <p>
                        We shall not be liable to you or any third-party for any modification, suspension or
                        discontinuance of the Service.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>
                        Section 4 &mdash; Optional Tools
                    </h2>
                    <p>
                        We may provide you with access to third-party tools over which we neither monitor nor have any
                        control nor input.
                    </p>
                    <p>
                        You acknowledge and agree that we provide access to such tools "as is" and “as available"
                        without any warranties, representations or conditions of any kind and any endorsement. We shall
                        have no liability whatsoever arising from or relating to your use of optional third-party tools.
                    </p>
                    <p>
                        Any use by you of optional tools offered through the site is entirely at your own risk and
                        discretion and you should ensure that you are familiar with and approve of the terms on which
                        tools are provided by the relevant third-party provider(s).
                    </p>
                    <p>
                        We may also, in the future, offer new services and/or features through the website (including,
                        the release of new tools and resources). Such new features and/or services shall also be subject
                        to these Terms of Service.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>
                        Section 5 &mdash; Third-Party Links
                    </h2>
                    <p>
                        Certain content, products, and services available via our Service may include materials from
                        third-parties.
                    </p>
                    <p>
                        Third-party links on this site may direct you to third-party websites that are not affiliated
                        with us. We are not responsible for examining or evaluating the content or accuracy and we do
                        not warrant and will not have any liability or responsibility for any third-party materials or
                        websites, or any other materials, products, or services of third-parties.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>
                        Section 6 &mdash; User Feedback
                    </h2>
                    <p>
                        If, at our request, you send certain specific submissions or without a request from us you send
                        creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, or
                        otherwise (collectively, 'feedback'), you agree that we may, at any time, without restriction,
                        edit, copy, publish, distribute, translate and otherwise use in any medium any feedback that you
                        forward to us. We are and shall be under no obligation (1) to maintain any feedback in
                        confidence; (2) to pay compensation for any feedback; or (3) to respond to any feedback.
                    </p>
                    <p>
                        You agree that your feedback will not violate any right of any third-party, including copyright,
                        trademark, privacy, personality or other personal or proprietary right. You further agree that
                        your feedback will not contain libelous or otherwise unlawful, abusive or obscene material, or
                        contain any computer virus or other malware that could in any way affect the operation of the
                        Service or any related website. You may not use a false e-mail address, pretend to be someone
                        other than yourself, or otherwise mislead us or third-parties as to the origin of any feedback.
                        You are solely responsible for any feedback you make and their accuracy. We take no
                        responsibility and assume no liability for any feedback posted by you or any third-party.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>
                        Section 7 &mdash; Personal Information
                    </h2>
                    <p>
                        Your submission of personal information on the website is governed by
                        our <Link to={"/privacy"}>Privacy Policy</Link>.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>
                        Section 8 &mdash; Disclaimer of Warranties; Limitation of Liability
                    </h2>
                    <p>
                        We do not guarantee, represent or warrant that your use of our service will be uninterrupted,
                        timely, secure or error-free.
                    </p>
                    <p>
                        We do not warrant that the results that may be obtained from the use of the service will be
                        accurate or reliable.
                    </p>
                    <p>
                        You agree that from time to time we may remove the service for indefinite periods or cancel the
                        service at any time, without notice to you.
                    </p>
                    <p>
                        You expressly agree that your use of, or inability to use, the service is at your sole risk. The
                        service and all products and services delivered to you through the service are (except as
                        expressly stated by us) provided 'as is' and 'as available' for your use, without any
                        representation, warranties or conditions of any kind.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>
                        Section 9 &mdash; Severability
                    </h2>
                    <p>
                        In the event that any provision of these Terms of Service is determined to be unlawful, void or
                        unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted
                        by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms
                        of Service, such determination shall not affect the validity and enforceability of any other
                        remaining provisions.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>
                        Section 10 &mdash; Termination
                    </h2>
                    <p>
                        These Terms of Service are effective unless and until terminated by either you or us. You may
                        terminate these Terms of Service at any time by notifying us that you no longer wish to use our
                        Services, or when you cease using our site.
                    </p>
                    <p>
                        If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or
                        provision of these Terms of Service, we also may terminate this agreement at any time without
                        notice and/or accordingly may deny you access to our Services (or any part thereof).
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>
                        Section 11 &mdash; Entire Agreement
                    </h2>
                    <p>
                        The failure of us to exercise or enforce any right or provision of these Terms of Service shall
                        not constitute a waiver of such right or provision.
                    </p>
                    <p>
                        These Terms of Service and any policies or operating rules posted by us on this site or in
                        respect to The Service constitutes the entire agreement and understanding between you and us and
                        govern your use of the Service, superseding any prior or contemporaneous agreements,
                        communications and proposals, whether oral or written, between you and us (including, but not
                        limited to, any prior versions of the Terms of Service).
                    </p>
                    <p>
                        Any ambiguities in the interpretation of these Terms of Service shall not be construed against
                        the drafting party.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>
                        Section 12 &mdash; Governing Law
                    </h2>
                    <p>
                        Wavy Labs Inc. is a Canadian federal corporation (Corporation Number 12326027) under
                        Ontario jurisdiction, so this document is to be interpreted in line with the laws governing
                        Ontario and Canada.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>
                        Section 13 &mdash; Changes to Terms of Service
                    </h2>
                    <p>
                        You can review the most current version of the Terms of Service at any time at this page.
                    </p>
                    <p>
                        We reserve the right, at our sole discretion, to update, change or replace any part of these
                        Terms of Service by posting updates and changes to our website. It is your responsibility to
                        check our website periodically for changes. Your continued use of or access to our website or
                        the Service following the posting of any changes to these Terms of Service constitutes
                        acceptance of those changes.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>
                        Section 14 &mdash; Contact Information
                    </h2>
                    <p>
                        Questions about the Terms of Service should be sent to us at contact@wavy.fm.
                    </p>
                </div>
            </div>
        )
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Terms of Service");
    }
}
