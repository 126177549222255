import React from "react";
import {ForeignUser} from "../../../Redux/Types";
import {GetProfileArtistsResponse} from "../../../API/Models";
import MusicList, {Entry} from "../MusicList/MusicList";
import {getProfileArtists} from "../../../API/Calls";
import Skeleton from "./Skeleton";
import styles from "./UserHistory.module.scss";
import DefaultArtist from "../../../Images/DefaultArtist.svg";
import {convertDateRange, Ranges} from "../ProfileDateRange/ProfileDateRange";
import {titleManager} from "../../../Title";
import {spotifyUriToId} from "../../../SpotifyUtil";
import ArtistSubject from "../MusicSubject/ArtistSubject";
import {OPEN_IN_SPOTIFY} from "../MusicActionPopup/MusicAction";

interface TopArtistsProps {
    user: ForeignUser;
    page: number;
    pageFunc: (hasNext: boolean, hasPrev: boolean, pageNumber: number, totalPages: number, totalResults: number) => void,
    searchFilter?: string;
    dateRange: Ranges;
}

interface TopArtistsState {
    /**
     * Whether the artists have loaded
     */
    artistsLoaded: boolean;
    /**
     * Response with artists
     */
    artistsResponse: GetProfileArtistsResponse | null;
    signal: AbortController;
}

export default class TopSongs extends React.Component<TopArtistsProps, TopArtistsState> {

    constructor(props: Readonly<TopArtistsProps>) {
        super(props);

        this.state = {
            artistsLoaded: false,
            artistsResponse: null,
            signal: new AbortController()
        }
    }

    render() {
        return (
            <div className={"TopArtistsPage"}>
                {this.renderMusicList()}
            </div>
        )
    }

    renderMusicList() {
        if (!this.state.artistsLoaded || !this.state.artistsResponse) {
            return <Skeleton/>
        }
        if (this.state.artistsResponse.artists.length === 0) {
            if (this.props.dateRange !== Ranges.AllTime || this.props.searchFilter) {
                return <div className={styles.Placeholder}>No results found</div>
            } else {
                return <div className={styles.Placeholder}>This user has no listening history</div>
            }
        }
        return (
            <MusicList
                entries={this.getEntries()}
                metricType={"count"}
                defaultSize={50}
                className={"RecentHistoryList"}
                autoCollapse={true}
                removeLastRowBorder={true}
                iconColumnClass={styles.ArtistIcon}
                isMobile={window.innerWidth <= 768}
                actionGenerator={entry => [
                    OPEN_IN_SPOTIFY(entry.playerLink)
                ]}
            />
        )
    }

    getArtistsPromise(): Promise<any> {
        if (this.props.user && this.props.user.id) {
            return getProfileArtists(this.props.user.id, 50, this.props.page - 1, convertDateRange(this.props.dateRange), 0, this.props.searchFilter, this.state.signal)
                .then(artists => {
                    this.setState({
                        artistsLoaded: true,
                        artistsResponse: artists,
                    }, () => {
                        this.props.pageFunc(
                            this.state.artistsLoaded && !!this.state.artistsResponse && this.state.artistsResponse.pages > this.props.page,
                            this.state.artistsLoaded && this.props.page > 1,
                            this.state.artistsLoaded ? this.props.page : 0,
                            this.state.artistsResponse?.pages ?? 0,
                            this.state.artistsResponse?.total_artists ?? 0,
                        )
                    })
                })
        } else {
            return Promise.reject("No profile loaded.");
        }
    }

    loadPage(): void {
        this.abortAndReset(() => {
            window.scrollTo(0, 0);
            this.props.pageFunc(false, false, 0, 0, 0);
            this.setState({artistsLoaded: false});
            this.getArtistsPromise()
                .catch(err => console.error(err));
        });
    }

    componentDidMount(): void {
        titleManager.set(`${this.props.user.username}'s Top Artists`);
        this.loadPage();
    }

    componentDidUpdate(prevProps: Readonly<TopArtistsProps>, prevState: Readonly<TopArtistsState>, snapshot?: any): void {
        if (prevProps.searchFilter !== this.props.searchFilter) {
            this.loadPage();
            return;
        }
        if (prevProps.page !== this.props.page) {
            this.loadPage();
            return;
        }
        if (prevProps.dateRange !== this.props.dateRange) {
            this.loadPage();
            return;
        }
    }

    componentWillUnmount(): void {
        this.state.signal.abort();
    }

    abortAndReset(cb?: () => void) {
        this.state.signal.abort();
        this.setState({signal: new AbortController()}, cb);
    }

    getEntries(): Entry[] {
        if (this.state.artistsResponse) {
            return this.state.artistsResponse.artists.map(artist => {
                // Spotify URL hack
                const url = `https://open.spotify.com/artist/${spotifyUriToId(artist.artist.uri)}`;
                const images = artist.artist.images
                    .sort((a, b) => a.width - b.width);
                const image = images.length > 0 ? images[0].url : DefaultArtist;
                return {
                    id: artist.artist.uri,
                    primary: artist.artist.name,
                    secondary: undefined,
                    metric: artist.count.toLocaleString(),
                    icon: image,
                    playerLink: url,
                    actionSubject: () => <ArtistSubject name={artist.artist.name} image={image}/>
                }
            });
        } else {
            return [];
        }
    }
}
