import React from "react";
import {UserState} from "../../../Redux/Types";
import "./UserNavbar.scss";
import UserMenuContainer from "../../Containers/UserMenuContainer";
import SearchContainer from "../../Containers/SearchContainer";
import Button, {ButtonColor} from "../Button/Button";
import Card from "../Card/Card";
import Avatar from "../Avatar/Avatar";
import {isDarkMode, setDarkMode} from "../../../DarkMode";
import LightToggle from "./LightToggle";

export interface UserNavbarProps {
    profileReady: boolean;
    user: UserState | null;
    isMenuOpen: boolean;
    hideSearch: boolean;

    openUserMenu: () => void;
    closeUserMenu: () => void;
}

export class UserNavbar extends React.Component<UserNavbarProps> {

    private toggleRef: React.RefObject<HTMLElement>;

    constructor(props: Readonly<UserNavbarProps>) {
        super(props);
        this.state = {menuOpen: false};

        this.toggleRef = React.createRef();
    }

    toggleUserMenu() {
        if (this.props.isMenuOpen) {
            this.props.closeUserMenu();
        } else {
            this.props.openUserMenu();
        }
    }

    render() {
        if (this.props.profileReady) {
            if (this.props.user !== null) {
                return (
                    <div className="NavBar-Right">
                        <SearchContainer/>
                        <div className="NavBar-Item">
                            <div className={"UserNavbar-Container " + (this.props.isMenuOpen ? "Open" : "")}
                                 onClick={() => this.toggleUserMenu()}
                                 onKeyDown={e => this.onKeyDown(e)}
                                 tabIndex={0}
                                 ref={this.toggleRef as React.RefObject<HTMLDivElement>}>
                                <Avatar user={this.props.user} size={"35px"}/>
                                <div className={"Username"}>{this.props.user.username}</div>
                            </div>
                        </div>
                        {
                            this.props.isMenuOpen ?
                                <Card className={"UserMenuFloat"}>
                                    <UserMenuContainer toggleRef={this.toggleRef}/>
                                </Card>
                                : ""
                        }
                    </div>
                )
            } else {
                return (
                    <div className="NavBar-Right">
                        <SearchContainer/>
                        <div className="NavBar-Item">
                            <Button color={ButtonColor.Primary} link={{to: "/join"}}>Join</Button>
                        </div>
                        <div className="NavBar-Item">
                            <Button color={ButtonColor.Light} link={{to: "/login"}}>Login</Button>
                        </div>
                        <div className="NavBar-Item">
                            <div className={"NavBar-DarkModeToggle"} onClick={() => setDarkMode(!isDarkMode())}>
                                <LightToggle/>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            return <div className="NavBar-Right"/>
        }
    }

    onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === 'Enter') {
            this.toggleUserMenu();
        }
    }
}
