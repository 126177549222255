import React from "react";
import styles from "./CommentReportForm.module.scss";
import Button, {ButtonColor} from "../Button/Button";
import CardModal from "../CardModal/CardModal";
import {CommentReportReason} from "../../../API/Models";
import {ForeignUser} from "../../../Redux/Types";
import {reportComment, reportReply} from "../../../API/Calls";

interface Props {
    profileId: string
    commentId: string;
    replyId?: string;
    commentUser: ForeignUser;

    isOpen: boolean;
}

interface Actions {
    closeCommentReportForm: () => void;
}

interface State {
    loading: boolean;
    submitted: boolean;
}

export default class CommentReportForm extends React.Component<Props & Actions, State> {

    private readonly abort = new AbortController();

    constructor(props: Readonly<Props & Actions>) {
        super(props);

        this.state = {
            submitted: false,
            loading: false
        }
    }

    renderForm() {
        return (
            <div className={styles.ReportForm}>
                <p className={styles.Question}>Which issue with this comment are you reporting?</p>
                <p className={this.getOptionClasses()} onClick={() => this.send(CommentReportReason.Spam)}>
                    This comment is spam.
                </p>
                <p className={this.getOptionClasses()} onClick={() => this.send(CommentReportReason.Abusive)}>
                    This comment is abusive.
                </p>
                <div className={styles.ButtonParent}>
                    <Button color={ButtonColor.Regular}
                            onClick={() => this.actions.closeCommentReportForm()}>Cancel</Button>
                </div>
            </div>
        )
    }

    renderSubmitted() {
        return (
            <div className={styles.ReportForm}>
                <p>Please note that abusing this function may result in your account being terminated.</p>
                <p>You can block this user while we look into this.</p>
                <div className={styles.ButtonParent}>
                    <Button color={ButtonColor.Primary}
                            onClick={() => this.actions.closeCommentReportForm()}>Done</Button>
                </div>
            </div>
        )
    }

    render() {
        if (this.state.submitted) {
            return (
                <CardModal title={"This comment has been reported"}
                           isOpen={this.props.isOpen}
                           onRequestClose={() => this.actions.closeCommentReportForm()}
                           children={this.renderSubmitted()}/>
            )
        } else {
            return (
                <CardModal title={`Report this comment by ${this.props.commentUser?.username}?`}
                           titleClassName={styles.ModalTitle}
                           isOpen={this.props.isOpen}
                           onRequestClose={() => this.actions.closeCommentReportForm()}
                           children={this.renderForm()}/>
            )
        }
    }

    send(reason: CommentReportReason) {
        if (this.state.loading) return;

        this.setState({loading: true}, () => {
            const {profileId, commentId, replyId} = this.props;

            const func = replyId ?
                () => reportReply(reason, profileId, commentId, replyId, this.abort) :
                () => reportComment(reason, profileId, commentId, this.abort);

            func()
                .then(() => {
                    this.setState({submitted: true});
                })
                .catch(err => {
                    console.error(err);
                    this.setState({loading: false});
                })
        });
    }

    componentDidUpdate(prevProps: Readonly<Props & Actions>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.isOpen && !this.props.isOpen) {
            this.setState({submitted: false, loading: false});
        }
    }

    componentWillUnmount() {
        this.abort.abort();
    }

    getOptionClasses(): string {
        if (this.state.loading) {
            return [styles.Option, styles.Loading].join(" ")
        } else {
            return styles.Option
        }
    }

    get actions() {
        return this.props as Actions;
    }
}
