import React from "react";
import {ForeignUser} from "../../../Redux/Types";
import {GetProfileAlbumsResponse, ProfileDateRangeDefaultKey, SpotifyAlbum} from "../../../API/Models";
import ProfileGrid, {Entry} from "./ProfileGrid";
import DefaultAlbum from "../../../Images/DefaultAlbum.svg";
import {getProfileAlbums, setDefaultDateRange} from "../../../API/Calls";
import styles from "./ProfileGrid.module.scss";
import ProfileDateRange, {
    convertDateRange,
    rangeFromDefaultValue,
    Ranges,
    rangeToDefaultValue
} from "../ProfileDateRange/ProfileDateRange";
import {EventEmitter} from "events";
import AlbumSubject from "../MusicSubject/AlbumSubject";
import {spotifyUriToId} from "../../../SpotifyUtil";
import {OPEN_IN_SPOTIFY} from "../MusicActionPopup/MusicAction";

interface Props {
    isOwnProfile: boolean;
    user: ForeignUser;
    focusedEntry?: Entry;
    onHover: (entry?: Entry) => void;
    reload$: EventEmitter;
}

interface State {
    /**
     * Response with album history info
     */
    albumsResponse: GetProfileAlbumsResponse | null;
    loading: boolean;
    dateRange: Ranges;
    defaultDateRange: Ranges;
}

export default class ProfileAlbumsGrid extends React.Component<Props, State> {

    private abort = new AbortController();

    constructor(props: Readonly<Props>) {
        super(props);

        this.changeDateRange = this.changeDateRange.bind(this);
        this.saveAsDefault = this.saveAsDefault.bind(this);

        const defaultDateRange = rangeFromDefaultValue(props.user.dateRangeDefaults?.[ProfileDateRangeDefaultKey.Albums]);
        this.state = {
            albumsResponse: null,
            loading: false,
            dateRange: defaultDateRange,
            defaultDateRange: defaultDateRange,
        }

        this.props.reload$.on("reload_listens", () => this.load());
    }

    render() {
        return (
            <div className={styles.GridHistoryParent}>
                <div className={styles.GridHistoryTitle}>
                    {this.renderTitle()}
                </div>
                <ProfileGrid loading={this.state.loading} onHover={this.props.onHover}
                             focusedEntry={this.props.focusedEntry} entries={this.getEntries()}
                             moreLink={this.moreLink}
                             actionGenerator={entry => [
                                 OPEN_IN_SPOTIFY(entry.playerLink),
                             ]}
                />
            </div>
        )
    }

    get moreLink(): string {
        let path = "/user/" + this.props.user.username + "/history/albums";
        switch (this.state.dateRange) {
            case Ranges.Days7:
                path += "?range=7d";
                break;
            case Ranges.Days30:
                path += "?range=30d";
                break;
            case Ranges.Days90:
                path += "?range=90d";
                break;
            case Ranges.Days365:
                path += "?range=365d";
                break;
        }
        return path;
    }

    changeDateRange(dateRange: Ranges) {
        this.abort.abort();
        this.abort = new AbortController();

        const cached = dateRange === Ranges.AllTime ? this.getCachedAlbums() : null;
        const since = convertDateRange(dateRange);

        this.setState({dateRange, loading: true, albumsResponse: this.state.albumsResponse || cached}, () => {
            getProfileAlbums(this.props.user.id, 9, 0, since, 0, undefined, this.abort)
                .then(albumsResponse => {
                    this.setState({albumsResponse, loading: false});
                    if (dateRange === Ranges.AllTime) {
                        this.saveCachedAlbums(albumsResponse);
                    }
                })
                .catch(console.error);
        });
    }

    componentDidMount(): void {
        this.load();
    }

    load() {
        this.changeDateRange(this.state.dateRange);
    }

    componentWillUnmount() {
        this.abort.abort();
    }

    getEntries(): Entry[] | undefined {
        if (!this.state.albumsResponse) {
            return undefined;
        }
        return this.state.albumsResponse.albums.map(album => {
            const image = this.getAlbumImage(album.album);
            const url = `https://open.spotify.com/album/${spotifyUriToId(album.album.uri)}`;
            return {
                key: album.album.uri,
                icon: image,
                name: album.album.name,
                subName: album.album.artists[0].name,
                plays: album.count,
                actionSubject: () => <AlbumSubject name={album.album.name} artist={album.album.artists[0].name}
                                                   image={image}/>,
                playerLink: url,
            }
        })
    }

    getAlbumImage(album: SpotifyAlbum): string {
        if (album.images && album.images.length > 0) {
            return album.images
                .sort((a, b) => b.width - a.width)[0].url;
        } else {
            return DefaultAlbum;
        }
    }

    getCachedAlbums(): GetProfileAlbumsResponse | null {
        const key = "wavy.cache.albums." + this.props.user.id;
        const value = window.localStorage.getItem(key);
        if (value === null) {
            return null;
        }
        try {
            return JSON.parse(value) as GetProfileAlbumsResponse;
        } catch (e) {
            console.error("Failed to read from cache", e);
            return null;
        }
    }

    saveCachedAlbums(albums: GetProfileAlbumsResponse) {
        const key = "wavy.cache.albums." + this.props.user.id;
        try {
            window.localStorage.setItem(key, JSON.stringify(albums));
        } catch (e) {
            console.error("Failed to save cache", e);
        }
    }

    renderTitle() {
        if (this.props.focusedEntry) {
            return ""; // Hide
        } else {
            return (
                <>
                    <span>Top Albums</span>
                    <div className={styles.LeftSide}>
                        {
                            !this.props.isOwnProfile || this.state.defaultDateRange === this.state.dateRange ? "" :
                                <span className={styles.SaveAsDefault}
                                      onClick={this.saveAsDefault}>(Set as default)</span>
                        }
                        <ProfileDateRange selected={this.state.dateRange} onChange={this.changeDateRange}/>
                    </div>
                </>
            )
        }
    }

    saveAsDefault() {
        if (this.props.isOwnProfile) {
            setDefaultDateRange(ProfileDateRangeDefaultKey.Albums, rangeToDefaultValue(this.state.dateRange))
                .then()
                .catch(console.error);
            this.setState({defaultDateRange: this.state.dateRange});
        }
    }
}
