import React, {ReactNode} from "react";
import styles from "./Button.module.scss";
import {Link, LinkProps} from "react-router-dom";

export enum ButtonColor {
    Accent,
    Danger,
    Light,
    Primary,
    Regular,
    Spotify,
    Discord
}

export enum ButtonSize {
    Regular,
    Big,
    Medium,
    Small
}

interface ButtonProps {
    color?: ButtonColor,
    outline?: boolean,
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    disabled?: boolean,
    mobile?: boolean,
    size?: ButtonSize,
    block?: boolean,

    form?: boolean,
    link?: LinkProps,
    children?: ReactNode,
    className?: string;
    tabIndex?: number;
}

export default class Button extends React.Component<ButtonProps> {
    render() {
        if (this.props.link) {
            return <Link className={Button.getButtonClasses(this.props)}
                         tabIndex={this.props.tabIndex}
                         children={this.props.children} {...this.props.link}/>
        } else if (this.props.form) {
            return <input type={"submit"} className={Button.getButtonClasses(this.props)}
                          tabIndex={this.props.tabIndex}
                          value={this.props.children ? this.props.children.toString() : ""}
                          disabled={this.props.disabled}/>
        } else {
            return <div className={Button.getButtonClasses(this.props)} onClick={this.props.onClick}
                        tabIndex={this.props.tabIndex}
                        children={this.props.children}/>
        }
    }

    static getButtonClasses(props: ButtonProps): string {
        const classes = [styles.Button];

        switch (props.color) {
            case ButtonColor.Accent:
                classes.push(styles.Accent);
                break;
            case ButtonColor.Danger:
                classes.push(styles.Danger);
                break;
            case ButtonColor.Light:
                classes.push(styles.Light);
                break;
            case ButtonColor.Primary:
                classes.push(styles.Primary);
                break;
            case ButtonColor.Spotify:
                classes.push(styles.Spotify);
                break;
            case ButtonColor.Discord:
                classes.push(styles.Discord);
                break;
            case ButtonColor.Regular:
            default:
                classes.push(styles.Regular);
        }

        if (props.outline) {
            classes.push(styles.Outline);
        }

        if (props.form) {
            classes.push(styles.Form);
        }

        if (props.disabled) {
            classes.push(styles.Disabled);
        }

        if (props.mobile) {
            classes.push(styles.Mobile);
        }

        if (props.block) {
            classes.push(styles.DisplayBlock);
        }

        switch (props.size) {
            case ButtonSize.Big:
                classes.push(styles.Big);
                break;
            case ButtonSize.Medium:
                classes.push(styles.Medium);
                break;
            case ButtonSize.Small:
                classes.push(styles.Small);
                break;
            case ButtonSize.Regular:
            default:
                break;
        }

        if (props.className) {
            classes.push(props.className);
        }

        return classes.join(" ");
    }
}
