import {ForeignUser} from "../Redux/Types";
import {CountryById} from "../Countries";
import DefaultAlbum from "../Images/DefaultAlbum.svg";
import DefaultArtist from "../Images/DefaultAlbum.svg";

export enum ProfileCommentsPrivacy {
    Everyone = "everyone",
    Friends = "friends",
    Me = "me"
}

export enum ProfileDateRangeDefaultKey {
    Artists = "artists",
    Albums = "albums",
    Songs = "songs",
}

export enum ProfileDateRangeDefaultValue {
    AllTime = "all",
    Days7 = "7d",
    Days30 = "30d",
    Days90 = "90d",
    Days365 = "365d",
}

export type ProfileDateRangeDefaults = { [key in ProfileDateRangeDefaultKey]: ProfileDateRangeDefaultValue | undefined };

export function readUser(obj: { [key: string]: any }): ForeignUser {
    return {
        id: obj.user_id,
        username: obj.username,
        joinTime: new Date(obj.join_time.$date),
        countryCode: obj.country_code,
        countryName: CountryById.get(obj.country_code) || undefined,
        bio: obj.bio,
        twitter: obj.twitter,
        instagram: obj.instagram,
        admin: obj.is_admin,
        supporter: obj.is_supporter,
        lastActivity: obj.last_activity ? new Date(obj.last_activity.$date) : undefined,
        spotifyId: obj.spotify_id,
        spotifyDisplayName: obj.spotify_display_name,
        discordId: obj.discord_id,
        discordDisplayName: obj.discord_display_name,
        dateRangeDefaults: obj.date_range_defaults,
        $access: obj.$access ? {
            view: obj.$access.view,
            blocked: obj.$access.blocked,
            blockedByThem: obj.$access.blocked_by_them,
            blockedByYou: obj.$access.blocked_by_you,
            viewSpotify: obj.$access.view_spotify,
            viewDiscord: obj.$access.view_discord,
            friends: obj.$access.friends,
            quarantined: obj.$access.quarantined,
            profileComments: obj.$access.profile_comments
        } : {
            view: true,
            blocked: false,
            blockedByThem: false,
            blockedByYou: false,
            viewSpotify: false,
            viewDiscord: false,
            friends: false,
            quarantined: false,
            profileComments: ProfileCommentsPrivacy.Me
        },
        $avatar_lease: obj.$avatar_lease,
    }
}

export interface PostProfilePictureResponse {
    event_id: string;
}

export interface GetProfilePictureEventResponse {
    state: "success" | "fail" | "loading",
    error: string,
    event_id: string,
}

export interface WavyTrackPlay {
    date: { "$date": number },
    play_id: string,
    track: SpotifyTrack,
}

export function createLiveWavyTrack(track: SpotifyTrack): WavyTrackPlay {
    return {
        date: {$date: 0},
        play_id: "live",
        track
    }
}

export interface SpotifyArtist {
    name: string,
    uri: string,
    images: {
        height: number;
        width: number;
        url: string;
    }[]
}

export function readSpotifyArtist(artist: Partial<SpotifyArtist>): SpotifyArtist {
    return {
        images: !!artist.images?.length ? artist.images : [{height: 100, width: 100, url: DefaultArtist}],
        name: artist.name || "Unknown Artist",
        uri: artist.uri || `local:${Math.random()}`,
    }
}

export interface SpotifyAlbum {
    name: string;
    uri: string;
    images: {
        height: number;
        width: number;
        url: string;
    }[];
    artists: SpotifyArtist[];
}

export function readSpotifyAlbum(album: Partial<SpotifyAlbum>): SpotifyAlbum {
    return {
        artists: !!album.artists?.length ? album.artists.map(readSpotifyArtist) : [readSpotifyArtist({})],
        images: !!album.images?.length ? album.images : [{height: 100, width: 100, url: DefaultAlbum}],
        name: album.name || "Untitled",
        uri: album.uri || `local:${Math.random()}`,
    }
}

export interface SpotifyTrack {
    name: string,
    uri: string,
    artists: SpotifyArtist[],
    album: SpotifyAlbum,
}

export function readSpotifyTrack(track: Partial<SpotifyTrack>): SpotifyTrack {
    return {
        name: track.name || "Untitled",
        uri: track.uri || `local:${Math.random()}`,
        artists: !!track.artists?.length ? track.artists.map(readSpotifyArtist) : [readSpotifyArtist({})],
        album: !!track.album ? readSpotifyAlbum(track.album) : readSpotifyAlbum({})
    }
}

export interface GetProfileListensResponse {
    total_tracks: number;
    total_artists: number;
    tracks: WavyTrackPlay[],
    pages: number,
    tracked_days: number,
    live?: SpotifyTrack,
}

export interface GetCountListensSinceResponse {
    count: number;
}

export type GetTrendingTracksResponse = { track: SpotifyTrack, users: number }[];

export interface GetSearchResultsResponse {
    users: ForeignUser[];
}

export interface GetProfileTracksResponse {
    tracks: {
        count: number;
        track: SpotifyTrack;
    }[];
    pages: number;
    total_tracks: number;
}

export interface GetProfileArtistsResponse {
    artists: {
        count: number,
        artist: SpotifyArtist
    }[];
    pages: number;
    total_artists: number;
}


export interface GetProfileAlbumsResponse {
    albums: {
        count: number,
        album: SpotifyAlbum
    }[];
    pages: number;
    total_albums: number;
}

export enum Friendship {
    None = "none",
    Friend = "friend",
    Pending = "pending",
    Sent = "sent"
}

export interface GetFriendStatusResponse {
    status: Friendship;
}

export type GetFriendListResponse = ForeignUser[];

export interface GetProfileActivityResponse {
    [key: string]: WavyTrackPlay | null
}

export interface GetNewUsersResponse {
    statuses: { [userId: string]: Friendship },
    users: ForeignUser[];
}

export interface GetPendingRequestsResponse {
    incoming: ForeignUser[];
    outgoing: ForeignUser[];
}

export interface GetPendingRequestsCountResponse {
    incoming: number;
    outgoing: number;
}

export enum ReportReason {
    Spam = "suspicious_spam",
    Abusive = "abusive_profile"
}

export enum CommentReportReason {
    Spam = "spam",
    Abusive = "abusive"
}

export type GetListensCalendarResponse = { [day: string]: number; };

export type GetBlockedUsersResponse = ForeignUser[];

export enum NotificationType {
    FriendRequest = "friend_request",
    FriendRequestAccepted = "friend_request_accepted",
    ProfileCommentReceived = "profile_comment_received",
    ProfileCommentReplied = "profile_comment_replied",
}

export interface Notification<T extends object> {
    id: string;
    date: Date;
    type: NotificationType;
    data?: T;
    read: boolean;
    eventId?: any;
}

export interface GetNotificationListResponse {
    total: number;
    unread: number;
    hasNext: boolean;
    items: Notification<any>[];
}

export function readNotification(obj: { [key: string]: any }): Notification<any> {
    return {
        id: obj.notification_id,
        date: new Date(obj.date.$date),
        type: obj.type,
        data: obj.data,
        read: obj.read,
        eventId: obj.event_id
    }
}

export interface CommentText {
    nodes: CommentNode[];
}

export interface CommentNode {
    children: CommentNodeChild[];
}

export interface CommentNodeChild {
    text: string;
}

export function readCommentText(obj: { [key: string]: any }): CommentText | null {
    const comment = obj as CommentText;

    if (!Array.isArray(comment.nodes)) {
        return null;
    }

    return comment;
}

export interface Comment {
    id: string;
    userId: string;
    profileId: string;
    date: Date;
    likes: string[];
    replies: Comment[];
    text: CommentText;
    $subscribed: boolean;
    replyingTo?: string;
}

export function readComment(obj: { [key: string]: any }): Comment | null {
    const text = readCommentText(obj.text);
    if (!text) {
        return null;
    }

    return {
        id: obj._id,
        userId: obj.user_id,
        profileId: obj.profile_id,
        date: new Date(obj.date.$date),
        likes: obj.likes.map((like: any) => like.user_id),
        replies: obj.replies ? obj.replies.map(readComment) : [],
        $subscribed: obj.$subscribed,
        replyingTo: obj.replying_to,
        text
    }
}

export interface GetProfileCommentsResponse {
    comments: Comment[];
    total: number;
}

export interface NewMfaSetupResponse {
    secret: string,
    qr: string,
    url: string,
    lease: string,
}
