import React from "react";
import styles from "./AboutPage.module.scss";
import {Redirect, Route, Switch} from 'react-router-dom';
import AboutWavy from "../AboutWavy/AboutWavy";
import AboutFaq from "../AboutFaq/AboutFaq";
import AboutContactContainer from "../../Containers/AboutContactContainer";
import AboutCodeOfConduct from "../AboutCodeOfConduct/AboutCodeOfConduct";


export default class AboutPage extends React.Component {
    render() {
        return (
            <div className={styles.Page}>
                <div className={styles.Banner}>
                    <div className={styles.PageTitle}>
                        <Switch>
                            <Route exact={true} path={"/about/wavy"}>About</Route>
                            <Route exact={true} path={"/about/faq"}>FAQ</Route>
                            <Route exact={true} path={"/about/contact"}>Contact Us</Route>
                            <Route exact={true} path={"/about/code-of-conduct"}>Code of Conduct</Route>
                        </Switch>
                    </div>
                </div>
                <div>
                    <Switch>
                        <Route exact={true} path={"/about/wavy"}>
                            <AboutWavy/>
                        </Route>
                        <Route exact={true} path={"/about/faq"}>
                            <AboutFaq/>
                        </Route>
                        <Route exact={true} path={"/about/contact"}>
                            <AboutContactContainer/>
                        </Route>
                        <Route exact={true} path={"/about/code-of-conduct"}>
                            <AboutCodeOfConduct/>
                        </Route>
                        <Route>
                            <Redirect to={"/about/wavy"}/>
                        </Route>
                    </Switch>
                </div>
            </div>
        )
    }
}
