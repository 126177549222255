import React from "react";
import styles from "./Flag.module.scss";

interface Props {
    code: string;
    className?: string;
}

export default class Flag extends React.Component<Props> {
    render() {
        return <div className={[styles.Flag, styles["bg-" + this.props.code], this.props.className || ""].join(" ")}/>
    }
}