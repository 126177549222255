import React from "react";

export default class FriendIcon extends React.Component {
    render() {
        return (
            <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={"IconSvg"}>
                <path className={"IconPath"}
                      d="M9.45 11C12.4327 11 14.85 8.53789 14.85 5.5C14.85 2.46211 12.4327 0 9.45 0C6.46734 0 4.05 2.46211 4.05 5.5C4.05 8.53789 6.46734 11 9.45 11ZM13.23 12.375H12.5255C11.5889 12.8133 10.5469 13.0625 9.45 13.0625C8.35313 13.0625 7.31531 12.8133 6.37453 12.375H5.67C2.53969 12.375 0 14.9617 0 18.15V19.9375C0 21.0762 0.907031 22 2.025 22H16.875C17.993 22 18.9 21.0762 18.9 19.9375V18.15C18.9 14.9617 16.3603 12.375 13.23 12.375ZM26.8566 6.85781L25.6838 5.65039C25.4897 5.44844 25.1733 5.44844 24.975 5.64609L20.5538 10.1148L18.6342 8.14688C18.4402 7.94492 18.1238 7.94492 17.9255 8.14258L16.74 9.34141C16.5417 9.53906 16.5417 9.86133 16.7358 10.0633L20.1825 13.5996C20.3766 13.8016 20.693 13.8016 20.8913 13.6039L26.8523 7.57969C27.0464 7.37773 27.0506 7.05547 26.8566 6.85781Z"/>
            </svg>
        )
    }
}
