import React from "react";
import {WavyTrackPlay} from "../../../API/Models";
import DefaultAvatar from "../../../Images/Avatar/Default.svg";
import {formatMinutes} from "../../../Times";
import ListenIcon from "./ListenIcon.svg";
import styles from "./ProfileListenHighlights.module.scss";
import {Link} from "react-router-dom";
import DefaultAlbum from "../../../Images/DefaultAlbum.svg";
import LoadingGif from "../../../Images/loading.gif";

interface ListenHighlightsProps {
    classes?: string;
    hasLoaded: boolean;

    totalSongs: number;
    lastSong: WavyTrackPlay | null;
    totalArtists: number;

    isOwnProfile: boolean;
    username: string;
    privateProfile: boolean;
    blockedByYou: boolean;
    blockedByThem: boolean;
    quarantined: boolean;

    totalFriends: number | null;
}

export default class ProfileListenHighlights extends React.Component<ListenHighlightsProps> {
    render() {
        return (
            <div className={this.getClasses()}>
                {this.renderContent()}
            </div>
        )
    }

    renderContent() {
        if (!this.props.hasLoaded) {
            return <img src={LoadingGif} alt={""} className={styles.Loading}/>
        }
        if (window.innerWidth > 768) {
            const placeholder = this.getPlaceholderMessage();
            if (placeholder) {
                return (
                    <div className={styles.NoHistory}>
                        {placeholder}
                    </div>
                )
            }
        }
        return (
            <div className={styles.WithHistory}>
                {
                    window.innerWidth <= 768 ? "" :
                        <div className={styles.LastSongColumn}>
                            <img className={styles.AlbumCover} src={this.getAlbumImage()} alt={"Album"}/>
                            <div className={styles.LastSongDescription}>
                                <div className={styles.LastSongName} title={this.getLastSongName(true)}>
                                    {this.getLastSongName(false)}
                                </div>
                                <div className={styles.LastSongArtist}>
                                    {this.props.lastSong?.track.artists[0].name}
                                </div>
                                {this.renderTime()}
                            </div>
                        </div>
                }
                <Link className={styles.SongCount} to={"/user/" + this.props.username + "/history/recent"}>
                    <div className={styles.CountTitle}>Listens</div>
                    <div className={styles.CountNumber}>
                        {this.props.totalSongs.toLocaleString()}
                        <div className={styles.ListenIcon}
                             style={{backgroundImage: "url(" + ListenIcon + ")"}}/>
                    </div>
                </Link>
                <Link className={styles.SongCount} to={"/user/" + this.props.username + "/history/artists"}>
                    <div className={styles.CountTitle}>Artists</div>
                    <div className={styles.CountNumber}>
                        {this.props.totalArtists.toLocaleString()}
                        <div className={styles.ListenIcon}
                             style={{backgroundImage: "url(" + ListenIcon + ")"}}/>
                    </div>
                </Link>
                <Link className={[styles.SongCount, styles.FriendCount].join(" ")}
                      to={"/user/" + this.props.username + "/friends"}>
                    <div className={styles.CountTitle}>Friends</div>
                    <div className={styles.CountNumber}>
                        {this.props.totalFriends?.toLocaleString() ?? ""}
                    </div>
                </Link>
            </div>
        )
    }

    renderTime() {
        if (this.props.lastSong?.play_id === "live") {
            return (
                <div className={[styles.LastSongTime, styles.Live].join(" ")}>
                    Listening now <div className={styles.LivePulse}/>
                </div>
            )
        } else {
            return (
                <div className={styles.LastSongTime}>
                    {formatMinutes(this.getLastSongTime())}
                </div>
            )
        }
    }

    getClasses(): string {
        const classes = [styles.UserListenHighlights];
        if (this.props.classes) {
            classes.push(this.props.classes);
        }
        return classes.join(" ");
    }

    getAlbumImage(): string {
        if (this.props.lastSong) {
            return this.props.lastSong.track.album.images
                .sort((a, b) => b.width - a.width)[0]?.url ?? DefaultAlbum;
        } else {
            return DefaultAvatar;
        }
    }

    getLastSongTime(): number {
        if (this.props.lastSong) {
            return Math.floor((Date.now() / 1000 - this.props.lastSong.date.$date / 1000) / 60);
        } else {
            return 0;
        }
    }

    getLastSongName(full: boolean): string {
        if (!this.props.lastSong) {
            return "";
        }
        const name = this.props.lastSong.track.name;
        if (!full && name.length > 25) {
            return name.substr(0, 22) + "...";
        } else {
            return name;
        }
    }

    getPlaceholderMessage(): string | undefined {
        if (this.props.blockedByThem) {
            return "This user has blocked you.";
        } else if (this.props.blockedByYou) {
            return "This user is blocked.";
        } else if (this.props.quarantined) {
            return "This profile has been flagged for abusive/spam behavior."
        } else if (this.props.privateProfile) {
            return "This profile is private."
        } else if (!this.props.lastSong) {
            return this.props.isOwnProfile ? "You have not listened to anything yet." : this.props.username + " has not listened to anything yet."
        } else {
            return undefined;
        }
    }
}
