import React from "react";
import styles from "./PopoverModal.module.scss";

interface Props {
    focused?: boolean;
    onFocusLost: () => void;
    className?: string;
    children?: React.ReactNode;
    closed?: boolean;
}

interface State {
    closing: boolean;
}

export default class PopoverModal extends React.Component<Props, State> {
    private lastTouchY?: number;

    constructor(props: Readonly<Props>) {
        super(props);

        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);

        this.state = {
            closing: false
        }
    }

    render() {
        return (
            <>
                {this.renderFocus()}
                {this.renderPopover()}
            </>
        )
    }

    renderPopover() {
        return (
            <div className={this.getPopoverClasses()} draggable={true} onTouchMove={this.onTouchMove}
                 onClick={e => e.stopPropagation()}
                 onTouchEnd={this.onTouchEnd}>
                <div className={styles.Handle}/>
                <div className={styles.Content}>
                    {this.props.children}
                </div>
            </div>
        )
    }

    componentDidMount() {
        const body = document.getElementsByTagName("body").item(0);
        if (body && this.props.focused) {
            body.style.overflow = "hidden";
        }
    }

    componentWillUnmount() {
        const body = document.getElementsByTagName("body").item(0);
        if (body && this.props.focused) {
            body.style.overflow = "initial";
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.closed && !prevProps.closed) {
            this.close()
        } else if (!this.props.closed && prevProps.closed) {
            this.reOpen();
        }
    }

    renderFocus() {
        if (this.props.focused) {
            return (
                <div className={this.getFocusClasses()} onClick={(e) => {
                    e.stopPropagation();
                    this.close();
                    setTimeout(() => this.props.onFocusLost?.(), 250);
                }}/>
            );
        } else {
            return "";
        }
    }

    getPopoverClasses() {
        const classes = [styles.Popover];
        if (this.state.closing) {
            classes.push(styles.Closing);
        }
        if (this.props.className) {
            classes.push(this.props.className);
        }
        return classes.join(" ");
    }

    getFocusClasses() {
        const classes = [styles.Focus];
        if (this.state.closing) {
            classes.push(styles.Closing);
        }
        return classes.join(" ");
    }

    close() {
        if (!this.state.closing) {
            this.setState({closing: true});
        }
    }

    onTouchMove(e: React.TouchEvent<HTMLDivElement>) {
        const currentTouch = e.nativeEvent.touches.item(0)?.clientY;
        if (this.lastTouchY && currentTouch && currentTouch > this.lastTouchY) {
            this.close();
            setTimeout(() => this.props.onFocusLost?.(), 250);
            return;
        }
        this.lastTouchY = currentTouch;
    }

    onTouchEnd() {
        this.lastTouchY = undefined;
    }

    reOpen() {
        this.setState({closing: false});
    }
}
