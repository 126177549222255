import React from "react";
import {ForeignUser, UserState} from "../../../Redux/Types";
import {getProfileByUsername} from "../../../API/Calls";
import {Error404} from "../Error404/Error404";
import styles from "./UserSocial.module.scss";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import Avatar from "../Avatar/Avatar";
import UserSocialNavigation from "../UserSocialNavigation/UserSocialNavigation";
import UserFriends from "../UserFriends/UserFriends";
import UserAddFriend from "../UserAddFriend/UserAddFriend";
import UserPendingRequests from "../UserPendingRequests/UserPendingRequests";
import UserBlockedList from "../UserBlockedList/UserBlockedList";
import {titleManager} from "../../../Title";

interface Props {
    /**
     * The user that is currently logged-in
     */
    authUser: UserState | null,
    /**
     * Whether the current logged-in user data has loaded
     */
    authProfileReady: boolean,
    /**
     * URL parameters
     */
    match: { params: { username: string } },
    toggleGlobalLoading: (loading: boolean) => void,

    incomingRequestsCount?: number;
    setIncomingRequests: (count: number) => void;
}

interface State {
    /**
     * Whether the profile shown here has loaded
     */
    profileLoaded: boolean,
    /**
     * The public user data of the profile.
     */
    profile: ForeignUser | null
}

export default class UserHistory extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            profileLoaded: false,
            profile: null
        }
    }

    isReady(): boolean {
        if (!this.state.profileLoaded) {
            return false;
        }
        if (this.props.authUser && !this.props.authProfileReady) {
            return false;
        }
        return true;
    }

    render() {
        return !this.isReady() ? "" :
            this.state.profile === null ?
                <Error404/>
                :
                <div className={"Container " + styles.SocialPage}>
                    <div className={styles.SummaryColumn}>
                        <div className={styles.UserInfo}>
                            <Link to={"/user/" + this.state.profile.username}>
                                <Avatar user={this.state.profile}
                                        size={"50px"}/>
                            </Link>
                            <Link to={"/user/" + this.state.profile.username}
                                  className={styles.Username}>{this.state.profile.username}</Link>
                        </div>
                        <UserSocialNavigation self={this.isOwnProfile()}
                                              incomingRequestsCount={this.props.incomingRequestsCount}
                                              setIncomingRequests={this.props.setIncomingRequests}
                                              username={this.state.profile.username}/>
                    </div>
                    <div className={styles.ListColumn}>
                        <Switch>
                            <Route exact={true} path={"/user/" + this.state.profile.username + "/friends"}>
                                <UserFriends profile={this.state.profile} self={this.isOwnProfile()}/>
                            </Route>
                            <Route exact={true} path={"/user/" + this.state.profile.username + "/friends/add"}>
                                {
                                    this.props.authUser && this.props.authProfileReady && this.isOwnProfile() ?
                                        <UserAddFriend profile={this.state.profile}/> :
                                        <Redirect to={"/user/" + this.state.profile.username + "/friends"}/>
                                }
                            </Route>
                            <Route exact={true} path={"/user/" + this.state.profile.username + "/friends/pending"}>
                                {
                                    this.isOwnProfile() ?
                                        <UserPendingRequests incomingRequestsCount={this.props.incomingRequestsCount}
                                                             setIncomingRequests={this.props.setIncomingRequests}/> :
                                        <Redirect to={"/user/" + this.state.profile.username + "/friends"}/>
                                }
                            </Route>
                            <Route exact={true} path={"/user/" + this.state.profile.username + "/friends/blocked"}>
                                {
                                    this.isOwnProfile() ?
                                        <UserBlockedList/> :
                                        <Redirect to={"/user/" + this.state.profile.username + "/friends"}/>
                                }
                            </Route>
                        </Switch>
                    </div>
                </div>
    }

    loadProfileFromRoute() {
        this.props.toggleGlobalLoading(true);
        getProfileByUsername(this.props.match.params.username)
            .then(profile => {
                this.props.toggleGlobalLoading(false);
                this.setState({
                    profile,
                    profileLoaded: true
                });
            })
            .catch(console.error);
    }

    isOwnProfile(): boolean {
        return this.props.authUser !== null && this.state.profile !== null
            && this.props.authUser.username === this.state.profile.username;
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set();
        this.loadProfileFromRoute();
    }

    unloadProfile() {
        this.setState({profileLoaded: false, profile: null});
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.match.params.username !== prevProps.match.params.username) {
            this.unloadProfile();
            this.loadProfileFromRoute();
        }
    }
}
