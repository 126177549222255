import {ForeignUser} from "../Redux/Types";
import {Notification, NotificationType} from "./Models";
import {getProfilesById} from "./Calls";


export class NotificationUserCache {
    private cache: Map<string, ForeignUser> = new Map<string, ForeignUser>();

    async withUsers(notifications: Notification<any>[]): Promise<Notification<any>[]> {
        const userIds = new Set<string>();
        const results: Notification<any>[] = [];

        notifications.forEach(notif => {
            switch (notif.type) {
                case NotificationType.FriendRequest:
                case NotificationType.FriendRequestAccepted:
                case NotificationType.ProfileCommentReceived:
                case NotificationType.ProfileCommentReplied:
                    const sourceId = notif.data?.source_id as string | undefined;
                    if (sourceId) {
                        userIds.add(sourceId);
                    }
                    break;
            }
        });

        const unknownUsers = Array.from(userIds).filter(u => !this.cache.has(u));

        if (unknownUsers.length > 0) {
            try {
                (await getProfilesById(...unknownUsers)).forEach(profile => {
                    this.cache.set(profile.id, profile);
                });
            } catch (e) {
                console.error(e);
            }
        }

        notifications.forEach(notif => {
            switch (notif.type) {
                case NotificationType.FriendRequest:
                case NotificationType.FriendRequestAccepted:
                case NotificationType.ProfileCommentReceived:
                case NotificationType.ProfileCommentReplied:
                    const sourceId = notif.data?.source_id as string | undefined;
                    if (sourceId) {
                        const profile = this.cache.get(sourceId);
                        if (profile) {
                            notif.data.$profile = profile;
                            results.push(notif);
                        }
                    }
                    break;
            }
        });

        return results;
    }

    getUser(id: string): ForeignUser | undefined {
        return this.cache.get(id);
    }
}

export const notificationUserCache = new NotificationUserCache();

export function mergeNotifications(source: Notification<any>[], add: Notification<any>[]): Notification<any>[] {
    const sourceIds = new Set<string>(source.map(notif => notif.id));
    const addIds = new Set<string>(add.map(notif => notif.id));
    const commonIds = new Set<string>(Array.from(sourceIds).filter(id => sourceIds.has(id) && addIds.has(id)));

    return [
        ...(source.filter(notif => !commonIds.has(notif.id))),
        ...(add.filter(notif => commonIds.has(notif.id) || !sourceIds.has(notif.id)))
    ].sort((a, b) => b.date.getTime() - a.date.getTime());
}
