import styles from "./ProfileDateRange.module.scss";
import React from "react";
import Card from "../Card/Card";
import {daysAgo} from "../../../API/UniversalTime";
import {ProfileDateRangeDefaultValue} from "../../../API/Models";

export enum Ranges {
    AllTime = "All Time",
    Days7 = "7 days",
    Days30 = "30 days",
    Days90 = "90 days",
    Days365 = "365 days",
}

export function convertDateRange(range: Ranges): number {
    switch (range) {
        case Ranges.Days7:
            return daysAgo(7);
        case Ranges.Days30:
            return daysAgo(30);
        case Ranges.Days90:
            return daysAgo(90);
        case Ranges.Days365:
            return daysAgo(365);
        default:
            return 0;
    }
}

export function rangeFromDefaultValue(value?: ProfileDateRangeDefaultValue): Ranges {
    if (!value) return Ranges.AllTime;
    switch (value) {
        case ProfileDateRangeDefaultValue.Days7:
            return Ranges.Days7;
        case ProfileDateRangeDefaultValue.Days30:
            return Ranges.Days30;
        case ProfileDateRangeDefaultValue.Days90:
            return Ranges.Days90;
        case ProfileDateRangeDefaultValue.Days365:
            return Ranges.Days365;
        default:
            return Ranges.AllTime;
    }
}

export function rangeToDefaultValue(range: Ranges): ProfileDateRangeDefaultValue {
    switch (range) {
        case Ranges.Days7:
            return ProfileDateRangeDefaultValue.Days7;
        case Ranges.Days30:
            return ProfileDateRangeDefaultValue.Days30;
        case Ranges.Days90:
            return ProfileDateRangeDefaultValue.Days90;
        case Ranges.Days365:
            return ProfileDateRangeDefaultValue.Days365;
        default:
            return ProfileDateRangeDefaultValue.AllTime;
    }
}

interface Props {
    selected: Ranges;
    onChange?: (newRange: Ranges) => void;
}

interface State {
    opened: boolean;
}

export default class ProfileDateRange extends React.Component<Props, State> {
    private menuContainer: React.RefObject<HTMLDivElement> = React.createRef();
    private selectedContainer: React.RefObject<HTMLSpanElement> = React.createRef();

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            opened: false
        };

        this.closeMenu = this.closeMenu.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    closeMenu(): void {
        this.setState({opened: false});
    }

    openMenu(): void {
        this.setState({opened: true});
    }

    toggleMenu(): void {
        this.setState({opened: !this.state.opened});
    }

    handleChange(range: Ranges): void {
        if (this.props.onChange) {
            this.props.onChange(range);
        }
    }

    render() {
        return (
            <span className={styles.CurrentValue + " " + (this.state.opened ? styles.Opened : "")}
                  onClick={this.toggleMenu} ref={this.selectedContainer}>
                {this.props.selected}
                {this.renderArrow()}
                {
                    !this.state.opened ? "" :
                        <div className={[styles.Menu, Card.getCardClasses({})].join(" ")} ref={this.menuContainer}>
                            <div className={this.getItemClasses(Ranges.Days7)}
                                 onClick={() => this.handleChange(Ranges.Days7)}>{Ranges.Days7}
                            </div>
                            <div className={this.getItemClasses(Ranges.Days30)}
                                 onClick={() => this.handleChange(Ranges.Days30)}>{Ranges.Days30}
                            </div>
                            <div className={this.getItemClasses(Ranges.Days90)}
                                 onClick={() => this.handleChange(Ranges.Days90)}>{Ranges.Days90}
                            </div>
                            <div className={this.getItemClasses(Ranges.Days365)}
                                 onClick={() => this.handleChange(Ranges.Days365)}>{Ranges.Days365}
                            </div>
                            <div className={this.getItemClasses(Ranges.AllTime)}
                                 onClick={() => this.handleChange(Ranges.AllTime)}>{Ranges.AllTime}
                            </div>
                        </div>
                }
            </span>
        )
    }

    componentDidMount(): void {
        window.addEventListener("mousedown", this.handlePageClick);
    }

    componentWillUnmount(): void {
        window.addEventListener("mousedown", this.handlePageClick);
    }

    handlePageClick(e: MouseEvent) {
        if (e.type === "mousedown" && this.menuContainer.current != null && !this.menuContainer.current.contains(e.target as Node)
            && this.selectedContainer.current != null && !this.selectedContainer.current.contains(e.target as Node)) {
            this.closeMenu();
        }
    }

    getArrowClasses(): string {
        const classes = [styles.Arrow];
        if (this.state.opened) {
            classes.push(styles.Opened);
        }
        return classes.join(" ");
    }

    getItemClasses(range: Ranges): string {
        const classes = [styles.Item];
        if (this.props.selected === range) {
            classes.push(styles.Selected);
        }
        return classes.join(" ");
    }

    renderArrow() {
        return (
            <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={this.getArrowClasses()}>
                <path
                    d="M4.03851 4.54583C3.77324 4.91217 3.22743 4.91217 2.96217 4.54583L0.655206 1.35986C0.337089 0.920536 0.650968 0.305729 1.19338 0.305729L5.8073 0.305729C6.34971 0.305729 6.66358 0.920536 6.34547 1.35986L4.03851 4.54583Z"
                    className={styles.ArrowPath}/>
            </svg>
        )
    }
}
