import React from "react";
import styles from "./NotificationBell.module.scss";
import ReconnectingWebSocket from "reconnecting-websocket";
import {getNotifications, getNotificationSocket} from "../../../API/Calls";
import {GetNotificationListResponse, readNotification} from "../../../API/Models";
import NotificationsMenuContainer from "../../Containers/NotificationsMenuContainer";
import {NotificationsState} from "../../../Redux/Types";
import {mergeNotifications, notificationUserCache} from "../../../API/NotificationUserCache";

interface Props {
    isLoggedIn: boolean;
    notifications: NotificationsState;
    menuOpen: boolean;

    setNotifications: (state: Partial<NotificationsState>) => void;
    toggleNotificationsMenu: (open: boolean) => void;
}

interface State {

}

export default class NotificationBell extends React.Component<Props, State> {
    private websocket?: ReconnectingWebSocket;
    private timeout?: any;
    private toggleRef: React.RefObject<HTMLElement> = React.createRef();

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {};
        this.openMenu = this.openMenu.bind(this);
    }

    render(): React.ReactNode {
        if (!this.props.isLoggedIn) {
            return "";
        }
        return (
            <div className={styles.Container}>
                <div className={this.getBellClasses()}
                     ref={this.toggleRef as React.RefObject<HTMLDivElement>}
                     onKeyDown={e => this.onKeyDown(e)}
                     tabIndex={0}
                     onClick={this.openMenu}>
                    {this.bellIcon()}
                    {this.renderChip()}
                </div>
                <NotificationsMenuContainer toggleRef={this.toggleRef}/>
            </div>
        )
    }

    openMenu() {
        this.props.toggleNotificationsMenu(!this.props.menuOpen);
    }

    renderChip(): React.ReactNode {
        if (this.props.notifications.unread < 1) {
            return "";
        }
        return (
            <div className={styles.Chip}>{this.props.notifications.unread}</div>
        )
    }

    openWebsocket() {
        this.closeWebsocket();
        if (!this.props.isLoggedIn) {
            return;
        }
        this.websocket = new ReconnectingWebSocket(getNotificationSocket());
        this.websocket.onerror = (event) => {
            if (event.error !== undefined && !(!event.error.name && !event.error.message && !event.message)) {
                console.error("WebSocket error:", event.error?.name, event.error?.message, event.message);
            }
        }
        this.websocket.onmessage = (msg) => {
            try {
                const data = JSON.parse(msg.data) as Partial<GetNotificationListResponse>;
                if (data.items) {
                    data.items = data.items.map(readNotification);
                }
                notificationUserCache.withUsers(data?.items ?? [])
                    .then(notifs => {
                        const merged = mergeNotifications(this.props.notifications.notifications, notifs);
                        const state: Partial<NotificationsState> = {
                            notifications: merged,
                            unread: data.unread,
                            loading: false
                        }
                        this.props.setNotifications(state);
                    });
            } catch (e) {
                console.error("Malformed WebSocket data:", msg.data);
                return;
            }
        }
    }

    loadNotifications() {
        if (!this.props.isLoggedIn) {
            return;
        }
        getNotifications(0)
            .then(resp => {
                notificationUserCache.withUsers(resp.items)
                    .then(notifs => {
                        const state: Partial<NotificationsState> = {
                            notifications: notifs,
                            unread: resp.unread,
                            hasMore: false
                        }
                        this.props.setNotifications(state);
                    })
            })
            .catch(console.error);
    }

    startPinging() {
        if (!this.timeout) {
            this.timeout = setInterval(() => {
                if (this.websocket) {
                    this.websocket.send("ping");
                }
            }, 10000);
        }
    }

    closeWebsocket() {
        if (this.websocket) {
            this.websocket.close();
            this.websocket = undefined;
        }
    }

    componentDidMount(): void {
        this.loadNotifications();
        this.openWebsocket();
        this.startPinging();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.isLoggedIn !== prevProps.isLoggedIn) {
            this.loadNotifications();
            this.openWebsocket();
            this.startPinging();
        }
    }

    componentWillUnmount(): void {
        this.closeWebsocket();
        if (this.timeout) {
            clearInterval(this.timeout);
        }
    }

    onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === 'Enter') {
            if (this.props.menuOpen) {
                this.props.toggleNotificationsMenu(false);
            } else {
                this.openMenu();
            }
        }
    }

    getBellClasses(): string {
        const classes = [styles.Bell];
        if (this.props.menuOpen) {
            classes.push(styles.Open);
        }
        return classes.join(" ");
    }

    bellIcon() {
        return (
            <svg width="20" height="20" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11 26C12.7345 26 14.1414 24.5451 14.1414 22.75H7.85863C7.85863 24.5451 9.26554 26 11 26ZM21.5772 18.3975C20.6284 17.3433 18.8532 15.7574 18.8532 10.5625C18.8532 6.6168 16.1778 3.4582 12.5704 2.68328V1.625C12.5704 0.727695 11.8672 0 11 0C10.1328 0 9.42956 0.727695 9.42956 1.625V2.68328C5.82216 3.4582 3.14681 6.6168 3.14681 10.5625C3.14681 15.7574 1.37159 17.3433 0.422845 18.3975C0.128203 18.7251 -0.0024214 19.1166 3.3952e-05 19.5C0.00543572 20.3328 0.637443 21.125 1.57637 21.125H20.4236C21.3626 21.125 21.9951 20.3328 22 19.5C22.0024 19.1166 21.8718 18.7246 21.5772 18.3975Z"
                    className={styles.IconPath}/>
            </svg>
        )
    }
}
