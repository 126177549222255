import React from "react";
import ReactModal, {Props as ModalProps} from "react-modal";
import styles from "./CardModal.module.scss";
import Card, {CardElevation} from "../Card/Card";

interface CardModalProps {
    title: string | null;
    titleClassName?: string;
    contentClassName?: string;
    centered?: boolean;
}

export default class CardModal extends React.Component<CardModalProps & ModalProps> {
    render() {
        if (this.props.isOpen) {
            return (
                <ReactModal {...this.props as ModalProps} overlayClassName={this.getOverlayClasses()}
                            className={this.getClasses()}
                            ariaHideApp={false}>
                    <div className={this.getTitleClasses()}>{this.props.title || ""}</div>
                    <div className={this.getContentClasses()}>{this.props.children}</div>
                </ReactModal>
            )
        } else {
            return "";
        }
    }

    getTitleClasses(): string {
        const classes = [styles.Title];
        if (this.props.centered) {
            classes.push(styles.TitleCentered);
        }
        if (this.props.titleClassName) {
            classes.push(this.props.titleClassName);
        }
        return classes.join(" ");
    }

    getOverlayClasses(): string {
        return [styles.CardModal, styles.Overlay]
            .join(" ");
    }

    getClasses(): string {
        return [Card.getCardClasses({elevation: CardElevation.Modal}), styles.InnerCard, this.props.className || ""].join(" ");
    }

    getContentClasses(): string {
        return [styles.Content, this.props.contentClassName || ""].join(" ");
    }

    componentDidUpdate(prevProps: Readonly<CardModalProps & ReactModal.Props>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.isOpen !== this.props.isOpen) {
            if (this.props.isOpen) {
                // Disable scrolling of the rest of the page
                const body = document.getElementsByTagName("body").item(0);
                if (body) {
                    body.style.overflow = "hidden";
                }
            } else {
                // Re-enable scrolling
                const body = document.getElementsByTagName("body").item(0);
                if (body) {
                    body.style.overflow = "initial";
                }
            }
        }
    }

    componentWillUnmount() {
        // Re-enable scrolling
        const body = document.getElementsByTagName("body").item(0);
        if (body) {
            body.style.overflow = "initial";
        }
    }
}
