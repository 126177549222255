import React from "react";
import {editPassword} from "../../../../API/Calls";
import {UserState} from "../../../../Redux/Types";
import Button, {ButtonColor} from "../../Button/Button";

interface PasswordState {
    oldPassword: string;
    password: string;
    confirmPassword: string;

    loading: boolean,
    error: Error | null,
}

interface PasswordProps {
    user: UserState | null
    closeFunction: () => void;
}

export default class Password extends React.Component<PasswordProps, PasswordState> {
    constructor(props: Readonly<PasswordProps>) {
        super(props);

        this.state = {
            oldPassword: "",
            password: "",
            confirmPassword: "",

            loading: false,
            error: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {
                    this.state.error ? <div className={"AuthError"}>{this.state.error.toString()}</div> : ""
                }
                <div className={"NewPasswordForm"}>
                    <div className={"FormField"}>
                        <label htmlFor={"OldPassword"}>
                            Old Password
                        </label>
                        <input required={true} name={"OldPassword"} type={"password"}
                               value={this.state.oldPassword}
                               autoComplete={"current-password"}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={"FormField"}>
                        <label htmlFor={"Password"}>
                            New Password (8+ characters)
                        </label>
                        <input required={true} name={"Password"} type={"password"} value={this.state.password}
                               autoComplete={"new-password"}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={"FormField"}>
                        <label htmlFor={"ConfirmPassword"}>
                            Confirm Password
                        </label>
                        <input required={true} name={"ConfirmPassword"} type={"password"}
                               value={this.state.confirmPassword}
                               autoComplete={"new-password"}
                               onChange={this.handleChange}/>
                    </div>
                    <div className="FormField SubmitRegion">
                        <Button color={ButtonColor.Regular}
                                onClick={() => this.props.closeFunction()}>Cancel</Button>
                        <Button form={true} color={ButtonColor.Primary} disabled={this.state.loading}>Save</Button>
                    </div>
                </div>
            </form>
        )
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        if (target.name === "Password") {
            this.setState({password: target.value});
        } else if (target.name === "OldPassword") {
            this.setState({oldPassword: target.value});
        } else if (target.name === "ConfirmPassword") {
            this.setState({confirmPassword: target.value});
        }
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        this.setState({loading: true, error: null});
        if (this.state.password !== this.state.confirmPassword) {
            this.setState({loading: false, error: new Error("Password confirmation does not match.")});
            return;
        }

        editPassword(this.state.oldPassword, this.state.password)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    return resp.json().then(json => {
                        throw Error(json.description);
                    })
                }
            })
            .then(resp => {
                // TODO: Show some message that password was updated
                this.props.closeFunction();
            })
            .catch(e => {
                this.setState({loading: false, error: e});
                console.error(e);
            })
    }
}
