import {AppState, ForeignUser, UserState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ProfileComments from "../Presentation/ProfileComments/ProfileComments";
import {Friendship, ProfileCommentsPrivacy} from "../../API/Models";
import {openCommentReportForm} from "../../Redux/Actions";

function canWriteOnProfile(user: UserState | null, profile: ForeignUser, friendship: Friendship | null): boolean {
    if (!user) return false;
    if (!user.emailVerified) return false;
    if (profile.$access.profileComments === ProfileCommentsPrivacy.Me && user.id !== profile.id) return false;
    if (profile.$access.profileComments === ProfileCommentsPrivacy.Friends && !profile.$access.friends && user.id !== profile.id) return false;
    return true;
}

function mapStateToProps(state: AppState) {
    return {
        authUser: state.user,
        profile: state.profilePage.profile as ForeignUser,
        canWrite: canWriteOnProfile(state.user, state.profilePage.profile as ForeignUser, state.profilePage.friendship)
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        openCommentReportForm
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileComments);
