import * as Sentry from '@sentry/browser';
import * as Types from "./Types";
import {CountryById} from "../Countries";
import {EventEmitter} from "events";
import {ProfileCommentsPrivacy} from "../API/Models";

const initialState: Types.AppState = {
    profileReady: false,
    user: null,
    authForms: {error: null},
    menus: {user: false, search: false, notifications: false},
    accountSettings: {
        emailModal: false,
        passwordModal: false,
        deleteModal: false,
        usernameModal: false,
        mfaSetupModal: false,
        mfaDisableModal: false,
        mfaRecoveryModal: false,
    },
    settingsLoading: false,
    isGlobalLoading: false,
    incomingRequestsCount: undefined,

    trackingDelayedDismissed: getTrackingDelayedDismissed(),

    notifications: {
        hasMore: false,
        notifications: [],
        unread: 0,
        loading: false
    },

    focusedComment: undefined,
    commentReportOpen: false,

    profilePage: {
        profile: null,
        editing: false,
        profilePictureFormOpen: false,
        recentListens: null,
        recentListensLoading: true,
        reload$: new EventEmitter(),
        totalFriends: null,
        friendship: null,
        reportFormOpen: false,
        unfriendConfirmOpen: false,
        blockConfirmOpen: false
    }
};

function getTrackingDelayedDismissed(): number | undefined {
    try {
        const val = parseInt(window.localStorage.getItem("wavy.tracking-delayed-dismiss") || "0");
        if (isNaN(val)) return undefined;
        return val;
    } catch (e) {
        return undefined;
    }
}

export function appReducer(state = initialState, action: Types.ActionTypes): Types.AppState {
    switch (action.type) {
        case Types.LOAD_USER_DATA:
            if (action.data === null) {
                return Object.assign({}, state, {profileReady: true, user: null});
            }
            Sentry.setUser({id: action.data.user_id, username: action.data.username});
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    username: action.data.username,
                    email: action.data.email,
                    id: action.data.user_id,
                    spotifyId: action.data.spotify_id,
                    spotifyDisplayName: action.data.spotify_display_name,
                    emailVerified: action.data.email_verified,
                    joinTime: new Date(action.data.join_time.$date),
                    discordId: action.data.discord_id || null,
                    discordDisplayName: action.data.discord_display_name || null,

                    countryCode: action.data.country_code,
                    countryName: CountryById.get(action.data.country_code) || null,
                    bio: action.data.bio,
                    twitter: action.data.twitter || null,
                    instagram: action.data.instagram || null,

                    admin: action.data.is_admin,

                    dateRangeDefaults: action.data.date_range_defaults,

                    privacySettings: {
                        privateProfile: action.data.privacy_settings?.private_profile || false,
                        publicSpotify: action.data.privacy_settings?.public_spotify || false,
                        publicDiscord: action.data.privacy_settings?.public_discord || false,
                        profileComments: action.data.privacy_settings?.profile_comments || ProfileCommentsPrivacy.Friends,
                    },

                    moderation: {
                        disallowAvatar: action.data.moderation?.disallow_avatar || false,
                        disallowComments: action.data.moderation?.disallow_comments || false,
                    },

                    $access: {
                        view: true,
                        addFriend: true,
                        blocked: false,
                        friends: true
                    },

                    lastUsernameChange: action.data.last_username_change ? new Date(action.data.last_username_change.$date) : null,

                    $avatar_lease: action.data.$avatar_lease,
                    $mfa_enabled: action.data.$mfa_enabled,
                },
                profileReady: true,
            });
        case Types.SET_USER_EMAIL:
            return Object.assign({}, state, {
                user: {...state.user, email: action.email, emailVerified: false},
            });
        case Types.SET_USER_PRIVACY:
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    privacySettings: Object.assign(state.user?.privacySettings ?? {}, action.privacy)
                },
            });
        case Types.SET_AUTH_FORMS_ERROR:
            return Object.assign({}, state, {
                authForms: {...state.authForms, error: action.error},
            });
        case Types.OPEN_USER_MENU:
            return Object.assign({}, state, {
                menus: {...state.menus, user: true}
            });
        case Types.CLOSE_USER_MENU:
            return Object.assign({}, state, {
                menus: {...state.menus, user: false}
            });
        case Types.OPEN_SEARCH_MENU:
            return Object.assign({}, state, {
                menus: {...state.menus, search: true}
            });
        case Types.CLOSE_SEARCH_MENU:
            return Object.assign({}, state, {
                menus: {...state.menus, search: false}
            });
        case Types.LOG_OUT:
            window.localStorage.removeItem("wavy.jwt");
            return state;
        case Types.TOGGLE_EDIT_PASSWORD_MODAL:
            return Object.assign({}, state, {
                accountSettings: {...state.accountSettings, passwordModal: action.visible}
            });
        case Types.TOGGLE_EDIT_EMAIL_MODAL:
            return Object.assign({}, state, {
                accountSettings: {...state.accountSettings, emailModal: action.visible}
            });
        case Types.TOGGLE_EDIT_USERNAME_MODAL:
            return Object.assign({}, state, {
                accountSettings: {...state.accountSettings, usernameModal: action.visible}
            });
        case Types.TOGGLE_DELETE_MODAL:
            return Object.assign({}, state, {
                accountSettings: {...state.accountSettings, deleteModal: action.visible}
            });
        case Types.TOGGLE_MFA_SETUP_MODAL:
            return Object.assign({}, state, {
                accountSettings: {...state.accountSettings, mfaSetupModal: action.visible}
            });
        case Types.TOGGLE_MFA_DISABLE_MODAL:
            return Object.assign({}, state, {
                accountSettings: {...state.accountSettings, mfaDisableModal: action.visible}
            });
        case Types.TOGGLE_MFA_RECOVERY_MODAL:
            return Object.assign({}, state, {
                accountSettings: {...state.accountSettings, mfaRecoveryModal: action.visible}
            });
        case Types.TOGGLE_SETTINGS_LOADING:
            return Object.assign({}, state, {
                settingsLoading: action.loading
            });
        case Types.TOGGLE_GLOBAL_LOADING:
            return Object.assign({}, state, {
                isGlobalLoading: action.loading
            });
        case Types.SET_INCOMING_REQUESTS:
            return Object.assign({}, state, {
                incomingRequestsCount: action.count
            });
        case Types.LOAD_PROFILE:
            const sameUser = action.profile && state.profilePage?.profile?.id === action.profile?.id;
            state.profilePage.reload$.removeAllListeners();
            state.profilePage.reload$.setMaxListeners(10);
            const newState = Object.assign({}, state, {
                profilePage: {
                    ...state.profilePage,
                    profile: action.profile,
                    friendship: sameUser ? state.profilePage.friendship : null,
                    reportFormOpen: false,
                    unfriendConfirmOpen: false,
                    blockConfirmOpen: false
                }
            });
            if (action.profile && action.profile.id === state.user?.id) {
                // Also reload the global user state
                Object.assign(newState, {user: {...state.user, ...action.profile}});
            }
            return newState;
        case Types.TOGGLE_PROFILE_EDITING:
            return Object.assign({}, state, {
                profilePage: {...state.profilePage, editing: action.editing}
            })
        case Types.TOGGLE_PROFILE_PICTURE_FORM:
            return Object.assign({}, state, {
                profilePage: {...state.profilePage, profilePictureFormOpen: action.open}
            })
        case Types.LOAD_PROFILE_RECENT_LISTENS:
            return Object.assign({}, state, {
                profilePage: {
                    ...state.profilePage,
                    recentListens: action.listens,
                    recentListensLoading: !action.listens
                }
            })
        case Types.SET_PROFILE_RECENT_LISTENS_LOADING:
            return Object.assign({}, state, {
                profilePage: {...state.profilePage, recentListensLoading: true}
            })
        case Types.LOAD_PROFILE_FRIEND_COUNT:
            return Object.assign({}, state, {
                profilePage: {...state.profilePage, totalFriends: action.count}
            })
        case Types.UPDATE_PROFILE_FRIENDSHIP:
            return Object.assign({}, state, {
                profilePage: {...state.profilePage, friendship: action.friendship}
            })
        case Types.TOGGLE_PROFILE_REPORT_FORM:
            return Object.assign({}, state, {
                profilePage: {...state.profilePage, reportFormOpen: action.open}
            })
        case Types.TOGGLE_PROFILE_UNFRIEND_CONFIRM:
            return Object.assign({}, state, {
                profilePage: {...state.profilePage, unfriendConfirmOpen: action.open}
            })
        case Types.TOGGLE_PROFILE_BLOCK_CONFIRM:
            return Object.assign({}, state, {
                profilePage: {...state.profilePage, blockConfirmOpen: action.open}
            })
        case Types.SET_NOTIFICATIONS:
            return Object.assign({}, state, {
                notifications: {...state.notifications, ...action.notifications}
            })
        case Types.TOGGLE_NOTIFICATIONS_MENU:
            return Object.assign({}, state, {
                menus: {...state.menus, notifications: action.open}
            });
        case Types.OPEN_COMMENT_REPORT_FORM:
            return Object.assign({}, state, {
                focusedComment: {
                    profileId: action.profileId,
                    commentId: action.commentId,
                    replyId: action.replyId,
                    commentUser: action.commentUser
                },
                commentReportOpen: true
            });
        case Types.CLOSE_COMMENT_REPORT_FORM:
            return Object.assign({}, state, {
                focusedComment: undefined,
                commentReportOpen: false
            });
        case Types.SET_TRACKING_DELAYED_SINCE:
            return Object.assign({}, state, {
                trackingDelayedSince: action.since
            })
        case Types.DISMISS_TRACKING_DELAYED:
            const dismissUntil = Date.now() + 4 * 3600 * 1000;
            try {
                window.localStorage.setItem("wavy.tracking-delayed-dismiss", '' + dismissUntil);
            } catch (e) {
                // Ignore
            }
            return Object.assign({}, state, {
                trackingDelayedDismissed: dismissUntil
            });
        default:
            return state;
    }
}
