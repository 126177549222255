import React from "react";
import MusicSubject from "./MusicSubject";

interface Props {
    name: string;
    artist: string;
    image: string;
}

export default class SongSubject extends React.Component<Props> {
    render() {
        return <MusicSubject name={this.props.name} image={this.props.image} subtext={this.props.artist}/>
    }
}
