import React from "react";
import MusicSubject from "./MusicSubject";

interface Props {
    name: string;
    image: string;
}

export default class ArtistSubject extends React.Component<Props> {
    render() {
        return <MusicSubject name={this.props.name} image={this.props.image} rounded={true}/>
    }
}

