import {AppState, ForeignUser} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ProfileGridSection from "../Presentation/ProfileGridSection/ProfileGridSection";

function mapStateToProps(state: AppState) {
    const profile = state.profilePage.profile as ForeignUser;
    const isOwnProfile = (!!state.user && state.profileReady && state.user.id === profile.id);
    return {
        isOwnProfile,
        profile,
        reload$: state.profilePage.reload$,
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileGridSection);
