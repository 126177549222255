import React from "react";
import styles from "./ProfileBlockConfirm.module.scss";
import CardModal from "../CardModal/CardModal";
import {blockUser} from "../../../API/Calls";
import Button, {ButtonColor} from "../Button/Button";
import {EventEmitter} from "events";

interface Props {
    username: string;
    isOpen: boolean;
    userId: string;
    reload$: EventEmitter;
}

interface Actions {
    toggleProfileBlockConfirm: (open: boolean) => void;
}

interface State {
    loading: boolean;
    signal: AbortController;
}

export default class ProfileBlockConfirm extends React.Component<Props & Actions, State> {

    constructor(props: Readonly<Props & Actions>) {
        super(props);

        this.state = {
            loading: false,
            signal: new AbortController()
        }
    }

    block() {
        this.setState({loading: true});
        blockUser(this.props.userId)
            .then(() => {
                this.props.reload$.emit("reload");
                this.props.reload$.emit("reload_listens");
                this.setState({loading: false});
            })
            .catch(err => {
                console.error(err);
                this.setState({loading: false});
            })
    }

    renderContent() {
        return (
            <div className={styles.Content}>
                <p className={styles.Question}>Are you sure you want to block {this.props.username}?</p>
                <div className={styles.ButtonParent}>
                    <Button color={ButtonColor.Regular}
                            onClick={() => this.actions.toggleProfileBlockConfirm(false)}>Cancel</Button>
                    <Button color={ButtonColor.Danger} outline={true} disabled={this.state.loading}
                            onClick={() => this.block()}>Block</Button>
                </div>
            </div>
        )
    }

    render() {
        return (
            <CardModal title={`Block ${this.props.username}?`}
                       titleClassName={styles.ModalTitle}
                       isOpen={this.props.isOpen}
                       onRequestClose={() => this.actions.toggleProfileBlockConfirm(false)}
                       children={this.renderContent()}/>
        )
    }

    componentWillUnmount(): void {
        this.state.signal.abort();
    }

    get actions() {
        return this.props as Actions;
    }
}
