import {Friendship} from "../../../API/Models";
import React from "react";
import {cancelFriendRequest, createFriendRequest, getFriendshipStatus, unblockUser} from "../../../API/Calls";
import Button, {ButtonColor} from "../Button/Button";
import AddFriendIcon from "../Icons/AddFriend/AddFriendIcon";
import styles from "./ProfileFriendButton.module.scss";
import FriendIcon from "../Icons/FriendIcon/FriendIcon";
import MenuIcon from "../Icons/MenuIcon/MenuIcon";
import Card from "../Card/Card";
import PopupMenu from "../PopupMenu/PopupMenu";
import {ForeignUser} from "../../../Redux/Types";
import BlockedIcon from "../Icons/BlockedIcon/BlockedIcon";
import {EventEmitter} from "events";
import FriendMenuIcon, {Icons} from "./FriendMenuIcon";

interface Props {
    isLoggedIn: boolean;
    profile: ForeignUser;
    status: Friendship | null;

    onChange?: (friendship: Friendship) => void;
    reloadTrigger?: number;
    updateProfileFriendship: (friendship: Friendship | null) => void;
    toggleProfileReportForm: (open: boolean) => void;
    toggleProfileUnfriendConfirm: (open: boolean) => void;
    toggleProfileBlockConfirm: (open: boolean) => void;

    reloadProfile$: EventEmitter
}

interface Actions {
    updateProfileFriendship: (friendship: Friendship | null) => void;
    toggleProfileReportForm: (open: boolean) => void;
    toggleProfileUnfriendConfirm: (open: boolean) => void;
    toggleProfileBlockConfirm: (open: boolean) => void;
}

interface State {
    loading: boolean;
    isMenuOpen: boolean;
    abort: AbortController;
}

export default class ProfileFriendButton extends React.Component<Props, State> {
    private toggleRef: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: false,
            isMenuOpen: false,
            abort: new AbortController()
        };
    }

    render() {
        if (!this.props.status) {
            return <div/>
        }

        if (this.props.profile.$access.blocked) {
            if (this.props.profile.$access.blockedByYou) {
                return (
                    <div className={styles.Parent}>
                        <div className={styles.WithMenu}>
                            <div className={styles.BlockedButton}>
                                <BlockedIcon/> Blocked
                            </div>
                            <div className={this.getMenuIconClasses()} ref={this.toggleRef}
                                 onClick={this.openMenu.bind(this)}>
                                <MenuIcon/>
                            </div>
                        </div>
                        {
                            this.state.isMenuOpen ?
                                <Card className={styles.MenuFloat}>
                                    <PopupMenu toggleRef={this.toggleRef} closeMenu={this.closeMenu.bind(this)}>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickUnblock.bind(this)}>
                                            <FriendMenuIcon icon={Icons.Unblock}/>Unblock
                                        </div>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickReport.bind(this)}>
                                            <FriendMenuIcon icon={Icons.Report}/>Report
                                        </div>
                                    </PopupMenu>
                                </Card>
                                : ""
                        }
                    </div>
                )
            } else {
                return (
                    <div className={styles.Parent}>
                        <div className={styles.WithMenu}>
                            <div className={styles.BlockedButton}>
                                <BlockedIcon/> Blocked
                            </div>
                            <div className={this.getMenuIconClasses()} ref={this.toggleRef}
                                 onClick={this.openMenu.bind(this)}>
                                <MenuIcon/>
                            </div>
                        </div>
                        {
                            this.state.isMenuOpen ?
                                <Card className={styles.MenuFloat}>
                                    <PopupMenu toggleRef={this.toggleRef} closeMenu={this.closeMenu.bind(this)}>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickReport.bind(this)}>
                                            <FriendMenuIcon icon={Icons.Report}/>Report
                                        </div>
                                    </PopupMenu>
                                </Card>
                                : ""
                        }
                    </div>
                )
            }
        }

        switch (this.props.status) {
            case Friendship.None:
                return (
                    <div className={styles.Parent}>
                        <div className={styles.WithMenu}>
                            <div className={styles.AddFriendButton}>
                                <Button color={ButtonColor.Accent} outline={true} disabled={this.state.loading}
                                        onClick={this.clickAddFriend.bind(this)}>
                                    <AddFriendIcon/> Add Friend</Button>
                            </div>
                            <div className={this.getMenuIconClasses()} ref={this.toggleRef}
                                 onClick={this.openMenu.bind(this)}>
                                <MenuIcon/>
                            </div>
                        </div>
                        {
                            this.state.isMenuOpen ?
                                <Card className={styles.MenuFloat}>
                                    <PopupMenu toggleRef={this.toggleRef} closeMenu={this.closeMenu.bind(this)}>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickBlock.bind(this)}>
                                            <FriendMenuIcon icon={Icons.Block}/>Block
                                        </div>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickReport.bind(this)}>
                                            <FriendMenuIcon icon={Icons.Report}/>Report
                                        </div>
                                    </PopupMenu>
                                </Card>
                                : ""
                        }
                    </div>
                )
            case Friendship.Sent:
                return (
                    <div className={styles.Parent}>
                        <div className={styles.WithMenu}>
                            <div className={styles.RequestSent}>
                                <FriendIcon/> Request Sent
                            </div>
                            <div className={this.getMenuIconClasses()} ref={this.toggleRef}
                                 onClick={this.openMenu.bind(this)}>
                                <MenuIcon/>
                            </div>
                        </div>
                        {
                            this.state.isMenuOpen ?
                                <Card className={styles.MenuFloat}>
                                    <PopupMenu toggleRef={this.toggleRef} closeMenu={this.closeMenu.bind(this)}>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickCancelRequest.bind(this)}>
                                            <FriendMenuIcon icon={Icons.CancelRequest}/>Cancel Request
                                        </div>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickBlock.bind(this)}>
                                            <FriendMenuIcon icon={Icons.Block}/>Block
                                        </div>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickReport.bind(this)}>
                                            <FriendMenuIcon icon={Icons.Report}/>Report
                                        </div>
                                    </PopupMenu>
                                </Card>
                                : ""
                        }
                    </div>
                )
            case Friendship.Pending:
                return (
                    <div className={styles.Parent}>
                        <div className={styles.WithMenu}>
                            <div className={styles.AcceptFriendButton}>
                                <Button color={ButtonColor.Primary} outline={true} disabled={this.state.loading}
                                        onClick={this.clickAcceptFriend.bind(this)}>
                                    <AddFriendIcon/> Accept Request</Button>
                            </div>
                            <div className={this.getMenuIconClasses()} ref={this.toggleRef}
                                 onClick={this.openMenu.bind(this)}>
                                <MenuIcon/>
                            </div>
                        </div>
                        {
                            this.state.isMenuOpen ?
                                <Card className={styles.MenuFloat}>
                                    <PopupMenu toggleRef={this.toggleRef} closeMenu={this.closeMenu.bind(this)}>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickCancelRequest.bind(this)}>
                                            <FriendMenuIcon icon={Icons.Unfriend} className={styles.UnfriendIcon}/>Ignore
                                        </div>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickBlock.bind(this)}>
                                            <FriendMenuIcon icon={Icons.Block}/>Block
                                        </div>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickReport.bind(this)}>
                                            <FriendMenuIcon icon={Icons.Report}/>Report
                                        </div>
                                    </PopupMenu>
                                </Card>
                                : ""
                        }
                    </div>
                )
            case Friendship.Friend:
                return (
                    <div className={styles.Parent}>
                        <div className={styles.WithMenu}>
                            <div className={styles.Friend}>
                                <FriendIcon/> Friends
                            </div>
                            <div className={this.getMenuIconClasses()} ref={this.toggleRef}
                                 onClick={this.openMenu.bind(this)}>
                                <MenuIcon/>
                            </div>
                        </div>
                        {
                            this.state.isMenuOpen ?
                                <Card className={styles.MenuFloat}>
                                    <PopupMenu toggleRef={this.toggleRef} closeMenu={this.closeMenu.bind(this)}>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickUnfriend.bind(this)}>
                                            <FriendMenuIcon icon={Icons.Unfriend} className={styles.UnfriendIcon}/>Unfriend
                                        </div>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickBlock.bind(this)}>
                                            <FriendMenuIcon icon={Icons.Block}/>Block
                                        </div>
                                        <div className={styles.MenuItem}
                                             onClick={this.clickReport.bind(this)}>
                                            <FriendMenuIcon icon={Icons.Report}/>Report
                                        </div>
                                    </PopupMenu>
                                </Card>
                                : ""
                        }
                    </div>
                )
        }

        return (
            <div>{this.props.status}</div>
        )
    }

    componentDidMount(): void {
        this.reloadStatus();
    }

    componentWillUnmount(): void {
        this.state.abort.abort();
    }

    getMenuIconClasses() {
        const classes = [styles.MenuIcon];
        if (this.state.isMenuOpen) {
            classes.push(styles.Open);
        }
        return classes.join(" ");
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.reloadTrigger !== this.props.reloadTrigger) {
            this.reloadStatus();
        }
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            this.reloadStatus();
        }
    }

    reloadStatus() {
        if (!this.props.isLoggedIn) {
            return;
        }
        const currentStatus = this.props.status;
        getFriendshipStatus(this.props.profile.id, this.state.abort)
            .then(resp => {
                if (resp.status !== currentStatus && this.props.onChange) {
                    this.props.onChange(resp.status);
                }
                this.actions.updateProfileFriendship(resp.status);
                this.setState({loading: false});
            })
            .catch(resp => console.error(resp));
    }

    clickAddFriend() {
        if (!this.props.isLoggedIn) {
            return;
        }
        this.setState({loading: true});
        createFriendRequest(this.props.profile.id)
            .then(() => {
                this.reloadStatus();
            })
            .catch(err => {
                console.error(err);
                this.setState({loading: false});
            })
    }

    clickAcceptFriend() {
        if (!this.props.isLoggedIn) {
            return;
        }
        this.setState({loading: true});
        createFriendRequest(this.props.profile.id)
            .then(() => {
                if (!this.props.profile.$access.view) {
                    this.props.reloadProfile$.emit("reload");
                    this.props.reloadProfile$.emit("reload_listens");
                } else {
                    this.reloadStatus();
                }
            })
            .catch(err => {
                console.error(err);
                this.setState({loading: false});
            })
    }

    clickUnfriend() {
        if (!this.props.isLoggedIn) {
            return;
        }
        this.closeMenu();
        this.actions.toggleProfileUnfriendConfirm(true);
    }

    clickReport() {
        this.closeMenu();
        this.actions.toggleProfileReportForm(true);
    }

    clickBlock() {
        this.closeMenu();
        this.actions.toggleProfileBlockConfirm(true);
    }

    clickUnblock() {
        if (!this.props.isLoggedIn) {
            return;
        }
        this.closeMenu();
        unblockUser(this.props.profile.id)
            .then(() => {
                this.actions.updateProfileFriendship(Friendship.None);
                this.props.reloadProfile$.emit("reload");
                this.props.reloadProfile$.emit("reload_listens");
            })
            .catch(err => {
                console.error(err);
            })
    }

    clickCancelRequest() {
        if (!this.props.isLoggedIn) {
            return;
        }
        this.closeMenu();
        this.setState({loading: true});
        cancelFriendRequest(this.props.profile.id)
            .then(() => {
                this.reloadStatus();
            })
            .catch(err => {
                console.error(err);
                this.setState({loading: false});
            })
    }

    openMenu() {
        this.setState({isMenuOpen: !this.state.isMenuOpen})
    }

    closeMenu() {
        this.setState({isMenuOpen: false})
    }

    get actions() {
        return this.props as Actions;
    }
}
