import React from "react";
import {ForeignUser} from "../../../Redux/Types";
import {GetProfileListensResponse} from "../../../API/Models";
import {getFriendList, getRecentListens} from "../../../API/Calls";
import ProfileListenHighlightsContainer from "../../Containers/ProfileListenHighlightsContainer";
import Card, {CardElevation} from "../Card/Card";
import ProfileRecentSongsContainer from "../../Containers/ProfileRecentSongsContainer";
import ProfileTutorialContainer from "../../Containers/ProfileTutorialContainer";
import ProfileGridSectionContainer from "../../Containers/ProfileGridSectionContainer";
import ProfileTopSongs from "../ProfileTopSongs/ProfileTopSongs";
import ProfileRequestHeaderContainer from "../../Containers/ProfileRequestHeaderContainer";
import {EventEmitter} from "events";
import ProfileCommentsContainer from "../../Containers/ProfileCommentsContainer";
import SpotifyTrackingAlertContainer from "../../Containers/SpotifyTrackingAlertContainer";
import styles from "./ProfileContent.module.scss";

interface Props {
    profile: ForeignUser;
    isLoggedIn: boolean;
    isOwnProfile: boolean;
    hasListenHistory: boolean;
    hasNonLiveHistory: boolean;
    hasLoadedHistory: boolean;
    reload$: EventEmitter;
}

interface State {
    canShowComments: boolean;
}

interface Actions {
    loadProfileRecentListens: (listens: GetProfileListensResponse | null) => void;
    loadProfileFriendCount: (count: number | null) => void;
}

export default class ProfileContent extends React.Component<Props & Actions, State> {
    private abort = new AbortController();

    constructor(props: Readonly<Props & Actions>) {
        super(props);

        this.state = {
            canShowComments: false
        }
    }

    componentDidMount() {
        this.actions.loadProfileRecentListens(null);
        this.actions.loadProfileFriendCount(null);

        if (this.props.profile.$access.view) {
            this.load();
        } else {
            this.actions.loadProfileFriendCount(0);
        }

        this.setState({canShowComments: true});
        this.props.reload$.on("reload_listens", () => this.loadListens());
    }

    load() {
        // this.actions.loadProfileFriendCount(null);
        this.loadListens();

        getFriendList(this.props.profile.id, this.abort)
            .then(friends => this.actions.loadProfileFriendCount(friends.length))
            .catch(err => {
                this.actions.loadProfileFriendCount(0);
                console.error(err);
            });
    }

    loadListens() {
        // this.actions.loadProfileRecentListens(null);
        getRecentListens(this.props.profile.id, 6, 0, 0, 0, undefined, true, this.abort)
            .then(resp => {
                this.actions.loadProfileRecentListens(resp);
            })
            .catch(console.error);
    }

    componentWillUnmount() {
        this.abort.abort();
    }

    render() {
        return (
            <div>
                <ProfileRequestHeaderContainer/>
                {
                    window.innerWidth <= 768 ? "" : <SpotifyTrackingAlertContainer/>
                }
                {this.renderHighlights()}
                {this.renderHistory()}
                <ProfileTutorialContainer/>
                {this.renderComments()}
            </div>
        )
    }

    renderHighlights() {
        if (window.innerWidth <= 768) {
            if (!this.props.profile.$access.view) {
                return "";
            }
            return <ProfileListenHighlightsContainer/>
        } else {
            return (
                <Card elevation={CardElevation.Sheet}
                      className={styles.HighlightsRounded}><ProfileListenHighlightsContainer/></Card>
            )
        }
    }

    renderHistory() {
        if (!this.props.hasNonLiveHistory) {
            return "";
        }
        return (
            <div>
                <ProfileRecentSongsContainer/>
                <ProfileGridSectionContainer/>
                <ProfileTopSongs user={this.props.profile} isOwnProfile={this.props.isOwnProfile}
                                 reload$={this.props.reload$}/>
            </div>
        )
    }

    renderComments() {
        if (!this.props.profile.$access.view || !this.props.hasLoadedHistory || !this.state.canShowComments) {
            return "";
        }
        return <ProfileCommentsContainer/>
    }

    get actions() {
        return this.props as Actions;
    }
}
