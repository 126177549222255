import React from "react";
import styles from "./MfaInput.module.scss";

interface Props {
}

interface State {
}

export default class MfaInput extends React.Component<Props, State> {
    inputRefs = [
        React.createRef<HTMLInputElement>(),
        React.createRef<HTMLInputElement>(),
        React.createRef<HTMLInputElement>(),
        React.createRef<HTMLInputElement>(),
        React.createRef<HTMLInputElement>(),
        React.createRef<HTMLInputElement>()];

    render() {
        return (
            <div className={styles.Parent}>
                {
                    this.inputRefs.map((ref, i) =>
                        <input key={i} ref={ref} type={"text"} className={styles.Digit}
                               autoComplete={"off"}
                               onInput={e => this.onInput(i, e)} onKeyDown={e => this.onKeyDown(i, e)}/>)
                }
            </div>
        )
    }

    componentDidMount() {
        this.inputRefs[0].current?.focus();
    }

   private onInput(i: number, e: React.FormEvent<HTMLInputElement>) {
        e.preventDefault();
        const target = e.target as HTMLInputElement;

        const newValue = target.value.split('').filter(c => c.match(/[0-9]/g));
        if (!newValue.length) {
            target.value = "";
            return;
        }

        if (target.selectionStart === 1 && newValue.length > 1) {
            newValue.pop();
        }

        newValue.forEach((digit, ii) => {
            const ref = this.inputRefs[i + ii]?.current;
            const refNext = this.inputRefs[i + ii + 1]?.current;
            if (ref) {
                ref.value = digit;
            }
            if (refNext) {
                refNext.focus();
                refNext.setSelectionRange(0, 0);
            }
        });
    }

    private onKeyDown(i: number, e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Backspace") {
            e.preventDefault();
            const current = this.inputRefs[i].current;
            const previous = i > 0 ? this.inputRefs[i - 1].current : null;
            if (current) {
                current.value = "";
            }
            if (previous) {
                previous.focus();
            }
        } else if (e.key === "ArrowLeft" && i > 0) {
            e.preventDefault();
            this.inputRefs[i - 1].current?.focus();
            this.inputRefs[i - 1].current?.setSelectionRange(0, 0);
        } else if (e.key === "ArrowRight" && (i + 1) < this.inputRefs.length) {
            e.preventDefault();
            this.inputRefs[i + 1].current?.focus();
            this.inputRefs[i + 1].current?.setSelectionRange(0, 0);
        }
    }

    reset() {
        for (let i = 0; i < this.inputRefs.length; i++){
            let ref = this.inputRefs[i];
            if (ref.current) {
                if (i === 0) {
                    ref.current.focus();
                }
                ref.current.value = "";
            }
        }
    }

    get value(): string {
        return this.inputRefs.map(e => e.current?.value).filter(e => !!e).join("");
    }

    get valid(): boolean {
        return !!this.value.match(/^[0-9]{6}$/g);
    }
}
