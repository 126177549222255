export const Countries = [
    {
        "id": "AF",
        "name": "Afghanistan"
    },
    {
        "id": "AL",
        "name": "Albania"
    },
    {
        "id": "DZ",
        "name": "Algeria"
    },
    {
        "id": "AD",
        "name": "Andorra"
    },
    {
        "id": "AO",
        "name": "Angola"
    },
    {
        "id": "AG",
        "name": "Antigua and Barbuda"
    },
    {
        "id": "AR",
        "name": "Argentina"
    },
    {
        "id": "AM",
        "name": "Armenia"
    },
    {
        "id": "AU",
        "name": "Australia"
    },
    {
        "id": "AT",
        "name": "Austria"
    },
    {
        "id": "AZ",
        "name": "Azerbaijan"
    },
    {
        "id": "BS",
        "name": "Bahamas"
    },
    {
        "id": "BH",
        "name": "Bahrain"
    },
    {
        "id": "BD",
        "name": "Bangladesh"
    },
    {
        "id": "BB",
        "name": "Barbados"
    },
    {
        "id": "BY",
        "name": "Belarus"
    },
    {
        "id": "BE",
        "name": "Belgium"
    },
    {
        "id": "BZ",
        "name": "Belize"
    },
    {
        "id": "BJ",
        "name": "Benin"
    },
    {
        "id": "BT",
        "name": "Bhutan"
    },
    {
        "id": "BO",
        "name": "Bolivia"
    },
    {
        "id": "BA",
        "name": "Bosnia and Herzegovina"
    },
    {
        "id": "BW",
        "name": "Botswana"
    },
    {
        "id": "BR",
        "name": "Brazil"
    },
    {
        "id": "BN",
        "name": "Brunei Darussalam"
    },
    {
        "id": "BG",
        "name": "Bulgaria"
    },
    {
        "id": "BF",
        "name": "Burkina Faso"
    },
    {
        "id": "BI",
        "name": "Burundi"
    },
    {
        "id": "KH",
        "name": "Cambodia"
    },
    {
        "id": "CM",
        "name": "Cameroon"
    },
    {
        "id": "CA",
        "name": "Canada"
    },
    {
        "id": "CV",
        "name": "Cape Verde"
    },
    {
        "id": "CF",
        "name": "Central African Republic"
    },
    {
        "id": "TD",
        "name": "Chad"
    },
    {
        "id": "CL",
        "name": "Chile"
    },
    {
        "id": "CN",
        "name": "China"
    },
    {
        "id": "CO",
        "name": "Colombia"
    },
    {
        "id": "KM",
        "name": "Comoros"
    },
    {
        "id": "CG",
        "name": "Congo"
    },
    {
        "id": "CD",
        "name": "Democratic Republic of the Congo"
    },
    {
        "id": "CR",
        "name": "Costa Rica"
    },
    {
        "id": "CI",
        "name": "C\u00F4te d'Ivoire"
    },
    {
        "id": "HR",
        "name": "Croatia"
    },
    {
        "id": "CU",
        "name": "Cuba"
    },
    {
        "id": "CY",
        "name": "Cyprus"
    },
    {
        "id": "CZ",
        "name": "Czech Republic"
    },
    {
        "id": "DK",
        "name": "Denmark"
    },
    {
        "id": "DJ",
        "name": "Djibouti"
    },
    {
        "id": "DM",
        "name": "Dominica"
    },
    {
        "id": "DO",
        "name": "Dominican Republic"
    },
    {
        "id": "EC",
        "name": "Ecuador"
    },
    {
        "id": "EG",
        "name": "Egypt"
    },
    {
        "id": "SV",
        "name": "El Salvador"
    },
    {
        "id": "ENG",
        "name": "England"
    },
    {
        "id": "GQ",
        "name": "Equatorial Guinea"
    },
    {
        "id": "ER",
        "name": "Eritrea"
    },
    {
        "id": "EE",
        "name": "Estonia"
    },
    {
        "id": "ET",
        "name": "Ethiopia"
    },
    {
        "id": "FJ",
        "name": "Fiji"
    },
    {
        "id": "FI",
        "name": "Finland"
    },
    {
        "id": "FR",
        "name": "France"
    },
    {
        "id": "GA",
        "name": "Gabon"
    },
    {
        "id": "GM",
        "name": "Gambia"
    },
    {
        "id": "GE",
        "name": "Georgia"
    },
    {
        "id": "DE",
        "name": "Germany"
    },
    {
        "id": "GH",
        "name": "Ghana"
    },
    {
        "id": "GR",
        "name": "Greece"
    },
    {
        "id": "GL",
        "name": "Greenland"
    },
    {
        "id": "GD",
        "name": "Grenada"
    },
    {
        "id": "GT",
        "name": "Guatemala"
    },
    {
        "id": "GN",
        "name": "Guinea"
    },
    {
        "id": "GW",
        "name": "Guinea-Bissau"
    },
    {
        "id": "GY",
        "name": "Guyana"
    },
    {
        "id": "HT",
        "name": "Haiti"
    },
    {
        "id": "HN",
        "name": "Honduras"
    },
    {
        "id": "HK",
        "name": "Hong Kong"
    },
    {
        "id": "HU",
        "name": "Hungary"
    },
    {
        "id": "IS",
        "name": "Iceland"
    },
    {
        "id": "IN",
        "name": "India"
    },
    {
        "id": "ID",
        "name": "Indonesia"
    },
    {
        "id": "IR",
        "name": "Iran"
    },
    {
        "id": "IQ",
        "name": "Iraq"
    },
    {
        "id": "IE",
        "name": "Ireland"
    },
    {
        "id": "IL",
        "name": "Israel"
    },
    {
        "id": "IT",
        "name": "Italy"
    },
    {
        "id": "JM",
        "name": "Jamaica"
    },
    {
        "id": "JP",
        "name": "Japan"
    },
    {
        "id": "JO",
        "name": "Jordan"
    },
    {
        "id": "KZ",
        "name": "Kazakhstan"
    },
    {
        "id": "KE",
        "name": "Kenya"
    },
    {
        "id": "KI",
        "name": "Kiribati"
    },
    {
        "id": "KW",
        "name": "Kuwait"
    },
    {
        "id": "KG",
        "name": "Kyrgyzstan"
    },
    {
        "id": "LA",
        "name": "Laos"
    },
    {
        "id": "LV",
        "name": "Latvia"
    },
    {
        "id": "LB",
        "name": "Lebanon"
    },
    {
        "id": "LS",
        "name": "Lesotho"
    },
    {
        "id": "LR",
        "name": "Liberia"
    },
    {
        "id": "LY",
        "name": "Libya"
    },
    {
        "id": "LI",
        "name": "Liechtenstein"
    },
    {
        "id": "LT",
        "name": "Lithuania"
    },
    {
        "id": "LU",
        "name": "Luxembourg"
    },
    {
        "id": "MK",
        "name": "Macedonia"
    },
    {
        "id": "MG",
        "name": "Madagascar"
    },
    {
        "id": "MW",
        "name": "Malawi"
    },
    {
        "id": "MY",
        "name": "Malaysia"
    },
    {
        "id": "MV",
        "name": "Maldives"
    },
    {
        "id": "ML",
        "name": "Mali"
    },
    {
        "id": "MT",
        "name": "Malta"
    },
    {
        "id": "MH",
        "name": "Marshall Islands"
    },
    {
        "id": "MR",
        "name": "Mauritania"
    },
    {
        "id": "MU",
        "name": "Mauritius"
    },
    {
        "id": "MX",
        "name": "Mexico"
    },
    {
        "id": "FM",
        "name": "Micronesia"
    },
    {
        "id": "MD",
        "name": "Moldova"
    },
    {
        "id": "MC",
        "name": "Monaco"
    },
    {
        "id": "MN",
        "name": "Mongolia"
    },
    {
        "id": "ME",
        "name": "Montenegro"
    },
    {
        "id": "MA",
        "name": "Morocco"
    },
    {
        "id": "MZ",
        "name": "Mozambique"
    },
    {
        "id": "MM",
        "name": "Myanmar"
    },
    {
        "id": "NA",
        "name": "Namibia"
    },
    {
        "id": "NR",
        "name": "Nauru"
    },
    {
        "id": "NP",
        "name": "Nepal"
    },
    {
        "id": "NL",
        "name": "Netherlands"
    },
    {
        "id": "NZ",
        "name": "New Zealand"
    },
    {
        "id": "NI",
        "name": "Nicaragua"
    },
    {
        "id": "NE",
        "name": "Niger"
    },
    {
        "id": "NG",
        "name": "Nigeria"
    },
    {
        "id": "KP",
        "name": "North Korea"
    },
    {
        "id": "NIR",
        "name": "Northern Ireland"
    },
    {
        "id": "NO",
        "name": "Norway"
    },
    {
        "id": "OM",
        "name": "Oman"
    },
    {
        "id": "PK",
        "name": "Pakistan"
    },
    {
        "id": "PW",
        "name": "Palau"
    },
    {
        "id": "PA",
        "name": "Panama"
    },
    {
        "id": "PG",
        "name": "Papua New Guinea"
    },
    {
        "id": "PY",
        "name": "Paraguay"
    },
    {
        "id": "PE",
        "name": "Peru"
    },
    {
        "id": "PH",
        "name": "Philippines"
    },
    {
        "id": "PL",
        "name": "Poland"
    },
    {
        "id": "PT",
        "name": "Portugal"
    },
    {
        "id": "QA",
        "name": "Qatar"
    },
    {
        "id": "RO",
        "name": "Romania"
    },
    {
        "id": "RU",
        "name": "Russia"
    },
    {
        "id": "RW",
        "name": "Rwanda"
    },
    {
        "id": "KN",
        "name": "Saint Kitts and Nevis"
    },
    {
        "id": "LC",
        "name": "Saint Lucia"
    },
    {
        "id": "VC",
        "name": "Saint Vincent and the Grenadines"
    },
    {
        "id": "WS",
        "name": "Samoa"
    },
    {
        "id": "SM",
        "name": "San Marino"
    },
    {
        "id": "ST",
        "name": "Sao Tome and Principe"
    },
    {
        "id": "SA",
        "name": "Saudi Arabia"
    },
    {
        "id": "SCT",
        "name": "Scotland"
    },
    {
        "id": "SN",
        "name": "Senegal"
    },
    {
        "id": "RS",
        "name": "Serbia"
    },
    {
        "id": "SC",
        "name": "Seychelles"
    },
    {
        "id": "SL",
        "name": "Sierra Leone"
    },
    {
        "id": "SG",
        "name": "Singapore"
    },
    {
        "id": "SK",
        "name": "Slovakia"
    },
    {
        "id": "SI",
        "name": "Slovenia"
    },
    {
        "id": "SB",
        "name": "Solomon Islands"
    },
    {
        "id": "SO",
        "name": "Somalia"
    },
    {
        "id": "ZA",
        "name": "South Africa"
    },
    {
        "id": "KR",
        "name": "South Korea"
    },
    {
        "id": "SS",
        "name": "South Sudan"
    },
    {
        "id": "ES",
        "name": "Spain"
    },
    {
        "id": "LK",
        "name": "Sri Lanka"
    },
    {
        "id": "SD",
        "name": "Sudan"
    },
    {
        "id": "SR",
        "name": "Suriname"
    },
    {
        "id": "SZ",
        "name": "Swaziland"
    },
    {
        "id": "SE",
        "name": "Sweden"
    },
    {
        "id": "CH",
        "name": "Switzerland"
    },
    {
        "id": "SY",
        "name": "Syria"
    },
    {
        "id": "TW",
        "name": "Taiwan"
    },
    {
        "id": "TJ",
        "name": "Tajikistan"
    },
    {
        "id": "TZ",
        "name": "Tanzania"
    },
    {
        "id": "TH",
        "name": "Thailand"
    },
    {
        "id": "TL",
        "name": "Timor-Leste"
    },
    {
        "id": "TG",
        "name": "Togo"
    },
    {
        "id": "TO",
        "name": "Tonga"
    },
    {
        "id": "TT",
        "name": "Trinidad and Tobago"
    },
    {
        "id": "TN",
        "name": "Tunisia"
    },
    {
        "id": "TR",
        "name": "Turkey"
    },
    {
        "id": "TM",
        "name": "Turkmenistan"
    },
    {
        "id": "TV",
        "name": "Tuvalu"
    },
    {
        "id": "UG",
        "name": "Uganda"
    },
    {
        "id": "UA",
        "name": "Ukraine"
    },
    {
        "id": "AE",
        "name": "United Arab Emirates"
    },
    {
        "id": "GB",
        "name": "United Kingdom"
    },
    {
        "id": "US",
        "name": "United States"
    },
    {
        "id": "UY",
        "name": "Uruguay"
    },
    {
        "id": "UZ",
        "name": "Uzbekistan"
    },
    {
        "id": "VU",
        "name": "Vanuatu"
    },
    {
        "id": "VA",
        "name": "Vatican"
    },
    {
        "id": "VE",
        "name": "Venezuela"
    },
    {
        "id": "VN",
        "name": "Vietnam"
    },
    {
        "id": "WLS",
        "name": "Wales"
    },
    {
        "id": "YE",
        "name": "Yemen"
    },
    {
        "id": "ZM",
        "name": "Zambia"
    },
    {
        "id": "ZW",
        "name": "Zimbabwe"
    }
];

function generateCountryById(): Map<string, string> {
    const result = new Map<string, string>();
    for (const country of Countries) {
        result.set(country.id, country.name);
    }
    return result;
}

export const CountryById = generateCountryById();
