import React, {ChangeEvent} from "react";
import styles from "./SearchBar.module.scss";
import {History} from "history";
import {Link} from "react-router-dom";
import Card from "../Card/Card";
import Avatar from "../Avatar/Avatar";
import SearchBox from "../SearchBox/SearchBox";
import debounce from "debounce-promise";
import {searchThings} from "../../../API/Calls";
import NotificationBellContainer from "../../Containers/NotificationBellContainer";
import {readUser} from "../../../API/Models";

const searchThingsDebounced = debounce(searchThings, 200);


export interface SearchBarProps {
    isMenuOpen: boolean,
    history: History;

    openSearchMenu: () => void;
    closeSearchMenu: () => void;
}

interface SearchBarState {
    value: string;
    loading: boolean;
    results: SearchResult[];
    selected: number;
}

interface SearchResult {
    name: string;
    icon: string;
    link: string;
}

export default class SearchBar extends React.Component<SearchBarProps, SearchBarState> {
    private inputContainer: React.RefObject<HTMLInputElement> = React.createRef();
    private resultsContainer: React.RefObject<HTMLDivElement> = React.createRef();
    private abortCtl?: AbortController;

    constructor(props: Readonly<SearchBarProps>) {
        super(props);

        this.state = {
            value: "",
            loading: false,
            results: [],
            selected: 0
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleNavigate = this.handleNavigate.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);

        this.props.history.listen((location, action) => {
            if (this.isMobile() && this.props.isMenuOpen) {
                this.closeMobileOverlay();
                return;
            }

            this.props.closeSearchMenu();
            this.setState({value: "", loading: false, results: []});

            // Blur
            if (this.inputContainer && this.inputContainer.current) {
                this.inputContainer.current.blur();
            }
        })
    }

    render() {
        if (this.props.isMenuOpen && this.isMobile()) {
            return this.renderMobileOverlay();
        }
        return (
            <div className={styles.SearchParent}>
                <div className={styles.SearchBarParent}>
                    <SearchBox placeholder={"Search"}
                               onChange={this.handleChange}
                               onKeyDown={this.handleKeyDown}
                               value={this.state.value}
                               onClick={() => this.state.value ? this.props.openSearchMenu() : null}
                               inputRef={this.inputContainer}/>
                    {
                        !this.props.isMenuOpen ? "" :
                            <Card className={styles.SearchMenuFloat} _ref={this.resultsContainer}>
                                <div className={styles.SearchMenu}>
                                    {
                                        this.state.results.length === 0 ?
                                            (this.state.loading ?
                                                <div className={[styles.Row, styles.Loading].join(" ")}/> :
                                                <span className={[styles.Row, styles.NoResults].join(" ")}>No results found.</span>) :
                                            this.state.results.map((result, index) =>
                                                <Link
                                                    className={[styles.Row, (this.state.selected === index ? styles.Selected : "")].join(" ")}
                                                    to={result.link}
                                                    key={index}
                                                    onClick={this.handleNavigate}>
                                                    <div className={styles.SearchIcon}
                                                         style={{backgroundImage: result.icon}}/>
                                                    {result.name}
                                                </Link>)
                                    }
                                </div>
                            </Card>
                    }
                </div>
                <div className={styles.Buttons}>
                    <div className={styles.SearchMobileButton} onClick={() => this.openMobileOverlay()}>
                        {this.mobileSearchIcon()}
                    </div>
                    <NotificationBellContainer/>
                </div>
            </div>
        )
    }

    renderMobileOverlay() {
        return (
            <div className={styles.MobileOverlay}>
                <div className={styles.MobileOverlayClose} onClick={() => this.closeMobileOverlay()}/>
                <div className={styles.MobileTopBar}>
                    <div className={styles.MobileCloseButton} onClick={() => this.closeMobileOverlay()}/>
                    <SearchBox className={styles.MobileSearchBox} placeholder={"Search"}
                               onChange={this.handleChange}
                               onKeyDown={this.handleKeyDown}
                               value={this.state.value}
                               inputRef={this.inputContainer}/>
                </div>
                {
                    this.state.value.length > 0 ?
                        <div className={styles.MobileResultsParent}>
                            {
                                this.state.results.length === 0
                                    ? this.state.loading ?
                                    ""
                                    :
                                    <div className={styles.MobileLoading}>No results found</div>
                                    :
                                    <div className={styles.Section}>
                                        <div className={styles.SectionTitle}>Users</div>
                                        <div className={styles.SectionContents}>
                                            {
                                                this.state.results.map((result, index) =>
                                                    <Link
                                                        className={[styles.Row].join(" ")}
                                                        to={result.link}
                                                        key={index}
                                                        onClick={this.handleNavigate}>
                                                        <div className={styles.SearchIcon}
                                                             style={{backgroundImage: result.icon}}/>
                                                        {result.name}
                                                    </Link>)
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                        :
                        ""
                }
            </div>
        )
    }

    handlePageClick(e: MouseEvent) {
        if (e.type === "mousedown" &&
            this.inputContainer.current != null && !this.inputContainer.current.contains(e.target as Node) &&
            this.resultsContainer.current != null && !this.resultsContainer.current.contains(e.target as Node)
        ) {
            this.props.closeSearchMenu();
        }
    }

    handleChange(event: ChangeEvent<HTMLInputElement>) {
        const value = event.target.value.trimStart();
        this.setState({value, selected: 0});

        if (this.abortCtl) {
            this.abortCtl.abort();
        }

        if (value.length === 0) {
            this.setState({loading: false, results: []});
            if (!this.isMobile()) {
                this.props.closeSearchMenu();
            }
            return;
        }

        this.props.openSearchMenu();
        this.setState({loading: true}, () => {
            this.abortCtl = new AbortController();
            searchThingsDebounced(value, this.abortCtl)
                .then(resp => {
                    if (!resp) return;
                    const results: SearchResult[] = resp.users?.map(readUser).map(user => {
                        return {
                            name: user.username,
                            link: "/user/" + user.username,
                            icon: Avatar.getUrl(user),
                        }
                    }) ?? [];
                    this.setState({results, loading: false});
                })
                .catch(err => console.error(err));
        });
    }

    handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Escape") {
            this.setState({selected: 0});
            if (this.props.isMenuOpen) {
                this.props.closeSearchMenu();
            } else if (this.inputContainer.current) {
                this.inputContainer.current.blur();
            }
            return;
        }

        if (e.key === "ArrowUp" && this.state.selected > 0 && !this.state.loading && this.state.results.length > 0) {
            this.setState({selected: this.state.selected - 1});
            return;
        }

        if (e.key === "ArrowDown" && !this.state.loading && this.state.selected < this.state.results.length - 1) {
            this.setState({selected: this.state.selected + 1});
            return;
        }

        if (e.key === "Enter" && !this.state.loading && this.state.selected < this.state.results.length) {
            this.props.history.push(this.state.results[this.state.selected].link);
            return;
        }
    }

    handleNavigate() {
        this.props.closeSearchMenu();
    }

    componentDidMount(): void {
        window.addEventListener("mousedown", this.handlePageClick);
    }

    componentWillUnmount(): void {
        window.addEventListener("mousedown", this.handlePageClick);
        if (this.abortCtl) {
            this.abortCtl.abort();
        }
    }

    openMobileOverlay() {
        this.props.openSearchMenu();
        const body = document.getElementsByTagName("body").item(0);
        if (body) {
            body.style.overflow = "hidden";
        }
        setTimeout(() => {
            if (this.inputContainer.current) {
                this.inputContainer.current.focus();
            }
        });
    }

    isMobile() {
        return window.innerWidth <= 1000;
    }

    closeMobileOverlay() {
        this.props.closeSearchMenu();
        const body = document.getElementsByTagName("body").item(0);
        if (body) {
            body.style.overflow = "initial";
        }
        this.setState({value: "", loading: false, results: []});
    }

    mobileSearchIcon() {
        return (
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.5792 11.9497H12.721L12.4168 11.6564C13.4814 10.418 14.1224 8.81018 14.1224 7.06118C14.1224 3.16124 10.9611 0 7.06118 0C3.16124 0 0 3.16124 0 7.06118C0 10.9611 3.16124 14.1224 7.06118 14.1224C8.81018 14.1224 10.418 13.4814 11.6564 12.4168L11.9497 12.721V13.5792L17.3814 19L19 17.3814L13.5792 11.9497ZM7.06118 11.9497C4.3562 11.9497 2.17267 9.76615 2.17267 7.06118C2.17267 4.3562 4.3562 2.17267 7.06118 2.17267C9.76615 2.17267 11.9497 4.3562 11.9497 7.06118C11.9497 9.76615 9.76615 11.9497 7.06118 11.9497Z"
                    className={styles.IconPath}/>
            </svg>
        )
    }
}
