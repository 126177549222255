import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {toggleGlobalLoading} from "../../Redux/Actions";
import UserHistory from "../Presentation/UserHistory/UserHistory";
import queryString from 'query-string';
import {Ranges} from "../Presentation/ProfileDateRange/ProfileDateRange";

function parsePage(): number {
    const pageString = queryString.parse(window.location.search).page as string || "1";
    const page = parseInt(pageString, 10);
    return (isNaN(page) || page < 1) ? 1 : page;
}

function parseSearchFilter(): string | undefined {
    const searchQuery = queryString.parse(window.location.search).search as string | undefined;
    if (searchQuery !== undefined && searchQuery.trim().length > 0) {
        return searchQuery;
    } else {
        return undefined;
    }
}

function parseDateRange(): Ranges {
    const dateRange = queryString.parse(window.location.search).range as string | undefined;
    if (dateRange !== undefined) {
        switch (dateRange) {
            case "7d":
                return Ranges.Days7;
            case "30d":
                return Ranges.Days30;
            case "90d":
                return Ranges.Days90;
            case "365d":
                return Ranges.Days365;
            default:
                return Ranges.AllTime;
        }
    } else {
        return Ranges.AllTime;
    }
}

function parseDateFilter(): Date | undefined {
    const dateStr = queryString.parse(window.location.search).date as string | undefined;
    if (dateStr !== undefined) {
        const parsed = Date.parse(dateStr);
        if (isNaN(parsed)) {
            return undefined;
        }
        return new Date(parsed + new Date(parsed).getTimezoneOffset() * 60000);
    } else {
        return undefined;
    }
}

function mapStateToProps(state: AppState) {
    return {
        page: parsePage(),
        searchFilter: parseSearchFilter(),
        dateRange: parseDateRange(),
        dateFilter: parseDateFilter(),

        authUser: state.user,
        authProfileReady: state.profileReady
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        toggleGlobalLoading
    }, dispatch);
}

function mergeProps(stateProps: object, dispatchProps: object, ownProps: object) {
    const merged = {};
    Object.assign(merged, stateProps);
    Object.assign(merged, dispatchProps);
    Object.assign(merged, ownProps);
    return merged;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UserHistory);
