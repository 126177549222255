import React from "react";
import {cssVar} from "../../../variables";
import styles from "./CommentViewIcon.module.scss";

export enum Icons {
    Unliked, Liked, More, Less
}

interface Props {
    icon: Icons;
    className?: string;
}

export default class CommentViewIcon extends React.Component<Props> {
    render(): React.ReactNode {
        switch (this.props.icon) {
            case Icons.Liked:
                return <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M12.6411 0.820517C11.1427 -0.430353 8.91423 -0.205357 7.53886 1.1848L7.0002 1.72854L6.46154 1.1848C5.08891 -0.205357 2.8577 -0.430353 1.35929 0.820517C-0.357861 2.25621 -0.448093 4.83294 1.0886 6.38917L6.37951 11.7409C6.7213 12.0864 7.27637 12.0864 7.61816 11.7409L12.9091 6.38917C14.4485 4.83294 14.3583 2.25621 12.6411 0.820517Z"
                        className={styles.LikedPath}/>
                </svg>
            case Icons.Unliked:
                return <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M12.5344 0.86553C10.9539 -0.435901 8.51211 -0.240419 7 1.26721C5.48789 -0.240419 3.04609 -0.438579 1.46563 0.86553C-0.590621 2.5606 -0.28984 5.32414 1.17578 6.78892L5.97187 11.5742C6.24531 11.8474 6.61172 12 7 12C7.39101 12 7.75469 11.85 8.02812 11.5769L12.8242 6.7916C14.2871 5.32682 14.5934 2.56328 12.5344 0.86553ZM11.8891 5.88648L7.09297 10.6718C7.02734 10.7361 6.97266 10.7361 6.90703 10.6718L2.11094 5.88648C1.11289 4.89033 0.91055 3.00513 2.31055 1.85098C3.37422 0.975321 5.01484 1.10654 6.04297 2.13215L7 3.08814L7.95703 2.13215C8.99062 1.10118 10.6312 0.975321 11.6894 1.8483C13.0867 3.00245 12.8789 4.89836 11.8891 5.88648Z"
                        className={styles.UnlikedPath}/>
                </svg>
            case Icons.More:
                return <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M0.521555 1.40701L0.522878 1.40569L1.40569 0.522878C1.63693 0.291638 2.00804 0.293552 2.23361 0.521555L2.23493 0.522878L6.00056 4.2885L6.24805 4.53599L6.49553 4.2885L10.2612 0.522878C10.4924 0.291638 10.8635 0.293552 11.0891 0.521551L11.0891 0.521569L11.092 0.524512L11.9818 1.40251C12.2123 1.6338 12.2101 2.00437 11.9824 2.22971L11.9824 2.2297L11.981 2.23103L6.66853 7.54353L6.66852 7.54352L6.66722 7.54484C6.43679 7.77773 6.06566 7.77771 5.83538 7.54743L0.522878 2.23493C0.291638 2.00369 0.293552 1.63258 0.521555 1.40701Z"
                        fill={cssVar('--light-text')} stroke={cssVar('--light-text')} strokeWidth="0.7"/>
                </svg>
            case Icons.Less:
                return <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M11.9824 6.66281L11.981 6.66413L11.0982 7.54695C10.867 7.77819 10.4959 7.77627 10.2703 7.54827L10.269 7.54695L6.50335 3.78132L6.25586 3.53383L6.00837 3.78132L2.24275 7.54695C2.01151 7.77819 1.6404 7.77627 1.41482 7.54827L1.41484 7.54826L1.41186 7.54531L0.522151 6.66731C0.291643 6.43602 0.293791 6.06545 0.521551 5.84012L0.521555 5.84012L0.522878 5.8388L5.83538 0.526296L5.83538 0.526299L5.83668 0.524983C6.06712 0.292097 6.43825 0.29211 6.66853 0.52239L11.981 5.83489C12.2123 6.06613 12.2104 6.43724 11.9824 6.66281Z"
                        fill={cssVar('--light-text')} stroke={cssVar('--light-text')} strokeWidth="0.7"/>
                </svg>
        }
    }
}
