import React from "react";
import styles from "./IntroGuest.module.scss";
import Button, {ButtonColor, ButtonSize} from "../../Button/Button";

export default class IntroGuest extends React.Component {
    render() {
        return (
            <div className={styles.Container}>
                {/* Thanks for names, Matei */}
                <div className={styles.BlobKentucky}/>
                <div className={styles.BlobLouisiana}/>
                <div className={styles.BlobGeorgia}/>

                <div className={styles.Logo}/>
                <div className={styles.Slogan}>
                    <span>Connecting people through</span>
                    &nbsp;
                    <span className={styles.UnderWavy}>music</span>.
                </div>
                <div className={styles.Subtitle}>
                    Track your music trends all while keeping your friends close to your new discoveries.
                </div>
                <Button className={styles.JoinButton} size={ButtonSize.Big} color={ButtonColor.Accent}
                        link={{to: "/join"}}>Join wavy.fm</Button>
            </div>
        )
    }
}
