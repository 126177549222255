import React from "react";
import {getCurrentUser, login} from "../../../API/Calls";
import {Link} from "react-router-dom";
import Button, {ButtonColor} from "../Button/Button";
import MfaInput from "../MfaInput/MfaInput";
import styles from "./LoginForm.module.scss";

export interface LoginFormProps {
    visible: boolean;

    loadUserData: (data: any) => void,
    setAuthFormsError: (error: Error | null) => void,
}

interface LoginFormState {
    username: string;
    password: string;
    loading: boolean;
    mfa: boolean;
    recovery: boolean;
}

export class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
    totpRef = React.createRef<MfaInput>();
    recoveryRef = React.createRef<HTMLInputElement>();

    constructor(props: Readonly<LoginFormProps>) {
        super(props);
        this.state = {username: "", password: "", loading: false, mfa: false, recovery: false};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        if (this.state.mfa) {
            if (this.state.recovery) {
                return this.renderRecovery();
            } else {
                return this.renderMfa();
            }
        } else {
            return this.renderCredentials()
        }
    }

    renderCredentials() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"LoginFormParent"}>
                    <div className={"FormField"}>
                        <label htmlFor={"Username"}>
                            Username or email address
                        </label>
                        <input required={true} name={"Username"} type={"text"} value={this.state.username}
                               tabIndex={this.props.visible ? 0 : -1}
                               autoComplete={"username"}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={"FormField"}>
                        <label htmlFor={"Password"}>
                            Password
                        </label>
                        <input required={true} name={"Password"} type={"password"}
                               autoComplete={"current-password"}
                               tabIndex={this.props.visible ? 0 : -1}
                               onChange={this.handleChange}/>
                    </div>
                    <div className="FormField SubmitRegion">
                        <Button form={true} color={ButtonColor.Primary} disabled={this.state.loading}
                                tabIndex={this.props.visible ? 0 : -1}>Login</Button>
                        <div className="ForgotPassword">
                            <Link to={"/password-reset/submit"}
                                  tabIndex={this.props.visible ? 0 : -1}>Forgot your password?</Link>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    renderMfa() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"LoginFormParent"}>
                    <div>
                        Please enter the 6-digit code from your authenticator app:
                    </div>
                    <div className={styles.MfaInput}>
                        <MfaInput ref={this.totpRef}/>
                    </div>
                    <div className="FormField SubmitRegion">
                        <Button form={true} color={ButtonColor.Primary} disabled={this.state.loading}
                                tabIndex={this.props.visible ? 0 : -1}>Login</Button>
                        <div className="ForgotPassword">
                            <Link to={"#"} onClick={() => this.setState({recovery: true})}
                                  tabIndex={this.props.visible ? 0 : -1}>Use a recovery code instead</Link>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    renderRecovery() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"LoginFormParent"}>
                    <div>
                        Enter one of your 10 recovery codes. After you login, you should disable 2FA and set it up again
                        with your new device. Each code is only usable once.
                    </div>
                    <div className={styles.MfaInput}>
                        <label htmlFor={"Recovery"}>
                            Recovery Code
                        </label>
                        <input name={"Recovery"} ref={this.recoveryRef} type={"text"} autoComplete={"off"}
                               tabIndex={this.props.visible ? 0 : -1} required={true}/>
                    </div>
                    <div className="FormField SubmitRegion">
                        <Button form={true} color={ButtonColor.Primary} disabled={this.state.loading}
                                tabIndex={this.props.visible ? 0 : -1}>Login</Button>
                        <div className="ForgotPassword">
                            <Link to={"#"} onClick={() => this.setState({recovery: false})}
                                  tabIndex={this.props.visible ? 0 : -1}>Use a 6-digit code instead</Link>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        this.props.setAuthFormsError(null);

        if (this.totpRef.current && !this.totpRef.current.valid) {
            return;
        }

        this.setState({loading: true});
        const totp_code = this.totpRef.current?.value || this.recoveryRef.current?.value;

        login(this.state.username.trim(), this.state.password, totp_code)
            .then(() => getCurrentUser())
            .then(data => {
                this.props.loadUserData(data);
            })
            .catch(e => {
                if (e.message === "totp.missing") {
                    this.setState({loading: false, mfa: true});
                    this.props.setAuthFormsError(null);
                } else {
                    this.setState({loading: false});
                    console.error("Login failed:", e);
                    this.props.setAuthFormsError(e);
                    if (this.totpRef.current) {
                        this.totpRef.current.reset();
                    }
                }
            })
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        if (target.name === "Username") {
            this.setState({username: target.value});
        } else if (target.name === "Password") {
            this.setState({password: target.value});
        }
    }
}
