import React from "react";
import CardModal from "../CardModal/CardModal";
import {UserState} from "../../../Redux/Types";
import ProfilePictureForm from "../ProfilePictureForm/ProfilePictureForm";
import {EventEmitter} from "events";

interface Props {
    user: UserState | null;
    open: boolean;
    reload$: EventEmitter;
}

interface Actions {
    toggleProfilePictureForm: (open: boolean) => void;
}

export default class ProfilePictureModal extends React.Component<Props & Actions> {
    render() {
        return (
            <CardModal isOpen={this.props.open}
                       contentLabel={"Change Picture"}
                       centered={true}
                       onRequestClose={() => this.actions.toggleProfilePictureForm(false)}
                       title={"Change Picture"}>
                <ProfilePictureForm user={this.props.user}
                                    closeFunction={() => this.actions.toggleProfilePictureForm(false)}
                                    reloadProfileFunction={() => this.reloadProfile()}/>
            </CardModal>
        )
    }

    reloadProfile() {
        this.props.reload$.emit("reload");
    }

    get actions(): Actions {
        return this.props as Actions;
    }
}
