import React from "react";
import styles from "./UserBlockedList.module.scss";
import Card, {CardElevation} from "../Card/Card";
import {GetBlockedUsersResponse} from "../../../API/Models";
import {getBlockedUsers, unblockUser} from "../../../API/Calls";
import UserFriendBio from "../UserFriendBio/UserFriendBio";
import Button, {ButtonSize} from "../Button/Button";
import {titleManager} from "../../../Title";

interface Props {
}

interface State {
    abort: AbortController;
    response?: GetBlockedUsersResponse;
    unblocked: Set<string>;
    loading: Set<string>;
}

export default class UserBlockedList extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            abort: new AbortController(),
            response: undefined,
            unblocked: new Set<string>(),
            loading: new Set<string>()
        }
    }

    render() {
        return (
            <div>
                <div className={styles.TitleBar}>
                    <div className={styles.Title}>Blocked Users</div>
                    <div className={styles.TitleRight}>
                    </div>
                </div>
                <Card className={this.getClasses()} invisible={this.isMobile()} elevation={CardElevation.Sheet}>
                    <div className={styles.Content}>
                        {this.renderList()}
                    </div>
                </Card>
            </div>
        )
    }

    renderList(): React.ReactNode {
        if (!this.state.response) {
            return <div className={styles.Skeleton}/>
        }
        if (this.state.response.length === 0) {
            return <div className={styles.NoRequests}>You do not have any blocked users</div>
        }
        return (
            <div className={styles.Grid}>
                {this.state.response.sort((a, b) => a.username.localeCompare(b.username))
                    .map(user =>
                        <div className={styles.User} key={user.id}>
                            <UserFriendBio user={user} className={styles.UserInfo}/>
                            <div className={styles.UserActions}>
                                {
                                    this.state.unblocked.has(user.id) ?
                                        <div className={styles.ActionDisabled}>Unblocked</div>
                                        :
                                        <div className={styles.ActionButtons}>
                                            <Button size={ButtonSize.Small}
                                                    onClick={() => this.unblock(user.id)}
                                                    disabled={this.state.loading.has(user.id)}>Unblock</Button>
                                        </div>
                                }
                            </div>
                        </div>)}
            </div>
        )
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Blocked Users");
        getBlockedUsers(this.state.abort)
            .then(response => this.setState({response}))
            .catch(console.error);
    }

    componentWillUnmount(): void {
        this.state.abort.abort();
    }

    unblock(userId: string) {
        this.state.loading.add(userId);
        unblockUser(userId, this.state.abort)
            .then(() => {
                this.state.unblocked.add(userId);
                this.forceUpdate();
            })
            .catch(console.error);
    }

    getClasses() {
        if (this.isMobile()) {
            return "";
        } else {
            return styles.ListCard;
        }
    }

    isMobile() {
        return window.innerWidth < 768;
    }
}
