import {Months} from "../Months";

export function beginningOfDay(): number {
    const currentDate = new Date();
    const sinceDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    return sinceDate.getTime();
}

export function daysAgo(days: number): number {
    return new Date().getTime() - 24 * 3600 * 1000 * days;
}

export function formatDay(date: Date): string {
    return date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate());
}

export function formatDayEnglish(date: Date): string {
    return `${formatWeekDayLong(date)}, ${formatMonthLong(date)} ${date.getDate()} ${date.getFullYear()}`
}

export function formatWeekDayLong(date: Date) {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][date.getDay()];
}

export function formatMonthLong(date: Date) {
    return Months[date.getMonth()];
}

function pad(n: number) {
    const nStr = n + '';
    return nStr.length >= 2 ? n : new Array(2 - nStr.length + 1).join("0") + n;
}
