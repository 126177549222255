import React from "react";
import {UserState} from "../../../Redux/Types";
import {Link} from "react-router-dom";
import styles from "./UserMenu.module.scss";
import {countPendingFriendRequests, deleteToken} from "../../../API/Calls";
import UserMenuIcon, {Icons} from "./UserMenuIcon";
import {isDarkMode, setDarkMode} from "../../../DarkMode";
import {cssVar} from "../../../variables";

interface Props {
    user: UserState;
    toggleRef: React.RefObject<HTMLElement>;
    incomingRequestsCount?: number;

    closeUserMenu: () => void;
    logout: () => void;
    setIncomingRequests: (count: number) => void;
}

export default class UserMenu extends React.Component<Props> {
    private menuContainer: React.RefObject<HTMLDivElement> = React.createRef();
    private abort: AbortController = new AbortController();

    constructor(props: Readonly<Props>) {
        super(props);

        this.menuContainer = React.createRef();

        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleNavigate = this.handleNavigate.bind(this);
        this.toggleDarkMode = this.toggleDarkMode.bind(this);
    }

    handlePageClick(e: MouseEvent) {
        if (e.type === "mousedown" && this.menuContainer.current != null && !this.menuContainer.current.contains(e.target as Node)
            && this.props.toggleRef.current != null && !this.props.toggleRef.current.contains(e.target as Node)) {
            this.props.closeUserMenu();
        }
    }

    handleNavigate() {
        this.props.closeUserMenu();
    }

    handleLogout() {
        this.props.closeUserMenu();
        this.props.logout();
        deleteToken()
            .then(() => window.location.href = "/")
            .catch(err => {
                console.error("Failed to purge token", err);
                window.location.href = "/";
            });
    }

    render() {
        return (
            <div className={styles.UserMenu} ref={this.menuContainer}>
                <Link className={styles.Item} to={"/user/" + this.props.user.username}
                      onClick={this.handleNavigate}>
                    <UserMenuIcon icon={Icons.Profile} className={styles.IconProfile}/>
                    Profile</Link>
                <Link className={styles.Item} to={"/user/" + this.props.user.username + "/friends"}
                      onClick={this.handleNavigate}>
                    <UserMenuIcon icon={Icons.Friends} className={styles.IconFriends}/>
                    Friends
                    {
                        this.props.incomingRequestsCount === undefined || this.props.incomingRequestsCount === 0 ? "" :
                            <span className={styles.FriendRequests}>{this.props.incomingRequestsCount}</span>
                    }
                </Link>
                <Link className={styles.Item} to={"/settings/account"}
                      onClick={this.handleNavigate}>
                    <UserMenuIcon icon={Icons.Settings} className={styles.IconSettings}/>
                    Settings</Link>
                <div className={styles.Item}
                     onClick={this.toggleDarkMode}>
                    <UserMenuIcon icon={Icons.DarkMode} className={styles.IconDarkMode}/>
                    {isDarkMode() ? 'Light' : 'Dark'} Mode
                </div>
                <Link className={[styles.Item, styles.Logout].join(" ")} to={"#"}
                      onClick={this.handleLogout}>
                    <UserMenuIcon icon={Icons.Logout} className={styles.IconLogout} fill={cssVar("--accent-pink")}/>
                    Logout</Link>
            </div>
        )
    }

    componentDidMount(): void {
        window.addEventListener("mousedown", this.handlePageClick);
        countPendingFriendRequests(this.abort)
            .then(resp => {
                this.props.setIncomingRequests(resp.incoming);
            })
            .catch(console.error);
    }

    componentWillUnmount(): void {
        window.addEventListener("mousedown", this.handlePageClick);
        this.abort.abort();
    }

    toggleDarkMode() {
        setDarkMode(!isDarkMode());
        this.props.closeUserMenu();
    }
}
