import React from "react";
import {titleManager} from "../../../Title";
import styles from "./AboutCodeOfConduct.module.scss";
import ReactMarkdown from "react-markdown";

interface State {
    source?: string;
    error?: boolean;
}

export default class AboutCodeOfConduct extends React.Component<{}, State> {

    constructor(props: Readonly<{}>) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className={styles.Container}>
                <div className={styles.Preface}>
                    The <i>Code of Conduct</i> is a pledge to make our community open, inclusive, and a positive
                    environment for everyone. You can help edit it on
                    our <a href={"https://github.com/wavy/community/blob/master/CodeOfConduct.md"}>GitHub Community
                    project</a>.
                </div>
                {
                    this.state.source ? <ReactMarkdown className={styles.Content} children={this.state.source}/> : ""
                }
                {
                    this.state.error ? <p>
                        Looks like there was an error getting this document. You can view it on
                        GitHub using the link above.
                    </p> : ""
                }
            </div>
        )
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        titleManager.set("Code of Conduct")

        fetch("https://raw.githubusercontent.com/wavy/community/master/CodeOfConduct.md")
            .then(resp => resp.text())
            .then(source => this.setState({source}))
            .catch(err => {
                console.error(err);
                this.setState({error: true});
            });
    }
}
