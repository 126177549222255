// Application State

import {EventEmitter} from "events";
import {
    Comment,
    Friendship,
    GetProfileListensResponse,
    Notification,
    ProfileCommentsPrivacy,
    ProfileDateRangeDefaults
} from "../API/Models";

export function isOwnProfile(state: AppState) {
    return state.profileReady && state.user !== null && state.profilePage?.profile?.username === state.user.username;
}

export interface AppState {
    profileReady: boolean,
    user: UserState | null;
    authForms: AuthFormsState;
    menus: MenuState;
    accountSettings: AccountSettingsState;
    settingsLoading: boolean;
    isGlobalLoading: boolean;
    incomingRequestsCount: number | undefined;

    notifications: NotificationsState;

    trackingDelayedSince?: Date;
    trackingDelayedDismissed?: number;

    focusedComment?: {
        commentUser: ForeignUser;
        replyId?: string;
        profileId: string,
        commentId: string;
    }

    commentReportOpen: boolean;

    profilePage: {
        profile: ForeignUser | null;
        editing: boolean;
        profilePictureFormOpen: boolean;
        recentListens: GetProfileListensResponse | null;
        recentListensLoading: boolean;
        reload$: EventEmitter;
        friendship: Friendship | null;
        totalFriends: number | null;
        reportFormOpen: boolean;
        unfriendConfirmOpen: boolean;
        blockConfirmOpen: boolean;
    }
}

export interface MenuState {
    user: boolean;
    search: boolean;
    notifications: boolean;
}

export interface UserState {
    username: string;
    id: string;
    email: string;
    emailVerified: boolean;
    joinTime: Date,

    bio: string,
    countryCode: string,
    countryName: string | null,
    instagram: string | null,
    twitter: string | null,
    admin: boolean;

    spotifyId: string | null;
    spotifyDisplayName: string | null;

    discordId: string | null;
    discordDisplayName: string | null;

    dateRangeDefaults?: ProfileDateRangeDefaults;

    privacySettings: {
        privateProfile: boolean;
        publicSpotify: boolean;
        publicDiscord: boolean;
        profileComments: ProfileCommentsPrivacy;
    },

    moderation: {
        disallowAvatar: boolean;
        disallowComments: boolean;
    },

    $access: {
        view: boolean;
        blocked: boolean;
        addFriend: boolean;
    },

    lastUsernameChange: Date | null,

    $avatar_lease?: string;
    $mfa_enabled?: boolean;
}

export interface ForeignUser {
    username: string,
    id: string,
    joinTime: Date,
    bio: string,
    countryCode: string,
    countryName?: string,
    admin: boolean;
    supporter: boolean;
    lastActivity?: Date;
    twitter?: string;
    instagram?: string;

    spotifyId?: string;
    spotifyDisplayName?: string;

    discordId?: string;
    discordDisplayName?: string;

    dateRangeDefaults?: ProfileDateRangeDefaults;

    $access: {
        view: boolean;
        blocked: boolean;
        blockedByThem: boolean;
        blockedByYou: boolean;
        viewSpotify: boolean;
        viewDiscord: boolean;
        friends: boolean;
        quarantined: boolean;
        profileComments: ProfileCommentsPrivacy;
    }

    $avatar_lease?: string;
}

export interface AuthFormsState {
    error: Error | null;
}

export interface AccountSettingsState {
    mfaRecoveryModal: boolean;
    mfaDisableModal: boolean;
    mfaSetupModal: boolean;
    emailModal: boolean,
    passwordModal: boolean,
    usernameModal: boolean,
    deleteModal: boolean,
}

export interface NotificationsState {
    unread: number;
    notifications: Notification<any>[];
    hasMore: boolean;
    loading: boolean;
}

export interface RenderedComment {
    user: ForeignUser;
    comment: Comment;
    replies: RenderedComment[];
    replyingTo?: ForeignUser;
}

// Actions

export const LOAD_USER_DATA = "LOAD_USER_DATA";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_PRIVACY = "SET_USER_PRIVACY";
export const SET_AUTH_FORMS_ERROR = "SET_AUTH_FORMS_ERROR";
export const OPEN_USER_MENU = "OPEN_USER_MENU";
export const CLOSE_USER_MENU = "CLOSE_USER_MENU";
export const LOG_OUT = "LOG_OUT";
export const TOGGLE_EDIT_PASSWORD_MODAL = "TOGGLE_EDIT_PASSWORD_MODAL";
export const TOGGLE_EDIT_EMAIL_MODAL = "TOGGLE_EDIT_EMAIL_MODAL";
export const TOGGLE_EDIT_USERNAME_MODAL = "TOGGLE_EDIT_USERNAME_MODAL";
export const TOGGLE_DELETE_MODAL = "TOGGLE_DELETE_MODAL";
export const TOGGLE_MFA_SETUP_MODAL = "TOGGLE_MFA_SETUP_MODAL";
export const TOGGLE_MFA_DISABLE_MODAL = "TOGGLE_MFA_DISABLE_MODAL";
export const TOGGLE_MFA_RECOVERY_MODAL = "TOGGLE_MFA_RECOVERY_MODAL";
export const TOGGLE_SETTINGS_LOADING = "TOGGLE_SETTINGS_LOADING";
export const TOGGLE_GLOBAL_LOADING = "TOGGLE_GLOBAL_LOADING";
export const OPEN_SEARCH_MENU = "OPEN_SEARCH_MENU";
export const CLOSE_SEARCH_MENU = "CLOSE_SEARCH_MENU";
export const SET_INCOMING_REQUESTS = "SET_INCOMING_REQUESTS";
export const LOAD_PROFILE = "LOAD_PROFILE";
export const TOGGLE_PROFILE_EDITING = "TOGGLE_PROFILE_EDITING";
export const TOGGLE_PROFILE_PICTURE_FORM = "TOGGLE_PROFILE_PICTURE_FORM";
export const LOAD_PROFILE_RECENT_LISTENS = "LOAD_PROFILE_RECENT_LISTENS";
export const SET_PROFILE_RECENT_LISTENS_LOADING = "SET_PROFILE_RECENT_LISTENS_LOADING";
export const LOAD_PROFILE_FRIEND_COUNT = "LOAD_PROFILE_FRIEND_COUNT";
export const UPDATE_PROFILE_FRIENDSHIP = "UPDATE_PROFILE_FRIENDSHIP";
export const TOGGLE_PROFILE_REPORT_FORM = "TOGGLE_PROFILE_REPORT_FORM";
export const TOGGLE_PROFILE_UNFRIEND_CONFIRM = "TOGGLE_PROFILE_UNFRIEND_CONFIRM";
export const TOGGLE_PROFILE_BLOCK_CONFIRM = "TOGGLE_PROFILE_BLOCK_CONFIRM";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const TOGGLE_NOTIFICATIONS_MENU = "TOGGLE_NOTIFICATIONS_MENU";
export const OPEN_COMMENT_REPORT_FORM = "OPEN_COMMENT_REPORT_FORM";
export const CLOSE_COMMENT_REPORT_FORM = "CLOSE_COMMENT_REPORT_FORM";
export const SET_TRACKING_DELAYED_SINCE = "SET_TRACKING_DELAYED_SINCE";
export const DISMISS_TRACKING_DELAYED = "DISMISS_TRACKING_DELAYED";

export interface LoadUserDataAction {
    type: typeof LOAD_USER_DATA,
    data: any
}

export interface SetUserEmail {
    type: typeof SET_USER_EMAIL,
    email: string,
}

export interface SetUserPrivacy {
    type: typeof SET_USER_PRIVACY,
    privacy: object,
}

export interface SetAuthFormsError {
    type: typeof SET_AUTH_FORMS_ERROR,
    error: Error | null
}

export interface OpenUserMenu {
    type: typeof OPEN_USER_MENU;
}

export interface CloseUserMenu {
    type: typeof CLOSE_USER_MENU;
}

export interface Logout {
    type: typeof LOG_OUT;
}

export interface ToggleEditPasswordModal {
    type: typeof TOGGLE_EDIT_PASSWORD_MODAL,
    visible: boolean;
}

export interface ToggleEditEmailModal {
    type: typeof TOGGLE_EDIT_EMAIL_MODAL,
    visible: boolean;
}

export interface ToggleEditUsernameModal {
    type: typeof TOGGLE_EDIT_USERNAME_MODAL,
    visible: boolean;
}

export interface ToggleDeleteModal {
    type: typeof TOGGLE_DELETE_MODAL,
    visible: boolean;
}

export interface ToggleMfaSetupModal {
    type: typeof TOGGLE_MFA_SETUP_MODAL,
    visible: boolean;
}

export interface ToggleMfaDisableModal {
    type: typeof TOGGLE_MFA_DISABLE_MODAL,
    visible: boolean;
}

export interface ToggleMfaRecoveryModal {
    type: typeof TOGGLE_MFA_RECOVERY_MODAL,
    visible: boolean;
}

export interface ToggleSettingsLoading {
    type: typeof TOGGLE_SETTINGS_LOADING,
    loading: boolean,
}

export interface ToggleGlobalLoading {
    type: typeof TOGGLE_GLOBAL_LOADING,
    loading: boolean,
}

export interface OpenSearchMenu {
    type: typeof OPEN_SEARCH_MENU;
}

export interface CloseSearchMenu {
    type: typeof CLOSE_SEARCH_MENU;
}

export interface SetIncomingRequests {
    type: typeof SET_INCOMING_REQUESTS;
    count: number;
}

export interface LoadProfile {
    type: typeof LOAD_PROFILE;
    profile: ForeignUser | null;
}

export interface ToggleProfileEditing {
    type: typeof TOGGLE_PROFILE_EDITING;
    editing: boolean;
}

export interface ToggleProfilePictureForm {
    type: typeof TOGGLE_PROFILE_PICTURE_FORM;
    open: boolean;
}

export interface LoadProfileRecentListens {
    type: typeof LOAD_PROFILE_RECENT_LISTENS;
    listens: GetProfileListensResponse | null;
}

export interface LoadProfileFriendCount {
    type: typeof LOAD_PROFILE_FRIEND_COUNT;
    count: number | null;
}

export interface UpdateProfileFriendship {
    type: typeof UPDATE_PROFILE_FRIENDSHIP;
    friendship: Friendship | null;
}

export interface ToggleProfileReportForm {
    type: typeof TOGGLE_PROFILE_REPORT_FORM;
    open: boolean;
}

export interface ToggleProfileUnfriendConfirm {
    type: typeof TOGGLE_PROFILE_UNFRIEND_CONFIRM;
    open: boolean;
}

export interface ToggleProfileBlockConfirm {
    type: typeof TOGGLE_PROFILE_BLOCK_CONFIRM;
    open: boolean;
}

export interface SetNotifications {
    type: typeof SET_NOTIFICATIONS;
    notifications: Partial<NotificationsState>;
}

export interface ToggleNotificationsMenu {
    type: typeof TOGGLE_NOTIFICATIONS_MENU;
    open: boolean;
}

export interface OpenCommentReportForm {
    type: typeof OPEN_COMMENT_REPORT_FORM;
    commentUser: ForeignUser;
    profileId: string;
    commentId: string;
    replyId?: string;
}

export interface CloseCommentReportForm {
    type: typeof CLOSE_COMMENT_REPORT_FORM;
}

export interface SetTrackingDelayedSince {
    type: typeof SET_TRACKING_DELAYED_SINCE;
    since?: Date;
}

export interface DismissTrackingDelayed {
    type: typeof DISMISS_TRACKING_DELAYED;
}

export interface SetProfileRecentListensLoading {
    type: typeof SET_PROFILE_RECENT_LISTENS_LOADING;
}

export type ActionTypes =
    LoadUserDataAction |
    SetUserEmail |
    SetUserPrivacy |
    SetAuthFormsError |
    OpenUserMenu |
    CloseUserMenu |
    Logout |
    ToggleEditPasswordModal |
    ToggleEditEmailModal |
    ToggleDeleteModal |
    ToggleMfaSetupModal |
    ToggleMfaDisableModal |
    ToggleMfaRecoveryModal |
    ToggleSettingsLoading |
    ToggleGlobalLoading |
    OpenSearchMenu |
    CloseSearchMenu |
    SetIncomingRequests |
    LoadProfile |
    ToggleProfileEditing |
    ToggleProfilePictureForm |
    LoadProfileRecentListens |
    LoadProfileFriendCount |
    UpdateProfileFriendship |
    ToggleProfileReportForm |
    ToggleProfileUnfriendConfirm |
    ToggleProfileBlockConfirm |
    SetNotifications |
    ToggleNotificationsMenu |
    OpenCommentReportForm |
    CloseCommentReportForm |
    SetTrackingDelayedSince |
    DismissTrackingDelayed |
    SetProfileRecentListensLoading |
    ToggleEditUsernameModal;
