import React from "react";
import {ForeignUser} from "../../../Redux/Types";
import {GetProfileArtistsResponse, ProfileDateRangeDefaultKey, SpotifyArtist} from "../../../API/Models";
import ProfileGrid, {Entry} from "./ProfileGrid";
import DefaultArtist from "../../../Images/DefaultArtist.svg";
import {getProfileArtists, setDefaultDateRange} from "../../../API/Calls";
import styles from "./ProfileGrid.module.scss";
import ProfileDateRange, {
    convertDateRange,
    rangeFromDefaultValue,
    Ranges,
    rangeToDefaultValue
} from "../ProfileDateRange/ProfileDateRange";
import {EventEmitter} from "events";
import ArtistSubject from "../MusicSubject/ArtistSubject";
import {spotifyUriToId} from "../../../SpotifyUtil";
import {OPEN_IN_SPOTIFY} from "../MusicActionPopup/MusicAction";

interface Props {
    isOwnProfile: boolean;
    user: ForeignUser;
    focusedEntry?: Entry;
    onHover: (entry?: Entry) => void;
    reload$: EventEmitter;
}

interface State {
    /**
     * Response with artist history info
     */
    artistsResponse: GetProfileArtistsResponse | null;
    loading: boolean;
    dateRange: Ranges;
    defaultDateRange: Ranges;
}

export default class ProfileArtistsGrid extends React.Component<Props, State> {

    private abort = new AbortController();

    constructor(props: Readonly<Props>) {
        super(props);

        this.changeDateRange = this.changeDateRange.bind(this);
        this.saveAsDefault = this.saveAsDefault.bind(this);

        const defaultDateRange = rangeFromDefaultValue(props.user.dateRangeDefaults?.[ProfileDateRangeDefaultKey.Artists]);
        this.state = {
            artistsResponse: null,
            loading: false,
            dateRange: defaultDateRange,
            defaultDateRange: defaultDateRange,
        }

        this.props.reload$.on("reload_listens", () => this.load());
    }

    render() {
        return (
            <div className={styles.GridHistoryParent}>
                <div className={styles.GridHistoryTitle}>
                    {this.renderTitle()}
                </div>
                <ProfileGrid loading={this.state.loading} onHover={this.props.onHover}
                             focusedEntry={this.props.focusedEntry} entries={this.getEntries()}
                             moreLink={this.moreLink}
                             actionGenerator={entry => [
                                 OPEN_IN_SPOTIFY(entry.playerLink),
                             ]}
                />
            </div>
        )
    }

    get moreLink(): string {
        let path = "/user/" + this.props.user.username + "/history/artists";
        switch (this.state.dateRange) {
            case Ranges.Days7:
                path += "?range=7d";
                break;
            case Ranges.Days30:
                path += "?range=30d";
                break;
            case Ranges.Days90:
                path += "?range=90d";
                break;
            case Ranges.Days365:
                path += "?range=365d";
                break;
        }
        return path;
    }

    changeDateRange(dateRange: Ranges) {
        this.abort.abort();
        this.abort = new AbortController();

        const cached = dateRange === Ranges.AllTime ? this.getCachedArtists() : null;
        const since = convertDateRange(dateRange);

        this.setState({dateRange, loading: true, artistsResponse: this.state.artistsResponse || cached}, () => {
            getProfileArtists(this.props.user.id, 9, 0, since, 0, undefined, this.abort)
                .then(artistsResponse => {
                    this.setState({artistsResponse, loading: false});
                    if (dateRange === Ranges.AllTime) {
                        this.saveCachedArtists(artistsResponse);
                    }
                })
                .catch(console.error);
        });
    }

    componentDidMount(): void {
        this.load();
    }

    load() {
        this.changeDateRange(this.state.dateRange);
    }

    componentWillUnmount() {
        this.abort.abort();
    }

    getEntries(): Entry[] | undefined {
        if (!this.state.artistsResponse) {
            return undefined;
        }
        return this.state.artistsResponse.artists.map(artist => {
            const image = this.getArtistImage(artist.artist);
            const url = `https://open.spotify.com/artist/${spotifyUriToId(artist.artist.uri)}`;
            return {
                key: artist.artist.uri,
                icon: image,
                name: artist.artist.name,
                plays: artist.count,
                actionSubject: () => <ArtistSubject name={artist.artist.name} image={image}/>,
                playerLink: url,
            }
        })
    }

    getArtistImage(artist: SpotifyArtist): string {
        if (artist.images && artist.images.length > 0) {
            return artist.images
                .sort((a, b) => b.width - a.width)[0].url;
        } else {
            return DefaultArtist;
        }
    }

    getCachedArtists(): GetProfileArtistsResponse | null {
        const key = "wavy.cache.artists." + this.props.user.id;
        const value = window.localStorage.getItem(key);
        if (value === null) {
            return null;
        }
        try {
            return JSON.parse(value) as GetProfileArtistsResponse;
        } catch (e) {
            console.error("Failed to read from cache", e);
            return null;
        }
    }

    saveCachedArtists(artists: GetProfileArtistsResponse) {
        const key = "wavy.cache.artists." + this.props.user.id;
        try {
            window.localStorage.setItem(key, JSON.stringify(artists));
        } catch (e) {
            console.error("Failed to save cache", e);
        }
    }

    renderTitle() {
        if (this.props.focusedEntry) {
            return ""; // Hide
        } else {
            return (
                <>
                    <span>Top Artists</span>
                    <div className={styles.LeftSide}>
                        {
                            !this.props.isOwnProfile || this.state.defaultDateRange === this.state.dateRange ? "" :
                                <span className={styles.SaveAsDefault}
                                      onClick={this.saveAsDefault}>(Set as default)</span>
                        }
                        <ProfileDateRange selected={this.state.dateRange} onChange={this.changeDateRange}/>
                    </div>
                </>
            )
        }
    }

    saveAsDefault() {
        if (this.props.isOwnProfile) {
            setDefaultDateRange(ProfileDateRangeDefaultKey.Artists, rangeToDefaultValue(this.state.dateRange))
                .then()
                .catch(console.error);
            this.setState({defaultDateRange: this.state.dateRange});
        }
    }
}
