import {AppState, isOwnProfile} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ProfileListenHighlights from "../Presentation/ProfileListenHighlights/ProfileListenHighlights";
import {createLiveWavyTrack, WavyTrackPlay} from "../../API/Models";

function mapStateToProps(state: AppState) {
    const publicProfile = state.profilePage.profile?.$access.view ?? true;

    let lastSong: WavyTrackPlay | null;
    if (state.profilePage.recentListens?.live) {
        lastSong = createLiveWavyTrack(state.profilePage.recentListens.live);
    } else {
        lastSong = state.profilePage.recentListens?.tracks[0] ?? null
    }

    return {
        hasLoaded: state.profileReady && (state.profilePage.recentListens !== null || !publicProfile),
        totalSongs: state.profilePage.recentListens?.total_tracks ?? 0,
        lastSong,
        totalArtists: state.profilePage.recentListens?.total_artists ?? 0,
        isOwnProfile: isOwnProfile(state),
        username: state.profilePage.profile?.username ?? "",
        totalFriends: state.profilePage.totalFriends,
        privateProfile: !publicProfile,
        blockedByThem: state.profilePage.profile?.$access?.blockedByThem ?? false,
        blockedByYou: state.profilePage.profile?.$access?.blockedByYou ?? false,
        quarantined: !publicProfile && (state.profilePage.profile?.$access?.quarantined ?? false)
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileListenHighlights);
