import "./Settings.scss";
import React from "react";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import AccountSettingsContainer from "../../Containers/AccountSettingsContainer";
import ConnectionSettingsContainer from "../../Containers/ConnectionSettingsContainer";
import {BarLoader} from "react-spinners";
import Card from "../Card/Card";
import PrivacySettingsContainer from "../../Containers/PrivacySettingsContainer";

interface SettingsProps {
    loading: boolean;
    authProfileReady: boolean;
}

export default class Settings extends React.Component<SettingsProps> {
    render() {
        if (!this.props.authProfileReady) {
            return "";
        }
        return (
            <div className={"Container SettingsPage"}>
                <div className={"Navigation"}>
                    <NavLink to={"/settings/account"} activeClassName={"Selected"}>Account</NavLink>
                    <NavLink to={"/settings/connections"} activeClassName={"Selected"}>Connections</NavLink>
                    <NavLink to={"/settings/privacy"} activeClassName={"Selected"}>Privacy</NavLink>
                    {/*<NavLink to={"/settings/notifications"} activeClassName={"Selected"}>Notifications</NavLink>*/}
                </div>
                <Card className={"SettingsCard"}>
                    {!this.props.loading ? "" :
                        <div className={"BarLoader"}><BarLoader width={"100%"} color={"#46C3D3"}/></div>}
                    <div className={"SubPage"}>
                        <Switch>
                            <Route path="/settings/account" exact={true}>
                                <AccountSettingsContainer/>
                            </Route>
                            <Route path="/settings/connections" exact={true}>
                                <ConnectionSettingsContainer/>
                            </Route>
                            <Route path="/settings/privacy" exact={true}>
                                <PrivacySettingsContainer/>
                            </Route>
                            {/* Redirect to Account Settings as fallback*/}
                            <Route component={() => <Redirect to={"/settings/account"}/>}/>
                        </Switch>
                    </div>
                </Card>
            </div>
        )
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
    }
}
