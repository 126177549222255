import React from "react";
import {UserState} from "../../../../Redux/Types";
import {changeUsername} from "../../../../API/Calls";
import Button, {ButtonColor} from "../../Button/Button";

interface Props {
    user: UserState | null
    closeFunction: (success: boolean) => void;
}

interface State {
    username: string,
    password: string,
    loading: boolean,
    error: Error | null,
}

export default class Username extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            password: "",
            username: "",
            loading: false,
            error: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {
                    this.state.error ? <div className={"AuthError"}>{this.state.error.toString()}</div> : ""
                }
                <div className={"NewUsernameForm"}>
                    <p className={"NewUsernameWarning"}>
                        Note: Anyone will be free to take your previous username. You won't be able to use
                        your previous username to login anymore. Usernames must be between 3 and 12 characters long.
                    </p>
                    <div className={"FormField"}>
                        <label htmlFor={"Username"}>
                            New Username
                        </label>
                        <input required={true} name={"Username"} type={"text"} value={this.state.username}
                               autoComplete={"username"}
                               onChange={this.handleChange}/>
                    </div>
                    <div className={"FormField"}>
                        <label htmlFor={"Password"}>
                            Password
                        </label>
                        <input required={true} name={"Password"} type={"password"}
                               value={this.state.password}
                               autoComplete={"current-password"}
                               onChange={this.handleChange}/>
                    </div>
                    <div className="FormField SubmitRegion">
                        <Button color={ButtonColor.Regular}
                                onClick={() => this.props.closeFunction(false)}>Cancel</Button>
                        <Button form={true} color={ButtonColor.Primary} disabled={this.state.loading}>Save</Button>
                    </div>
                </div>
            </form>
        )
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        if (target.name === "Password") {
            this.setState({password: target.value});
        } else if (target.name === "Username") {
            this.setState({username: target.value});
        }
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        this.setState({loading: true, error: null});
        changeUsername(this.state.username, this.state.password)
            .then(resp => {
                this.props.closeFunction(true);
            })
            .catch(e => {
                this.setState({loading: false, error: e});
                console.error(e);
            })
    }
}
