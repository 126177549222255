import React from "react";
import styles from "./CommentLikesModal.module.scss";
import {ForeignUser, RenderedComment} from "../../../Redux/Types";
import CommentViewIcon, {Icons} from "../CommentView/CommentViewIcon";
import {getProfilesById} from "../../../API/Calls";
import UserFriendBio from "../UserFriendBio/UserFriendBio";

interface Props {
    comment: RenderedComment;
}

interface State {
    users?: ForeignUser[];
}

export default class CommentLikesModal extends React.Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className={styles.Parent}>
                <div className={styles.TitleSection}>
                    <CommentViewIcon icon={Icons.Liked} className={styles.Icon}/>
                    <div className={styles.Amount}>{this.props.comment.comment.likes.length}</div>
                </div>
                <div className={styles.List}>
                    {this.renderList()}
                </div>
            </div>
        )
    }

    renderList() {
        if (!this.state.users) {
            return (
                <div className={styles.LoadingItem}>
                    Loading...
                </div>
            )
        }
        return this.state.users.map(this.renderUser);
    }

    renderUser(user: ForeignUser) {
        return (
            <div key={user.id} className={styles.Item}>
                <UserFriendBio user={user}/>
            </div>
        )
    }

    componentDidMount() {
        getProfilesById(...this.props.comment.comment.likes)
            .then(users => {
                this.setState({users: users.sort((a, b) => a.username.localeCompare(b.username))})
            })
            .catch(console.error)
    }
}
