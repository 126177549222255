import React, {CSSProperties, DOMAttributes} from "react";
import DefaultAvatar from "../../../Images/Avatar/Default.svg";
import styles from "./Avatar.module.scss";
import {ForeignUser, UserState} from "../../../Redux/Types";
import {_getInternal} from "../../../API/Calls";

interface Props {
    user: UserState | ForeignUser | undefined,
    size?: string,
    editable?: boolean,
    rounder?: boolean,
    fullSize?: boolean;
    disabled?: boolean,

    className?: string,
    dom?: DOMAttributes<HTMLDivElement>
}

export default class Avatar extends React.Component<Props> {
    render() {
        return (
            <div className={this.getClasses()} style={this.getStyle()} {...this.props.dom}/>
        )
    }

    static getUrl(user: UserState | ForeignUser | undefined, small: boolean = true): string {
        if (user?.$avatar_lease) {
            const url = `${_getInternal("/avatar/")}${user.id}.jpg?small=${small || false}&lease=${encodeURIComponent(user.$avatar_lease)}`;
            return `url(${url})`;
        } else {
            return "url(" + DefaultAvatar + ")";
        }
    }

    getStyle(): CSSProperties {
        const size = this.props.size || "80px";

        return {
            backgroundImage: Avatar.getUrl(this.props.user, !this.props.fullSize),
            height: size,
            width: size,
            maxWidth: size,
            minWidth: size
        }
    }

    getClasses(): string {
        const classes = [styles.Avatar];

        if (this.props.editable) {
            classes.push(styles.Editable);
        }

        if (this.props.rounder) {
            classes.push(styles.Rounder);
        }

        if (this.props.disabled) {
            classes.push(styles.Disabled);
        }

        if (this.props.className) {
            classes.push(this.props.className);
        }

        return classes.join(" ");
    }
}
