import React from "react";
import styles from "./ProfileReportForm.module.scss";
import {ReportReason} from "../../../API/Models";
import Button, {ButtonColor} from "../Button/Button";
import {submitReport} from "../../../API/Calls";
import CardModal from "../CardModal/CardModal";

interface Props {
    username: string;
    userId: string;
    isOpen: boolean;
}

interface Actions {
    toggleProfileReportForm: (open: boolean) => void;
}

interface State {
    loading: boolean;
    submitted: boolean;
    signal: AbortController;
}

export default class ProfileReportForm extends React.Component<Props & Actions, State> {

    constructor(props: Readonly<Props & Actions>) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            signal: new AbortController()
        }
    }

    renderForm() {
        return (
            <div className={styles.ReportForm}>
                <p className={styles.Question}>Which issue with this user are you reporting?</p>
                <p className={this.getOptionClasses()} onClick={() => this.send(ReportReason.Spam)}>
                    Their account is suspicious or spam.
                </p>
                <p className={this.getOptionClasses()} onClick={() => this.send(ReportReason.Abusive)}>
                    Their profile contains abusive or hateful content.
                </p>
                <div className={styles.ButtonParent}>
                    <Button color={ButtonColor.Regular}
                            onClick={() => this.actions.toggleProfileReportForm(false)}>Cancel</Button>
                </div>
            </div>
        )
    }

    renderSubmitted() {
        return (
            <div className={styles.ReportForm}>
                <p>Please note that abusing this function may result in your account being terminated.</p>
                <p>You can block this user while we look into this.</p>
                <div className={styles.ButtonParent}>
                    <Button color={ButtonColor.Primary}
                            onClick={() => this.actions.toggleProfileReportForm(false)}>Done</Button>
                </div>
            </div>
        )
    }

    render() {
        if (this.state.submitted) {
            return (
                <CardModal title={"This user has been reported"}
                           isOpen={this.props.isOpen}
                           onRequestClose={() => this.actions.toggleProfileReportForm(false)}
                           children={this.renderSubmitted()}/>
            )
        } else {
            return (
                <CardModal title={`Report ${this.props.username}?`}
                           titleClassName={styles.ModalTitle}
                           isOpen={this.props.isOpen}
                           onRequestClose={() => this.actions.toggleProfileReportForm(false)}
                           children={this.renderForm()}/>
            )
        }
    }

    send(reason: ReportReason) {
        if (this.state.loading) return;

        this.setState({loading: true}, () => {
            submitReport(this.props.userId, reason, this.state.signal)
                .then(() => {
                    this.setState({submitted: true});
                })
                .catch(err => {
                    console.error(err);
                    this.setState({loading: false});
                })
        });
    }

    componentWillUnmount() {
        this.state.signal.abort();
    }

    getOptionClasses(): string {
        if (this.state.loading) {
            return [styles.Option, styles.Loading].join(" ")
        } else {
            return styles.Option
        }
    }

    get actions() {
        return this.props as Actions;
    }
}
