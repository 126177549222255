import React from "react";
import {cssVar} from "../../../variables";

export enum Icons {
    CancelRequest, Unfriend, Block, Report, Unblock
}

interface Props {
    icon: Icons;
    className?: string;
    fill?: string;
}

export default class FriendMenuIcon extends React.Component<Props> {
    render(): React.ReactNode {
        switch (this.props.icon) {
            case Icons.CancelRequest:
                return <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M5.5 0C2.46169 0 0 2.46169 0 5.5C0 8.53831 2.46169 11 5.5 11C8.53831 11 11 8.53831 11 5.5C11 2.46169 8.53831 0 5.5 0ZM8.19677 6.94375C8.30101 7.04798 8.30101 7.21653 8.19677 7.32077L7.31855 8.19677C7.21431 8.30101 7.04577 8.30101 6.94153 8.19677L5.5 6.74194L4.05625 8.19677C3.95202 8.30101 3.78347 8.30101 3.67923 8.19677L2.80323 7.31855C2.69899 7.21431 2.69899 7.04577 2.80323 6.94153L4.25806 5.5L2.80323 4.05625C2.69899 3.95202 2.69899 3.78347 2.80323 3.67923L3.68145 2.80101C3.78569 2.69677 3.95423 2.69677 4.05847 2.80101L5.5 4.25806L6.94375 2.80323C7.04798 2.69899 7.21653 2.69899 7.32077 2.80323L8.19899 3.68145C8.30323 3.78569 8.30323 3.95423 8.19899 4.05847L6.74194 5.5L8.19677 6.94375Z"
                        fill={this.props.fill || cssVar('--default-text')}/>
                </svg>
            case Icons.Unfriend:
                return <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M12.899 5.15625L13.8966 4.17656C14.0345 4.04121 14.0345 3.82207 13.8966 3.68672L13.3978 3.19688C13.26 3.06152 13.0368 3.06152 12.899 3.19688L11.9014 4.17656L10.9038 3.19688C10.7659 3.06152 10.5428 3.06152 10.405 3.19688L9.90616 3.68672C9.76833 3.82207 9.76833 4.04121 9.90616 4.17656L10.9038 5.15625L9.90616 6.13594C9.76833 6.27129 9.76833 6.49043 9.90616 6.62578L10.405 7.11563C10.5428 7.25098 10.7659 7.25098 10.9038 7.11563L11.9014 6.13594L12.899 7.11563C13.0368 7.25098 13.26 7.25098 13.3978 7.11563L13.8966 6.62578C14.0345 6.49043 14.0345 6.27129 13.8966 6.13594L12.899 5.15625ZM4.90057 5.5C6.44732 5.5 7.7009 4.26895 7.7009 2.75C7.7009 1.23105 6.44732 0 4.90057 0C3.35383 0 2.10025 1.23105 2.10025 2.75C2.10025 4.26895 3.35383 5.5 4.90057 5.5ZM6.8608 6.1875H6.49545C6.00977 6.40664 5.46939 6.53125 4.90057 6.53125C4.33176 6.53125 3.79357 6.40664 3.3057 6.1875H2.94034C1.31703 6.1875 0 7.48086 0 9.075V9.96875C0 10.5381 0.470368 11 1.05012 11H8.75103C9.33078 11 9.80115 10.5381 9.80115 9.96875V9.075C9.80115 7.48086 8.48412 6.1875 6.8608 6.1875Z"
                        fill={this.props.fill || cssVar('--default-text')}/>
                </svg>

            case Icons.Block:
                return <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M5.5 0C2.46245 0 0 2.46243 0 5.5C0 8.53757 2.46245 11 5.5 11C8.53755 11 11 8.53755 11 5.5C11 2.46245 8.53757 0 5.5 0ZM8.38546 2.61454C9.83693 4.06601 9.93788 6.28448 8.84402 7.84036L3.15962 2.156C4.71656 1.06141 6.93479 1.16389 8.38546 2.61454ZM2.61454 8.38546C1.16307 6.93399 1.06212 4.71552 2.15598 3.15964L7.84038 8.844C6.28346 9.93859 4.06521 9.83613 2.61454 8.38546Z"
                        fill={this.props.fill || cssVar('--default-text')}/>
                </svg>
            case Icons.Report:
                return <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M7.33831 2.12228C6.18703 2.12228 5.23619 1.37499 3.78873 1.37499C3.25258 1.37499 2.77232 1.46918 2.32682 1.63308C2.38856 1.47184 2.41484 1.29918 2.40387 1.12687C2.36579 0.51594 1.86497 0.0259026 1.25336 0.00102368C0.566006 -0.026949 0 0.521977 0 1.20312C0 1.61188 0.204037 1.9728 0.515625 2.19022V10.4844C0.515625 10.7691 0.746475 11 1.03125 11H1.375C1.65978 11 1.89062 10.7691 1.89062 10.4844V8.45624C2.49887 8.19705 3.25664 7.98096 4.34919 7.98096C5.50049 7.98096 6.45131 8.72825 7.89877 8.72825C8.93365 8.72825 9.76076 8.37819 10.5307 7.85045C10.7173 7.72259 10.8281 7.51031 10.8281 7.28416V2.06131C10.8281 1.55873 10.3067 1.22621 9.85091 1.43792C9.11318 1.78055 8.20834 2.12228 7.33831 2.12228Z"
                        fill={this.props.fill || cssVar('--default-text')}/>
                </svg>
            case Icons.Unblock:
                return <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M5.32917 0.00259559C2.37074 0.0928581 1.21421e-05 2.51952 1.21421e-05 5.49996C-0.00150795 6.22266 0.139714 6.93855 0.415573 7.60653C0.691431 8.27451 1.09649 8.88143 1.60751 9.39246C2.11853 9.90348 2.72544 10.3086 3.39341 10.5844C4.06137 10.8603 4.77725 11.0015 5.49993 11C8.48054 11 10.9072 8.62943 10.9974 5.67073C11.0937 2.51176 8.48808 -0.0938767 5.32917 0.00259559ZM8.84402 7.84036L6.70459 5.70089L5.70241 6.70597L7.84028 8.84389C6.28345 9.93857 4.06508 9.83611 2.61447 8.38548C1.16387 6.93485 1.06207 4.71554 2.15607 3.15957L4.29217 5.29571L5.29435 4.29062L3.15958 2.15603C4.71642 1.06135 6.93479 1.16381 8.3854 2.61444C9.836 4.06508 9.93779 6.28438 8.84402 7.84036Z"
                        fill={this.props.fill || cssVar('--default-text')}/>
                </svg>
        }
    }
}
