import React from "react";
import styles from "./UserFriend.module.scss";
import {Link} from "react-router-dom";
import Avatar from "../Avatar/Avatar";
import {ForeignUser} from "../../../Redux/Types";
import {SpotifyTrack} from "../../../API/Models";
import DefaultAlbum from "../../../Images/DefaultAlbum.svg";

interface Props {
    user: ForeignUser;
    activity?: SpotifyTrack;
}

export default class UserFriend extends React.Component<Props> {
    render() {
        return (
            <div className={styles.User} key={this.props.user.id}>
                <Link to={"/user/" + this.props.user.username}>
                    <Avatar user={this.props.user} size={"60px"}/></Link>
                <div className={styles.UserInfo}>
                    <div className={styles.UserName}>
                        <Link to={"/user/" + this.props.user.username}>{this.props.user.username}</Link></div>
                    {
                        !this.props.activity ?
                            <div className={styles.Activity}/>
                            :
                            <div className={styles.Activity}>
                                <div className={styles.Artwork}
                                     style={{backgroundImage: "url(" + this.artwork() + ")"}}/>
                                <div className={styles.SongInfo}>
                                    <div className={styles.SongName} title={this.props.activity.name}>
                                        {this.props.activity.name}
                                    </div>
                                    <div className={styles.SongArtist} title={this.props.activity.artists[0].name}>
                                        {this.props.activity.artists[0].name}
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        )
    }

    artwork(): string {
        if (!this.props.activity) return "";

        return this.props.activity.album.images
            .sort((a, b) => a.width - b.width)[0]?.url ?? DefaultAlbum;
    }
}
