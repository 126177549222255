import React from "react";
import styles from "./MenuIcon.module.scss";

interface Props {
    className?: string;
}

export default class MenuIcon extends React.Component<Props> {
    render() {
        return (
            <svg width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={"IconSvg " + styles.Icon + " " + (this.props.className || "")}>
                <ellipse className={this.getPathClasses()} cx="2.55404" cy="2.44445" rx="2.44589" ry="2.44445"/>
                <ellipse className={this.getPathClasses()} cx="2.55404" cy="19.5558" rx="2.44589" ry="2.44445"/>
                <ellipse className={this.getPathClasses()} cx="2.55404" cy="10.9999" rx="2.44589" ry="2.44445"/>
            </svg>
        )
    }

    getPathClasses(): string {
        return ["IconPath", styles.Path].join(" ");
    }
}


// <svg width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <ellipse cx="2.55404" cy="2.44445" rx="2.44589" ry="2.44445" fill="#BBBBBB"/>
//     <ellipse cx="2.55404" cy="19.5558" rx="2.44589" ry="2.44445" fill="#BBBBBB"/>
//     <ellipse cx="2.55404" cy="10.9999" rx="2.44589" ry="2.44445" fill="#BBBBBB"/>
// </svg>
