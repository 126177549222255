import React from "react";

export default class LocationIcon extends React.Component {
    render() {
        return (
            <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={"IconSvg"}>
                <path className={"IconPath"}
                      d="M4.93476 14.6974C0.772578 8.5263 0 7.89296 0 5.625C0 2.51839 2.46242 0 5.5 0C8.53758 0 11 2.51839 11 5.625C11 7.89296 10.2274 8.5263 6.06524 14.6974C5.7921 15.1009 5.20787 15.1009 4.93476 14.6974ZM5.5 7.96875C6.76566 7.96875 7.79167 6.91942 7.79167 5.625C7.79167 4.33057 6.76566 3.28125 5.5 3.28125C4.23434 3.28125 3.20833 4.33057 3.20833 5.625C3.20833 6.91942 4.23434 7.96875 5.5 7.96875Z"/>
            </svg>
        )
    }
}
