import React from "react";
import styles from "./PrivacySettingDropdown.module.scss";
import PrivacySettingsIcons, {Icons} from "./PrivacySettingsIcons";
import Card from "../../Card/Card";
import PopupMenu from "../../PopupMenu/PopupMenu";

interface Props {
    possibleValues: PrivacySettingValue[];
    value: PrivacySettingValue;
    onChange?: (newValue: PrivacySettingValue) => void;
    disabled?: boolean;
}

interface State {
    active: boolean;
}

export interface PrivacySettingValue {
    id: number;
    name: string;
    icon: Icons;
}

export default class PrivacySettingDropdown extends React.Component<Props, State> {
    private toggleRef: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Readonly<Props>) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.closeMenu = this.closeMenu.bind(this);

        this.state = {
            active: false
        }
    }

    render() {
        return (
            <>
                <div className={this.getCurrentValueClasses()} onClick={this.onClick} ref={this.toggleRef}
                     title={this.props.disabled ? "This setting cannot be changed yet." : ""}>
                    <PrivacySettingsIcons icon={this.props.value.icon}
                                          className={styles.Icon}/>
                    <div className={styles.Name}>{this.props.value.name}</div>
                    {
                        this.state.active ?
                            <Card className={styles.MenuFloat}>
                                <PopupMenu toggleRef={this.toggleRef} closeMenu={this.closeMenu}>
                                    {
                                        this.props.possibleValues.map(val =>
                                            <div className={styles.MenuItem} key={val.id}
                                                 onClick={() => this.props.onChange?.(val)}>
                                                <PrivacySettingsIcons icon={val.icon}/>{val.name}
                                            </div>)
                                    }
                                </PopupMenu>
                            </Card>
                            : ""
                    }
                </div>
            </>
        )
    }

    onClick() {
        if (this.props.disabled) return;
        this.setState({active: !this.state.active})
    }

    closeMenu() {
        this.setState({active: false});
    }

    getCurrentValueClasses() {
        const classes = [styles.CurrentValue];
        if (this.props.disabled) {
            classes.push(styles.Disabled);
        }
        if (this.state.active) {
            classes.push(styles.Active)
        }
        return classes.join(" ");
    }
}
