import React, {Ref} from "react";
import styles from "./Card.module.scss";

interface CardProps {
    elevation?: CardElevation,
    children?: React.ReactNode,
    className?: string,
    invisible?: boolean,

    _ref?: Ref<HTMLDivElement>
}

export enum CardElevation {
    Float,
    Sheet,
    Modal,
    None
}

export default class Card extends React.Component<CardProps> {
    render() {
        return <div className={Card.getCardClasses(this.props)} children={this.props.children} ref={this.props._ref}
                    onClick={e => e.stopPropagation()}/>
    }

    static getCardClasses(props: CardProps): string {
        if (props.invisible) {
            return [props.className || ""].join(" ");
        }

        const classes = [styles.Card];

        switch (props.elevation) {
            case CardElevation.Sheet:
                classes.push(styles.ElevationSheet);
                break;
            case CardElevation.None:
                classes.push(styles.ElevationNone);
                break;
            case CardElevation.Modal:
                classes.push(styles.ElevationModal);
                break;
            case CardElevation.Float:
            default:
                classes.push(styles.ElevationFloat);
        }

        if (props.className) {
            classes.push(props.className);
        }

        return classes.join(" ");
    }
}
