import React from "react";
import LoginFormContainer from "../../Containers/LoginFormContainer";
import JoinFormContainer from "../../Containers/JoinFormContainer";


interface AuthFormProps {
    view: "login" | "join";
}

export default class AuthFormParent extends React.Component<AuthFormProps> {
    render() {
        return (
            <div className={"AuthFormParent " + (this.props.view === "join" ? "Left" : "Right")}>
                <LoginFormContainer visible={this.props.view === "login"}/>
                <JoinFormContainer visible={this.props.view === "join"}/>
            </div>
        )
    }
}
