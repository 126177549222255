import React from "react";
import Card, {CardElevation} from "../Card/Card";
import styles from "./SpotifyTrackingAlert.module.scss";
import {cssVar} from "../../../variables";

interface Props {
    className?: string;
    delayedSince?: Date;
    dismissTrackingDelayed: () => void;
}

export default class SpotifyTrackingAlert extends React.Component<Props> {

    paused = true;
    critical = false;

    render() {
        if (this.paused) {
            return (
                <Card className={this.getClasses()} elevation={CardElevation.Sheet}>
                    <div className={styles.Message}>
                        As of December 1st, 2021, Spotify tracking will be paused indefinitely.&nbsp;
                        <a href={"https://medium.com/p/c5f72b5b1e81"}>Click
                            here</a> for more information.
                    </div>
                </Card>
            )
        }
        if (this.critical) {
            return (
                <Card className={this.getClasses()} elevation={CardElevation.Sheet}>
                    <div className={styles.Message}>
                        Spotify tracking is currently unavailable due to an issue on Spotify's end.&nbsp;
                        <a href={"https://community.spotify.com/t5/Ongoing-Issues/Mobile-Recently-Played-not-updating/idi-p/5233455"}>Click
                            here</a> for more information.
                    </div>
                </Card>
            )
        }
        if (!this.props.delayedSince) return "";
        return (
            <Card className={this.getClasses()} elevation={CardElevation.Sheet}>
                <div className={styles.Message}>
                    Spotify tracking is delayed by about {this.getDelayTime()} minutes.
                    Check out <a href={"https://status.wavy.fm"}>status.wavy.fm</a> for updates.
                </div>
                <div className={styles.Dismiss} onClick={this.props.dismissTrackingDelayed}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.0833 1.11625L9.96708 0L5.54167 4.42542L1.11625 0L0 1.11625L4.42542 5.54167L0 9.96708L1.11625 11.0833L5.54167 6.65792L9.96708 11.0833L11.0833 9.96708L6.65792 5.54167L11.0833 1.11625Z"
                            fill={cssVar('--default-text')}/>
                    </svg>
                </div>
            </Card>
        )
    }

    getDelayTime() {
        if (!this.props.delayedSince) return "";
        return Math.floor((Date.now() / 1000 - this.props.delayedSince.getTime() / 1000) / 60);
    }

    getClasses() {
        const classes = [styles.Alert];
        if (this.critical || this.paused) {
            classes.push(styles.Critical);
        }
        if (this.props.className) {
            classes.push(this.props.className);
        }
        return classes.join(" ");
    }
}
