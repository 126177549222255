import React from "react";
import styles from "./ProfileGrid.module.scss";
import {Link} from "react-router-dom";
import AngleRightIcon from "../Icons/AngleRight/AngleRight";
import MusicActionPopup from "../MusicActionPopup/MusicActionPopup";
import {MusicAction} from "../MusicActionPopup/MusicAction";

interface Props {
    entries?: Entry[];
    focusedEntry?: Entry;
    onHover: (entry?: Entry) => void;
    moreLink: string;
    loading: boolean;
    actionGenerator: (entry: Entry) => MusicAction[],
}

interface State {
    hoveredEntry?: Entry;
    openMenuEntryId?: string;
}

export interface Entry {
    key: string;
    icon: string;
    name: string;
    subName?: string;
    plays: number;
    playerLink: string;

    actionSubject: () => React.ReactNode;
}

export default class ProfileGrid extends React.Component<Props, State> {
    private entryToggleRefMap = new Map<string, React.RefObject<HTMLDivElement>>();

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            hoveredEntry: undefined
        }
    }

    render() {
        if (this.props.focusedEntry) {
            return (
                <div className={styles.FocusedImage}
                     style={{backgroundImage: "url(" + this.props.focusedEntry.icon + ")"}}>
                    <div className={styles.Info}>
                        <div className={styles.Title}>{this.props.focusedEntry.name}</div>
                        {
                            !this.props.focusedEntry.subName ? "" :
                                <div className={styles.SubTitle}>{this.props.focusedEntry.subName}</div>
                        }
                        <div className={styles.Plays}>{this.props.focusedEntry.plays.toLocaleString()} plays</div>
                    </div>
                </div>
            )
        }
        return (
            <div className={this.props.loading ? styles.Loading : ""}>
                <div className={styles.Grid}>
                    {
                        this.getActualEntries()
                    }
                </div>
                <div className={styles.ViewMore}>
                    <Link className={["AccentLink", styles.ViewMoreLink].join(" ")} to={this.props.moreLink}
                          aria-label={"View More of This"}>
                        View More
                        <AngleRightIcon/>
                    </Link>
                </div>
            </div>
        )
    }

    getActualEntries() {
        const entries = !this.props.entries ? [] : this.props.entries.map(entry =>
            <React.Fragment key={entry.key}>
                <div className={styles.Cell + " " + styles.Filled} key={entry.key}
                     onClick={() => this.doToggleFor(entry)}
                     ref={this.toggleFor(entry)}
                     onMouseEnter={() => this.hover(entry)}
                     onMouseOut={() => this.exit(entry)}
                     style={{backgroundImage: "url(" + entry.icon + ")"}}>
                </div>
                <div style={{position: "relative"}}>
                    {this.renderPopup(entry)}
                </div>
            </React.Fragment>
        );
        const length = entries.length;
        for (let i = 0; i < 9 - length; i++) {
            entries.push(<div className={styles.Cell} key={"placeholder-" + i}/>)
        }
        return entries;
    }

    hover(entry: Entry) {
        if (window.innerWidth <= 768) return;
        this.props.onHover(entry);
        this.setState({hoveredEntry: entry});
    }

    exit(entry: Entry) {
        if (window.innerWidth <= 768) return;
        if (this.state.hoveredEntry && this.state.hoveredEntry.key === entry.key) {
            this.props.onHover(undefined);
        }
    }

    toggleFor(entry: Entry): React.RefObject<HTMLDivElement> {
        const existing = this.entryToggleRefMap.get(entry.key);
        if (existing) {
            return existing;
        } else {
            const ref = React.createRef<HTMLDivElement>();
            this.entryToggleRefMap.set(entry.key, ref);
            return ref;
        }
    }

    doToggleFor(entry: Entry) {
        if (this.state.openMenuEntryId) {
            this.closeRowMenu();
        } else {
            this.openRowMenu(entry);
        }
    }

    isRowMenuOpen(entry: Entry) {
        return this.state.openMenuEntryId === entry.key;
    }

    openRowMenu(entry: Entry) {
        this.setState({openMenuEntryId: entry.key});
    }

    closeRowMenu() {
        this.setState({openMenuEntryId: undefined});
    }

    renderPopup(entry: Entry) {
        if (this.isRowMenuOpen(entry)) {
            return <MusicActionPopup subject={entry.actionSubject()} toggleRef={this.toggleFor(entry)}
                                     mobile={true} desktop={false}
                                     actions={this.props.actionGenerator(entry)}
                                     onCloseRequested={() => this.closeRowMenu()}/>
        } else {
            return "";
        }
    }
}
