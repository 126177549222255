import React from "react";

export enum Icons {
    Twitter, Instagram, Spotify, Discord
}

interface Props {
    icon: Icons;
    className?: string;
}

export default class ProfileMetaIcon extends React.Component<Props> {
    render(): React.ReactNode {
        switch (this.props.icon) {
            case Icons.Twitter:
                return <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M13.4582 3.07857C13.4677 3.21368 13.4677 3.34881 13.4677 3.48391C13.4677 7.60476 10.3744 12.3529 4.72083 12.3529C2.97906 12.3529 1.36105 11.8414 0 10.9536C0.247472 10.9825 0.485393 10.9922 0.742386 10.9922C2.17955 10.9922 3.50254 10.5 4.55902 9.6604C3.20749 9.63144 2.07487 8.73392 1.68464 7.49862C1.87501 7.52755 2.06535 7.54686 2.26524 7.54686C2.54125 7.54686 2.81729 7.50824 3.07425 7.44072C1.66562 7.15118 0.609114 5.89659 0.609114 4.38143V4.34284C1.01836 4.57446 1.49429 4.71922 1.9987 4.7385C1.17065 4.17874 0.628157 3.22333 0.628157 2.14244C0.628157 1.56341 0.780413 1.03262 1.04693 0.569379C2.56026 2.46092 4.83503 3.6962 7.38577 3.83133C7.33819 3.59971 7.30963 3.35847 7.30963 3.11719C7.30963 1.39934 8.6802 0 10.3839 0C11.269 0 12.0685 0.376378 12.6301 0.984375C13.3249 0.849271 13.9911 0.588688 14.5812 0.231619C14.3528 0.955441 13.8674 1.56344 13.2297 1.94944C13.8484 1.88192 14.448 1.70817 15 1.46692C14.5813 2.08454 14.0578 2.63461 13.4582 3.07857Z"
                        fill="#6A737D"/>
                </svg>
            case Icons.Instagram:
                return <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M7.50167 3.65419C5.3734 3.65419 3.65672 5.37125 3.65672 7.5C3.65672 9.62875 5.3734 11.3458 7.50167 11.3458C9.62995 11.3458 11.3466 9.62875 11.3466 7.5C11.3466 5.37125 9.62995 3.65419 7.50167 3.65419ZM7.50167 10.0003C6.12633 10.0003 5.00195 8.879 5.00195 7.5C5.00195 6.121 6.12298 4.99972 7.50167 4.99972C8.88037 4.99972 10.0014 6.121 10.0014 7.5C10.0014 8.879 8.87702 10.0003 7.50167 10.0003ZM12.4007 3.49688C12.4007 3.99559 11.9992 4.3939 11.5039 4.3939C11.0053 4.3939 10.6071 3.99225 10.6071 3.49688C10.6071 3.00151 11.0086 2.59985 11.5039 2.59985C11.9992 2.59985 12.4007 3.00151 12.4007 3.49688ZM14.9473 4.40729C14.8904 3.20568 14.616 2.1413 13.7359 1.26436C12.8592 0.387426 11.795 0.112964 10.5937 0.0527167C9.35555 -0.0175722 5.64445 -0.0175722 4.4063 0.0527167C3.20831 0.109617 2.14417 0.384079 1.26408 1.26102C0.383993 2.13796 0.112939 3.20233 0.052705 4.40394C-0.0175683 5.64236 -0.0175683 9.35429 0.052705 10.5927C0.109593 11.7943 0.383993 12.8587 1.26408 13.7356C2.14417 14.6126 3.20496 14.887 4.4063 14.9473C5.64445 15.0176 9.35555 15.0176 10.5937 14.9473C11.795 14.8904 12.8592 14.6159 13.7359 13.7356C14.6127 12.8587 14.8871 11.7943 14.9473 10.5927C15.0176 9.35429 15.0176 5.64571 14.9473 4.40729ZM13.3477 11.9215C13.0867 12.5775 12.5814 13.083 11.9222 13.3474C10.935 13.739 8.59258 13.6486 7.50167 13.6486C6.41076 13.6486 4.06497 13.7356 3.08115 13.3474C2.42526 13.0863 1.91997 12.5809 1.65561 11.9215C1.26408 10.9341 1.35443 8.59115 1.35443 7.5C1.35443 6.40885 1.26743 4.06253 1.65561 3.07849C1.91662 2.42246 2.42192 1.91705 3.08115 1.65263C4.06832 1.26102 6.41076 1.35139 7.50167 1.35139C8.59258 1.35139 10.9384 1.26436 11.9222 1.65263C12.5781 1.9137 13.0834 2.41911 13.3477 3.07849C13.7393 4.06588 13.6489 6.40885 13.6489 7.5C13.6489 8.59115 13.7393 10.9375 13.3477 11.9215Z"
                        fill="#6A737D"/>
                </svg>
            case Icons.Spotify:
                return <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M7.5 0C3.35988 0 0 3.35988 0 7.5C0 11.6401 3.35988 15 7.5 15C11.6401 15 15 11.6401 15 7.5C15 3.35988 11.6401 0 7.5 0ZM10.5454 11.0353C10.4183 11.0353 10.3397 10.996 10.2218 10.9264C8.33468 9.78931 6.13911 9.74093 3.97077 10.1855C3.85282 10.2157 3.69859 10.2641 3.61089 10.2641C3.31754 10.2641 3.13306 10.0312 3.13306 9.78629C3.13306 9.4748 3.31754 9.32661 3.54435 9.27822C6.02117 8.73085 8.55242 8.77923 10.7117 10.0706C10.8962 10.1885 11.005 10.2944 11.005 10.5696C11.005 10.8448 10.7903 11.0353 10.5454 11.0353ZM11.3589 9.05141C11.2016 9.05141 11.0958 8.98185 10.9869 8.92439C9.09677 7.80544 6.27823 7.35484 3.77117 8.03528C3.62601 8.0746 3.54738 8.11391 3.41129 8.11391C3.0877 8.11391 2.8246 7.85081 2.8246 7.52722C2.8246 7.20363 2.98185 6.98891 3.29335 6.90121C4.13407 6.66532 4.99294 6.48992 6.25101 6.48992C8.21371 6.48992 10.1099 6.97681 11.6038 7.86593C11.8488 8.01109 11.9456 8.19859 11.9456 8.46169C11.9425 8.78831 11.6885 9.05141 11.3589 9.05141ZM12.2964 6.74698C12.1391 6.74698 12.0423 6.70766 11.9062 6.62903C9.75302 5.34375 5.90323 5.03528 3.41129 5.73085C3.30242 5.76109 3.16633 5.80948 3.02117 5.80948C2.62198 5.80948 2.31653 5.49798 2.31653 5.09577C2.31653 4.68448 2.57056 4.45161 2.84274 4.37298C3.90726 4.06149 5.09879 3.91331 6.39617 3.91331C8.60383 3.91331 10.9173 4.37298 12.6079 5.35887C12.8437 5.49496 12.998 5.68246 12.998 6.04234C12.998 6.45363 12.6653 6.74698 12.2964 6.74698Z"
                        fill="#6A737D"/>
                </svg>
            case Icons.Discord:
                return <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M12.6981 1.03473C11.7421 0.596057 10.7169 0.27286 9.64494 0.0877516C9.62542 0.0841791 9.60592 0.093107 9.59586 0.110963C9.46401 0.345473 9.31796 0.651409 9.21568 0.891874C8.06275 0.719269 6.91574 0.719269 5.78645 0.891874C5.68415 0.646064 5.5328 0.345473 5.40036 0.110963C5.3903 0.0937028 5.3708 0.0847748 5.35128 0.0877516C4.27994 0.272269 3.25473 0.595466 2.29808 1.03473C2.2898 1.0383 2.2827 1.04426 2.27799 1.05199C0.33337 3.95722 -0.199344 6.79104 0.0619876 9.58972C0.06317 9.60341 0.0708562 9.61651 0.0814988 9.62483C1.36451 10.567 2.60732 11.1391 3.82705 11.5182C3.84657 11.5242 3.86725 11.517 3.87968 11.5009C4.1682 11.1069 4.4254 10.6915 4.64592 10.2546C4.65894 10.229 4.64651 10.1986 4.61992 10.1885C4.21196 10.0337 3.8235 9.84506 3.44983 9.63079C3.42027 9.61353 3.41791 9.57126 3.4451 9.55102C3.52373 9.49209 3.60239 9.43078 3.67747 9.36888C3.69106 9.35758 3.70999 9.35519 3.72596 9.36233C6.1808 10.4831 8.83847 10.4831 11.2643 9.36233C11.2803 9.3546 11.2992 9.35699 11.3134 9.36829C11.3885 9.43019 11.4672 9.49209 11.5464 9.55102C11.5736 9.57126 11.5718 9.61353 11.5422 9.63079C11.1686 9.84922 10.7801 10.0337 10.3716 10.1879C10.345 10.198 10.3331 10.229 10.3462 10.2546C10.5714 10.6908 10.8286 11.1063 11.1118 11.5003C11.1236 11.517 11.1449 11.5242 11.1644 11.5182C12.3901 11.1391 13.6329 10.567 14.9159 9.62483C14.9271 9.61651 14.9342 9.604 14.9354 9.59031C15.2482 6.35473 14.4116 3.54415 12.7176 1.05259C12.7135 1.04426 12.7064 1.0383 12.6981 1.03473ZM5.01251 7.88561C4.27344 7.88561 3.66446 7.20709 3.66446 6.37378C3.66446 5.54048 4.26163 4.86195 5.01251 4.86195C5.7693 4.86195 6.37238 5.54644 6.36055 6.37378C6.36055 7.20709 5.76338 7.88561 5.01251 7.88561ZM9.99672 7.88561C9.25766 7.88561 8.64868 7.20709 8.64868 6.37378C8.64868 5.54048 9.24583 4.86195 9.99672 4.86195C10.7535 4.86195 11.3566 5.54644 11.3448 6.37378C11.3448 7.20709 10.7535 7.88561 9.99672 7.88561Z"
                        fill="#6A737D"/>
                </svg>
        }
    }
}
