import {ActionTypes, AppState, UserState} from "../../Redux/Types";
import {Dispatch} from "react";
import {connect} from "react-redux";
import {App} from "../Presentation/App/App";

function isLoggedIn(profileReady: boolean, user: UserState | null): boolean {
    return profileReady && user !== null;
}

function isLoggedOut(profileReady: boolean, user: UserState | null): boolean {
    return profileReady && user === null;
}

function isEmailVerified(profileReady: boolean, user: UserState | null): boolean {
    return isLoggedIn(profileReady, user) && user !== null && user.emailVerified;
}

function mapStateToProps(state: AppState) {
    return {
        isLoggedIn: isLoggedIn(state.profileReady, state.user),
        isLoggedOut: isLoggedOut(state.profileReady, state.user),
        isGlobalLoading: state.isGlobalLoading,
        isEmailVerified: isEmailVerified(state.profileReady, state.user),
        username: state.user?.username
    }
}

function mapDispatchToProps(dispatch: Dispatch<ActionTypes>) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
