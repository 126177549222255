import React from "react";
import styles from "./AboutFaq.module.scss";
import {Link} from "react-router-dom";
import BlockReportScreenshot from "./BlockReportScreenshot.png";
import {titleManager} from "../../../Title";

interface State {
    expanded: Map<string, boolean>;
}

export default class AboutFaq extends React.Component<{}, State> {

    constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            expanded: new Map<string, boolean>()
        }
    }

    toggleExpanded(id: string) {
        if (this.state.expanded.get(id)) {
            this.state.expanded.set(id, false);
        } else {
            this.state.expanded.set(id, true);
        }
        this.setState({expanded: this.state.expanded});
    }

    classes(id: string): string {
        return !!this.state.expanded.get(id) ? styles.Body : styles.Body + " " + styles.Hidden;
    }

    render() {
        return (
            <div className={styles.Container}>
                <div className={styles.Section}>
                    <div className={styles.SectionTitle}>About wavy.fm</div>
                    <div className={styles.List}>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("01")}>
                            Q1: What does wavy.fm do?
                            <div className={this.classes("01")}>
                                wavy.fm is a social media platform. What differentiates it from Discord, Reddit,
                                Instagram, among others, is that it can track what music you listen to. This will allow
                                you to build a music profile, and find people with similar tastes as you. It may also
                                lead to some wonderful discoveries!
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("02")}>
                            Q2: Coming from Last.fm, what can I expect?
                            <div className={this.classes("02")}>
                                wavy.fm is still in early stages. The scale of wavy.fm is a tiny fraction of Last.fm,
                                which has been around for far longer. However, we believe that wavy.fm presents a great
                                opportunity for music-lovers and to attract a more mainstream user-base. We hope that
                                you will also believe in this opportunity!
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("03")}>
                            Q3: How far along is the website? How long has it been launched for?
                            <div className={this.classes("03")}>
                                wavy.fm hasn't launched officially, and most of you are here through word-of-mouth. We
                                are actively working on new features and releasing them as we go. We've been working on
                                the website since October 2019.
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("04")}>
                            Q4: I don't use Spotify, what's in it for me?
                            <div className={this.classes("04")}>
                                At the moment, not a whole lot. We hope that you can come and give us your ideas and
                                feedback for making wavy.fm what the community wants it to be.
                                <br/><br/>
                                In the future, you will
                                be able to track your music history from a variety of sources. There will also be a
                                free-to-use API to integrate with your favorite music player, as well as an interface
                                for entering your history manually on the website.
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("05")}>
                            Q5: Will I be able to transfer my history from Last.fm?
                            <div className={this.classes("05")}>
                                <strong>Yes</strong>, it will be possible to transfer your history from Last.fm. This
                                will work as an API for bulk-uploading your music history. This feature will likely be a
                                paid service to help us adapt and scale. It will be part of a subscription/premium
                                model.
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.Section}>
                    <div className={styles.SectionTitle}>Account</div>
                    <div className={styles.List}>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("11")}>
                            Q1: Do I need Spotify to create an account?
                            <div className={this.classes("11")}>
                                <strong>No.</strong> You do not need a Spotify account to be able to sign up on wavy.fm,
                                but you will need one to track music on your profile. In the future, it will be possible
                                to use wavy.fm with other streaming services and media players.
                                <br/><br/>
                                Note that you can still use the website for its social features without tracking music.
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("12")}>
                            Q2: Which music streaming services can I link to my account?
                            <div className={this.classes("12")}>
                                At the moment, only a <strong>Spotify</strong> account can be linked to your profile.
                                Both Spotify Premium and non-Premium accounts can be linked.
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("13")}>
                            Q3: I'm having trouble signing in.
                            <div className={this.classes("13")}>
                                <Link to={"/password-reset/submit"}>Click here to reset your password.</Link>
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("14")}>
                            Q4: Can I change my username?
                            <div className={this.classes("14")}>
                                You can change your username every 24 hours from
                                your <Link to={"/settings/account"}>Account Settings</Link>.
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("15")}>
                            Q5: How do I permanently delete my account?
                            <div className={this.classes("15")}>
                                <strong>Careful! This action is non-reversible.</strong>
                                <br/><br/>
                                To delete your account,
                                head over to your <Link to={"/settings/account"}>Account Settings</Link> and click
                                on <strong>Delete</strong>.
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.Section}>
                    <div className={styles.SectionTitle}>Profile</div>
                    <div className={styles.List}>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("21")}>
                            Q1: How do I change my profile picture?
                            <div className={this.classes("21")}>
                                You can upload a profile picture by heading over to your profile, and
                                clicking on your avatar on the top-left. Click <strong>Choose File</strong> to
                                select a JPEG or PNG file, and finally click <strong>Upload</strong> to save it.
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("22")}>
                            Q2: How do I change my profile bio?
                            <div className={this.classes("22")}>
                                You can update your <strong>bio</strong> and&nbsp;
                                <strong>country</strong> by clicking on the <strong>Edit Profile</strong> button on
                                your own profile page.
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("23")}>
                            Q3: How do I listen to songs?
                            <div className={this.classes("23")}>
                                Once your Spotify account is linked to your profile, you can
                                simply listen a song on Spotify (<strong>until it ends</strong>) and it will be
                                tracked automatically by wavy.fm.
                                <br/><br/>
                                If you do not listen to a song fully, it will not be tracked.
                                <br/><br/>
                                Note that it may take up to 2 minutes until the song is added
                                to your wavy.fm profile.
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("24")}>
                            Q4: Why can't I see any of my listens?
                            <div className={this.classes("24")}>
                                There are a few possible reasons that wavy.fm isn't tracking your profile:
                                <ul>
                                    <li>
                                        Make sure you listen to songs until they end, and wait up to 2 minutes
                                        for them to appear on wavy.fm.
                                    </li>
                                    <li>
                                        Make sure your Spotify account is not
                                        in <strong>private</strong> or <strong>offline</strong> mode.
                                    </li>
                                </ul>
                                If it's still not working, shoot us a message on the&nbsp;
                                <Link to={"/about/contact"}>Contact Us</Link> page and we'll look into it as soon
                                as possible.
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("25")}>
                            Q5: Why do I have more "artists" than "listens"?
                            <div className={this.classes("25")}>
                                wavy.fm considers all artists that are <strong>featuring</strong> on a song,
                                in addition to the main artist.
                                <br/><br/>
                                Since one song may have more than one artist, it is entirely possible for your unique
                                "artists" counter to be higher than your "listens" counter.
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("26")}>
                            Q6: How do I remove songs from my history?
                            <div className={this.classes("26")}>
                                You can remove songs from your history by clicking the <strong>View More</strong> button
                                under the recent
                                songs on your profile. Then, click <strong>Edit</strong> and remove the songs you want
                                to delete from your
                                history. Finally, click <strong>Save</strong> to save your changes.
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("27")}>
                            Q7: How do I display my Spotify and/or Discord accounts on my profile?
                            <div className={this.classes("27")}>
                                On your own profile page, click on <strong>Edit Profile</strong> and
                                check <strong>Show Spotify account</strong> and/or <strong>Show Discord account</strong>.
                                Your accounts first need to be connected in
                                your <Link to={"/settings/connections"}>Connections Settings</Link>.
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.Section}>
                    <div className={styles.SectionTitle}>Social</div>
                    <div className={styles.List}>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("31")}>
                            Q1: How do I add friends?
                            <div className={this.classes("31")}>
                                To become friends with another user, you must first send them a friend request.
                                You can send a friend request by clicking on your name on the top-right of wavy.fm,
                                clicking <strong>Friends</strong>, and then <strong>Add a Friend</strong>.
                                <br/><br/>
                                To accept an incoming friend request, click on your name on the top-right of wavy.fm,
                                click <strong>Friends</strong>, and then <strong>Pending Requests</strong>. You can
                                either Accept or Ignore the friend request in the list.
                            </div>
                        </div>
                        <div className={styles.Elem} onClick={() => this.toggleExpanded("32")}>
                            Q2: How do I block or report another user?
                            <div className={this.classes("32")}>
                                <strong>Screenshot:</strong>
                                <br/>
                                <br/>
                                <img src={BlockReportScreenshot} alt={"The menu to report and block a user."}
                                     style={{border: "solid 1px"}}/>
                                <br/>
                                <br/>
                                To block a user, go to their profile, and click the 3 dots (&#8942;) next to the Friend
                                button.
                                Click <strong>Block</strong> and then confirm.
                                <br/><br/>
                                To report a user, go to their profile, and click the 3 dots (&#8942;) next to the Friend
                                button.
                                Click <strong>Report</strong> and then select the reason for reporting the user.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        titleManager.set("FAQ")
    }
}
