export class HistoryEditManager {
    private deleteQueue: Set<string> = new Set<string>();
    private listener?: () => void;

    toggle(item: string): void {
        if (this.has(item)) {
            this.deleteQueue.delete(item);
        } else {
            this.deleteQueue.add(item);
        }
        this._publish();
    }

    has(item: string): boolean {
        return this.deleteQueue.has(item);
    }

    get size(): number {
        return this.deleteQueue.size;
    }

    toArray(): string[] {
        return Array.from(this.deleteQueue);
    }

    flush(): void {
        this.deleteQueue.clear();
        this._publish();
    }

    setListener(listener: () => void): void {
        this.listener = listener;
    }

    unregister(): void {
        this.listener = undefined;
    }

    private _publish() {
        if (this.listener) this.listener();
    }
}
