import React from "react";
import styles from "./Deletion.module.scss";
import Card from "../Card/Card";
import {titleManager} from "../../../Title";
import Button, {ButtonColor} from "../Button/Button";
import {sendDeletionFeedback} from "../../../API/Calls";

interface Props {
}

interface State {
    submitted: boolean;
}

export default class Deletion extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {submitted: false};
        this.onSubmit = this.onSubmit.bind(this);
    }

    render() {
        return (
            <Card className={styles.Card}>
                <div className={styles.Title}>Deletion in Progress</div>
                <p>
                    We're sad to see you leave wavy.fm. Your account is currently being deleted. It may take a few
                    minutes for the account to be completely removed.
                </p>
                {this.renderForm()}
            </Card>
        )
    }

    renderForm() {
        if (this.state.submitted) {
            return (
                <p><strong>Thanks, your feedback has been submitted.</strong></p>
            )
        } else {
            return (
                <>
                    <p>
                        <strong>We would greatly appreciate your feedback.</strong> Please let us know why you deleted
                        your
                        account:
                    </p>
                    <form className={styles.Form} onSubmit={this.onSubmit} action={"none"}>
                        <input type={"radio"} name={"Reason"} value={"wavy.fm didn't work on my device"} id={"1"}
                               required={true}/>
                        <label htmlFor={"1"}>wavy.fm didn't work on my device</label><br/>
                        <input type={"radio"} name={"Reason"} value={"I didn't have a Spotify account to link to"}
                               id={"2"}/>
                        <label htmlFor={"2"}>I didn't have a Spotify account to link to</label><br/>
                        <input type={"radio"} name={"Reason"} value={"wavy.fm didn't track my Spotify account properly"}
                               id={"3"}/>
                        <label htmlFor={"3"}>wavy.fm didn't track my Spotify account properly</label><br/>
                        <input type={"radio"} name={"Reason"} value={"I wasn't using wavy.fm anymore"} id={"4"}/>
                        <label htmlFor={"4"}>I wasn't using wavy.fm anymore</label><br/>
                        <input type={"radio"} name={"Reason"} value={"Other"} id={"5"}/>
                        <label htmlFor={"5"}>Other (please describe)</label><br/>
                        <div className={styles.MessageField}>
                            <label htmlFor={"Message"} className={styles.Label}>
                                Message
                            </label>
                            <textarea required={false} name={"Message"} className={styles.MessageInput}
                                      maxLength={1000}/>
                        </div>
                        <input type={"submit"} className={Button.getButtonClasses({color: ButtonColor.Primary})}
                               value={"Submit"}/>
                    </form>
                </>
            )
        }
    }

    onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const reason = (form.elements.namedItem("Reason") as RadioNodeList).value || "";
        const message = (form.elements.namedItem("Message") as HTMLTextAreaElement).value || "";
        this.setState({submitted: true});
        sendDeletionFeedback(reason, message)
            .catch(console.error);
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Deletion in Progress");
    }
}
