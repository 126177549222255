import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeSearchMenu, openSearchMenu} from "../../Redux/Actions";
import SearchBar from "../Presentation/SearchBar/SearchBar";
import {withRouter} from "react-router-dom";

function mapStateToProps(state: AppState) {
    return {
        isMenuOpen: state.menus.search,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        openSearchMenu,
        closeSearchMenu,
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchBar));
