import "./Welcome.scss";
import React from "react";
import {UserState} from "../../../Redux/Types";
import {editEmail} from "../../../API/Calls";
import Button, {ButtonColor} from "../Button/Button";
import Card from "../Card/Card";
import {titleManager} from "../../../Title";

interface WelcomeProps {
    user: UserState | null;

    setUserEmail: (newEmail: string) => void,
}

interface WelcomeState {
    email: string,
    loading: boolean,
    error: Error | null,
}

export default class Welcome extends React.Component<WelcomeProps, WelcomeState> {

    constructor(props: Readonly<WelcomeProps>) {
        super(props);

        this.state = {
            email: "",
            loading: false,
            error: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <div className={"Container WelcomePage"}>
                <Card className={"WelcomeCard"}>
                    <div className={"Content"}>
                        <div className={"Title"}>Email Verification</div>
                        <div className={"Subtitle"}>
                            <p>
                                Welcome to wavy.fm, {this.props.user ? this.props.user.username : ""}!
                            </p>
                            <p>
                                We emailed you a verification link to&nbsp;
                                <span className={"Email"}>{this.props.user ? this.props.user.email : ""}</span>.
                            </p>
                        </div>
                        <div className={"Separator"}/>
                        <p>
                            In case you put in the wrong email address, you can set a new one here:
                        </p>
                        <form onSubmit={this.handleSubmit}>
                            <div className={"WelcomeNewEmailForm"}>
                                <div className={"FormField"}>
                                    <label htmlFor={"Email"}>
                                        New Email Address
                                    </label>
                                </div>
                                <div className="SubmitRegion">
                                    <input required={true} name={"Email"} type={"email"} value={this.state.email}
                                           onChange={this.handleChange}/>
                                    <Button form={true} color={ButtonColor.Primary}
                                            disabled={this.state.loading}>Save</Button>
                                </div>
                            </div>
                            {
                                this.state.error ? <div className={"AuthError"}>{this.state.error.toString()}</div> : ""
                            }
                        </form>
                    </div>
                </Card>
            </div>
        )
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        if (target.name === "Email") {
            this.setState({email: target.value});
        }
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        this.setState({loading: true, error: null});
        editEmail(this.state.email, "")
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    return resp.json().then(json => {
                        throw Error(json.description);
                    })
                }
            })
            .then(resp => {
                this.props.setUserEmail(this.state.email);
                this.setState({loading: false, error: null});
            })
            .catch(e => {
                this.setState({loading: false, error: e});
                console.error(e);
            })
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Welcome");
    }
}
