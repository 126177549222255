import React from "react";
import {GetProfileTracksResponse, ProfileDateRangeDefaultKey} from "../../../API/Models";
import {ForeignUser} from "../../../Redux/Types";
import {getProfileTracks, setDefaultDateRange} from "../../../API/Calls";
import MusicList, {Entry} from "../MusicList/MusicList";
import styles from "./ProfileTopSongs.module.scss";
import ProfileDateRange, {
    convertDateRange,
    rangeFromDefaultValue,
    Ranges,
    rangeToDefaultValue
} from "../ProfileDateRange/ProfileDateRange";
import DefaultAlbum from "../../../Images/DefaultAlbum.svg";
import {spotifyUriToId} from "../../../SpotifyUtil";
import {EventEmitter} from "events";
import SongSubject from "../MusicSubject/SongSubject";
import {OPEN_IN_SPOTIFY} from "../MusicActionPopup/MusicAction";

interface Props {
    isOwnProfile: boolean;
    user: ForeignUser;
    reload$: EventEmitter;
}

interface State {
    dateRange: Ranges;
    defaultDateRange: Ranges;
    ready: boolean;
    tracksResponse: GetProfileTracksResponse | null;
}

export default class ProfileTopSongs extends React.Component<Props, State> {

    private abort = new AbortController();

    constructor(props: Readonly<Props>) {
        super(props);

        this.changeDateRange = this.changeDateRange.bind(this);
        this.saveAsDefault = this.saveAsDefault.bind(this);

        const defaultDateRange = rangeFromDefaultValue(props.user.dateRangeDefaults?.[ProfileDateRangeDefaultKey.Songs]);
        this.state = {
            dateRange: defaultDateRange,
            defaultDateRange: defaultDateRange,
            ready: false,
            tracksResponse: null
        }

        this.props.reload$.on("reload_listens", () => this.load());
    }

    render() {
        return (
            <div className={styles.TopSongsSection}>
                <div className={styles.TopSongsTitle}>
                    {this.renderTitle()}
                </div>
                {this.renderMusicList()}
            </div>
        )
    }

    changeDateRange(dateRange: Ranges) {
        this.abort.abort();
        this.abort = new AbortController();

        const cached = dateRange === Ranges.AllTime ? this.getCachedTracks() : null;
        const since = convertDateRange(dateRange);

        this.setState({dateRange, ready: false, tracksResponse: cached}, () => {
            getProfileTracks(this.props.user.id, 5, 0, since, 0, undefined, this.abort)
                .then(tracksResponse => {
                    this.setState({tracksResponse, ready: true});
                    if (dateRange === Ranges.AllTime) {
                        this.saveCachedTracks(tracksResponse);
                    }
                })
                .catch(console.error);
        });
    }

    renderMusicList() {
        if (this.state.tracksResponse) {
            return <MusicList entries={this.getEntries()} metricType={"count"} autoCollapse={true}
                              className={this.getMusicListClasses()}
                              moreLink={this.moreLink}
                              actionGenerator={entry => [
                                  OPEN_IN_SPOTIFY(entry.playerLink)
                              ]}/>
        } else {
            return <div/>
        }
    }

    get moreLink(): string {
        let path = "/user/" + this.props.user.username + "/history/songs";
        switch (this.state.dateRange) {
            case Ranges.Days7:
                path += "?range=7d";
                break;
            case Ranges.Days30:
                path += "?range=30d";
                break;
            case Ranges.Days90:
                path += "?range=90d";
                break;
            case Ranges.Days365:
                path += "?range=365d";
                break;
        }
        return path;
    }

    componentDidMount(): void {
        this.load();
    }

    load() {
        this.changeDateRange(this.state.dateRange);
    }

    componentWillUnmount() {
        this.abort.abort();
    }

    getEntries(): Entry[] {
        if (this.state.tracksResponse) {
            return this.state.tracksResponse.tracks.map(track => {
                // Spotify URL hack
                const url = `https://open.spotify.com/track/${spotifyUriToId(track.track.uri)}`;
                const image = track.track.album.images
                    .sort((a, b) => a.width - b.width)[0]?.url ?? DefaultAlbum;
                return {
                    id: track.track.uri,
                    primary: track.track.name,
                    secondary: track.track.artists[0].name,
                    metric: track.count.toLocaleString(),
                    icon: image,
                    playerLink: url,
                    actionSubject: () => <SongSubject name={track.track.name} artist={track.track.artists[0].name}
                                                      image={image}/>
                }
            });
        } else {
            return [];
        }
    }

    getMusicListClasses(): string {
        const classes = [styles.TopSongsList];
        if (!this.state.ready) {
            classes.push(styles.Loading);
        }
        return classes.join(" ");
    }

    getCachedTracks(): GetProfileTracksResponse | null {
        const key = "wavy.cache.tracks." + this.props.user.id;
        const value = window.localStorage.getItem(key);
        if (value === null) {
            return null;
        }
        try {
            return JSON.parse(value) as GetProfileTracksResponse;
        } catch (e) {
            console.error("Failed to read from cache", e);
            return null;
        }
    }

    saveCachedTracks(tracks: GetProfileTracksResponse) {
        const key = "wavy.cache.tracks." + this.props.user.id;
        try {
            window.localStorage.setItem(key, JSON.stringify(tracks));
        } catch (e) {
            console.error("Failed to save cache", e);
        }
    }

    renderTitle() {
        return (
            <>
                <span>Top Songs</span>
                <div className={styles.LeftSide}>
                    {
                        !this.props.isOwnProfile || this.state.defaultDateRange === this.state.dateRange ? "" :
                            <span className={styles.SaveAsDefault}
                                  onClick={this.saveAsDefault}>(Set as default)</span>
                    }
                    <ProfileDateRange selected={this.state.dateRange} onChange={this.changeDateRange}/>
                </div>
            </>
        )
    }

    saveAsDefault() {
        if (this.props.isOwnProfile) {
            setDefaultDateRange(ProfileDateRangeDefaultKey.Songs, rangeToDefaultValue(this.state.dateRange))
                .then()
                .catch(console.error);
            this.setState({defaultDateRange: this.state.dateRange});
        }
    }
}
