import React from "react";
import styles from "./ProfileRequestHeader.module.scss";
import {ForeignUser} from "../../../Redux/Types";
import Button, {ButtonColor} from "../Button/Button";
import {Friendship} from "../../../API/Models";
import {cancelFriendRequest, createFriendRequest} from "../../../API/Calls";
import {EventEmitter} from "events";

interface Props {
    profile: ForeignUser;
    friendship: Friendship | null,
    reload$: EventEmitter;
    updateProfileFriendship: (friendship: Friendship | null) => void;
}

interface State {
    abort: AbortController
}

export default class ProfileRequestHeader extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            abort: new AbortController()
        }

        this.onAccept = this.onAccept.bind(this);
        this.onIgnore = this.onIgnore.bind(this);
    }

    render() {
        if (this.props.friendship !== Friendship.Pending || window.innerWidth <= 768) {
            return "";
        }
        return (
            <div className={styles.Parent}>
                <div className={styles.Title}>{this.props.profile.username} sent you a friend request</div>
                <div className={styles.Action}>
                    <Button color={ButtonColor.Primary} onClick={this.onAccept}>Accept</Button>
                    <Button color={ButtonColor.Regular} onClick={this.onIgnore}>Ignore</Button>
                </div>
            </div>
        )
    }

    onAccept() {
        createFriendRequest(this.props.profile.id, this.state.abort)
            .then(() => {
                this.props.updateProfileFriendship(Friendship.Friend);
                if (!this.props.profile.$access.view) {
                    this.props.reload$.emit("reload");
                    this.props.reload$.emit("reload_listens");
                }
            })
            .catch(console.error);
    }

    onIgnore() {
        cancelFriendRequest(this.props.profile.id, this.state.abort)
            .then(() => {
                this.props.updateProfileFriendship(Friendship.None);
            })
            .catch(console.error)
    }
}

