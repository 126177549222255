import React, {FormEvent} from "react";
import styles from "./AboutContact.module.scss";
import Card, {CardElevation} from "../Card/Card";
import Button, {ButtonColor} from "../Button/Button";
import {sendContactMessage} from "../../../API/Calls";
import {UserState} from "../../../Redux/Types";
import SuccessIcon from "./SuccessIcon.svg";
import {titleManager} from "../../../Title";
import {DISCORD_INVITE} from "../../../Discord";

interface Props {
    user: UserState | null;
}

interface State {
    loading: boolean;
    completed: boolean;
}

export default class AboutContact extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: false,
            completed: false
        }
    }

    render() {
        return (
            <div className={styles.Container}>
                <div className={styles.Column}>
                    <div className={styles.Title}>We'd love to hear from you.</div>
                    <div className={styles.Subtitle}>
                        Whether you want to give us some feedback, share some ideas or simply talk to us, send us a
                        message!
                    </div>
                    <div className={styles.LinksSection}>
                        <div className={styles.LinksTitle}>Social</div>
                        <div className={styles.Icons}>
                            {/*eslint-disable-next-line*/}
                            <a href={"https://twitter.com/wavyfm"} target={"_blank"} rel={"noopener noreferrer"}
                               className={styles.Twitter} title={"Twitter (@wavyfm)"}>
                                Twitter
                            </a>
                            {/*eslint-disable-next-line*/}
                            <a href={"https://instagram.com/wavydotfm"} target={"_blank"} rel={"noopener noreferrer"}
                               className={styles.Instagram} title={"Instagram (@wavydotfm)"}>
                                Instagram
                            </a>
                            {/*eslint-disable-next-line*/}
                            <a href={DISCORD_INVITE} target={"_blank"} rel={"noopener noreferrer"}
                               className={styles.Discord} title={"Discord"}>
                                Discord
                            </a>
                        </div>
                    </div>
                    <div className={styles.LinksSection}>
                        <div className={styles.LinksTitle}>Email</div>
                        <a href={"mailto:contact@wavy.fm"} className={styles.Email}>contact@wavy.fm</a>
                    </div>
                </div>
                <div className={styles.Column}>
                    <Card elevation={CardElevation.Float} className={styles.Form}>
                        {
                            this.state.completed ?
                                <div className={styles.CompleteMessage}>
                                    <div className={styles.ThankYou}>
                                        <span>Thank you!</span>
                                        <img src={SuccessIcon} alt={""} className={styles.SuccessIcon}/>
                                    </div>
                                    <div className={styles.Message}>
                                        Your message has been sent.<br/>We’ll be in touch soon.
                                    </div>
                                </div>
                                :
                                <form onSubmit={this.onSubmit.bind(this)}>
                                    <div className={"FormField"}>
                                        <label htmlFor={"FullName"} className={styles.Label}>
                                            Your Name
                                        </label>
                                        <input required={true} name={"FullName"} type={"text"} maxLength={100}
                                               defaultValue={this.props.user?.username || ""}/>
                                    </div>
                                    <div className={"FormField"}>
                                        <label htmlFor={"Email"} className={styles.Label}>
                                            Email Address
                                        </label>
                                        <input required={true} name={"Email"} type={"email"} maxLength={100}
                                               defaultValue={this.props.user?.email || ""}/>
                                    </div>
                                    <div className={"FormField"}>
                                        <label htmlFor={"Message"} className={styles.Label}>
                                            Message
                                        </label>
                                        <textarea required={true} name={"Message"} className={styles.MessageInput}
                                                  maxLength={2000}/>
                                    </div>
                                    <div className="FormField SubmitRegion">
                                        <Button form={true} color={ButtonColor.Primary}
                                                disabled={this.state.loading}>Submit</Button>
                                    </div>
                                </form>
                        }
                    </Card>
                </div>
            </div>
        )
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Contact Us");
    }

    onSubmit(e: FormEvent) {
        e.preventDefault();
        const target = e.target as HTMLFormElement;

        const fullName = (target.elements.namedItem("FullName") as HTMLTextAreaElement).value;
        const email = (target.elements.namedItem("Email") as HTMLTextAreaElement).value;
        const message = (target.elements.namedItem("Message") as HTMLTextAreaElement).value;

        this.setState({loading: true});
        sendContactMessage(fullName, email, message)
            .then(() => this.setState({completed: true}))
            .catch(err => {
                alert("Error: " + err);
                this.setState({loading: false});
            })
    }
}
