import {ForeignUser} from "../Redux/Types";
import {
    Comment,
    CommentReportReason,
    CommentText,
    GetBlockedUsersResponse,
    GetCountListensSinceResponse,
    GetFriendListResponse,
    GetFriendStatusResponse,
    GetListensCalendarResponse,
    GetNewUsersResponse,
    GetNotificationListResponse,
    GetPendingRequestsCountResponse,
    GetPendingRequestsResponse,
    GetProfileActivityResponse,
    GetProfileAlbumsResponse,
    GetProfileArtistsResponse,
    GetProfileCommentsResponse,
    GetProfileListensResponse,
    GetProfilePictureEventResponse,
    GetProfileTracksResponse,
    GetSearchResultsResponse,
    GetTrendingTracksResponse,
    NewMfaSetupResponse,
    PostProfilePictureResponse,
    ProfileCommentsPrivacy,
    ProfileDateRangeDefaultKey,
    ProfileDateRangeDefaultValue,
    readComment,
    readNotification,
    readSpotifyAlbum,
    readSpotifyArtist,
    readSpotifyTrack,
    readUser,
    ReportReason,
    SpotifyArtist
} from "./Models";
import queryString from 'query-string';

function silenceAbort(reason: any) {
    if (reason instanceof DOMException && reason.name === "AbortError") {
        return undefined;
    }
    throw reason;
}

export function _getLegacy(url: string): string {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return "http://localhost:3001" + url;
    } else {
        return "/api/internal/legacy" + url;
    }
}

export function _getInternal(url: string): string {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return "http://localhost:3003" + url;
    } else {
        return "/api/internal" + url;
    }
}

export function postToken(): Promise<boolean> {
    return fetch(_getInternal("/auth/token"), {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
            "Content-Type": "text/plain",
        }
    })
        .then(resp => {
            if (resp.ok) {
                return true;
            } else if (resp.status === 401) {
                return false;
            } else {
                return resp.json().then(json => {
                    throw Error(`Refreshing token failed: ${json.detail}`);
                })
            }
        })
}

export function getCurrentUser(): Promise<object> {
    return fetch(_getLegacy("/profile"), {
        method: "GET",
        cache: "no-cache",
        credentials: "include"
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(`Getting current user failed: ${json.description}`);
                })
            }
        })
}

export function login(username: string, password: string, totp_code?: string): Promise<null> {
    const postData = JSON.stringify({
        username, password, totp_code
    });

    return fetch(_getInternal("/auth/login"), {
        method: "POST",
        body: postData,
        cache: "no-cache",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    if (json.code === "totp.missing") {
                        throw Error("totp.missing");
                    } else {
                        throw Error(json.name);
                    }
                })
            }
        })
}

export function createAccount(username: string, password: string, email: string): Promise<null> {
    const postData = JSON.stringify({
        username,
        password,
        email
    });

    return fetch(_getLegacy("/auth/join"), {
        method: "POST",
        body: postData,
        cache: "no-cache",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
}

export function editPassword(oldPassword: string, newPassword: string): Promise<Response> {
    const patchData = JSON.stringify({
        oldPassword, newPassword,
    });

    return fetch(_getLegacy("/auth/password"), {
        method: "PATCH",
        body: patchData,
        cache: "no-cache",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export function editEmail(newEmail: string, password: string): Promise<Response> {
    const patchData = JSON.stringify({
        newEmail, password,
    });

    return fetch(_getLegacy("/auth/email"), {
        method: "PATCH",
        body: patchData,
        cache: "no-cache",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export function openSpotifyAuthorization(): void {
    fetch(_getLegacy("/connections/spotify/state"), {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(resp => {
            const spotifyEndpoint = resp.spotifyUrl;
            const clientId = resp.clientId;
            const redirectUri = resp.redirectUri;
            const scope = resp.scope;
            const state = resp.state;

            window.location.href = encodeURI(spotifyEndpoint +
                "?client_id=" + clientId +
                "&response_type=code" +
                "&redirect_uri=" + redirectUri +
                "&scope=" + scope +
                "&state=" + state +
                "&show_dialog=true"
            )
        })
        .catch(e => {
            console.error(e);
        });
}

export function openDiscordAuthorization(): void {
    fetch(_getLegacy("/connections/discord/state"), {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(resp => {
            const discordEndpoint = resp.discordUrl;
            const clientId = resp.clientId;
            const redirectUri = resp.redirectUri;
            const scope = resp.scope;
            const state = resp.state;

            window.location.href = encodeURI(discordEndpoint +
                "?client_id=" + clientId +
                "&response_type=code" +
                "&redirect_uri=" + redirectUri +
                "&scope=" + scope +
                "&state=" + state
            )
        })
        .catch(e => {
            console.error(e);
        });
}

export function deleteSpotifyData(): Promise<Response> {
    return fetch(_getLegacy("/connections/spotify"), {
        method: "DELETE",
        credentials: "include"
    });
}

export function deleteDiscordData(): Promise<Response> {
    return fetch(_getLegacy("/connections/discord"), {
        method: "DELETE",
        credentials: "include"
    });
}

export function getProfileByUsername(username: string): Promise<ForeignUser | null> {
    return fetch(_getLegacy("/profiles?username=" + username), {
        method: "GET",
        credentials: "include"
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw Error("An error occurred.");
            }
        })
        .then(resp => {
            if (resp.length !== 1) {
                return null;
            }
            return readUser(resp[0]);
        })
}

export function getProfilesById(...id: string[]): Promise<ForeignUser[]> {
    return fetch(_getLegacy("/profiles?id=" + id.join("&id=")), {
        method: "GET",
        credentials: "include"
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw Error("An error occurred.");
            }
        })
        .then(resp => {
            return resp.map(readUser);
        })
}

export function editPublicBiography(bio: string, countryCode: string, instagram: string, twitter: string): Promise<Response> {
    const patchData = JSON.stringify({
        bio, countryCode, instagram, twitter
    });

    return fetch(_getLegacy("/profile"), {
        method: "PATCH",
        credentials: "include",
        body: patchData,
        headers: {
            "Content-Type": "application/json"
        }
    });
}

export function emailVerify(code: string): Promise<Response> {
    const postData = JSON.stringify({
        code,
    });

    return fetch(_getLegacy("/auth/email_verify"), {
        method: "POST",
        credentials: "include",
        body: postData,
        headers: {
            "Content-Type": "application/json"
        }
    });
}

export function sendEmailVerification(): Promise<Response> {
    return fetch(_getLegacy("/auth/email_send_verify"), {
        method: "POST",
        credentials: "include"
    });
}

export function uploadProfilePicture(image: File): Promise<PostProfilePictureResponse> {
    const postData = new FormData();
    postData.append("img", image);
    return fetch(_getLegacy("/profile/picture/upload"), {
        method: "POST",
        credentials: "include",
        body: postData
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function getProfilePictureEvent(eventId: string): Promise<GetProfilePictureEventResponse> {
    return fetch(_getLegacy("/profile/picture/upload/" + eventId), {
        method: "GET",
        credentials: "include",
        cache: "no-cache"
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function setDefaultPicture(): Promise<any> {
    return fetch(_getLegacy("/profile/picture/default"), {
        method: "POST",
        credentials: "include"
    })
        .then(resp => {
            if (!resp.ok) {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            } else {
                return resp;
            }
        });
}

export function getRecentListens(userId: string, size: number = 10, page: number = 0, since: number = 0, before: number = 0, search?: string, live?: boolean, signal?: AbortController): Promise<GetProfileListensResponse> {
    const params = queryString.stringify({
        size,
        page,
        search,
        since: since || null,
        before: before || null,
        live
    }, {skipNull: true});

    return fetch(_getLegacy("/profile/listens/" + userId + "?" + params), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json() as Promise<GetProfileListensResponse>;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(resp => {
            return {
                ...resp,
                tracks: resp.tracks.map(play => {
                    return {...play, track: readSpotifyTrack(play.track)}
                }),
                live: resp.live ? readSpotifyTrack(resp.live) : undefined
            }
        })
}

export function countListensSince(userId: string, since: number): Promise<GetCountListensSinceResponse> {
    return fetch(_getLegacy("/profile/listens/" + userId + "/count?since=" + since), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function getTrendingArtists(): Promise<SpotifyArtist[]> {
    return fetch(_getLegacy("/trending/artists"), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        headers: {},
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json() as Promise<SpotifyArtist[]>;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(resp => {
            return resp.map(readSpotifyArtist);
        })
}

export function getTrendingTracks(): Promise<GetTrendingTracksResponse> {
    return fetch(_getLegacy("/trending/tracks"), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        headers: {},
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json() as Promise<GetTrendingTracksResponse>;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(resp => {
            return resp.map(track => {
                return {
                    ...track,
                    track: readSpotifyTrack(track.track)
                }
            })
        })
}

export function searchThings(term: string, signal?: AbortController): Promise<GetSearchResultsResponse | undefined> {
    const encoded = encodeURI("/search?term=" + term);
    return fetch(_getLegacy(encoded), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal,
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .catch(silenceAbort);
}

export function getProfileTracks(userId: string, size: number = 10, page: number = 0, since: number = 0, before: number = 0, search?: string, signal?: AbortController): Promise<GetProfileTracksResponse> {
    const params: { [key: string]: any } = {size, page};
    if (since > 0) {
        params["since"] = since;
    }
    if (before > 0) {
        params["before"] = before;
    }
    if (!!search) {
        params["search"] = search;
    }

    return fetch(_getLegacy("/profile/tracks/" + userId + "?" + queryString.stringify(params)), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json() as Promise<GetProfileTracksResponse>;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(resp => {
            return {
                ...resp,
                tracks: resp.tracks.map(track => {
                    return {
                        ...track,
                        track: readSpotifyTrack(track.track)
                    }
                })
            }
        })
}

export function getProfileArtists(userId: string, size: number = 10, page: number = 0, since: number = 0, before: number = 0, search?: string, signal?: AbortController): Promise<GetProfileArtistsResponse> {
    const params: { [key: string]: any } = {size, page};
    if (since > 0) {
        params["since"] = since;
    }
    if (before > 0) {
        params["before"] = before;
    }
    if (!!search) {
        params["search"] = search;
    }

    return fetch(_getLegacy("/profile/artists/" + userId + "?" + queryString.stringify(params)), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json() as Promise<GetProfileArtistsResponse>;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(resp => {
            return {
                ...resp,
                artists: resp.artists.map(artist => {
                    return {
                        ...artist,
                        artist: readSpotifyArtist(artist.artist)
                    }
                })
            }
        })
}


export function getProfileAlbums(userId: string, size: number = 10, page: number = 0, since: number = 0, before: number = 0, search?: string, signal?: AbortController): Promise<GetProfileAlbumsResponse> {
    const params: { [key: string]: any } = {size, page};
    if (since > 0) {
        params["since"] = since;
    }
    if (before > 0) {
        params["before"] = before;
    }
    if (!!search) {
        params["search"] = search;
    }

    return fetch(_getLegacy("/profile/albums/" + userId + "?" + queryString.stringify(params)), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json() as Promise<GetProfileAlbumsResponse>;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(resp => {
            return {
                ...resp,
                artists: resp.albums.map(album => {
                    return {
                        ...album,
                        album: readSpotifyAlbum(album.album)
                    }
                })
            }
        })
}

export function sendContactMessage(fullName: string, email: string, message: string): Promise<null> {
    const postData = JSON.stringify({fullName, email, message});

    return fetch(_getLegacy("/contact"), {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json"
        },
        body: postData
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function sendDeletionFeedback(reason: string, message: string): Promise<null> {
    const postData = JSON.stringify({reason, message});

    return fetch(_getLegacy("/contact/deletion"), {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: postData
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function submitPasswordReset(email: string): Promise<null> {
    const postData = JSON.stringify({email});

    return fetch(_getLegacy("/auth/password_reset/submit"), {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: postData
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function completePasswordReset(code: string, password: string): Promise<null> {
    const postData = JSON.stringify({code, password});

    return fetch(_getLegacy("/auth/password_reset/complete"), {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: postData
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function getFriendshipStatus(userId: string, signal?: AbortController): Promise<GetFriendStatusResponse> {
    return fetch(_getLegacy("/friends/status/" + userId), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function createFriendRequest(userId: string, abort?: AbortController): Promise<any> {
    return fetch(_getLegacy("/friends/request/" + userId), {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        signal: abort?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function deleteFriendship(userId: string): Promise<any> {
    return fetch(_getLegacy("/friends/status/" + userId), {
        method: "DELETE",
        credentials: "include",
        cache: "no-cache"
    })
        .then(resp => {
            if (resp.ok) {
                return resp;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}


export function cancelFriendRequest(userId: string, abort?: AbortController): Promise<any> {
    return fetch(_getLegacy("/friends/request/" + userId), {
        method: "DELETE",
        credentials: "include",
        cache: "no-cache",
        signal: abort?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function getFriendList(userId: string, signal?: AbortController): Promise<GetFriendListResponse> {
    return fetch(_getLegacy("/profile/friends/" + userId), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(resp => (resp as any[]).map(readUser));
}

export function getProfileActivity(userIds: string[], signal?: AbortController): Promise<GetProfileActivityResponse> {
    return fetch(_getLegacy("/profile/activity/" + userIds.join(",")), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json() as Promise<GetProfileActivityResponse>;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(resp => {
            Object.keys(resp).forEach(key => {
                const play = resp[key];
                if (!play) {
                    return;
                }
                resp[key] = {...play, track: readSpotifyTrack(play.track)};
            });
            return resp;
        })
}

export function getNewUsers(term: string, signal?: AbortController): Promise<GetNewUsersResponse> {
    const encoded = encodeURI("/search/new_users?term=" + term);
    return fetch(_getLegacy(encoded), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal,
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function getPendingFriendRequests(signal?: AbortController): Promise<GetPendingRequestsResponse> {
    return fetch(_getLegacy("/friends/requests"), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(resp => {
            return {
                incoming: resp.incoming.map(readUser),
                outgoing: resp.outgoing.map(readUser)
            }
        })
}

export function countPendingFriendRequests(signal?: AbortController): Promise<GetPendingRequestsCountResponse> {
    return fetch(_getLegacy("/friends/requests/count"), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
}

export function deleteAccount(password: string, signal?: AbortController): Promise<null> {
    const postData = JSON.stringify({password});

    return fetch(_getLegacy("/auth/delete"), {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json"
        },
        body: postData,
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
}

export function submitReport(user_id: string, reason: ReportReason, signal?: AbortController): Promise<null> {
    const postData = JSON.stringify({user_id, reason});

    return fetch(_getLegacy("/report"), {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json"
        },
        body: postData,
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
}

export function getListensCalendar(userId: string, month: number, year: number, signal?: AbortController): Promise<GetListensCalendarResponse> {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
    const params = queryString.stringify({month, year, tz});
    return fetch(_getLegacy("/profile/listens/" + userId + "/calendar?" + params), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}


export function deletePlays(play_ids: string[], signal?: AbortController): Promise<null> {
    const postData = JSON.stringify({play_ids});
    return fetch(_getLegacy("/profile/listens"), {
        method: "DELETE",
        credentials: "include",
        cache: "no-cache",
        body: postData,
        headers: {
            "Content-Type": "application/json"
        },
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function updatePrivacySettingsPrivateProfile(privateProfile: boolean, signal?: AbortController): Promise<null> {
    const postData = JSON.stringify({privateProfile});
    return fetch(_getLegacy("/profile/privacy"), {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        body: postData,
        headers: {
            "Content-Type": "application/json"
        },
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function updatePrivacySettingsPublicSpotify(publicSpotify: boolean, signal?: AbortController): Promise<null> {
    const postData = JSON.stringify({publicSpotify});
    return fetch(_getLegacy("/profile/privacy"), {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        body: postData,
        headers: {
            "Content-Type": "application/json"
        },
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function updatePrivacySettingsPublicDiscord(publicDiscord: boolean, signal?: AbortController): Promise<null> {
    const postData = JSON.stringify({publicDiscord});
    return fetch(_getLegacy("/profile/privacy"), {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        body: postData,
        headers: {
            "Content-Type": "application/json"
        },
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function updatePrivacySettingsProfileComments(profileComments: ProfileCommentsPrivacy, signal?: AbortController): Promise<null> {
    const postData = JSON.stringify({profileComments});
    return fetch(_getLegacy("/profile/privacy"), {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        body: postData,
        headers: {
            "Content-Type": "application/json"
        },
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function blockUser(userId: string, signal?: AbortController): Promise<null> {
    return fetch(_getLegacy("/block/" + userId), {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function unblockUser(userId: string, signal?: AbortController): Promise<null> {
    return fetch(_getLegacy("/block/" + userId), {
        method: "DELETE",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function getBlockedUsers(signal?: AbortController): Promise<GetBlockedUsersResponse> {
    return fetch(_getLegacy("/blocked"), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(resp => (resp as any[]).map(readUser));
}

export function getNotifications(size: number, offset: number = 0, signal?: AbortController): Promise<GetNotificationListResponse> {
    const params = queryString.stringify({size, offset});
    return fetch(_getLegacy("/notifications?" + params), {
        method: "GET",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(resp => {
            resp.items = resp.items.map(readNotification);
            return resp;
        });
}

export function getNotificationSocket(): string {
    return (process.env.NODE_ENV === "production" ? "wss://wavy.fm/api/internal/ws/notifications/updates" : "ws://localhost:3002/updates");
}

export function markNotificationAsRead(id: string, signal?: AbortController): Promise<null> {
    return fetch(_getLegacy("/notifications/read?id=" + id), {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function deleteNotification(id: string, signal?: AbortController): Promise<null> {
    return fetch(_getLegacy("/notifications?id=" + id), {
        method: "DELETE",
        credentials: "include",
        cache: "no-cache",
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        });
}

export function postComment(profileId: string, text: CommentText): Promise<Comment | null> {
    const postData = JSON.stringify({text});

    return fetch(_getLegacy("/profile/comments/" + profileId), {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json"
        },
        body: postData
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(readComment);
}

export function getComments(profileId: string, before?: number, abort?: AbortController): Promise<GetProfileCommentsResponse> {
    const params = queryString.stringify({
        before
    }, {skipNull: true});

    return fetch(_getLegacy("/profile/comments/" + profileId + "?" + params), {
        method: "GET",
        credentials: "include",
        signal: abort?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                throw Error("An error occurred.");
            }
        })
        .then(resp => {
            if (!Array.isArray(resp.comments)) {
                return {total: 0, comments: []};
            }
            return {
                total: resp.total,
                comments: resp.comments.map(readComment).filter((comment: Comment | null) => comment !== null) as Comment[]
            }
        })
}

export function likeComment(profileId: string, commentId: string): Promise<null> {
    return fetch(_getLegacy("/profile/comments/" + profileId + "/" + commentId + "/like"), {
        method: "POST",
        credentials: "include"
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
}

export function unlikeComment(profileId: string, commentId: string): Promise<null> {
    return fetch(_getLegacy("/profile/comments/" + profileId + "/" + commentId + "/like"), {
        method: "DELETE",
        credentials: "include"
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
}

export function deleteComment(profileId: string, commentId: string): Promise<null> {
    return fetch(_getLegacy("/profile/comments/" + profileId + "/" + commentId), {
        method: "DELETE",
        credentials: "include"
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
}

export function postReply(profileId: string, text: CommentText, commentId: string, replyingTo: string): Promise<Comment | null> {
    const postData = JSON.stringify({text, "replying_to": replyingTo});

    return fetch(_getLegacy("/profile/comments/" + profileId + "/" + commentId + "/reply"), {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json"
        },
        body: postData
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
        .then(readComment);
}

export function deleteReply(profileId: string, commentId: string, replyId: string): Promise<null> {
    return fetch(_getLegacy("/profile/comments/" + profileId + "/" + commentId + "/reply/" + replyId), {
        method: "DELETE",
        credentials: "include",
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
}


export function likeReply(profileId: string, commentId: string, replyId: string): Promise<null> {
    return fetch(_getLegacy("/profile/comments/" + profileId + "/" + commentId + "/reply/" + replyId + "/like"), {
        method: "POST",
        credentials: "include"
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
}

export function unlikeReply(profileId: string, commentId: string, replyId: string): Promise<null> {
    return fetch(_getLegacy("/profile/comments/" + profileId + "/" + commentId + "/reply/" + replyId + "/like"), {
        method: "DELETE",
        credentials: "include"
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
}

export function reportComment(reason: CommentReportReason, profileId: string, commentId: string, abort: AbortController): Promise<null> {
    return fetch(_getLegacy("/profile/comments/" + profileId + "/" + commentId + "/report"), {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({reason}),
        signal: abort.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
}

export function reportReply(reason: CommentReportReason, profileId: string, commentId: string, replyId: string, abort: AbortController): Promise<null> {
    return fetch(_getLegacy("/profile/comments/" + profileId + "/" + commentId + "/reply/" + replyId + "/report"), {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({reason}),
        signal: abort.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
}

export function countTotalListens(signal?: AbortController): Promise<number> {
    return fetch(_getLegacy("/metrics/listens/total"), {
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.detail);
                })
            }
        })
}

export function getTrackingDelayedSince(): Promise<Date | undefined> {
    return fetch(_getInternal("/metrics/listens/status"))
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.detail);
                })
            }
        })
        .then(json => {
            const delayedSince = json.delayed_since;
            if (delayedSince) {
                return new Date(delayedSince);
            }
        })
}

export function setDefaultDateRange(key: ProfileDateRangeDefaultKey, value: ProfileDateRangeDefaultValue) {
    const postData = JSON.stringify({key, value});

    return fetch(_getLegacy("/profile/date_range_defaults"), {
        method: "POST",
        credentials: "include",
        body: postData,
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json"
        },
    })
}

export function deleteToken() {
    return fetch(_getInternal("/auth/token"), {
        method: "DELETE",
        credentials: "include",
        cache: "no-cache"
    })
}

export function newMfaSetup(password: string, abort?: AbortController): Promise<NewMfaSetupResponse> {
    return fetch(_getInternal("/auth/mfa/new"), {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({password}),
        signal: abort?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.name);
                })
            }
        })
        .then(resp => {
            return {
                lease: resp.lease,
                qr: resp.qr,
                secret: resp.secret,
                url: resp.url,
            }
        })
}

export function confirmMfaSetup(code: string, lease: string, abort?: AbortController): Promise<string[]> {
    return fetch(_getInternal("/auth/mfa/confirm"), {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({code, lease}),
        signal: abort?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.name);
                })
            }
        })
        .then(resp => {
            return resp.recovery_codes
        })
}

export function disableMfa(password: string, signal?: AbortController): Promise<null> {
    const postData = JSON.stringify({password});

    return fetch(_getInternal("/auth/mfa"), {
        method: "DELETE",
        credentials: "include",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json"
        },
        body: postData,
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return null;
            } else {
                return resp.json().then(json => {
                    throw Error(json.name);
                })
            }
        })
}


export function viewMfaRecovery(password: string, signal?: AbortController): Promise<string[]> {
    const postData = JSON.stringify({password});

    return fetch(_getInternal("/auth/mfa/recovery"), {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json"
        },
        body: postData,
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.name);
                })
            }
        })
        .then(resp => {
            return resp.recovery_codes
        })
}

export function changeUsername(new_username: string, password: string, signal?: AbortController): Promise<string> {
    const postData = JSON.stringify({new_username, password});

    return fetch(_getInternal("/auth/username"), {
        method: "PATCH",
        credentials: "include",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json"
        },
        body: postData,
        signal: signal?.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.name);
                })
            }
        })
        .then(resp => {
            return resp.new_username
        })
}
