import {AppState, ForeignUser} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {updateProfileFriendship} from "../../Redux/Actions";
import ProfileRequestHeader from "../Presentation/ProfileRequestHeader/ProfileRequestHeader";

function mapStateToProps(state: AppState) {
    return {
        isLoggedIn: !!state.user?.id,
        profile: state.profilePage.profile as ForeignUser,
        friendship: state.profilePage.friendship,
        reload$: state.profilePage.reload$
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        updateProfileFriendship
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileRequestHeader);
