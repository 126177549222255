import React from "react";
import {NotificationsState, UserState} from "../../../Redux/Types";
import Card from "../Card/Card";
import styles from "./NotificationsMenu.module.scss";
import {Link} from "react-router-dom";
import NotificationsMenuItem from "../NotificationsMenuItem/NotificationsMenuItem";
import {Notification} from "../../../API/Models";
import {getNotifications, markNotificationAsRead} from "../../../API/Calls";
import {mergeNotifications, notificationUserCache} from "../../../API/NotificationUserCache";

interface Props {
    user: UserState | null;
    open: boolean;
    toggleRef: React.RefObject<HTMLElement>;
    notifications: NotificationsState;

    setNotifications: (state: Partial<NotificationsState>) => void;
    toggleNotificationsMenu: (open: boolean) => void;
}

interface State {
    ready: boolean;
}

export default class NotificationsMenu extends React.Component<Props, State> {
    private menuContainer: React.RefObject<HTMLElement> = React.createRef();

    constructor(props: Readonly<Props>) {
        super(props);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.close = this.close.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.markAllAsRead = this.markAllAsRead.bind(this);

        this.state = {
            ready: false
        }
    }

    render(): React.ReactNode {
        if (!this.props.user || !this.props.open) {
            return "";
        }
        return (
            <div className={styles.FloatingMenu} ref={this.menuContainer as React.RefObject<HTMLDivElement>}>
                <Card className={styles.Card}>
                    <div className={styles.Header}>
                        <div className={styles.Title}>Notifications</div>
                        <div className={this.getMarkAsReadClasses()} onClick={this.markAllAsRead}>Mark all as read</div>
                    </div>
                    <div className={styles.Content}>
                        {this.renderContent()}
                    </div>
                </Card>
            </div>
        )
    }

    renderContent(): React.ReactNode {
        if (this.props.notifications.notifications.length === 0 && !this.props.notifications.loading) {
            return (
                <div className={styles.Empty}>
                    You're all caught up!
                </div>
            )
        }

        // This is to fix a FF bug where the scrollbar gets persisted
        setTimeout(() => {
            if (!this.state.ready) {
                this.setState({ready: true});
            }
        });

        return (
            <div>
                {
                    !this.state.ready ?
                        <div className={styles.List}/>
                        :
                        <div className={styles.List} onScroll={this.onScroll}>
                            {this.renderItems()}
                            {this.props.notifications.loading ? <div className={styles.Spinner}>Loading...</div> : ""}
                            {this.props.notifications.hasMore ? <div className={styles.Filler}/> : ""}
                        </div>
                }
                <div className={styles.Footer}>
                    <Link to={"/notifications"} onClick={this.close}>View All</Link>
                </div>
            </div>
        )
    }

    renderItems() {
        if (!this.props.user) {
            return "";
        }
        return this.props.notifications.notifications.map(item => {
            return (
                <div className={this.getItemClasses(item)} key={item.id} onClick={() => this.onItemClick(item)}>
                    <NotificationsMenuItem notification={item} user={this.props.user as UserState}/>
                </div>
            )
        });
    }

    onItemClick(item: Notification<any>) {
        this.close();
        if (!item.read) {
            markNotificationAsRead(item.id)
                .then()
                .catch(console.error);
        }
    }

    onScroll(event: React.UIEvent<HTMLDivElement>) {
        if (!this.props.open) {
            return;
        }

        const canLoad = this.props.notifications.hasMore && !this.props.notifications.loading;
        const shouldLoad = (event.currentTarget.scrollTop + event.currentTarget.clientHeight + 50) > event.currentTarget.scrollHeight;

        if (canLoad && shouldLoad) {
            this.props.setNotifications({loading: true});
            const offset = this.props.notifications.notifications.length;
            getNotifications(5, offset)
                .then(resp => {
                    notificationUserCache.withUsers(resp.items)
                        .then(notifs => {
                            const merged = mergeNotifications(this.props.notifications.notifications, notifs);
                            this.props.setNotifications({
                                notifications: merged,
                                loading: false,
                                unread: resp.unread,
                                hasMore: resp.hasNext
                            });
                        });
                })
                .catch(console.error);
        }
    }

    getItemClasses(item: Notification<any>): string {
        const classes = [styles.Item];
        if (item.read) {
            classes.push(styles.Read);
        }
        return classes.join(" ");
    }

    close() {
        this.props.toggleNotificationsMenu(false);
    }

    handlePageClick(e: MouseEvent) {
        if (e.type === "mousedown" && !this.menuContainer.current?.contains(e.target as Node) && !this.props.toggleRef.current?.contains(e.target as Node)) {
            this.close();
        }
    }

    markAllAsRead() {
        if (this.canMarkAllAsRead) {
            this.props.notifications.notifications.forEach(notif => notif.read = true);
            markNotificationAsRead("all")
                .then()
                .catch(console.error);
        }
    }

    onOpen() {
        this.props.setNotifications({notifications: [], loading: true, hasMore: false});
        getNotifications(5, 0)
            .then(resp => {
                notificationUserCache.withUsers(resp.items)
                    .then(items => {
                        const state: Partial<NotificationsState> = {
                            notifications: items,
                            unread: resp.unread,
                            hasMore: resp.hasNext,
                            loading: false
                        }
                        this.props.setNotifications(state);
                    })
                    .catch(console.error)
            })
            .catch(console.error)
    }

    componentDidMount(): void {
        window.addEventListener("mousedown", this.handlePageClick);
    }

    componentWillUnmount(): void {
        window.removeEventListener("mousedown", this.handlePageClick);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (!prevProps.open && this.props.open) {
            this.onOpen();
        }
    }

    getMarkAsReadClasses(): string {
        const classes = [styles.MarkAsRead];
        if (this.canMarkAllAsRead) {
            classes.push(styles.Enabled);
        }
        return classes.join(" ");
    }

    get canMarkAllAsRead(): boolean {
        return this.props.notifications.unread > 0 && this.props.notifications.notifications.length > 0;
    }
}
