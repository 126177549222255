import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import HomePage from "../Presentation/Home/Home";

function mapStateToProps(state: AppState) {
    return {
        user: state.user,
        profileReady: state.profileReady,
    }
}

function mapDispatchToProps(dispatch: any) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);