import React from "react";
import {cssVar} from "../../../../variables";

interface Props {
    fill?: string;
}

export default class AngleLeftIcon extends React.Component<Props> {
    render() {
        return (
            <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={"IconSvg"}>
                <path className={"IconPath"}
                      d="M0.238461 4.91581L4.84668 0.242269C5.16518 -0.0807552 5.68022 -0.0807552 5.99534 0.242269L6.76112 1.0189C7.07963 1.34192 7.07963 1.86426 6.76112 2.18385L3.49471 5.49656L6.76112 8.80928C7.07963 9.1323 7.07963 9.65464 6.76112 9.97423L5.99873 10.7577C5.68022 11.0808 5.16518 11.0808 4.85006 10.7577L0.241849 6.08419C-0.0800486 5.76117 -0.0800486 5.23883 0.238461 4.91581Z"
                      fill={this.props.fill || cssVar('--default-text')}/>
            </svg>
        )
    }
}
