export interface DocPage {
    id: string;
    title: string;
    subtitle: string;
}

export interface DocChapter {
    id: string;
    title: String;
    pages: DocPage[];
}

export interface DocIndex {
    chapters: DocChapter[];
}

export function getDocIndex(): Promise<DocIndex> {
    return fetch("https://raw.githubusercontent.com/wavy/wavyfm-docs/master/index.json")
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            } else {
                return resp.json().then(json => {
                    throw Error(json.description);
                })
            }
        })
}

export function getDocSource(chapter: string, page: string, signal: AbortController): Promise<string> {
    return fetch(`https://raw.githubusercontent.com/wavy/wavyfm-docs/master/chapters/${chapter}/${page}.md`, {
        signal: signal.signal
    })
        .then(resp => {
            if (resp.ok) {
                return resp.text();
            } else {
                return resp.text().then(text => {
                    throw Error(text);
                })
            }
        })
}
