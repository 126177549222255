import React from "react";
import {cssVar} from "../../../../variables";

export default class NavIconFriendAdd extends React.Component {
    render() {
        return (
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={"IconSvg"}>
                <path className={"IconPath"}
                      d="M14.5507 5.97596V6.60596H15.1807H16.7194V7.0809H15.1807H14.5507V7.7109V9.24957H14.0758V7.7109V7.0809H13.4458H11.9071V6.60596H13.4458H14.0758V5.97596V4.43728H14.5507V5.97596ZM8.91217 3.80728C8.91217 5.37591 7.64092 6.64716 6.07229 6.64716C4.50366 6.64716 3.23241 5.37591 3.23241 3.80728C3.23241 2.23866 4.50366 0.967402 6.07229 0.967402C7.64092 0.967402 8.91217 2.23865 8.91217 3.80728ZM6.07229 9.20837C6.82128 9.20837 7.53485 9.0517 8.18187 8.77463H8.50121C10.1647 8.77463 11.5146 10.1245 11.5146 11.788V12.9157C11.5146 13.2861 11.2138 13.5869 10.8434 13.5869H1.3012C0.930771 13.5869 0.63 13.2861 0.63 12.9157V11.788C0.63 10.1245 1.97987 8.77463 3.64337 8.77463H3.96316C4.61135 9.05137 5.3225 9.20837 6.07229 9.20837Z"
                      stroke={cssVar('--light-text')} strokeWidth="1.26"/>
            </svg>
        )
    }
}

// <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M14.5507 5.97596V6.60596H15.1807H16.7194V7.0809H15.1807H14.5507V7.7109V9.24957H14.0758V7.7109V7.0809H13.4458H11.9071V6.60596H13.4458H14.0758V5.97596V4.43728H14.5507V5.97596ZM8.91217 3.80728C8.91217 5.37591 7.64092 6.64716 6.07229 6.64716C4.50366 6.64716 3.23241 5.37591 3.23241 3.80728C3.23241 2.23866 4.50366 0.967402 6.07229 0.967402C7.64092 0.967402 8.91217 2.23865 8.91217 3.80728ZM6.07229 9.20837C6.82128 9.20837 7.53485 9.0517 8.18187 8.77463H8.50121C10.1647 8.77463 11.5146 10.1245 11.5146 11.788V12.9157C11.5146 13.2861 11.2138 13.5869 10.8434 13.5869H1.3012C0.930771 13.5869 0.63 13.2861 0.63 12.9157V11.788C0.63 10.1245 1.97987 8.77463 3.64337 8.77463H3.96316C4.61135 9.05137 5.3225 9.20837 6.07229 9.20837Z" stroke="#6F6F6F" stroke-width="1.26"/>
// </svg>

