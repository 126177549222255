import React from "react";
import Calendar, {CalendarTileProperties, ViewCallbackProperties} from "react-calendar";
import styles from "./ProfileDateCalendar.module.scss";

interface Props {
    className?: string;
    loading: boolean;
    countProvider: (date: Date) => number;
    onChangeMonth: (month: number, year: number) => void;
    onClickDay: (date: Date) => void;
    defaultValue?: Date;
    average: number;
}

interface State {
}

export default class ProfileDateCalendar extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.tileDisabled = this.tileDisabled.bind(this);
        this.formatWeekDay = this.formatWeekDay.bind(this);
        this.onViewChange = this.onViewChange.bind(this);
        this.tileClassName = this.tileClassName.bind(this);
    }

    render() {
        return (
            <Calendar className={this.getClasses()} tileDisabled={this.tileDisabled} view={"month"}
                      onClickDay={this.props.onClickDay} value={this.value}
                      tileClassName={this.tileClassName}
                      formatShortWeekday={this.formatWeekDay} onActiveStartDateChange={this.onViewChange}/>
        )
    }

    get value(): Date | undefined {
        if (this.props.defaultValue === undefined) {
            return null as unknown as undefined;
        } else {
            return this.props.defaultValue;
        }
    }

    onViewChange(props: ViewCallbackProperties) {
        this.props.onChangeMonth(props.activeStartDate.getMonth() + 1, props.activeStartDate.getFullYear());
        this.tileClassName = this.tileClassName.bind(this);
        this.forceUpdate();
    }

    tileDisabled(tile: CalendarTileProperties & { activeStartDate: Date }): boolean {
        if (tile.activeStartDate.getMonth() !== tile.date.getMonth()) return true;
        return !this.props.countProvider(tile.date);
    }

    formatWeekDay(locale: string, date: Date): string {
        return ["S", "M", "T", "W", "T", "F", "S"][date.getDay()];
    }

    componentDidMount(): void {
        const date = this.props.defaultValue ?? new Date();
        this.props.onChangeMonth(date.getMonth() + 1, date.getFullYear());
    }

    getClasses() {
        const classes = [styles.Calendar];
        if (this.props.loading) {
            classes.push(styles.Loading)
        }
        if (this.props.className) {
            classes.push(this.props.className);
        }
        return classes.join(" ");
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        // Force re-bind everytime we update
        this.tileClassName = this.tileClassName.bind(this);
    }

    tileClassName(props: CalendarTileProperties): string {
        let tile = props as (CalendarTileProperties & { activeStartDate: Date });

        if (tile.activeStartDate.getMonth() !== tile.date.getMonth()) return "";
        const count = this.props.countProvider(tile.date);
        if (!count) {
            return "";
        }
        const difference = count - this.props.average;
        if (difference > 100) {
            return "calendar--darkest";
        } else if (difference > 50) {
            return "calendar--darker"
        } else if (difference < -100) {
            return "calendar--lightest";
        } else if (difference < -50) {
            return "calendar--lighter";
        } else {
            return "";
        }
    }
}
