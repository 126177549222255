import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {setIncomingRequests, toggleGlobalLoading} from "../../Redux/Actions";
import UserSocial from "../Presentation/UserSocial/UserSocial";

function mapStateToProps(state: AppState) {
    return {
        authUser: state.user,
        authProfileReady: state.profileReady,
        incomingRequestsCount: state.incomingRequestsCount
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        toggleGlobalLoading,
        setIncomingRequests
    }, dispatch);
}

function mergeProps(stateProps: object, dispatchProps: object, ownProps: object) {
    const merged = {};
    Object.assign(merged, stateProps);
    Object.assign(merged, dispatchProps);
    Object.assign(merged, ownProps);
    return merged;
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UserSocial);
