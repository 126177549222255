export class TitleManager {
    private _title? = "";
    private _notif = 0;

    set(title?: string) {
        this._title = title;
        this.update();
    }

    setNotif(unread: number) {
        this._notif = unread;
        this.update();
    }

    private update() {
        let title = this._title ? (this._title + " · wavy.fm") : "wavy.fm";
        if (this._notif > 0) {
            title = `(${this._notif}) ${title}`;
        }
        document.title = title;
    }
}

export const titleManager = new TitleManager();
