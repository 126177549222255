import React from "react";
import {ForeignUser} from "../../../Redux/Types";
import {GetProfileAlbumsResponse} from "../../../API/Models";
import MusicList, {Entry} from "../MusicList/MusicList";
import {getProfileAlbums} from "../../../API/Calls";
import Skeleton from "./Skeleton";
import {convertDateRange, Ranges} from "../ProfileDateRange/ProfileDateRange";
import styles from "./UserHistory.module.scss";
import {titleManager} from "../../../Title";
import DefaultAlbum from "../../../Images/DefaultAlbum.svg";
import {spotifyUriToId} from "../../../SpotifyUtil";
import AlbumSubject from "../MusicSubject/AlbumSubject";
import {OPEN_IN_SPOTIFY} from "../MusicActionPopup/MusicAction";

interface TopAlbumsProps {
    user: ForeignUser;
    page: number;
    pageFunc: (hasNext: boolean, hasPrev: boolean, pageNumber: number, totalPages: number, totalResults: number) => void,
    searchFilter?: string;
    dateRange: Ranges;
}

interface TopAlbumsState {
    /**
     * Whether the albums have loaded
     */
    albumsLoaded: boolean;
    /**
     * Response with albums history info
     */
    albumsResponse: GetProfileAlbumsResponse | null;
    signal: AbortController;
}

export default class TopSongs extends React.Component<TopAlbumsProps, TopAlbumsState> {

    constructor(props: Readonly<TopAlbumsProps>) {
        super(props);

        this.state = {
            albumsLoaded: false,
            albumsResponse: null,
            signal: new AbortController()
        }
    }

    render() {
        return (
            <div className={"TopAlbumsPage"}>
                {this.renderMusicList()}
            </div>
        )
    }

    renderMusicList() {
        if (!this.state.albumsLoaded || !this.state.albumsResponse) {
            return <Skeleton/>
        }
        if (this.state.albumsResponse.albums.length === 0) {
            if (this.props.dateRange !== Ranges.AllTime || this.props.searchFilter) {
                return <div className={styles.Placeholder}>No results found</div>
            } else {
                return <div className={styles.Placeholder}>This user has no listening history</div>
            }
        }
        return (
            <MusicList
                entries={this.getEntries()}
                metricType={"count"}
                defaultSize={50}
                className={"RecentHistoryList"}
                autoCollapse={true}
                removeLastRowBorder={true}
                isMobile={window.innerWidth <= 768}
                actionGenerator={entry => [
                    OPEN_IN_SPOTIFY(entry.playerLink),
                ]}
            />
        )
    }

    getAlbumsPromise(): Promise<any> {
        if (this.props.user && this.props.user.id) {
            return getProfileAlbums(this.props.user.id, 50, this.props.page - 1, convertDateRange(this.props.dateRange), 0, this.props.searchFilter, this.state.signal)
                .then(albums => {
                    this.setState({
                        albumsLoaded: true,
                        albumsResponse: albums,
                    }, () => {
                        this.props.pageFunc(
                            this.state.albumsLoaded && !!this.state.albumsResponse && this.state.albumsResponse.pages > this.props.page,
                            this.state.albumsLoaded && this.props.page > 1,
                            this.state.albumsLoaded ? this.props.page : 0,
                            this.state.albumsResponse?.pages ?? 0,
                            this.state.albumsResponse?.total_albums ?? 0,
                        )
                    })
                })
        } else {
            return Promise.reject("No profile loaded.");
        }
    }

    loadPage(): void {
        this.abortAndReset(() => {
            window.scrollTo(0, 0);
            this.props.pageFunc(false, false, 0, 0, 0);
            this.setState({albumsLoaded: false});
            this.getAlbumsPromise()
                .catch(err => console.error(err));
        });
    }

    componentDidMount(): void {
        titleManager.set(`${this.props.user.username}'s Top Albums`);
        this.loadPage();
    }

    componentDidUpdate(prevProps: Readonly<TopAlbumsProps>, prevState: Readonly<TopAlbumsState>, snapshot?: any): void {
        if (prevProps.searchFilter !== this.props.searchFilter) {
            this.loadPage();
            return;
        }
        if (prevProps.page !== this.props.page) {
            this.loadPage();
            return;
        }
        if (prevProps.dateRange !== this.props.dateRange) {
            this.loadPage();
            return;
        }
    }

    componentWillUnmount(): void {
        this.state.signal.abort();
    }

    abortAndReset(cb?: () => void) {
        this.state.signal.abort();
        this.setState({signal: new AbortController()}, cb);
    }

    private getEntries(): Entry[] {
        if (this.state.albumsResponse) {
            return this.state.albumsResponse.albums.map(album => {
                // Spotify URL hack
                const url = `https://open.spotify.com/album/${spotifyUriToId(album.album.uri)}`;
                const image = album.album.images
                    .sort((a, b) => a.width - b.width)[0]?.url ?? DefaultAlbum;
                return {
                    id: album.album.uri,
                    primary: album.album.name,
                    secondary: album.album.artists[0].name,
                    metric: album.count.toLocaleString(),
                    icon: image,
                    playerLink: url,
                    actionSubject: () => <AlbumSubject name={album.album.name} artist={album.album.artists[0].name}
                                                       image={image}/>
                }
            });
        } else {
            return [];
        }
    }
}
