import React from "react";
import styles from "./NotificationsMenuItem.module.scss";
import {Notification, NotificationType} from "../../../API/Models";
import {ForeignUser, UserState} from "../../../Redux/Types";
import Avatar from "../Avatar/Avatar";
import {formatMinutes} from "../../../Times";
import {Link} from "react-router-dom";
import {markNotificationAsRead} from "../../../API/Calls";

interface Props {
    user: UserState;
    notification: Notification<any & { $profile?: ForeignUser }>;

    canDelete?: boolean;
    deleteButtonClass?: string;
    onDelete?: () => void;
}

interface State {

}

export default class NotificationsMenuItem extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.markAsRead = this.markAsRead.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    render() {
        return (
            <Link to={this.getLink()} className={this.getContainerClasses()}>
                {this.renderIcon()}
                <div className={styles.Info}>
                    {this.renderMessage()}
                    {this.renderTime()}
                </div>
                {this.renderChip()}
            </Link>
        )
    }

    getContainerClasses(): string {
        const classes = [styles.Container];
        if (this.props.notification.read) {
            classes.push(styles.Read);
        }
        return classes.join(" ");
    }

    renderMessage() {
        switch (this.props.notification.type) {
            case NotificationType.FriendRequest:
                return <div><span className={styles.Medium}>{this.profile?.username}</span> sent you a friend request
                </div>
            case NotificationType.FriendRequestAccepted:
                return <div><span className={styles.Medium}>{this.profile?.username}</span> accepted your friend request
                </div>
            case NotificationType.ProfileCommentReceived:
                return <div><span className={styles.Medium}>{this.profile?.username}</span> wrote a comment on your
                    profile
                </div>
            case NotificationType.ProfileCommentReplied:
                return <div><span className={styles.Medium}>{this.profile?.username}</span> replied to your comment
                </div>
            default:
                return <div>Error: Invalid notification</div>
        }
    }

    renderTime() {
        const minutes = Math.floor((Date.now() / 1000 - this.props.notification.date.getTime() / 1000) / 60);
        return <div className={styles.Time}>{formatMinutes(minutes)}</div>
    }

    renderIcon() {
        return <Avatar user={this.profile} size={"45px"}/>
    }

    renderChip() {
        return (
            <div className={styles.Actions}>
                {!this.props.notification.read ?
                    <div className={styles.Chip} title={"Mark as read"} onClick={this.markAsRead}/> : ""}
                {this.props.canDelete ?
                    <div className={this.getDeleteButtonClasses()} title={"Delete"}
                         onClick={this.onDelete}/> : ""}
            </div>
        )
    }

    markAsRead(e: React.MouseEvent) {
        e.stopPropagation();
        e.preventDefault();

        this.props.notification.read = true;
        markNotificationAsRead(this.props.notification.id)
            .catch(console.error);
        this.forceUpdate();
    }

    getLink(): string {
        switch (this.props.notification.type) {
            case NotificationType.FriendRequest:
            case NotificationType.FriendRequestAccepted:
                return `/user/${this.profile?.username}`;
            case NotificationType.ProfileCommentReceived:
                return `/user/${this.props.user.username}#comment=${this.props.notification.eventId}`
            case NotificationType.ProfileCommentReplied:
                return `/user/${this.props.notification.data.profile_id}#comment=${this.props.notification.eventId}`
            default:
                return "#";
        }
    }

    getDeleteButtonClasses() {
        const classes = [styles.Delete];
        if (this.props.deleteButtonClass) {
            classes.push(this.props.deleteButtonClass);
        }
        return classes.join(" ");
    }

    onDelete(e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();

        if (this.props.onDelete) {
            this.props.onDelete();
        }
    }

    get profile(): ForeignUser | undefined {
        return this.props.notification.data.$profile;
    }
}
