import * as Types from "./Types";
import {ForeignUser, NotificationsState} from "./Types";
import {Friendship, GetProfileListensResponse} from "../API/Models";
import {titleManager} from "../Title";

export function loadUserDataEmpty(): Types.LoadUserDataAction {
    return {
        type: Types.LOAD_USER_DATA,
        data: null
    }
}

export function loadUserData(data: any): Types.LoadUserDataAction {
    return {
        type: Types.LOAD_USER_DATA,
        data
    }
}

export function setUserEmail(email: string): Types.SetUserEmail {
    return {
        type: Types.SET_USER_EMAIL,
        email
    }
}

export function setUserPrivacy(privacy: object): Types.SetUserPrivacy {
    return {
        type: Types.SET_USER_PRIVACY,
        privacy
    }
}

export function setAuthFormsError(error: Error | null): Types.SetAuthFormsError {
    return {
        type: Types.SET_AUTH_FORMS_ERROR,
        error
    }
}

export function openUserMenu(): Types.OpenUserMenu {
    return {
        type: Types.OPEN_USER_MENU,
    }
}

export function closeUserMenu(): Types.CloseUserMenu {
    return {
        type: Types.CLOSE_USER_MENU,
    }
}

export function logout(): Types.Logout {
    return {
        type: Types.LOG_OUT
    }
}

export function toggleEditPasswordModal(visible: boolean): Types.ToggleEditPasswordModal {
    return {
        type: Types.TOGGLE_EDIT_PASSWORD_MODAL,
        visible,
    }
}

export function toggleEditEmailModal(visible: boolean): Types.ToggleEditEmailModal {
    return {
        type: Types.TOGGLE_EDIT_EMAIL_MODAL,
        visible,
    }
}

export function toggleEditUsernameModal(visible: boolean): Types.ToggleEditUsernameModal {
    return {
        type: Types.TOGGLE_EDIT_USERNAME_MODAL,
        visible,
    }
}

export function toggleDeleteModal(visible: boolean): Types.ToggleDeleteModal {
    return {
        type: Types.TOGGLE_DELETE_MODAL,
        visible,
    }
}


export function toggleMfaSetupModal(visible: boolean): Types.ToggleMfaSetupModal {
    return {
        type: Types.TOGGLE_MFA_SETUP_MODAL,
        visible,
    }
}

export function toggleMfaDisableModal(visible: boolean): Types.ToggleMfaDisableModal {
    return {
        type: Types.TOGGLE_MFA_DISABLE_MODAL,
        visible,
    }
}

export function toggleMfaRecoveryModal(visible: boolean): Types.ToggleMfaRecoveryModal {
    return {
        type: Types.TOGGLE_MFA_RECOVERY_MODAL,
        visible,
    }
}

export function toggleSettingsLoading(loading: boolean): Types.ToggleSettingsLoading {
    return {
        type: Types.TOGGLE_SETTINGS_LOADING,
        loading,
    }
}

export function toggleGlobalLoading(loading: boolean): Types.ToggleGlobalLoading {
    return {
        type: Types.TOGGLE_GLOBAL_LOADING,
        loading,
    }
}

export function openSearchMenu() {
    return {
        type: Types.OPEN_SEARCH_MENU,
    }
}

export function closeSearchMenu() {
    return {
        type: Types.CLOSE_SEARCH_MENU,
    }
}

export function setIncomingRequests(count: number) {
    return {
        type: Types.SET_INCOMING_REQUESTS,
        count
    }
}

export function loadProfile(profile: ForeignUser | null) {
    return {
        type: Types.LOAD_PROFILE,
        profile
    }
}

export function toggleProfileEditing(editing: boolean) {
    return {
        type: Types.TOGGLE_PROFILE_EDITING,
        editing
    }
}

export function toggleProfilePictureForm(open: boolean) {
    return {
        type: Types.TOGGLE_PROFILE_PICTURE_FORM,
        open
    }
}

export function loadProfileRecentListens(listens: GetProfileListensResponse | null) {
    return {
        type: Types.LOAD_PROFILE_RECENT_LISTENS,
        listens
    }
}

export function setProfileRecentListensLoading() {
    return {
        type: Types.SET_PROFILE_RECENT_LISTENS_LOADING,
    }
}

export function loadProfileFriendCount(count: number | null) {
    return {
        type: Types.LOAD_PROFILE_FRIEND_COUNT,
        count
    }
}

export function updateProfileFriendship(friendship: Friendship | null) {
    return {
        type: Types.UPDATE_PROFILE_FRIENDSHIP,
        friendship
    }
}

export function toggleProfileReportForm(open: boolean) {
    return {
        type: Types.TOGGLE_PROFILE_REPORT_FORM,
        open
    }
}

export function toggleProfileUnfriendConfirm(open: boolean) {
    return {
        type: Types.TOGGLE_PROFILE_UNFRIEND_CONFIRM,
        open
    }
}

export function toggleProfileBlockConfirm(open: boolean) {
    return {
        type: Types.TOGGLE_PROFILE_BLOCK_CONFIRM,
        open
    }
}

export function setNotifications(notifications: Partial<NotificationsState>): Types.SetNotifications {
    titleManager.setNotif(notifications.unread ?? 0);
    return {
        type: Types.SET_NOTIFICATIONS,
        notifications
    }
}

export function toggleNotificationsMenu(open: boolean): Types.ToggleNotificationsMenu {
    return {
        type: Types.TOGGLE_NOTIFICATIONS_MENU,
        open
    }
}

export function openCommentReportForm(commentUser: ForeignUser, profileId: string, commentId: string, replyId?: string): Types.OpenCommentReportForm {
    return {
        type: Types.OPEN_COMMENT_REPORT_FORM,
        commentUser,
        profileId,
        commentId,
        replyId,
    }
}

export function closeCommentReportForm(): Types.CloseCommentReportForm {
    return {
        type: Types.CLOSE_COMMENT_REPORT_FORM,
    }
}

export function setTrackingDelayedSince(since?: Date): Types.SetTrackingDelayedSince {
    return {
        type: Types.SET_TRACKING_DELAYED_SINCE,
        since
    }
}

export function dismissTrackingDelayed(): Types.DismissTrackingDelayed {
    return {
        type: Types.DISMISS_TRACKING_DELAYED,
    }
}
