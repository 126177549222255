import React from "react";
import "./IntroUser.scss";
import {UserState} from "../../../../Redux/Types";
import ListenIcon from "./ListenIcon.svg";
import ListenIconDark from "./ListenIconDark.svg";
import {Link} from "react-router-dom";
import {countListensSince, getProfileArtists} from "../../../../API/Calls";
import {beginningOfDay} from "../../../../API/UniversalTime";
import {isDarkMode} from "../../../../DarkMode";

export interface IntroUserProps {
    user: UserState;
}

export interface IntroUserState {
    listensToday: number;
    artistUrl?: string;
    artistName?: string;
    artistCount?: number;
}

export default class IntroUser extends React.Component<IntroUserProps, IntroUserState> {

    constructor(props: Readonly<IntroUserProps>) {
        super(props);

        const cachedListens = window.localStorage.getItem("wavy.cache.listens_today");
        let listens = 0;
        if (cachedListens) {
            listens = parseInt(cachedListens, 10);
        }

        this.state = {
            listensToday: listens
        }
    }

    render() {
        return (
            <div className={"IntroBannerUserParent"}>
                <div className={"IntroBannerUser"}>
                <span className={"IntroText"}>
                    {this.getGreetingMessage()}
                    <span className={"Username"}>{this.props.user.username}</span>?
                </span>
                    <div className={"Listens"}>
                        <div className={"ListenCounterParent"}>
                            <img src={!isDarkMode() ? ListenIcon : ListenIconDark} alt={"Icon"}/>
                            <span className={"ListenCounter"}>{this.state.listensToday}</span> today
                        </div>
                        <Link to={"/user/" + this.props.user.username} className={"ProfileLink"}/>
                    </div>
                </div>
                <div className={"UserArtistBlob"} style={{backgroundImage: this.getBlobImage()}}
                     title={this.getBlobTitle()}/>
            </div>
        )
    }

    componentDidMount(): void {
        countListensSince(this.props.user.id, beginningOfDay())
            .then(resp => {
                window.localStorage.setItem("wavy.cache.listens_today", resp.count.toString());
                this.setState({listensToday: resp.count});
            })
            .catch(console.error);

        getProfileArtists(this.props.user.id, 1, 0, beginningOfDay())
            .then(resp => {
                const artist = resp.artists[0];
                if (!artist || artist.artist.images.length === 0) return;

                const image = artist.artist.images
                    .sort((a, b) => b.width - a.width)[0].url;

                this.setState({artistUrl: image, artistName: artist.artist.name, artistCount: artist.count});
            })
            .catch(console.error);
    }

    getBlobImage(): string | undefined {
        if (this.state.artistUrl) {
            return `url(${this.state.artistUrl})`;
        } else {
            return "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=)";
        }
    }

    getGreetingMessage() {
        const date = new Date();
        const day = date.getDay();

        switch (day % 3) {
            case 0:
                return "Which song is stuck in your head, "
            case 1:
                return "What are you listening to today, "
            default:
                return "What caught your ear today, "
        }
    }

    getBlobTitle(): string {
        if (this.state.artistName && this.state.artistCount) {
            return `You listened to ${this.state.artistName} ${this.state.artistCount} times today`
        }
        return "";
    }
}
