import React from "react";
import {formatMinutes} from "../../../Times";
import {Link} from "react-router-dom";
import AngleRightIcon from "../Icons/AngleRight/AngleRight";
import styles from "./MusicList.module.scss";
import AddIcon from "./AddIcon.svg";
import DeleteIcon from "./DeleteIcon.svg";
import MenuIcon from "../Icons/MenuIcon/MenuIcon";
import MusicActionPopup from "../MusicActionPopup/MusicActionPopup";
import {MusicAction} from "../MusicActionPopup/MusicAction";

export interface Entry {
    id: string;
    icon?: string;
    primary: string;
    secondary?: string;
    metric: any;
    editMode?: "none" | "deleted";
    playerLink: string;

    actionSubject: () => React.ReactNode;
}

interface MusicListProps {
    entries: Entry[];
    isMobile?: boolean;
    autoCollapse?: boolean;

    defaultSize?: number;
    moreLink?: string;
    className?: string;

    primaryColumnClasses?: string;

    metricType: "count" | "time";
    metricIcon?: React.ReactNode;
    metricColumnClass?: string;

    rowClass?: string;
    removeLastRowBorder?: boolean;

    iconColumnClass?: string;

    ranked?: boolean;
    rankColumnClasses?: string;

    editing?: boolean;
    toggleEntry?: (entry: Entry) => void;

    actionGenerator: (entry: Entry) => MusicAction[];
}

interface State {
    openMenuEntryId?: string;
}

export default class MusicList extends React.Component<MusicListProps, State> {

    private entryToggleRefMap = new Map<string, React.RefObject<HTMLDivElement>>();
    private autoUpdateHandle?: any;

    constructor(props: Readonly<MusicListProps>) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={this.getListClasses()}>
                {this.getRows()}
                {
                    !this.props.moreLink ? "" :
                        <div className={styles.ViewMore}>
                            <Link className={["AccentLink", styles.ViewMoreLink].join(" ")} to={this.props.moreLink}
                                  aria-label={"View More Songs"}>
                                View More
                                <AngleRightIcon/>
                            </Link>
                        </div>
                }
            </div>
        )
    }

    getRows() {
        return this.props.entries.map((entry, index) => {
            if (!this.props.defaultSize || this.props.defaultSize > index) {
                return (
                    <div className={this.getRowClasses(entry)} key={entry.id} onClick={() => this.clickRow(entry)}>
                        <div className={styles.Columns}>
                            {
                                !this.props.editing ? "" :
                                    <img src={entry.editMode === "deleted" ? AddIcon : DeleteIcon}
                                         className={this.getEditIconClasses()}
                                         alt={entry.editMode === "deleted" ? "Cancel" : "Delete"}
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             this.props.toggleEntry?.(entry)
                                         }}
                                         title={entry.editMode === "deleted" ? "Cancel" : "Delete"}/>
                            }
                            {
                                !this.props.ranked ? "" :
                                    <div className={this.getRankColumnClasses()}>{index + 1}</div>
                            }
                            {
                                !entry.icon ? "" :
                                    <div className={this.getIconColumnClasses()}
                                         style={{backgroundImage: "url(" + entry.icon + ")"}}/>
                            }
                            {this.renderPrimaryAndSecondary(entry)}
                            <div className={this.getMetricColumnClasses()}>
                                <div title={this.explainMetric(entry.metric)}>
                                    {this.printMetric(entry.metric)}</div>
                                {
                                    this.props.metricIcon || ""
                                }
                            </div>
                        </div>
                    </div>
                )
            } else {
                return null;
            }
        });
    }

    renderPrimary(entry: Entry) {
        return (
            <div className={this.getPrimaryColumnClasses()}>
                <span title={entry.primary}>{entry.primary}</span>
            </div>
        )
    }

    renderSecondary(entry: Entry) {
        return (
            <div className={styles.SecondaryColumn}>
                <span title={entry.secondary}>{entry.secondary}</span>
                <div className={styles.EllipsisColumn + " " + (this.isForceShowEllipsis(entry) ? styles.Opened : "")}
                     ref={this.toggleFor(entry)} onClick={() => this.doToggleFor(entry)}>
                    <MenuIcon className={styles.EllipsisIcon}/>
                </div>
                {this.renderPopup(entry)}
            </div>
        )
    }

    renderPopup(entry: Entry) {
        if (this.isRowMenuOpen(entry)) {
            return <MusicActionPopup subject={entry.actionSubject()} toggleRef={this.toggleFor(entry)}
                                     actions={this.props.actionGenerator(entry)}
                                     onCloseRequested={() => this.closeRowMenu()}/>
        } else {
            return "";
        }
    }

    renderPrimaryAndSecondary(entry: Entry) {
        if (this.isMobile()) {
            return (
                <div className={styles.MainColumn}>
                    {this.renderPrimary(entry)}
                    {this.renderSecondary(entry)}
                </div>
            )
        } else {
            return (
                <>
                    {this.renderPrimary(entry)}
                    {this.renderSecondary(entry)}
                </>
            )
        }
    }

    isMobile() {
        return window.innerWidth <= 768;
    }

    printMetric(metric: any) {
        if (this.props.metricType === "time") {
            metric = metric as Date;
            if (metric.getTime() === 0) {
                return "now"
            }
            const minutes = Math.floor((Date.now() / 1000 - metric / 1000) / 60);
            return <span>{formatMinutes(minutes, this.props.isMobile)}</span>
        } else if (this.props.metricType === "count") {
            return <span>{metric}</span>
        }
    }

    explainMetric(metric: any): string {
        if (this.props.metricType === "time") {
            return (metric as Date).toLocaleString();
        } else {
            return "";
        }
    }

    shortenName(name: string, full: boolean): string {
        if (!full && name.length > 35) {
            return name.substr(0, 32) + "...";
        } else {
            return name;
        }
    }

    getListClasses(): string {
        const classes = [styles.MusicList];
        if (this.props.className) {
            classes.push(this.props.className);
        }
        return classes.join(" ");
    }

    getRowClasses(entry: Entry): string {
        const classes = [styles.Row];
        if (this.props.autoCollapse) {
            classes.push(styles.AutoCollapse);
        }
        if (this.props.removeLastRowBorder) {
            classes.push(styles.RemoveLastRowBorder);
        }
        if (this.props.editing) {
            classes.push(styles.Editing);
        }
        if (this.props.editing && entry.editMode === "deleted") {
            classes.push(styles.Deleted);
        }
        if (this.isRowMenuOpen(entry)) {
            classes.push(styles.Opened);
        }
        if (this.props.rowClass) {
            classes.push(this.props.rowClass);
        }
        return classes.join(" ");
    }

    getMetricColumnClasses(): string {
        const classes = [styles.MetricColumn];
        if (this.props.metricColumnClass) {
            classes.push(this.props.metricColumnClass);
        }
        return classes.join(" ");
    }

    getIconColumnClasses(): string {
        const classes = [styles.IconColumn];
        if (this.props.iconColumnClass) {
            classes.push(this.props.iconColumnClass);
        }
        return classes.join(" ");
    }

    getRankColumnClasses(): string {
        const classes = [styles.RankColumn];
        if (this.props.rankColumnClasses) {
            classes.push(this.props.rankColumnClasses);
        }
        return classes.join(" ");
    }

    getPrimaryColumnClasses(): string {
        const classes = [styles.PrimaryColumn];
        if (this.props.primaryColumnClasses) {
            classes.push(this.props.primaryColumnClasses);
        }
        return classes.join(" ");
    }

    getEditIconClasses(): string {
        return styles.EditIcon;
    }

    isForceShowEllipsis(entry: Entry) {
        return window.innerWidth > 768 && this.isRowMenuOpen(entry);
    }

    isRowMenuOpen(entry: Entry) {
        return this.state.openMenuEntryId === entry.id;
    }

    openRowMenu(entry: Entry) {
        this.setState({openMenuEntryId: entry.id});
    }

    closeRowMenu() {
        this.setState({openMenuEntryId: undefined});
    }

    toggleFor(entry: Entry): React.RefObject<HTMLDivElement> {
        const existing = this.entryToggleRefMap.get(entry.id);
        if (existing) {
            return existing;
        } else {
            const ref = React.createRef<HTMLDivElement>();
            this.entryToggleRefMap.set(entry.id, ref);
            return ref;
        }
    }

    doToggleFor(entry: Entry) {
        if (this.state.openMenuEntryId) {
            this.closeRowMenu();
        } else {
            this.openRowMenu(entry);
        }
    }

    componentDidMount() {
        this.autoUpdateHandle = setInterval(() => this.forceUpdate(), 5000);
    }

    componentWillUnmount() {
        if (this.autoUpdateHandle) {
            clearInterval(this.autoUpdateHandle);
        }
    }

    clickRow(entry: Entry) {
        if (window.innerWidth <= 768) {
            if (this.props.editing) {
                // Toggles edit on mobile
                this.props.toggleEntry?.(entry);
            } else {
                // Opens the menu on mobile
                this.openRowMenu(entry);
            }
        }
    }
}
