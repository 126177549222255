import "./AccountSettings.scss";
import React from "react";
import {UserState} from "../../../../Redux/Types";
import CardModal from "../../CardModal/CardModal";
import Email from "./Email";
import Password from "./Password";
import {getCurrentUser, postToken, sendEmailVerification} from "../../../../API/Calls";
import Button, {ButtonColor} from "../../Button/Button";
import {titleManager} from "../../../../Title";
import MfaSetup from "./MfaSetup";
import MfaDisable from "./MfaDisable";
import MfaRecovery from "./MfaRecovery";
import Username from "./Username";

export interface AccountSettingsProps {
    user: UserState | null,
    showPasswordModal: boolean,
    showEmailModal: boolean,
    showUsernameModal: boolean;
    showMfaSetupModal: boolean;
    showMfaDisableModal: boolean;
    showMfaRecoveryModal: boolean;

    toggleEditPasswordModal: (visible: boolean) => void,
    toggleEditEmailModal: (visible: boolean) => void,
    toggleEditUsernameModal: (visible: boolean) => void,
    toggleMfaSetupModal: (visible: boolean) => void,
    toggleMfaDisableModal: (visible: boolean) => void,
    toggleMfaRecoveryModal: (visible: boolean) => void,
    setUserEmail: (newEmail: string) => void,
    loadUserData: (data: any) => void,
    toggleSettingsLoading: (loading: boolean) => void,
}

interface AccountSettingsState {
    emailVerificationSent: boolean,
    ready: boolean,
}

export default class AccountSettings extends React.Component<AccountSettingsProps, AccountSettingsState> {

    constructor(props: Readonly<AccountSettingsProps>) {
        super(props);

        this.state = {
            emailVerificationSent: false,
            ready: false,
        };

        this.openEmailModal = this.openEmailModal.bind(this);
        this.closeEmailModal = this.closeEmailModal.bind(this);
        this.closeEmailModalWithResult = this.closeEmailModalWithResult.bind(this);
        this.openPasswordModal = this.openPasswordModal.bind(this);
        this.closePasswordModal = this.closePasswordModal.bind(this);
        this.openMfaSetupModal = this.openMfaSetupModal.bind(this);
        this.closeMfaSetupModal = this.closeMfaSetupModal.bind(this);
        this.closeMfaDisableModal = this.closeMfaDisableModal.bind(this);
        this.openMfaDisableModal = this.openMfaDisableModal.bind(this);
        this.openMfaRecoveryModal = this.openMfaRecoveryModal.bind(this);
        this.closeMfaRecoveryModal = this.closeMfaRecoveryModal.bind(this);
        this.sendEmailVerification = this.sendEmailVerification.bind(this);
        this.openUsernameModal = this.openUsernameModal.bind(this);
        this.closeUsernameModal = this.closeUsernameModal.bind(this);
    }

    render() {
        return (
            <div className={"AccountSettings"}>
                <div>
                    <div className={"Title"}>Account</div>
                </div>
                <div className={"Content"}>
                    {
                        !this.state.ready || this.props.user === null ? <div className={"LoadingEmpty"}/> :
                            <div>
                                <CardModal isOpen={this.props.showEmailModal}
                                           contentLabel={"Change Email Address"}
                                           onRequestClose={this.closeEmailModal}
                                           title={"Change Email Address"}>
                                    <Email user={this.props.user}
                                           closeFunction={this.closeEmailModalWithResult}/>
                                </CardModal>
                                <CardModal isOpen={this.props.showPasswordModal}
                                           contentLabel={"Change Password"}
                                           onRequestClose={this.closePasswordModal}
                                           title={"Change Password"}>
                                    <Password user={this.props.user} closeFunction={this.closePasswordModal}/>
                                </CardModal>
                                <CardModal isOpen={this.props.showUsernameModal}
                                           contentLabel={"Change Username"}
                                           onRequestClose={() => this.closeUsernameModal(false)}
                                           title={"Change Username"}>
                                    <Username user={this.props.user} closeFunction={this.closeUsernameModal}/>
                                </CardModal>
                                <CardModal isOpen={this.props.showMfaSetupModal}
                                           contentLabel={"Two-Factor Authentication"}
                                           onRequestClose={() => {
                                           }}
                                           title={"Two-Factor Authentication"}>
                                    <MfaSetup user={this.props.user} closeFunction={this.closeMfaSetupModal}/>
                                </CardModal>
                                <CardModal isOpen={this.props.showMfaDisableModal}
                                           contentLabel={"Disable 2FA"}
                                           onRequestClose={() => this.closeMfaDisableModal(false)}
                                           title={"Disable 2FA"}>
                                    <MfaDisable user={this.props.user} closeFunction={this.closeMfaDisableModal}/>
                                </CardModal>
                                <CardModal isOpen={this.props.showMfaRecoveryModal}
                                           contentLabel={"View Recovery Codes"}
                                           onRequestClose={this.closeMfaRecoveryModal}
                                           title={"View Recovery Codes"}>
                                    <MfaRecovery user={this.props.user} closeFunction={this.closeMfaRecoveryModal}/>
                                </CardModal>
                                <div>
                                    <div className={"Section"}>
                                        <div className={"DescriptionParent"}>
                                            <div className={"DescriptionTitle"}>Email Address</div>
                                            <div
                                                className={"Description" + (!this.props.user.emailVerified ? " UnverifiedEmail" : "")}>
                                                {!this.props.user.emailVerified ?
                                                    !this.state.emailVerificationSent ?
                                                        <span>
                                                            <span className={"VerifyLink"}
                                                                  onClick={this.sendEmailVerification}>Verify</span> -&nbsp;
                                                        </span>
                                                        :
                                                        <span>
                                                            <span>Verification sent to </span>
                                                        </span>
                                                    : ""
                                                }
                                                {this.props.user.email}
                                            </div>
                                        </div>
                                        <div className={"ButtonParent"}>
                                            <Button onClick={this.openEmailModal}
                                                    color={ButtonColor.Primary}>Change</Button>
                                        </div>
                                    </div>
                                    <div className={"Section"}>
                                        <div className={"DescriptionParent"}>
                                            <div className={"DescriptionTitle"}>Change Username</div>
                                            <div className={"Description"}>
                                                You can change your username every 24 hours
                                            </div>
                                        </div>
                                        <div className={"ButtonParent"}>
                                            <Button onClick={this.openUsernameModal}
                                                    disabled={!this.canChangeUsername()}
                                                    color={ButtonColor.Primary}>Change</Button>
                                        </div>
                                    </div>
                                    <div className={"Section"}>
                                        <div className={"DescriptionParent"}>
                                            <div className={"DescriptionTitle"}>Change Password</div>
                                            <div className={"Description"}>
                                                Password must be at least 8 characters long
                                            </div>
                                        </div>
                                        <div className={"ButtonParent"}>
                                            <Button onClick={this.openPasswordModal}
                                                    color={ButtonColor.Primary}>Change</Button>
                                        </div>
                                    </div>
                                    {this.renderMfa()}
                                </div>
                            </div>
                    }
                </div>
            </div>
        )
    }

    renderMfa() {
        if (this.props.user?.$mfa_enabled) {
            return (
                <div className={"Section"}>
                    <div className={"DescriptionParent"}>
                        <div className={"DescriptionTitle"}>Two-Factor Authentication</div>
                        <div className={"DescriptionPrimary"}>
                            <svg width="12" height="14" viewBox="0 0 10 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 style={{marginRight: "8px", transform: "translateY(1px)"}}>
                                <path
                                    d="M8.92857 5.25H8.39286V3.5625C8.39286 1.59844 6.87054 0 5 0C3.12946 0 1.60714 1.59844 1.60714 3.5625V5.25H1.07143C0.479911 5.25 0 5.75391 0 6.375V10.875C0 11.4961 0.479911 12 1.07143 12H8.92857C9.52009 12 10 11.4961 10 10.875V6.375C10 5.75391 9.52009 5.25 8.92857 5.25ZM6.60714 5.25H3.39286V3.5625C3.39286 2.63203 4.11384 1.875 5 1.875C5.88616 1.875 6.60714 2.63203 6.60714 3.5625V5.25Z"
                                    fill="#49C5D3"/>
                            </svg>
                            Your account is secured with 2FA
                        </div>
                    </div>
                    <div className={"ButtonParent"}>
                        <Button color={ButtonColor.Primary} outline={true} onClick={this.openMfaRecoveryModal}>
                            View Recovery Codes</Button>
                        <Button color={ButtonColor.Danger} outline={true}
                                onClick={this.openMfaDisableModal}>Disable</Button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={"Section"}>
                    <div className={"DescriptionParent"}>
                        <div className={"DescriptionTitle"}>Two-Factor Authentication</div>
                        <div className={"Description"}>
                            Secure your account with 2FA
                        </div>
                    </div>
                    <div className={"ButtonParent"}>
                        <Button onClick={this.openMfaSetupModal}
                                color={ButtonColor.Primary}>Enable</Button>
                    </div>
                </div>
            )
        }
    }

    openEmailModal() {
        this.props.toggleEditEmailModal(true);
    }

    openPasswordModal() {
        this.props.toggleEditPasswordModal(true);
    }

    openMfaSetupModal() {
        this.props.toggleMfaSetupModal(true);
    }

    openMfaDisableModal() {
        this.props.toggleMfaDisableModal(true);
    }

    openMfaRecoveryModal() {
        this.props.toggleMfaRecoveryModal(true);
    }

    closeEmailModal() {
        this.props.toggleEditEmailModal(false);
    }

    closeEmailModalWithResult(newEmail?: string) {
        if (newEmail) {
            this.props.setUserEmail(newEmail);
            this.setState({emailVerificationSent: true});
        }
        this.closeEmailModal();
    }

    openUsernameModal() {
        this.props.toggleEditUsernameModal(true);
    }

    closeUsernameModal(success: boolean) {
        this.props.toggleEditUsernameModal(false);
        if (success) {
            this.loadUser();
        }
    }

    closePasswordModal() {
        this.props.toggleEditPasswordModal(false);
    }

    closeMfaDisableModal(success: boolean) {
        this.props.toggleMfaDisableModal(false);
        if (success) {
            this.loadUser();
        }
    }

    closeMfaSetupModal(success: boolean) {
        this.props.toggleMfaSetupModal(false);
        if (success) {
            this.loadUser();
        }
    }

    closeMfaRecoveryModal() {
        this.props.toggleMfaRecoveryModal(false);
    }

    sendEmailVerification() {
        this.setState({emailVerificationSent: true});
        if (this.props.user) {
            sendEmailVerification()
                .catch(console.error);
        }
    }

    loadUser(): void {
        this.props.toggleSettingsLoading(true);

        postToken()
            .then(() => getCurrentUser()
                .then(data => {
                    this.props.loadUserData(data);
                    this.props.toggleSettingsLoading(false);
                    this.setState({ready: true});
                }))
            .catch(console.error)
    }

    canChangeUsername(): boolean {
        const lastChangeDate = this.props.user?.lastUsernameChange;
        if (lastChangeDate) {
            return Date.now() > (lastChangeDate.getTime() + (24 * 3600 * 1000));
        } else {
            return true;
        }
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Account Settings");

        if (this.props.user) {
            this.loadUser();
        }
    }

    componentDidUpdate(prevProps: Readonly<AccountSettingsProps>, prevState: Readonly<AccountSettingsState>, snapshot?: any): void {
        if (this.props.user && !prevProps.user) {
            this.setState({ready: true});
            this.props.toggleSettingsLoading(false);
        }
    }

    componentWillUnmount() {
        this.closeEmailModal();
        this.closePasswordModal();
        this.closeMfaSetupModal(false);
    }
}
