import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import './Polyfills/trimStart';
import * as Sentry from '@sentry/browser';
import {CaptureConsole} from "@sentry/integrations";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {createStore} from "redux";
import {appReducer} from "./Redux/Reducers";
import {Provider} from "react-redux";
import {getCurrentUser, getTrackingDelayedSince, postToken} from "./API/Calls";
import {loadUserData, loadUserDataEmpty, setTrackingDelayedSince} from "./Redux/Actions";
import AppContainer from "./Components/Containers/AppContainer";
import {isDarkMode} from "./DarkMode";

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

if (sentryDsn) {
    Sentry.init({
        dsn: sentryDsn,
        debug: true,
        integrations: [new CaptureConsole({levels: ['error']})]
    });
}

function countStorage() {
    let _lsTotal = 0, _xLen, _x;
    for (_x in localStorage) {
        if (!localStorage.hasOwnProperty(_x)) {
            continue;
        }
        _xLen = ((localStorage[_x].length + _x.length) * 2);
        _lsTotal += _xLen;
    }
    return _lsTotal / 1024;
}

function clearCache() {
    Object.entries(localStorage).map(x => x[0]).filter(key => key.startsWith("wavy.cache.")).forEach(key => localStorage.removeItem(key));
}

if (countStorage() > 2000) {
    clearCache();
}

if (isDarkMode()) {
    document.documentElement.setAttribute('data-theme', 'dark');
} else {
    document.documentElement.setAttribute('data-theme', 'light');
}

const store = createStore(appReducer);

ReactDOM.render(
    <Provider store={store}>
        <AppContainer/>
    </Provider>,
    document.getElementById('root'));

postToken()
    .then(loggedIn => {
        if (loggedIn) {
            return getCurrentUser();
        } else {
            return Promise.resolve(null);
        }
    })
    .then(data => {
        store.dispatch(loadUserData(data));
    })
    .catch(e => {
        console.error("Error while checking user token:", e);
        store.dispatch(loadUserDataEmpty());
    });

function checkDelay() {
    if (store.getState().trackingDelayedDismissed) {
        return;
    }
    getTrackingDelayedSince()
        .then(since => {
            store.dispatch(setTrackingDelayedSince(since));
        })
        .catch(console.error)
}

setInterval(checkDelay, 60_000);
checkDelay();
