export function formatMinutes(minutes: number, short?: boolean): string {
    if (minutes <= 0) {
        return !short ? "just now" : "now";
    }
    if (minutes === 1) {
        return !short ? "1 minute ago" : "1m ago"
    }
    if (minutes < 60) {
        return minutes + (!short ? " minutes ago" : "m ago");
    }
    if (minutes < 120) {
        return !short ? "an hour ago" : "1h ago";
    }
    if (minutes < 24 * 60) {
        return Math.floor(minutes / 60) + (!short ? " hours ago" : "h ago");
    }
    if (minutes < 48 * 60) {
        return !short ? "a day ago" : "1d ago";
    }
    if (minutes < 24 * 60 * 30) {
        return Math.floor(minutes / 60 / 24) + (!short ? " days ago" : "d ago");
    }
    if (minutes < 24 * 60 * 365) {
        const months = Math.floor(minutes / 60 / 24 / 30);
        return months + (months === 1 ? " month ago" : " months ago");
    }
    if (minutes < 24 * 60 * 365 * 2) {
        return !short ? "a year ago" : "1y ago";
    }
    return Math.floor(minutes / 60 / 24 / 365) + "y ago";
}