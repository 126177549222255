import React from "react";
import styles from "./IntroTimeline.module.scss";

export default class IntroTimeline extends React.Component {
    render() {
        if (window.innerWidth <= 768) {
            return (
                <div className={styles.Background}>
                    <div className={styles.Container}>
                        <div className={[styles.TimelineGroup, styles.Current].join(" ")}>
                            <div className={styles.TimelineSide}>
                            </div>
                            <span className={styles.TimelineGuide}/>
                            <div className={styles.TimelineSide}>
                                {this.renderEvent("October 2019", "Project Started", "The first version of wavy.fm is deployed publicly on October 19, 2019")}
                                {this.renderEvent("February 2020", "Spotify Tracking", "wavy.fm can automatically record what you listen to on Spotify")}
                                {this.renderEvent("July 2021", "50 Million", "Over 6,000 wavy.fm users achieve 50 million combined listens")}
                            </div>
                        </div>
                        <div className={styles.TimelineGroup}>
                            <div className={styles.YouAreHere}/>
                        </div>
                        {/* <div className={[styles.TimelineGroup, styles.Future].join(" ")}>
                            <div className={styles.TimelineSide}>
                            </div>
                            <span className={styles.TimelineGuide}/>
                            <div className={styles.TimelineSide}>
                                {this.renderEvent("Early 2021", "Music Database", "A brand new database for music history, supporting any music source and a public API")}
                                {this.renderEvent("2021", "Music Reviews", "Rate and share your thoughts on your music library")}
                                {this.renderEvent("2021", "Communities", "Reinventing how you discover, share, and discuss music")}
                            </div>
                        </div> */}
                        <div className={styles.TimelineGroup}>
                            <div className={styles.Beyond}/>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={styles.Background}>
                    <div className={styles.Container}>
                        <div className={[styles.TimelineGroup, styles.Current].join(" ")}>
                            <div className={styles.TimelineSide}>
                                {this.renderEvent("October 2019", "Project Started", "The first version of wavy.fm is deployed publicly on October 19, 2019")}
                                {this.renderEvent("July 2021", "50 Million", "Over 6,000 wavy.fm users achieve 50 million combined listens")}
                            </div>
                            <span className={styles.TimelineGuide}/>
                            <div className={styles.TimelineSide}>
                                {this.renderEvent("February 2020", "Spotify Tracking", "wavy.fm can automatically record what you listen to on Spotify")}
                            </div>
                        </div>
                        <div className={styles.TimelineGroup}>
                            <div className={styles.YouAreHere}/>
                        </div>
                        {/* <div className={[styles.TimelineGroup, styles.Future].join(" ")}>
                            <div className={styles.TimelineSide}>
                                {this.renderEvent("2021", "Music Reviews", "Rate and share your thoughts on your music library")}
                            </div>
                            <span className={styles.TimelineGuide}/>
                            <div className={styles.TimelineSide}>
                                {this.renderEvent("Early 2021", "Music Database", "A brand new database for music history, supporting any music source and a public API")}
                                {this.renderEvent("2021", "Communities", "Reinventing how you discover, share, and discuss music")}
                            </div>
                        </div> */}
                        <div className={styles.TimelineGroup}>
                            <div className={styles.Beyond}/>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderEvent(date: string, title: string, text: string) {
        return (
            <div className={styles.EventContainer}>
                <div className={styles.EventMeta}>
                    <div className={styles.EventDate}>
                        {date}
                    </div>
                    <div className={styles.EventTitle}>
                        {title}
                    </div>
                    <div className={styles.EventText}>
                        {text}
                    </div>
                </div>
                <div className={styles.EventGuide}/>
            </div>
        )
    }
}
