import React from "react";
import styles from "./AboutWavy.module.scss";
import SpotifyIcon from "./SpotifyIcon.svg";
import MusicIcon from "./MusicIcon.svg";
import CommunitiesIcon from "./CommunitiesIcon.svg";
import DiscoverIcon from "./DiscoverIcon.svg";
import ExploreIcon from "./ExploreIcon.svg";
import FriendsIcon from "./FriendsIcon.svg";
import {titleManager} from "../../../Title";
import GetStarted from "../GetStarted/GetStarted";
import IntroTimeline from "../Home/IntroTimeline/IntroTimeline";

export default class AboutWavy extends React.Component {
    render() {
        return (
            <div className={styles.Content}>
                <div className={styles.SectionIntro}>
                    <div className={styles.Container}>
                        <div className={styles.Columns}>
                            <div className={styles.Headline}>
                                Connecting people through music.
                            </div>
                            <div className={styles.LongForm}>
                                Track your music history, showcase your favorite songs, artists and albums, all while
                                keeping your friends and family close to your new discoveries. Just connect and start
                                listening!
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.SectionFeatures}>
                    <div className={styles.Container}>
                        <div className={styles.Title}>
                            Features
                        </div>
                        <div className={styles.Columns}>
                            <div>
                                <div className={styles.FeatureCard}>
                                    <img src={SpotifyIcon} alt={"Spotify"}/>
                                    <div>
                                        Track your Spotify history
                                    </div>
                                </div>
                                <div className={styles.FeatureCard}>
                                    <div className={styles.Soon}>Soon</div>
                                    <img src={FriendsIcon} alt={"Spotify"}/>
                                    <div>
                                        Share songs with friends
                                    </div>
                                </div>
                                <div className={styles.FeatureCard}>
                                    <div className={styles.Soon}>Soon</div>
                                    <img src={DiscoverIcon} alt={"Spotify"}/>
                                    <div>
                                        Discover and review music
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={styles.FeatureCard}>
                                    <img src={MusicIcon} alt={"Spotify"}/>
                                    <div>
                                        Build your music profile
                                    </div>
                                </div>
                                <div className={styles.FeatureCard}>
                                    <div className={styles.Soon}>Soon</div>
                                    <img src={CommunitiesIcon} alt={"Spotify"}/>
                                    <div>
                                        Join and create communities
                                    </div>
                                </div>
                                <div className={styles.FeatureCard}>
                                    <div className={styles.Soon}>Soon</div>
                                    <img src={ExploreIcon} alt={"Spotify"}/>
                                    <div>
                                        Explore global trends
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <IntroTimeline/>
                <GetStarted/>
            </div>
        )
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        titleManager.set("About")
    }
}
