import React from 'react';
import {BrowserRouter as Router, Link, Redirect, Route, Switch} from 'react-router-dom';

import styles from './App.module.scss';
import {Error404} from "../Error404/Error404";
import Footer from "../Footer/Footer";
import UserNavbarContainer from "../../Containers/UserNavbarContainer";
import AuthContainer from "../../Containers/AuthContainer";
import HomePageContainer from "../../Containers/HomePageContainer";
import ProfileContainer from "../../Containers/ProfileContainer";
import EmailVerify from "../EmailVerify/EmailVerify";
import WelcomeContainer from "../../Containers/WelcomeContainer";
import SettingsContainer from "../../Containers/SettingsContainer";
import {BarLoader} from "react-spinners";
import UserHistoryContainer from "../../Containers/UserHistoryContainer";
import AboutPage from "../AboutPage/AboutPage";
import Terms from "../Terms/Terms";
import Privacy from "../Privacy/Privacy";
import UserSocialContainer from "../../Containers/UserSocialContainer";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ForgotPasswordConfirm from "../ForgotPasswordConfirm/ForgotPasswordConfirm";
import Deletion from "../Deletion/Deletion";
import NotificationsPageContainer from "../../Containers/NotificationsPageContainer";
import Discord from "../Discord/Discord";
import Developers from "../Developers/Developers";
import UserDefaultRedirect from "../UserDefaultRedirect/UserDefaultRedirect";

export interface AppProps {
    isLoggedIn: boolean,
    isLoggedOut: boolean,
    isGlobalLoading: boolean,
    isEmailVerified: boolean,
    username: string | undefined
}

export class App extends React.Component<AppProps> {
    render() {
        return (
            <Router>
                <div className={styles.App}>
                    {this.renderNavBar()}
                    <div className={styles.PageContent}>
                        <Switch>
                            <Route exact path={"/"}>
                                <HomePageContainer/>
                            </Route>
                            <Route exact path={"/login"}>
                                {this.props.isLoggedIn ?
                                    (<Redirect to={this.props.isEmailVerified ?
                                        `/user/${this.props.username}` : "/welcome"}/>) :
                                    (this.props.isLoggedOut ? <AuthContainer view={"login"}/> : "")}
                            </Route>
                            <Route exact path={"/join"}>
                                {this.props.isLoggedIn ?
                                    (<Redirect to={this.props.isEmailVerified ?
                                        `/user/${this.props.username}` : "/welcome"}/>) :
                                    (this.props.isLoggedOut ? <AuthContainer view={"join"}/> : "")}
                            </Route>
                            <Route path={"/settings"}>
                                {this.props.isLoggedOut ? <Redirect to={"/"}/> : <SettingsContainer/>}
                            </Route>
                            <Route path={"/welcome"}>
                                {this.props.isLoggedOut ? <Redirect to={"/"}/> : <WelcomeContainer/>}
                            </Route>
                            <Route path={"/notifications"} exact={true}>
                                {this.props.isLoggedOut ? <Redirect to={"/login"}/> : <NotificationsPageContainer/>}
                            </Route>
                            <Route exact path={"/user/:username"} component={ProfileContainer}/>
                            <Route path={"/user/:username/history"} component={UserHistoryContainer}/>
                            <Route path={"/user/:username/friends"} component={UserSocialContainer}/>
                            <Route exact path={"/emailVerify"} component={EmailVerify}/>
                            <Route exact path={"/password-reset/submit"} component={ForgotPassword}/>
                            <Route exact path={"/password-reset/confirm"} component={ForgotPasswordConfirm}/>
                            <Route path={"/about"}>
                                <AboutPage/>
                            </Route>
                            <Route exact={true} path={"/terms"}>
                                <Terms/>
                            </Route>
                            <Route exact={true} path={"/privacy"}>
                                <Privacy/>
                            </Route>
                            <Route exact={true} path={"/privacy/account-deletion"}>
                                <Deletion/>
                            </Route>
                            <Route exact path={"/discord"}>
                                <Discord/>
                            </Route>
                            <Route path={"/developers"}>
                                <Developers/>
                            </Route>
                            <Route exact path={"/:username"} component={UserDefaultRedirect}/>
                            <Route component={Error404}/>
                        </Switch>
                    </div>
                    <Footer/>
                </div>
            </Router>
        );
    }

    reloadIfNecessary() {
        if (window.location.pathname === '/') {
            window.location.reload();
        }
    }

    renderNavBar() {
        return (
            <Switch>
                <Route exact path={"/"}>
                    {
                        this.props.isLoggedIn ? this.renderDefaultNavBar() : this.renderGuestNavBar()
                    }
                </Route>
                <Route>
                    {this.renderDefaultNavBar()}
                </Route>
            </Switch>
        )
    }

    renderDefaultNavBar() {
        return (
            <div className={styles.NavBar}>
                {!this.props.isGlobalLoading ? "" :
                    <div className={styles.GlobalBarLoader}><BarLoader width={"100%"} color={"#46C3D3"}/></div>}
                <div className={["Container", styles.NavBarContent].join(" ")}>
                    <Link onClick={() => this.reloadIfNecessary()} to="/" className={styles.Logo} role="img"
                          aria-label="wavy.fm"/>
                    <UserNavbarContainer hideSearch={false}/>
                </div>
            </div>
        )
    }

    renderGuestNavBar() {
        return (
            <div className={styles.GuestNavBar}>
                <div className={styles.GuestNavContainer}>
                    <div className={styles.GuestNavLeft}>
                        <Link onClick={() => this.reloadIfNecessary()} to={"/"} className={styles.GuestLogo}
                              role={"img"} aria-label={"wavy.fm"}/>
                        <Link to={"/about/wavy"} className={styles.GuestNavLink}>About</Link>
                        <Link to={"/about/faq"} className={styles.GuestNavLink}>FAQ</Link>
                    </div>
                    <UserNavbarContainer hideSearch={true}/>
                </div>
            </div>
        )
    }
}
