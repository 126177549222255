import React, {ChangeEvent} from "react";
import {ForeignUser} from "../../../Redux/Types";
import styles from "./UserAddFriend.module.scss";
import Card, {CardElevation} from "../Card/Card";
import SearchBox from "../SearchBox/SearchBox";
import {createFriendRequest, getNewUsers} from "../../../API/Calls";
import {Friendship, readUser} from "../../../API/Models";
import UserFriendBio from "../UserFriendBio/UserFriendBio";
import {titleManager} from "../../../Title";
import {cssVar} from "../../../variables";

interface Props {
    profile: ForeignUser;
}

interface State {
    searchAbort?: AbortController;
    value: string;
    loading: boolean;
    users: ForeignUser[];
    statuses: Map<string, Friendship>;
}

export default class UserAddFriend extends React.Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            searchAbort: undefined,
            value: "",
            loading: false,
            users: [],
            statuses: new Map<string, Friendship>()
        }

        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        return (
            <div>
                <div className={styles.TitleBar}>
                    <div className={styles.Title}>Add a Friend</div>
                    <div className={styles.TitleRight}>
                    </div>
                </div>
                <Card className={this.getClasses()} invisible={this.isMobile()} elevation={CardElevation.Sheet}>
                    <div className={styles.Content}>
                        <div className={styles.SearchBoxParent}>
                            <SearchBox placeholder={"Search by username"}
                                       value={this.state.value}
                                       onChange={this.handleChange}/>
                        </div>
                        {
                            this.state.value.trim() === "" ?
                                <div className={styles.Placeholder}>
                                    <svg width="65" height="64" viewBox="0 0 65 64" fill="none"
                                         xmlns="http://www.w3.org/2000/svg" className={styles.PlaceholderIcon}>
                                        <circle cx="32.2403" cy="31.92" r="31.92" fill={cssVar('--light-text')}/>
                                        <path
                                            d="M39.1999 36.4038H37.8955L37.4331 35.9579C39.0513 34.0755 40.0256 31.6317 40.0256 28.9732C40.0256 23.0453 35.2205 18.2402 29.2926 18.2402C23.3646 18.2402 18.5596 23.0453 18.5596 28.9732C18.5596 34.9011 23.3646 39.7062 29.2926 39.7062C31.951 39.7062 34.3949 38.732 36.2773 37.1138L36.7231 37.5761V38.8806L44.9792 47.1202L47.4396 44.6599L39.1999 36.4038ZM29.2926 36.4038C25.181 36.4038 21.862 33.0848 21.862 28.9732C21.862 24.8617 25.181 21.5427 29.2926 21.5427C33.4041 21.5427 36.7231 24.8617 36.7231 28.9732C36.7231 33.0848 33.4041 36.4038 29.2926 36.4038Z"
                                            fill="white"/>
                                    </svg>
                                    <div className={styles.PlaceholderText}>Search to add friends</div>
                                </div>
                                :
                                this.state.loading ?
                                    <div className={styles.Results}/>
                                    :
                                    <div className={styles.Results}>
                                        {
                                            this.renderUsers()
                                        }
                                    </div>
                        }
                    </div>
                </Card>
            </div>
        )
    }

    renderUsers() {
        if (this.state.users.length === 0) {
            return <div className={styles.NoResults}>No results found</div>;
        }
        return (
            <div className={styles.Grid}>
                {this.state.users.sort((a, b) => a.username.localeCompare(b.username))
                    .map(user =>
                        <div className={styles.User} key={user.id}>
                            <UserFriendBio user={user} className={styles.UserInfo}/>
                            <div className={this.getStatusClass(user)} onClick={() => this.handleAction(user)}/>
                        </div>)}
            </div>
        )
    }

    getStatusClass(user: ForeignUser): string {
        if (this.state.statuses.get(user.id) === Friendship.Sent) {
            return styles.Requested;
        } else {
            return styles.AddButton;
        }
    }

    handleAction(user: ForeignUser) {
        const friendship = this.state.statuses.get(user.id);
        switch (friendship) {
            case Friendship.Sent:
            case Friendship.Friend:
                return;
            case Friendship.None:
            case Friendship.Pending:
                createFriendRequest(user.id)
                    .then(() => {
                        this.state.statuses.set(user.id, Friendship.Sent);
                        this.forceUpdate();
                    })
                    .catch(err => {
                        console.error(err);
                    });
        }
    }

    handleChange(event: ChangeEvent<HTMLInputElement>) {
        const value = event.target.value.trimStart();
        this.setState({value});

        if (this.state.searchAbort) {
            this.state.searchAbort.abort();
        }

        if (value.length === 0) {
            this.setState({loading: false, users: []});
            return;
        }

        this.setState({loading: true, searchAbort: new AbortController()}, () => {
            getNewUsers(value, this.state.searchAbort)
                .then(resp => {
                    const statuses = new Map<string, Friendship>(Object.entries(resp.statuses));
                    const users = resp.users
                        .map(readUser)
                        .sort((a, b) => a.username.localeCompare(b.username));
                    this.setState({users, statuses, loading: false});
                })
                .catch(err => console.error(err));
        });
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Add a Friend");
    }

    componentWillUnmount(): void {
        if (this.state.searchAbort) {
            this.state.searchAbort.abort();
        }
    }

    getClasses() {
        if (this.isMobile()) {
            return "";
        } else {
            return styles.ListCard;
        }
    }

    isMobile() {
        return window.innerWidth < 768;
    }
}
