import React from "react";
import {UserState} from "../../../../Redux/Types";
import Button, {ButtonColor} from "../../Button/Button";
import {disableMfa} from "../../../../API/Calls";
import styles from "./MfaDisable.module.scss";

interface Props {
    user: UserState;
    closeFunction: (success: boolean) => void;
}

interface State {
    loading: boolean,
    errorMessage?: string;
}

export default class MfaDisable extends React.Component<Props, State> {
    abort = new AbortController();

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            loading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {
                    this.state.errorMessage ? <div className={"AuthError"}>{this.state.errorMessage}</div> : ""
                }
                <div className={styles.DeleteForm}>
                    <p>
                        You will no longer be prompted for a 2FA code when you login.
                        You should also remove wavy.fm from your authenticator app.
                    </p>
                    <div className={"FormField " + styles.FormField}>
                        <label htmlFor={"Password"}>
                            Confirm with your password
                        </label>
                        <input required={true} name={"Password"} type={"password"}
                               autoComplete={"off"}/>
                    </div>
                    <div className={"FormField SubmitRegion " + styles.FormField + " " + styles.SubmitRegion}>
                        <Button color={ButtonColor.Regular}
                                onClick={() => this.props.closeFunction(false)}>Cancel</Button>
                        <Button form={true} color={ButtonColor.Danger} disabled={this.state.loading}>Disable</Button>
                    </div>
                </div>
            </form>
        )
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (this.state.loading) {
            return;
        }

        const password = ((event.target as HTMLFormElement).elements.namedItem("Password") as HTMLInputElement).value;
        if (!password || !password.trim()) {
            return;
        }

        this.setState({loading: true, errorMessage: undefined}, () => {
            disableMfa(password, this.abort)
                .then(() => {
                    this.props.closeFunction(true);
                })
                .catch(error => this.setState({loading: false, errorMessage: error.toString()}))
        });
    }

    componentWillUnmount(): void {
        this.abort.abort();
    }
}
