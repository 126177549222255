import React from "react";
import styles from "./LightToggle.module.scss";

export default class LightToggle extends React.Component {
    render() {
        return (
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.0704 20C13.1766 20 16.0135 18.5967 17.8929 16.2972C18.171 15.957 17.8678 15.4601 17.4381 15.5413C12.5523 16.4653 8.06549 12.7454 8.06549 7.84781C8.06549 5.02664 9.58635 2.43238 12.0582 1.03555C12.4392 0.820234 12.3434 0.246602 11.9104 0.167188C11.3035 0.056052 10.6876 9.12632e-05 10.0704 0C4.51166 0 0 4.47309 0 10C0 15.5199 4.50458 20 10.0704 20Z"
                    className={"IconPath " + styles.Path}/>
            </svg>
        )
    }
}
