import React from "react";
import styles from "./Privacy.module.scss";
import {Link} from "react-router-dom";
import {titleManager} from "../../../Title";

export default class Privacy extends React.Component {
    render() {
        return (
            <div className={styles.Container}>
                <h1 className={styles.Title}>Privacy Policy</h1>
                <p>Last updated: October 1, 2020</p>

                <div className={styles.Section}>
                    <p>
                        We take every step to ensure that your data is used ethically, and that includes making sure
                        that you know exactly what data we collect. As such, we’ve provided this information in an easy,
                        human-readable format below.
                    </p>
                    <p>
                        In general, any Personally Identifiable Information (PII) that you submit to us, as well as PII
                        derived from your usage of the website (such as your IP address), is only available to Data
                        Officers designated by Wavy Labs Inc.’s internal policies. However, certain information may be
                        disclosed to trusted third parties for reasons explicitly stated in this policy.
                    </p>
                    <p>
                        If you have any concerns related to the information and privacy on this website, you can contact
                        Wavy Labs Inc.’s Data Controller, Aram Peres, by email: <strong>aram.peres@wavy.fm</strong>.
                    </p>
                    <p>
                        If you wish to get a copy of your data, or for us to purge it from our systems, please contact
                        the Data Controller above. We are actively working on offering these services in an automated
                        manner.
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>Data Collection</h2>
                    <table className={styles.Table}>
                        <tr>
                            <th>What we collect</th>
                            <th>When it's collected</th>
                            <th>What it's used for</th>
                            <th>Who can access it</th>
                        </tr>
                        <tr>
                            <td>Username</td>
                            <td>
                                <ul>
                                    <li>When you join wavy.fm</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Signing in</li>
                                    <li>Public information
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Anyone.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Email Address</td>
                            <td>
                                <ul>
                                    <li>When you join wavy.fm</li>
                                    <li>When you send an email to a <i>wavy.fm</i> email address</li>
                                    <li>When you send a message through
                                        the <Link to={"/about/contact"}>Contact Us</Link> page on wavy.fm
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Signing in</li>
                                    <li>Sending you password change confirmations and account/security-related
                                        notifications
                                    </li>
                                    <li>Sending you general notifications for wavy.fm, as configured in your account
                                        settings
                                    </li>
                                    <li>Replying to messages sent by email
                                    </li>
                                    <li>Replying to messages sent through the <i>Contact Us</i> page
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Yourself.
                                    </li>
                                    <li>Designated Data Officers at Wavy Labs Inc.
                                    </li>
                                    <li>Certain trusted third parties that respect our privacy requirements (see below).
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Password</td>
                            <td>
                                <ul>
                                    <li>When you join wavy.fm</li>
                                    <li>When you change or reset your password</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Signing in</li>
                                    <li>Confirming sensitive account operations (e.g. changing your email address) on
                                        wavy.fm
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>The plain-text version of the password is not accessible by anyone.</li>
                                    <li>Designated Data Officers at Wavy Labs Inc. may access the <i>bcrypt</i>
                                        -hashed version of the password.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Spotify Display Name</td>
                            <td>
                                <ul>
                                    <li>When you connect your Spotify account to wavy.fm</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Displaying your Spotify account information in your account settings
                                    </li>
                                    <li>Optionally displaying your Spotify account on your profile
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Yourself.
                                    </li>
                                    <li>Designated Data Officers at Wavy Labs Inc.
                                    </li>
                                    <li><i>If you set your Spotify account as public in your wavy.fm Privacy
                                        Settings</i>, your friends.
                                    </li>
                                    <li><i>If you set your Spotify account as public in your wavy.fm Privacy
                                        Settings, and your wavy.fm profile is public</i>, anyone.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Spotify User ID</td>
                            <td>
                                <ul>
                                    <li>When you connect your Spotify account to wavy.fm</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Displaying your Spotify account information in your account settings
                                    </li>
                                    <li>Associating the Spotify account’s recently played tracks with the wavy.fm
                                        account
                                    </li>
                                    <li>Optionally displaying your Spotify account on your profile
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Yourself.
                                    </li>
                                    <li>Designated Data Officers at Wavy Labs Inc.
                                    </li>
                                    <li><i>If you set your Spotify account as public in your wavy.fm Privacy
                                        Settings</i>, your friends.
                                    </li>
                                    <li><i>If you set your Spotify account as public in your wavy.fm Privacy
                                        Settings, and your wavy.fm profile is public</i>, anyone.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Spotify Access Token and Refresh Token</td>
                            <td>
                                <ul>
                                    <li>At any time while the Spotify account is connected to a wavy.fm profile</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Accessing the following data from Spotify’s
                                        API: <i>Spotify Display Name</i>, <i>Spotify Recently Played Tracks</i>
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Designated Data Officers at Wavy Labs Inc.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Spotify Played Tracks</td>
                            <td>
                                <ul>
                                    <li>At any time while the Spotify account is connected to a wavy.fm profile</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Storing the account's Spotify listening history for an indeterminate period
                                    </li>
                                    <li>Displaying the listening history of the Spotify account on the associated
                                        wavy.fm profiles
                                    </li>
                                    <li>Calculating individual statistics and metrics based on the history
                                    </li>
                                    <li>Distributing and displaying the listening history in an anonymized form on
                                        wavy.fm
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Yourself.
                                    </li>
                                    <li>Your friends.
                                    </li>
                                    <li>Designated Data Officers at Wavy Labs Inc.
                                    </li>
                                    <li><i>If the profile is public</i>, anyone.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Bio, Location, Twitter username, and Instagram username</td>
                            <td>
                                <ul>
                                    <li>When you supply your Bio, Location, Twitter username, and/or Instagram username
                                        on your profile
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Your wavy.fm profile
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Yourself.
                                    </li>
                                    <li>Your friends.
                                    </li>
                                    <li>Designated Data Officers at Wavy Labs Inc.
                                    </li>
                                    <li><i>If the profile is public</i>, anyone.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Profile Picture</td>
                            <td>
                                <ul>
                                    <li>When you upload a profile picture on your profile</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Your wavy.fm profile
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Yourself.
                                    </li>
                                    <li>Your friends.
                                    </li>
                                    <li>Designated Data Officers at Wavy Labs Inc.
                                    </li>
                                    <li><i>If the profile is public</i>, anyone.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Join Date and Time</td>
                            <td>
                                <ul>
                                    <li>When you join wavy.fm</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Your wavy.fm profile
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Anyone.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Discord ID and Username</td>
                            <td>
                                <ul>
                                    <li>When you connect your Discord account to wavy.fm</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Displaying your Discord account information in your account settings
                                    </li>
                                    <li>Interacting with your wavy.fm account from our official Discord server
                                    </li>
                                    <li>Optionally displaying your Discord account on your profile
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Yourself.
                                    </li>
                                    <li>Designated Data Officers at Wavy Labs Inc.
                                    </li>
                                    <li><i>If you set your Discord account as public in your wavy.fm Privacy
                                        Settings</i>, your friends.
                                    </li>
                                    <li><i>If you set your Discord account as public in your wavy.fm Privacy
                                        Settings, and your wavy.fm profile is public</i>, anyone.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>IP Address</td>
                            <td>
                                <ul>
                                    <li>At any time you access the wavy.fm website</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Protecting the website from attacks
                                    </li>
                                    <li>Protecting your account
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Designated Data Officers at Wavy Labs Inc.
                                    </li>
                                    <li>Certain trusted third-parties that respect our privacy requirements (see below).
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Browser and Operating System</td>
                            <td>
                                <ul>
                                    <li>At any time you access the wavy.fm website</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Debugging errors when you use wavy.fm
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Designated Data Officers at Wavy Labs Inc.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>Information Disclosed to Third Parties</h2>
                    <p>
                        In the circumstances described in this section, Wavy Labs Inc. may disclose data it collects,
                        without additional consent, to specific third parties that fit each of our requirements.
                    </p>
                    <p>
                        For a third party to be compliant with our requirements, it must:
                        <ul>
                            <li>Provide an extensive Privacy Policy describing their use of disclosed information;
                            </li>
                            <li>Have a good track record regarding user privacy;
                            </li>
                            <li>Not use any of the disclosed information for unethical or illegal purposes; and
                            </li>
                            <li>Not use any of the disclosed information for commercial purposes, unless the user
                                provides explicit consent.
                            </li>
                        </ul>
                    </p>
                    <p>
                        We provide the following exhaustive list of third parties to which we periodically
                        disclose information collected by Wavy Labs Inc., as well as a link to each of their
                        Privacy Policies, the disclosed information, and the purpose of our information disclosure:
                    </p>
                    <table className={styles.Table}>
                        <tr>
                            <th>Third Party</th>
                            <th>Privacy Policy</th>
                            <th>Information Disclosed</th>
                            <th>Purpose of Disclosure</th>
                            <th>Start of Disclosure</th>
                        </tr>
                        <tr>
                            <td>Stop Forum Spam</td>
                            <td>
                                <a href={"https://www.stopforumspam.com/privacy"}>Privacy Policy</a>
                            </td>
                            <td>
                                <ul>
                                    <li>The email address used to join wavy.fm</li>
                                    <li>The IP address used to join wavy.fm
                                    </li>
                                </ul>
                            </td>
                            <td>
                                We use this third party service to ensure that your email address and IP address are not
                                known for abuse or spam on other websites.
                            </td>
                            <td>
                                September 11, 2020
                            </td>
                        </tr>
                        <tr>
                            <td>Mailgun</td>
                            <td>
                                <a href={"https://www.mailgun.com/privacy-policy/"}>Privacy Policy</a>
                            </td>
                            <td>
                                <ul>
                                    <li>The email address associated with your account</li>
                                </ul>
                            </td>
                            <td>
                                We use this third party service to send emails on behalf of wavy.fm.
                            </td>
                            <td>
                                October 31, 2019
                            </td>
                        </tr>
                    </table>
                    <p>
                        Additionally, we will provide information to a third party in the event of any reorganization,
                        merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of
                        our business, assets or stock (including in connection with any bankruptcy or similar
                        proceedings).
                    </p>
                </div>

                <div className={styles.Section}>
                    <h2 className={styles.SectionTitle}>Backups</h2>
                    <p>
                        For operational and continuity purposes, your account information may be persisted in encrypted
                        backups. These backups are purged within 14 days of their creation. The backup files are only
                        accessible to designated Data Officers at Wavy Labs Inc., and may only be accessed in specific
                        circumstances defined by Wavy Labs Inc.'s internal policies.
                    </p>
                </div>
            </div>
        )
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set("Privacy Policy");
    }
}
