import {AppState, UserState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ProfileEditForm from "../Presentation/ProfileEditForm/ProfileEditForm";
import {setUserPrivacy} from "../../Redux/Actions";

function mapStateToProps(state: AppState) {
    return {
        authUser: state.user as UserState,
        reload$: state.profilePage.reload$
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        setUserPrivacy
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditForm);
