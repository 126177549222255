import React from "react";
import styles from "./SearchBox.module.scss";

interface Props {
    className?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
}

interface State {

}

export default class SearchBox extends React.Component<Props & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, State> {
    render() {
        const {inputRef, className, ...inputProps} = Object.assign({}, this.props);

        return (
            <input {...inputProps}
                   className={styles.SearchBox + " NoDefaultStyle " + (className || "")} type={"text"}
                   ref={inputRef}
            />
        )
    }
}
