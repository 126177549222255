import React from "react";

export default class AddFriendIcon extends React.Component {
    render() {
        return (
            <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className={"IconSvg"}>
                <path className={"IconPath"}
                      d="M22.639 8.9375V9.9375H23.639H26.016V10.6875H23.639H22.639V11.6875V14.125H21.9374V11.6875V10.6875H20.9374H18.5604V9.9375H20.9374H21.9374V8.9375V6.5H22.639V8.9375ZM13.8588 5.5C13.8588 8.00242 11.8711 10 9.45559 10C7.04012 10 5.0524 8.00242 5.0524 5.5C5.0524 2.99758 7.04012 1 9.45559 1C11.8711 1 13.8588 2.99758 13.8588 5.5ZM9.45559 14.0625C10.6249 14.0625 11.7385 13.814 12.7478 13.375H13.2378C15.801 13.375 17.9112 15.4972 17.9112 18.15V19.9375C17.9112 20.5407 17.4347 21 16.885 21H2.0262C1.47652 21 1 20.5407 1 19.9375V18.15C1 15.4972 3.11014 13.375 5.67335 13.375H6.16408C7.17519 13.8135 8.28504 14.0625 9.45559 14.0625Z"
                      stroke="#EE5B8F" strokeWidth="2"/>
            </svg>
        )
    }
}
