import React from "react";
import {cssVar} from "../../../variables";

export enum Icons {
    Profile, Friends, Settings, Logout, DarkMode
}

interface Props {
    icon: Icons;
    className?: string;
    fill?: string;
}

export default class UserMenuIcon extends React.Component<Props> {
    render(): React.ReactNode {
        switch (this.props.icon) {
            case Icons.Friends:
                return <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M4.5 5.5C5.95078 5.5 7.125 4.26987 7.125 2.75C7.125 1.23013 5.95078 0 4.5 0C3.04922 0 1.875 1.23013 1.875 2.75C1.875 4.26987 3.04922 5.5 4.5 5.5ZM6.3 6.28571H6.10547C5.61797 6.53125 5.07656 6.67857 4.5 6.67857C3.92344 6.67857 3.38438 6.53125 2.89453 6.28571H2.7C1.20937 6.28571 0 7.55268 0 9.11429V9.82143C0 10.4721 0.503906 11 1.125 11H7.875C8.49609 11 9 10.4721 9 9.82143V9.11429C9 7.55268 7.79062 6.28571 6.3 6.28571ZM11.25 5.5C12.4922 5.5 13.5 4.4442 13.5 3.14286C13.5 1.84152 12.4922 0.785714 11.25 0.785714C10.0078 0.785714 9 1.84152 9 3.14286C9 4.4442 10.0078 5.5 11.25 5.5ZM12.375 6.28571H12.2859C11.9602 6.40357 11.6156 6.48214 11.25 6.48214C10.8844 6.48214 10.5398 6.40357 10.2141 6.28571H10.125C9.64688 6.28571 9.20625 6.43058 8.81953 6.66384C9.39141 7.3096 9.75 8.16652 9.75 9.11429V10.0571C9.75 10.1112 9.73828 10.1627 9.73594 10.2143H13.875C14.4961 10.2143 15 9.68638 15 9.03571C15 7.51585 13.8258 6.28571 12.375 6.28571Z"
                        fill={this.props.fill || cssVar('--default-text')}/>
                </svg>
            case Icons.Profile:
                return <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M5.5 6C7.23594 6 8.64286 4.65703 8.64286 3C8.64286 1.34297 7.23594 0 5.5 0C3.76406 0 2.35714 1.34297 2.35714 3C2.35714 4.65703 3.76406 6 5.5 6ZM7.7 6.75H7.28996C6.74487 6.98906 6.13839 7.125 5.5 7.125C4.86161 7.125 4.25759 6.98906 3.71004 6.75H3.3C1.47812 6.75 0 8.16094 0 9.9V10.875C0 11.4961 0.527902 12 1.17857 12H9.82143C10.4721 12 11 11.4961 11 10.875V9.9C11 8.16094 9.52187 6.75 7.7 6.75Z"
                        fill={this.props.fill || cssVar('--default-text')}/>
                </svg>
            case Icons.Logout:
                return <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M13.8062 5.98659L9.13934 10.7983C8.72266 11.2279 8.0004 10.9272 8.0004 10.3114V7.56185H4.22243C3.85297 7.56185 3.55573 7.25539 3.55573 6.87447V4.12492C3.55573 3.744 3.85297 3.43754 4.22243 3.43754H8.0004V0.687995C8.0004 0.075076 8.71988 -0.228519 9.13934 0.201097L13.8062 5.0128C14.0646 5.28202 14.0646 5.71737 13.8062 5.98659ZM5.3336 10.6551V9.50945C5.3336 9.32042 5.18359 9.16575 5.00025 9.16575H2.6668C2.17511 9.16575 1.77787 8.75619 1.77787 8.24924V2.75015C1.77787 2.24321 2.17511 1.83364 2.6668 1.83364H5.00025C5.18359 1.83364 5.3336 1.67898 5.3336 1.48995V0.344302C5.3336 0.155271 5.18359 0.000609158 5.00025 0.000609158H2.6668C1.1945 0.000609158 0 1.23218 0 2.75015V8.24924C0 9.76722 1.1945 10.9988 2.6668 10.9988H5.00025C5.18359 10.9988 5.3336 10.8441 5.3336 10.6551Z"
                        fill={this.props.fill || cssVar('--default-text')}/>
                </svg>
            case Icons.Settings:
                return <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M11.8467 8.06209L10.7701 7.41734C10.8788 6.80927 10.8788 6.18548 10.7701 5.57742L11.8467 4.93267C11.9706 4.85928 12.0262 4.70726 11.9857 4.56573C11.7052 3.63267 11.2275 2.78872 10.6033 2.08631C10.5073 1.97885 10.3506 1.95264 10.2293 2.02603L9.15266 2.67078C8.70028 2.26715 8.17966 1.95526 7.61608 1.75082V0.463934C7.61608 0.31716 7.51752 0.188734 7.37852 0.157282C6.45101 -0.0576365 5.50076 -0.0471527 4.61874 0.157282C4.47974 0.188734 4.38118 0.31716 4.38118 0.463934V1.75345C3.82012 1.9605 3.2995 2.27239 2.8446 2.6734L1.77051 2.02865C1.64667 1.95526 1.49251 1.97885 1.39647 2.08893C0.772235 2.78872 0.294581 3.63267 0.0140546 4.56835C-0.0289089 4.70989 0.0292182 4.8619 0.153054 4.93529L1.22967 5.58004C1.121 6.18811 1.121 6.81189 1.22967 7.41996L0.153054 8.06471C0.0292182 8.1381 -0.0263817 8.29011 0.0140546 8.43165C0.294581 9.36471 0.772235 10.2087 1.39647 10.9111C1.49251 11.0185 1.6492 11.0447 1.77051 10.9714L2.84712 10.3266C3.2995 10.7302 3.82012 11.0421 4.3837 11.2466V12.5361C4.3837 12.6828 4.48227 12.8113 4.62127 12.8427C5.54877 13.0576 6.49903 13.0472 7.38104 12.8427C7.52004 12.8113 7.61861 12.6828 7.61861 12.5361V11.2466C8.17966 11.0395 8.70028 10.7276 9.15519 10.3266L10.2318 10.9714C10.3556 11.0447 10.5098 11.0212 10.6058 10.9111C11.2301 10.2113 11.7077 9.36733 11.9883 8.43165C12.0262 8.28749 11.9706 8.13548 11.8467 8.06209ZM5.99863 8.59414C4.8841 8.59414 3.97681 7.65322 3.97681 6.49738C3.97681 5.34154 4.8841 4.40061 5.99863 4.40061C7.11315 4.40061 8.02044 5.34154 8.02044 6.49738C8.02044 7.65322 7.11315 8.59414 5.99863 8.59414Z"
                        fill={this.props.fill || cssVar('--default-text')}/>
                </svg>
            case Icons.DarkMode:
                return <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className={this.props.className}>
                    <path
                        d="M5.59467 11C7.32032 11 8.89637 10.2282 9.94052 8.96345C10.095 8.77637 9.92656 8.50304 9.68784 8.54773C6.97349 9.05592 4.48083 7.00996 4.48083 4.3163C4.48083 2.76465 5.32575 1.33781 6.69898 0.569551C6.91066 0.451129 6.85742 0.135631 6.6169 0.0919531C6.2797 0.0308286 5.93754 5.01948e-05 5.59467 0C2.50648 0 0 2.4602 0 5.5C0 8.53594 2.50254 11 5.59467 11Z"
                        fill={this.props.fill || cssVar('--default-text')}/>
                </svg>
        }
    }
}
