import React from "react";
import "./Footer.scss";
import {Link} from "react-router-dom";
import {DISCORD_INVITE} from "../../../Discord";

export default class Footer extends React.Component {
    render() {
        return (
            <div className={"Footer"}>
                <div className={"FooterContent"}>
                    <div className={"FooterLogo"}/>
                    <div className={"AboutLinks"}>
                        <Link to={"/about/wavy"}>About</Link>
                        <Link to={"/about/faq"}>FAQ</Link>
                        <Link to={"/about/contact"}>Contact Us</Link>
                        {/*eslint-disable-next-line*/}
                        <a href={"https://medium.com/wavy-fm"} target={"_blank"} rel={"noopener noreferrer"}>Blog</a>
                    </div>
                    <div className={"SocialLinks"}>
                        {/*eslint-disable-next-line*/}
                        <a href={"https://twitter.com/wavyfm"} target={"_blank"} rel={"noopener noreferrer"} className={"Twitter"}>
                            Twitter
                        </a>
                        {/*eslint-disable-next-line*/}
                        <a href={"https://instagram.com/wavydotfm"} target={"_blank"} rel={"noopener noreferrer"} className={"Instagram"}>
                            Instagram
                        </a>
                        {/*eslint-disable-next-line*/}
                        <a href={DISCORD_INVITE} target={"_blank"} rel={"noopener noreferrer"} className={"Discord"}>
                            Discord
                        </a>
                    </div>
                    <div className={"TermsLinks"}>
                        <Link to={"/terms"}>Terms of Service</Link>
                        <Link to={"/privacy"}>Privacy Policy</Link>
                    </div>
                    <div className={"Copyright"}>Certain assets such as song names, artists, albums, and artwork are retrieved from Spotify. Spotify is a trademark of Spotify AB. This website is not affiliated with Spotify.</div>
                    <div className={"Copyright"}>All song names, album names, and album artworks are the property of their copyright holders.</div>
                    <div className={"Copyright"}>&copy; 2024 Wavy Labs Inc.</div>
                </div>
            </div>
        )
    }
}
