import React from "react";
import {ForeignUser, UserState} from "../../../Redux/Types";
import styles from "./ProfileMeta.module.scss";
import Avatar from "../Avatar/Avatar";
import {Months} from "../../../Months";
import Button, {ButtonColor} from "../Button/Button";
import Flag from "../Flag/Flag";
import ProfileFriendButtonContainer from "../../Containers/ProfileFriendButtonContainer";
import ProfileEditFormContainer from "../../Containers/ProfileEditFormContainer";
import LocationIcon from "../Icons/LocationIcon/LocationIcon";
import ProfileMetaIcon, {Icons} from "./ProfileMetaIcon";
import UserBadge from "../UserBadge/UserBadge";

interface Props {
    profile: ForeignUser;
    authProfileReady: boolean;
    authUser: UserState | null;
    isOwnProfile: boolean;
    isLoggedIn: boolean;
    isEditing: boolean;
}

interface Actions {
    toggleProfileEditing: (editing: boolean) => void;
    toggleProfilePictureForm: (open: boolean) => void;
}

interface State {
}

export default class ProfileMeta extends React.Component<Props & Actions, State> {

    constructor(props: Readonly<Props & Actions>) {
        super(props);

        this.state = {}

        this.openPictureModal = this.openPictureModal.bind(this);
    }

    render() {
        return (
            <div className={styles.ProfileMeta}>
                <div className={styles.MetaHeader}>
                    <Avatar user={this.props.profile} className={styles.Avatar}
                            editable={this.props.isOwnProfile} size={this.avatarSize}
                            rounder={true}
                            fullSize={true}
                            dom={{onClick: this.openPictureModal}}/>
                    <div className={styles.HeaderText}>
                        <div className={styles.Username}>
                            <div className={styles.UsernameText}>{this.props.profile.username}{this.badges}</div>
                        </div>
                        <div className={styles.JoinDate}>
                            Joined {this.joinDate}
                        </div>
                    </div>
                </div>
                {this.renderEditing()}
                {this.renderBioSection()}
            </div>
        )
    }

    renderEditing() {
        if (this.props.isOwnProfile && this.props.isEditing) {
            return <ProfileEditFormContainer/>
        }
    }

    renderBioSection() {
        if (this.props.isOwnProfile && this.props.isEditing) {
            return;
        }
        if (this.hasBio() || this.hasCountry() || this.hasSocialLinks()) {
            return (
                <div className={styles.MetaBio}>
                    <div className={styles.BioLayoutContent}>
                        {this.renderBio()}
                        {this.renderSocialLinks()}
                        {this.renderCountry()}
                    </div>
                    <div className={styles.BioLayoutActions}>
                        {this.renderAction()}
                    </div>
                </div>
            )
        } else {
            return (
                <div className={styles.MetaBio}>
                    <div className={styles.BioLayoutActions}>
                        {this.renderAction()}
                    </div>
                </div>
            )
        }
    }

    renderBio() {
        const bio = this.props.profile.bio?.trim();
        if (!bio) {
            return "";
        }

        const lines = bio.split("\n").filter(line => !!line.trim());
        const remainder = lines.splice(3).join(" ");
        if (remainder) {
            lines[lines.length - 1] += " " + remainder;
        }

        return <div className={[styles.BioElement, styles.BioText].join(" ")}>
            {lines.map((line, i) => <p className={styles.BioLine} key={i}>{line}</p>)}
        </div>
    }

    renderSocialLinks() {
        const {twitter, instagram} = this.props.profile;

        const spotifyDisplayName = this.spotifyVisible ? this.props.profile.spotifyDisplayName : "";
        const spotifyId = this.spotifyVisible ? this.props.profile.spotifyId : "";

        const discordDisplayName = this.discordVisible ? this.props.profile.discordDisplayName : "";
        const discordId = this.discordVisible ? this.props.profile.discordId : "";

        if (this.hasSocialLinks()) {
            return (
                <div className={[styles.BioElement, styles.SocialLinks].join(" ")}>
                    {spotifyId && spotifyDisplayName ?
                        <div className={styles.SocialLink} title={spotifyDisplayName + " on Spotify"}>
                            <ProfileMetaIcon icon={Icons.Spotify} className={styles.Icon}/>
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a href={"https://open.spotify.com/user/" + spotifyId} target={"_blank"}>
                                {spotifyDisplayName}
                            </a>
                        </div> : ""}
                    {twitter ? <div className={styles.SocialLink} title={"@" + twitter + " on Twitter"}>
                        <ProfileMetaIcon icon={Icons.Twitter} className={styles.Icon}/>
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href={"https://twitter.com/" + twitter} target={"_blank"}>
                            {twitter}
                        </a>
                    </div> : ""}
                    {instagram ? <div className={styles.SocialLink} title={"@" + instagram + " on Instagram"}>
                        <ProfileMetaIcon icon={Icons.Instagram} className={styles.Icon}/>
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href={"https://instagram.com/" + instagram} target={"_blank"}>
                            {instagram}
                        </a>
                    </div> : ""}
                    {discordId && discordDisplayName ?
                        <div className={styles.SocialLink} title={discordDisplayName + " on Discord"}>
                            <ProfileMetaIcon icon={Icons.Discord} className={styles.Icon}/>
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a href={"https://discordapp.com/users/" + discordId} target={"_blank"}>
                                {discordDisplayName}
                            </a>
                        </div> : ""}
                </div>
            )
        } else {
            return "";
        }
    }

    renderCountry() {
        if (this.props.profile.countryCode && this.props.profile.countryName) {
            return <div className={[styles.BioElement, styles.Country].join(" ")}>
                <LocationIcon/>
                {this.props.profile.countryName}
                <Flag code={this.props.profile.countryCode} className={styles.Flag}/>
            </div>
        } else {
            return "";
        }
    }

    hasBio() {
        return !!this.props.profile.bio && !!this.props.profile.bio.trim();
    }

    hasCountry() {
        return !!this.props.profile.countryCode && !!this.props.profile.countryName;
    }

    hasSocialLinks() {
        return !!this.props.profile.instagram || !!this.props.profile.twitter || this.spotifyVisible || this.discordVisible;
    }

    renderAction() {
        if (this.props.isOwnProfile) {
            if (this.props.isEditing) {
                return "";
            } else {
                return (
                    <Button color={ButtonColor.Regular} outline={true}
                            onClick={() => this.actions.toggleProfileEditing(true)}
                            className={styles.EditButton}>
                        Edit Profile
                    </Button>
                )
            }
        } else if (this.props.isLoggedIn) {
            return <ProfileFriendButtonContainer/>
        } else {
            return "";
        }
    }

    openPictureModal() {
        if (this.props.isOwnProfile) {
            this.actions.toggleProfilePictureForm(true);
        }
    }

    componentWillUnmount() {
        this.actions.toggleProfilePictureForm(false);
        this.actions.toggleProfileEditing(false);
    }

    get badges(): React.ReactNode {
        return <UserBadge supporter={this.props.profile.supporter} admin={this.props.profile.admin} size={"0.61em"}/>
    }

    get joinDate(): string {
        const date = this.props.profile.joinTime;
        const month = Months[date.getMonth()];
        return month + " " + date.getDate() + ", " + date.getFullYear();
    }

    get avatarSize(): string {
        if (window.innerWidth <= 768) {
            return "100px";
        } else {
            return "200px";
        }
    }

    get actions(): Actions {
        return this.props as Actions;
    }

    get spotifyVisible(): boolean {
        if (!this.props.authProfileReady || !this.props.profile.spotifyId) {
            return false;
        }
        if (this.props.isOwnProfile) {
            return (this.props.authUser?.privacySettings.publicSpotify ?? false);
        } else {
            return this.props.profile.$access.viewSpotify;
        }
    }

    get discordVisible(): boolean {
        if (!this.props.authProfileReady || !this.props.profile.discordId) {
            return false;
        }
        if (this.props.isOwnProfile) {
            return (this.props.authUser?.privacySettings.publicDiscord ?? false)
        } else {
            return this.props.profile.$access.viewDiscord;
        }
    }
}
