import {ForeignUser} from "../../../Redux/Types";
import React from "react";
import styles from "./UserFriends.module.scss";
import Card, {CardElevation} from "../Card/Card";
import {GetFriendListResponse, SpotifyTrack} from "../../../API/Models";
import {getFriendList, getProfileActivity} from "../../../API/Calls";
import UserFriend from "../UserFriend/UserFriend";
import Button, {ButtonColor} from "../Button/Button";
import AddFriendIcon from "../Icons/AddFriend/AddFriendIcon";
import SearchBox from "../SearchBox/SearchBox";
import {titleManager} from "../../../Title";

interface Props {
    profile: ForeignUser;
    self: boolean;
}

interface State {
    friendsResponse: GetFriendListResponse | null;
    activities: Map<string, SpotifyTrack>;
    abort: AbortController;
}

export default class UserFriends extends React.Component<Props, State> {
    private inputContainer: React.RefObject<HTMLInputElement> = React.createRef();

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            friendsResponse: null,
            activities: new Map<string, SpotifyTrack>(),
            abort: new AbortController()
        }
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        titleManager.set(`${this.props.profile.username}'s Friends`);
        if (this.props.profile.$access.view) {
            getFriendList(this.props.profile.id, this.state.abort)
                .then(friendsResponse => {
                    this.setState({friendsResponse});

                    const userIds = friendsResponse.map(u => u.id);
                    if (userIds.length > 0) {
                        // Split in chunks to fix HTTP max size
                        const chunkSize = 50;
                        for (let i = 0; i < userIds.length; i += chunkSize) {
                            const chunk = userIds.slice(i, i + chunkSize);
                            getProfileActivity(chunk, this.state.abort)
                                .then(resp => {
                                    Object.keys(resp).forEach(userId => {
                                        const track = resp[userId];
                                        if (track) {
                                            this.state.activities.set(userId, track.track);
                                        }
                                    })
                                    this.forceUpdate();
                                });
                        }
                    }
                })
                .catch(console.error)
        }
    }

    componentWillUnmount(): void {
        this.state.abort.abort();
    }

    render() {
        return (
            <div>
                <div className={styles.TitleBar}>
                    <div className={styles.Title}>Friends {this.friendCount()}</div>
                    <div className={styles.TitleRight}>
                        <SearchBox className={styles.SearchBox} placeholder={this.getSearchPlaceholder()}
                                   inputRef={this.inputContainer} onChange={() => this.forceUpdate()}/>
                        {
                            this.props.self ?
                                <Button color={ButtonColor.Accent} className={styles.AddButton}
                                        link={{to: "/user/" + this.props.profile.username + "/friends/add"}}>
                                    <AddFriendIcon/>
                                    Add a Friend
                                </Button>
                                :
                                ""
                        }
                    </div>
                </div>
                <Card className={this.getClasses()} invisible={this.isMobile()} elevation={CardElevation.Sheet}>
                    <div className={styles.Content}>
                        <div className={styles.Grid}>
                            {this.renderUsers()}
                        </div>
                    </div>
                </Card>
            </div>
        )
    }

    getClasses() {
        if (this.isMobile()) {
            return "";
        } else {
            return styles.ListCard;
        }
    }

    isMobile() {
        return window.innerWidth < 768;
    }

    renderUsers() {
        if (!this.props.profile.$access.view) {
            return <div className={styles.Placeholder}>This profile is private</div>
        }
        if (!this.state.friendsResponse) {
            return <div className={styles.Skeleton}/>
        }
        const friends = this.state.friendsResponse.sort((a, b) => a.username.localeCompare(b.username))
            .filter(user => {
                if (!this.isSearching()) {
                    return true;
                }
                return user.username.toLowerCase().includes(this.inputContainer?.current?.value.trim().toLowerCase() ?? "");
            });
        if (friends.length > 0) {
            return friends.map(user => <UserFriend user={user} key={user.id}
                                                   activity={this.state.activities.get(user.id)}/>);
        } else if (this.isSearching()) {
            return <div className={styles.Placeholder}>No results found</div>
        } else if (this.props.self) {
            return <div className={styles.Placeholder}>You do not have friends yet</div>
        } else {
            return <div className={styles.Placeholder}>{this.props.profile.username} does not have friends yet</div>
        }
    }

    isSearching() {
        return this.inputContainer.current && this.inputContainer.current.value.trim().length > 0;
    }

    friendCount() {
        if (!this.state.friendsResponse) {
            return "";
        }
        return <span className={styles.FriendCount}>({this.state.friendsResponse.length})</span>
    }

    getSearchPlaceholder() {
        if (this.props.self) {
            return "Search your friends";
        } else {
            return "Search " + this.props.profile.username + "'s friends";
        }
    }
}
