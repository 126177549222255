import {AppState, UserState} from "../../Redux/Types";
import {connect} from "react-redux";
import AccountSettings from "../Presentation/Settings/Account/AccountSettings";
import {bindActionCreators} from "redux";
import {
    loadUserData,
    setUserEmail,
    toggleDeleteModal,
    toggleEditEmailModal,
    toggleEditPasswordModal,
    toggleEditUsernameModal,
    toggleMfaDisableModal,
    toggleMfaRecoveryModal,
    toggleMfaSetupModal,
    toggleSettingsLoading
} from "../../Redux/Actions";

function mapStateToProps(state: AppState) {
    return {
        user: state.user as UserState,
        showEmailModal: state.accountSettings.emailModal,
        showPasswordModal: state.accountSettings.passwordModal,
        showUsernameModal: state.accountSettings.usernameModal,
        showMfaSetupModal: state.accountSettings.mfaSetupModal,
        showMfaDisableModal: state.accountSettings.mfaDisableModal,
        showMfaRecoveryModal: state.accountSettings.mfaRecoveryModal,
    }
}


function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        toggleEditPasswordModal,
        toggleEditEmailModal,
        toggleEditUsernameModal,
        toggleDeleteModal,
        toggleMfaSetupModal,
        toggleMfaDisableModal,
        toggleMfaRecoveryModal,
        setUserEmail,
        loadUserData,
        toggleSettingsLoading,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
