import "./TrendingArtists.scss";
import React from "react";
import {SpotifyArtist} from "../../../../API/Models";
import {getTrendingArtists} from "../../../../API/Calls";

interface TrendingArtistsState {
    artists: SpotifyArtist[] | null,
}

export default class TrendingArtists extends React.Component<{}, TrendingArtistsState> {

    constructor(props: Readonly<{}>) {
        super(props);

        const cachedArtists = window.localStorage.getItem("wavy.cache.trending_artists");
        let artists = null;
        if (cachedArtists) {
            artists = JSON.parse(cachedArtists);
        }

        this.state = {
            artists
        }
    }

    render() {
        return (
            <div className={"TrendingArtistsRow"}>
                {
                    this.state.artists === null ? "" :
                        this.state.artists.map((artist, index) => {
                            return (
                                <div className={"ArtistParent"} key={index}>
                                    <div className={"ArtistIcon"} style={{backgroundImage: this.getArtistIcon(artist)}}/>
                                    <div className={"ArtistName"}>{artist.name}</div>
                                </div>
                            )
                        })
                }
            </div>
        )
    }

    componentDidMount(): void {
        getTrendingArtists()
            .then(artists => {
                window.localStorage.setItem("wavy.cache.trending_artists", JSON.stringify(artists));
                this.setState({artists});
            })
            .catch(console.error);
    }

    getArtistIcon(artist: SpotifyArtist): string {
        const image = artist.images
            .filter(img => img.width < 500)
            .sort((a, b) => b.width - a.width)[0];
        if (image) {
            return "url(\"" + image.url + "\")";
        } else {
            return "none";
        }
    }
}
