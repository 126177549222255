import {AppState} from "../../Redux/Types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {toggleProfileUnfriendConfirm, updateProfileFriendship} from "../../Redux/Actions";
import ProfileUnfriendConfirm from "../Presentation/ProfileUnfriendConfirm/ProfileUnfriendConfirm";

function mapStateToProps(state: AppState) {
    return {
        username: state.profilePage.profile?.username ?? "",
        userId: state.profilePage.profile?.id ?? "",
        isOpen: state.profilePage.unfriendConfirmOpen
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        toggleProfileUnfriendConfirm,
        updateProfileFriendship
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUnfriendConfirm);
