import React from "react";
import styles from "./ProfileUnfriendConfirm.module.scss";
import CardModal from "../CardModal/CardModal";
import {deleteFriendship} from "../../../API/Calls";
import {Friendship} from "../../../API/Models";
import Button, {ButtonColor} from "../Button/Button";

interface Props {
    username: string;
    isOpen: boolean;
    userId: string;
}

interface Actions {
    toggleProfileUnfriendConfirm: (open: boolean) => void;
    updateProfileFriendship: (friendship: Friendship | null) => void;
}

interface State {
    loading: boolean;
    signal: AbortController;
}

export default class ProfileUnfriendConfirm extends React.Component<Props & Actions, State> {

    constructor(props: Readonly<Props & Actions>) {
        super(props);

        this.state = {
            loading: false,
            signal: new AbortController()
        }
    }

    unfriend() {
        this.setState({loading: true});
        deleteFriendship(this.props.userId)
            .then(() => {
                this.actions.updateProfileFriendship(Friendship.None);
                this.actions.toggleProfileUnfriendConfirm(false);
            })
            .catch(err => {
                console.error(err);
                this.setState({loading: false});
            })
    }

    renderContent() {
        return (
            <div className={styles.Content}>
                <p className={styles.Question}>Are you sure you want to unfriend {this.props.username}?</p>
                <div className={styles.ButtonParent}>
                    <Button color={ButtonColor.Regular}
                            onClick={() => this.actions.toggleProfileUnfriendConfirm(false)}>Cancel</Button>
                    <Button color={ButtonColor.Danger} outline={true} disabled={this.state.loading}
                            onClick={() => this.unfriend()}>Unfriend</Button>
                </div>
            </div>
        )
    }

    render() {
        return (
            <CardModal title={`Unfriend ${this.props.username}?`}
                       titleClassName={styles.ModalTitle}
                       isOpen={this.props.isOpen}
                       onRequestClose={() => this.actions.toggleProfileUnfriendConfirm(false)}
                       children={this.renderContent()}/>
        )
    }

    componentWillUnmount(): void {
        this.state.signal.abort();
    }

    get actions() {
        return this.props as Actions;
    }
}
